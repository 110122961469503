<bpc-custom-modal [visible]="showComments" (onHide)="close()"  >
  <ng-container modal-header>
    <span class="p-dialog-title"
      >{{ company?.name }}
      <span class="location">{{ company?.address?.city + ", " + company?.address?.country?.name }}</span>
    </span>

    <div class="flex" style="flex-direction: column; align-items: flex-end;" *ngIf="!addCommentViewToggle">
      <!-- Score and Votes Length Row -->
      <div style="display: flex; align-items: center; justify-content: flex-end; width: 100%;">
        <div class="mr-2">
          <button 
            pButton
            *ngIf="currentChildIndex==1"
            pTooltip="Email Deal Team" 
            tooltipPosition="bottom"   
            style="background: white; border-color: #FF6C39; border-width: 1px; border-radius: 100px; width: 34.2px; height: 34.2px"
            (click)="showSendIcWorkflowEmailPopup()">
            <span class="icon-mail"></span>
          </button>
        </div>
        <div class="mr-2">
          <button
            [disabled]="loading"
            pButton
            (click)="onRefreshClick()"
            style="background: #FF6C39; border-color: #FF6C39; border-width: 1px; border-radius: 100px; width: 34.2px; height: 34.2px"
          >
              <img
              src="assets/icons/refresh.svg"
              [class.spin]="loading"
              style="width: 20px; height: 20px; fill: white;"
              alt="Refresh"
            />
          </button>
        </div>
        <div class="star-tag txt-blue w-i mr-2">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#095A70" />
            <path
              d="M8.09383 9.79434V15H6V6.11568H8.00129V7.62532H8.1054C8.30977 7.12789 8.6356 6.73265 9.08291 6.43959C9.53406 6.14653 10.0913 6 10.7545 6C11.3676 6 11.9017 6.13111 12.3567 6.39332C12.8156 6.65553 13.1703 7.03535 13.421 7.53278C13.6754 8.03021 13.8008 8.63368 13.7969 9.34319V15H11.7031V9.66709C11.7031 9.07326 11.5488 8.60861 11.2404 8.27314C10.9357 7.93766 10.5135 7.76992 9.97365 7.76992C9.60733 7.76992 9.28149 7.8509 8.99614 8.01285C8.71465 8.17095 8.49293 8.40039 8.33098 8.70116C8.17288 9.00193 8.09383 9.36632 8.09383 9.79434Z"
              fill="white"
            />
          </svg>
    
          <span class="ml-2 star-value f-14" *ngIf="!loading">
            {{ comments?.length }}
          </span>
          <span class="ml-2 star-value f-14" *ngIf="loading">
            -
          </span>
        </div>
      </div>
    </div>
  </ng-container>
  
  <ng-container modal-content >
    <section id="vote-stats-container" class="voting-modal-wrapper" *ngIf="!loading else showLoaders">
      <div class="voting-summary-container" >
        <section *ngIf="!addCommentViewToggle" id="comment-summary" style="margin-bottom: 10px;">
          <h4 (click)="toggleSummary()" style="cursor: pointer;">
            Summary (AI Generated)
            <i class="pi" style="color: #FF6C39;" [class.pi-chevron-down]="!isSummaryOpen" [class.pi-chevron-up]="isSummaryOpen"></i>
          </h4>
          <div *ngIf="isSummaryOpen" style="margin-top: 12px;">
            <div *ngIf="!getSummaryLoading else showSummaryLoaders">
              <span *ngIf="summary != null">
                {{ summary }}
              </span>
              <span *ngIf="summary == null">
                No summary generated.
              </span>
            </div>
          </div>
        </section>
      </div>
      <section id="comments-view">
        <div>
          <ng-template #showSummaryLoaders>
            <div>
              <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
            </div>
          </ng-template>

          <div class="grid grid-no-tb-gutter mt-2" *ngIf="!addCommentViewToggle">
            <div class="col list-action">
              <i class="pi pi-th-large mr-2" [class.active]="!detailedViewToggle" (click)="setDisplayToogle(false)"></i>
              <i class="pi pi-book" [class.active]="detailedViewToggle" (click)="setDisplayToogle(true)"></i>
            </div>
            <div *ngIf="detailedViewToggle">
              <span class="cursor-pointer" style="margin-right: 10px;" [ngClass]="{ 'blocked-pointer': hasUserMadeComment() }">
                <button 
                      class="p-element button-dark p-button p-component" 
                      [ngClass]="{ 'disabled-opacity blocked-pointer': hasUserMadeComment() }" 
                      (click)="toggleAddComment(true)" 
                      title="Add new comment">
                      Add Comment
                    </button>  
              </span>
            </div>
          </div>

          <section class="listview-wrapper" *ngIf="!detailedViewToggle && !addCommentViewToggle">
            <div class="grid grid-no-tb-gutter">
              <div class="col flex-w-auto" >
                <span [ngClass]="{ 'blocked-pointer': hasUserMadeComment() }">
                    <button 
                      pButton
                      class="circle-button" 
                      [ngClass]="{ 'disabled-opacity': hasUserMadeComment() }" 
                      (click)="toggleAddComment(true)" 
                      title="Add a comment">
                      +
                    </button>
                </span>
              </div>

              <div class="col flex-w-auto" *ngFor="let comment of comments; index as index;">
                <span class="cursor-pointer voting-members" [overlayPanelTooltip]="COMMENT">
                  <div class="image-container">
                    <img class="profile-image cursor-pointer"
                      [src]="comment?.user.profilePictureUrl ? comment?.user.profilePictureUrl : 'assets/no-image.png'"
                      alt="{{comment.user.name}}" />
                    <div *ngIf="isCrossIconVisible(comment)">
                      <i class="cross-icon" (click)="showDeleteCommentPopup(comment)" aria-hidden="true">&times;</i>
                    </div>
                  </div>
                </span>
                <p-overlayPanel #COMMENT>
                  <ng-template pTemplate="content">
                    <div class="flex items-center justify-between border-b w-72 pb-4 mt-2">
                      <div>
                        <div class="font-bold text-xl mb-1">{{ comment.user.name }} <i  *ngIf="comment.user.id === loggedInUserId" class="pi pi-pencil icon cursor-pointer" [pTooltip]="'Edit'" (click)="toggleAddComment(true, comment.comment, true, comment.id)"></i></div>
                        <div class="text-xs text-gray-500">{{ comment.modifyDate ?? comment.createDate | easternTime }}
                        </div>
                      </div>
                    </div>
                    <div class="w-72">
                      <div class="mb-1 vote-comment-title">Comment</div>
                      <div *ngIf="comment.comment" class="text-sm text-gray-500 listview-wrapper2">
                        <p class="comment" [ngStyle]="{'white-space': 'pre-wrap'}" [innerHTML]="processComment(comment.comment)"></p>
                        <div class="text-sm text-gray-500 listview-wrapper2" *ngIf="comment.modifyDate!=comment.createDate">(Edited)</div>
                      </div>
                      <div *ngIf="!comment.comment" class="text-sm text-gray-500 listview-wrapper">
                        No comment
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </section>

          <section class="listview-wrapper" *ngIf="detailedViewToggle && !addCommentViewToggle">
            <div class="grid grid-no-tb-gutter">
              <div class="col-12" *ngFor="let comment of comments; index as index;">
                <div class="grid grid-no-tb-gutter flex align-items-center list-view">
                  <div class="col">
                    <div class="grid grid-no-tb-gutter flex align-items-center">
                      <div class="col flex-w-auto">
                        <span class="cursor-pointer voting-members">
                          <div class="image-container">
                            <img class="profile-image cursor-pointer"
                              [src]="comment?.user.profilePictureUrl ? comment?.user.profilePictureUrl : 'assets/no-image.png'"
                              alt="{{comment.user.name}}" />
                            <div *ngIf="isCrossIconVisible(comment)">
                              <i class="cross-icon" (click)="showDeleteCommentPopup(comment)" aria-hidden="true">&times;</i>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="col flex-w-auto list-info">
                        <div>
                          <h2>{{comment.user.name}} <i  *ngIf="comment.user.id === loggedInUserId" class="pi pi-pencil icon cursor-pointer" [pTooltip]="'Edit'" (click)="toggleAddComment(true, comment.comment, true, comment.id)"></i></h2>
                        </div>
                        <div>
                          <p>
                          <span>{{ (comment.modifyDate ? comment.modifyDate : comment.createDate) | easternTime
                            }} </span>
                        </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <p class="comment" [ngStyle]="{'white-space': 'pre-wrap'}" [innerHTML]="processComment(comment.comment)">
                        <span *ngIf="comment.modifyDate !== comment.createDate">(Edited)</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="listview-wrapper add-new-comment mt-2" *ngIf="addCommentViewToggle">
            <div class="grid grid-no-tb-gutter comment-box">
              <textarea pInputTextarea class="w-100" placeholder="Write your comment here..." class="comment-input" [(ngModel)]="commentInput"></textarea>
              <div class="button-row">
                <button class="p-element p-button p-component" (click)="toggleAddComment(false)">Cancel</button>
                <button class="p-element button-dark p-button p-component" (click)="addEditComment()" [disabled]="commentInput.trim() === ''">Submit</button>
              </div>
            </div>
          </section>
        </div> 
      </section>
    </section>
  </ng-container>
  <ng-template #showLoaders>
    <div class="voting-modal-wrapper">
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="56rem" styleClass="mb-2"></p-skeleton>
    </div>
  </ng-template>
  <ng-container modal-footer *ngIf="!addCommentViewToggle">
    <div class="ng-star-inserted">
      <div style="justify-content: flex-start;">
        <!-- <button 
          [disabled]="loading"
          pButton 
          class="button-dark" 
          (click)="onRefreshClick()"
          >Refresh
        </button> -->
      </div>
      <div style="justify-content: flex-end;">
        <button (click)="close()" class="p-element p-button p-component">Close</button>
      </div>
    </div>
  </ng-container>
</bpc-custom-modal>
<bpc-delete-comment-popup [comment]="commentToRemove" [visible]="deletePopupVisible"
  [companyWorkflowCommentId]="companyWorkflowCommentId" (toggleVisible)="toggleDeleteVotePopupVisible($event)"
  (refreshComments)="refreshComments()">
</bpc-delete-comment-popup>
<bpc-generic-popup
  [visible]="genericPopupVisible"
  (toggleVisible)="toggleGenericPopupVisible($event)"
></bpc-generic-popup>