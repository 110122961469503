import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DealDropReason } from "../deal-drop-widget.component";
import { ColumnMapping } from "@core/components/dynamic-table/dynamic-table.component";

@Component({
  selector: "bpc-dropout-companies-modal",
  templateUrl: "./dropout-companies-modal.component.html",
  styleUrl: "./dropout-companies-modal.component.scss",
})
export class DropoutCompaniesModalComponent implements OnInit {
  @Input() dealDropReason: DealDropReason = {};
  @Input() showModal = false;

  @Output() onClosed = new EventEmitter<void>();

  onDialogHide() {
    // Handle dialog hide logic if needed
    this.onClosed.emit();
  }

  companies: any = [];
  companiesColumns: ColumnMapping[] = [
    { header: "Company", mappedTo: "name", case: "company" },
    { header: "City", mappedTo: "city" },
    { header: "Financials", mappedTo: "targetFinancials" },
    { header: "Coverage", mappedTo: "coverageUsers" },
    { header: "Investors", mappedTo: "investorsDetails" },
    { header: "CEO / Founder City	", mappedTo: "ceoFounderCity" },
    { header: "Stage", mappedTo: "stage" },
  ];

  ngOnInit(): void {
    // Initialize component
    const response = {
      data: [
        {
          targetFinancials: "FY23E (Mar'23): $27M of ARR (100% YoY), 82% GM, -$4M of EBITDA",
          name: "DataVisor",
          description:
            "DataVisor is a fraud detection and prevention platform that utilizes unsupervised machine learning to identify fraudulent activities.",
          investorsDetails: "saad",
          ceoFounderCity: "San Francisco-Oakland-Fremont, CA MSA",
          city: "Mountain View",
          coverageUsers: ["Matt Wappler", "Sarah Frauen", "Tim Drager"],
          stage: "IC",
          id: 4712,
        },
        {
          targetFinancials: "No financial data available",
          name: "Htec testing proxy 4a qan",
          description: "Htec testing proxy 4a qan",
          investorsDetails: "saad",
          ceoFounderCity: "karachi",
          city: "karachi",
          coverageUsers: [],
          stage: "No stage provided",
          id: 3076759,
        },
        {
          targetFinancials: "$24M ARR, 41% Growth, 40% EBITDA Margins",
          name: "Integra Scripts",
          description: "Integra Scripts is a provider of pharmacy benefit management services.",
          investorsDetails: "No investor details provided",
          ceoFounderCity: "Lakewood",
          city: "Lakewood",
          coverageUsers: ["Aaron Newman"],
          stage: "IC",
          id: 2784281,
        },
        {
          targetFinancials:
            "$84M ARR 2024 (up 42% YoY), burn $6-7M this year (-7% EBITDA margins), low 80s gross margins",
          name: "Kiddom",
          description:
            "Kiddom is a K-12 education platform that provides curriculum planning, assessment, and data analysis tools for teachers.",
          investorsDetails: "No investor details provided",
          ceoFounderCity: "San Francisco-Oakland-Fremont, CA MSA",
          city: "San Francisco, CA",
          coverageUsers: ["Sam Kentor", "Nicole Schreiber", "Tina Yuan"],
          stage: "No stage provided",
          id: 8124,
        },
        {
          targetFinancials: "14M 2022 ARR, 25M 2023E ARR, $41M 2024E ARR, Burning $10M",
          name: "LucidLink",
          description: "LucidLink is a provider of cloud storage solutions.",
          investorsDetails: "No investor details provided",
          ceoFounderCity: "San Francisco",
          city: "San Francisco",
          coverageUsers: ["Mike Gregoire", "Sam Kentor", "Tina Yuan", "Michael Hsu"],
          stage: "IC",
          id: 44981,
        },
      ],
      count: 5,
      totalCount: 5,
    };

    this.companies = response.data.map((company: any) => {
      return {
        ...company,
        name: [company.name, company.city, company.description],
        coverageUsers: company.coverageUsers.join(", "),
      };
    });
  }
}
