import { Component, Input, OnDestroy } from "@angular/core";
import { FilterType, WidgetFilter } from "@core/models/analytics-dashboard-enums";
import { AnalyticsWidgetService } from "src/app/services/analytics-widget/analytics-widget.service";
import { Subscription } from "rxjs"; // Import Subscription
import { TopIcContributers } from "@core/models/analytics-dashboard-models";
import { lastn } from "@core/models/analytics-dashboard-constants";

@Component({
  selector: "bpc-rankingwidget",
  templateUrl: "./rankingwidget.component.html",
  styleUrls: ["./rankingwidget.component.scss"],
})
export class RankingwidgetComponent {
  // Implement OnDestroy
  @Input() title!: string;
  @Input() loaders!: number | undefined;

  selectedFilters: any = [];
  filters: WidgetFilter[] = [{ filterType: FilterType.last_n, options: lastn, default: lastn[1] }];

  loading: boolean = true;

  constructor(private analyticsWidgetService: AnalyticsWidgetService) {
    this.selectedFilters = { last_n: lastn[1] };
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.analyticsWidgetService.getTopICContributers(this.selectedFilters.last_n).subscribe((data: any) => {
      if (data) {
        this.loading = false;
      }
    });
  }

  onFilterChange(selectedFilters: any) {
    this.selectedFilters = selectedFilters;
    this.getData();
  }

  getTopICContributerData() {
    return this.analyticsWidgetService.topIcContributersData.slice(0, 20);
  }

  onContributorClick(contributor: TopIcContributers) {
    this.analyticsWidgetService.loadUserIcs(contributor, this.selectedFilters.last_n);
  }
}
