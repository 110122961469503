import { Injectable } from "@angular/core";
import { CompaniesService } from "../../services/companies/companies.service";
import { map, Observable } from "rxjs";
import { HorizontalBarType } from "../components/generic-listing/generic-listing.component";
import { PodInteraction } from "../models/pod-filter";
import { interactions } from "../models/auxiliary-company-models";

@Injectable({
  providedIn: "root",
})
export class GenericListingService {
  constructor(private companiesService: CompaniesService) {}

  fetchBarData(type: HorizontalBarType, ids: number[] | undefined): Observable<any[]> {
    switch (type) {
      case HorizontalBarType.Stage:
        return this.fetchStageStats(ids);
      case HorizontalBarType.Interaction:
        return this.fetchInteractionStats(ids).pipe(
          map((data) => {
            return data.map((item) => {
              return {
                ...item,
                dispalyName: item.name,
                count: item.total,
              };
            });
          }),
        );
      default:
        return new Observable();
    }
  }

  private fetchStageStats(stageIds: number[] | undefined): Observable<any[]> {
    return this.companiesService.getCompanyStages(stageIds).pipe(
      map((pods) => {
        if (pods.data) {
          const stages = pods.data.statuses;
          return stages.reverse(); // Reverse the stages and return
        }
        return []; // Return an empty array if pods.data is undefined
      }),
    );
  }

  private fetchInteractionStats(interactionInitiatorsInclude: number[] | undefined): Observable<any[]> {
    return this.companiesService.getCompanyInteraction(interactionInitiatorsInclude).pipe(
      map((response) => {
        if (response?.data != null) {
          const data: PodInteraction = response.data;
          const int = interactions;
          Object.entries(data).forEach(([key, value]) => {
            int.forEach((interaction) => {
              if (interaction.label === key) {
                interaction.total = value;
              }
            });
          });
          return int;
        }
        return []; // Return an empty array if interactions.data is undefined
      }),
    );
  }
}
