import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Pod, PodGroupType } from "@core/models/pod-filter";
import { firmPod, myCompaniesPod, customPod, myInteractionsPod } from "@core/static/tabs";
import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "bpc-pods-dropdown",
  templateUrl: "./pods-dropdown.component.html",
  styleUrl: "./pods-dropdown.component.scss",
})
export class PodsDropdownComponent implements OnInit {
  allPods: Pod[] = [];
  activePod = {};
  isLoading = true;
  @Input() includeCompaniesPod = false;
  @Input() includeInteractionPod = false;
  @Output() onChange = new EventEmitter<Pod>();

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    if (sessionStorage.getItem("pods")) {
      this.allPods = JSON.parse(sessionStorage.getItem("pods") || "[]");
      this.activePod = this.allPods[0];
      this.isLoading = false;
      return;
    }

    this.usersService.getPods().subscribe((response) => {
      if (response.data && response.data.length) {
        let pods: Pod[] = response.data
          .filter(({ groupType }) => groupType === PodGroupType.Pod)
          .map((pod) => ({ ...pod }));
        pods = pods.map((pod) => {
          const sortedPodMembers = pod.members.sort((a: any, b: any) => {
            return b.isAdmin - a.isAdmin;
          });
          pod.name = "Pod: " + sortedPodMembers.map((member) => member.initials).join(", ");
          return pod;
        });
        this.usersService.getActiveUsers().subscribe((res) => {
          if (res.data) {
            const users = res.data;

            // Map users to the desired format
            const userPods = users.map((user) => ({
              name: user.name,
              members: [{ userId: user.id }],
            }));

            // Combine pods and user pods
            this.allPods = [
              firmPod,
              ...(this.includeCompaniesPod ? [myCompaniesPod] : []), // Include myCompaniesPod if includeCompaniesPod is true
              ...(this.includeInteractionPod ? [myInteractionsPod] : []), // Include myInteractionsPod if includeInteractionPod is true
              ...pods,
              ...userPods,
              //customPod
            ];
            this.activePod = this.allPods[0];
            this.isLoading = false;

            sessionStorage.setItem("pods", JSON.stringify(this.allPods));
          }
        });
      }
    });
  }

  onPodsChange(e: any) {
    this.onChange.emit(e.value);
  }

  resetActivePod(): void {
    this.activePod = this.allPods[0];
  }
}
