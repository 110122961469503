import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class DeviceGuard implements CanActivate {
  constructor(private deviceDetectorService: DeviceDetectorService, private router: Router) {}

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUrl = state.url; // Get the current route URL

    // Check if user is on mobile and the URL does not already contain `/m/`
    if (this.isMobile && !currentUrl.startsWith("/m/")) {
      const mobileUrl = `/m${currentUrl}`; // Prepend `/m/` to the route
      this.router.navigateByUrl(mobileUrl); // Redirect to the mobile URL
      return false; // Prevent further activation of the route
    }

    // Redirect desktop users to non-`/m/` routes if they're using `/m/`
    if (!this.isMobile && currentUrl.startsWith("/m/")) {
      const desktopUrl = currentUrl.replace("/m", "");
      this.router.navigateByUrl(desktopUrl);
      return false; // Prevent further activation
    }

    return true;
  }
}
