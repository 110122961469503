import { Pipe, PipeTransform } from "@angular/core";
import { WorkFlow } from "@models/auxiliary-company-models";
import { Nullable } from "@models/nullable";
import { getActiveOrLastICVotingWorkflow } from "../../../utils/company-utils";

@Pipe({
  name: "votingTeamMembers",
})
export class VotingTeamMembersPipe implements PipeTransform {
  transform(workflows: WorkFlow[]): Nullable<any[]> {
    //console.log(getActiveOrLastICVotingWorkflow(workflows)?.companyWorkflowUsers)
    return getActiveOrLastICVotingWorkflow(workflows)?.companyWorkflowUsers ?? null;
  }
}
