<p-dialog
  [header]="currentModalAction! | modalTitle"
  [closable]="modalClosable"
  (onHide)="resetModalItems()"
  [(visible)]="displayModal"
>
  <form #dialogForm="ngForm">
    <ng-container *ngIf="currentModalAction === 'removeFromJarvis'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to remove this company from Jarvis?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'createOrEditNotificationTrigger'">
      <div class="grid grid-no-tb-gutter" style="max-width: 480px">
        <div class="col-12 flex align-items-center justify-content-between flex-wrap mg-b-20">
          <label class="inline-label large">Time Based</label>
          <p-inputSwitch
            name="timeBasedChecked"
            onLabel="On"
            offLabel="Off"
            [(ngModel)]="modalParameters.timeBasedChecked"
          >
          </p-inputSwitch>
        </div>
        <div class="col-12 mg-b-20">
          <label class="w-100">Occurrence</label>
          <!-- <p-dropdown
            name="timeBasedTriggerType"
            timeBasedTriggerOccurrences
            styleClass="w-full"
            [disabled]="!timeBasedChecked"
            [(ngModel)]="timeBasedTriggerType"
          >
          </p-dropdown> -->
        </div>
        <div class="col-12 mg-b-20">
          <div class="grid grid-no-tb-gutter">
            <div class="col flex-w-auto">
              <p-checkbox
                name="time1"
                [disabled]="!modalParameters.timeBasedChecked"
                (onChange)="onClickRecurring()"
                [(ngModel)]="modalParameters.recurringCheckbox"
                [binary]="true"
                inputId="binary"
                label="Recurring"
                class="mr-4"
              ></p-checkbox>
            </div>
            <div class="col flex-w-auto">
              <p-checkbox
                name="time2"
                [disabled]="!modalParameters.timeBasedChecked"
                (onChange)="onClickOneTime()"
                [(ngModel)]="modalParameters.oneTimeCheckBox"
                [binary]="true"
                inputId="binary"
                label="One Time"
              ></p-checkbox>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="modalParameters.timeBasedTriggerType === 0">
          <label class="w-100">Alert on</label>
          <p-calendar
            name="triggerOn"
            styleClass="w-100"
            placeholder="Select date"
            [required]="true"
            [minDate]="modalParameters.minTriggerDate!"
            [disabled]="!modalParameters.timeBasedChecked"
            [showClear]="true"
            [(ngModel)]="modalParameters.triggerOn"
          >
          </p-calendar>
          <p class="modal-content-msg top-space" [class.invisible]="!notifyIn || notifyIn < 1">
            Notify in {{ notifyIn }} day{{ notifyIn !== 1 ? "(s)" : "" }}
          </p>
        </div>
        <div class="col-12" *ngIf="modalParameters.timeBasedTriggerType === 1">
          <div class="grid grid-no-tb-gutter">
            <div class="col-6">
              <div class="grid grid-nogutter">
                <div class="col-12">
                  <p-radioButton
                    name="recurring"
                    label="Weekly every"
                    [required]="true"
                    [value]="true"
                    [disabled]="!modalParameters.timeBasedChecked"
                    [(ngModel)]="modalParameters.recurring"
                  >
                  </p-radioButton>
                </div>
                <div class="col-12 mg-t-10">
                  <p-dropdown
                    name="recurringWeekly"
                    recurringWeekly
                    styleClass="w-100"
                    [disabled]="!(modalParameters.timeBasedChecked && modalParameters.recurring === true)"
                    [(ngModel)]="modalParameters.recurringWeekly"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="grid grid-nogutter">
                <div class="col-12">
                  <p-radioButton
                    name="recurring"
                    label="Monthly every"
                    [required]="true"
                    [value]="false"
                    [disabled]="!modalParameters.timeBasedChecked"
                    [(ngModel)]="modalParameters.recurring"
                  >
                  </p-radioButton>
                </div>
                <div class="col-12" *ngIf="modalParameters.recurringMonthly! > 28">
                  <p class="modal-content-msg">
                    If a month doesn't have {{ modalParameters.recurringMonthly }} days, the notification will arrive on
                    the last day of the month
                  </p>
                </div>
                <div class="col-12 mg-t-10">
                  <p-dropdown
                    name="recurringMonthly"
                    recurringMonthly
                    styleClass="w-100"
                    [disabled]="!(modalParameters.timeBasedChecked && modalParameters.recurring === false)"
                    [(ngModel)]="modalParameters.recurringMonthly"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mg-tb-30">
          <hr class="hr" />
        </div>
        <div class="col-12 mg-b-20">
          <label class="inline-label large">Data Based</label>
        </div>
        <div class="col-12 mg-b-10">
          <div class="grid grid-no-tb-gutter">
            <div class="col-4 flex align-items-center">
              <label>By FTE</label>
            </div>
            <div class="col-3">
              <p-dropdown
                name="fteOperator"
                styleClass="w-100"
                dataBasedTriggerComparisonValues
                [(ngModel)]="modalParameters.fteTrigger!.operator"
              >
              </p-dropdown>
            </div>
            <div class="col-5">
              <p-inputNumber
                updateOnChange
                name="fteValue"
                [required]="true"
                [(ngModel)]="modalParameters.fteTrigger!.value"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
        <div class="col-12 mg-b-10">
          <div class="grid grid-no-tb-gutter">
            <div class="col-4 flex align-items-center">
              <label>By FTE growth %</label>
            </div>
            <div class="col-3">
              <p-dropdown
                name="fteGrowthOperator"
                styleClass="w-100"
                dataBasedTriggerComparisonValues
                [(ngModel)]="modalParameters.fteGrowthTrigger!.operator"
              >
              </p-dropdown>
            </div>
            <div class="col-5">
              <p-inputNumber
                updateOnChange
                name="fteGrowthValue"
                [required]="true"
                [(ngModel)]="modalParameters.fteGrowthTrigger!.value"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
        <div class="col-12 mg-b-10">
          <div class="grid grid-no-tb-gutter">
            <div class="col-4 flex align-items-center">
              <label>By PIC</label>
            </div>
            <div class="col-3">
              <p-dropdown
                name="picOperator"
                styleClass="w-100"
                dataBasedTriggerComparisonValues
                [(ngModel)]="modalParameters.picTrigger!.operator"
              >
              </p-dropdown>
            </div>
            <div class="col-5">
              <p-inputNumber
                updateOnChange
                name="picValue"
                [required]="true"
                [(ngModel)]="modalParameters.picTrigger!.value"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
        <div class="col-12 mg-b-10">
          <div class="grid grid-no-tb-gutter">
            <div class="col-4 flex align-items-center">
              <label>By revenue</label>
            </div>
            <div class="col-3">
              <p-dropdown
                name="revenueOperator"
                styleClass="w-100"
                dataBasedTriggerComparisonValues
                [(ngModel)]="modalParameters.revenueTrigger!.operator"
              >
              </p-dropdown>
            </div>
            <div class="col-5">
              <p-inputNumber
                updateOnChange
                name="revenueValue"
                [required]="true"
                [(ngModel)]="modalParameters.revenueTrigger!.value"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'actionTaken'">
      <div class="grid">
        <div class="col-12">
          <p-radioButton
            class="w-full"
            name="actionTaken"
            label="Had a meeting"
            [value]="1"
            [(ngModel)]="modalParameters.actionTaken"
          >
          </p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton
            class="w-full"
            name="actionTaken"
            label="Had a phone call"
            [value]="2"
            [(ngModel)]="modalParameters.actionTaken"
          >
          </p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton
            class="w-full"
            name="actionTaken"
            label="Sent an email"
            [value]="3"
            [(ngModel)]="modalParameters.actionTaken"
          >
          </p-radioButton>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Title</label>
          <input class="w-100" pInputText id="search-conn" class="w-full" [(ngModel)]="modalParameters.title" />
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Please explain</label>
          <p-editor [style]="{ height: '8rem' }" [(ngModel)]="modalParameters.comment">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button class="ql-bold" aria-label="Bold" type="button"></button>
                <button class="ql-italic" aria-label="Italic" type="button"></button>
                <button class="ql-underline" aria-label="Underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" aria-label="Ordered List" type="button"></button>
                <button class="ql-list" value="bullet" aria-label="Unordered List" type="button"></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'editICAsk'">
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Please write a short description</label>
          <textarea
            pInputTextarea
            class="w-100"
            placeholder="What is your IC Ask?"
            rows="3"
            maxlength="500"
            [autoResize]="true"
            [(ngModel)]="modalParameters.comment"
            name="comment"
          >
          </textarea>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'removeFromIC'">
      <div class="grid">
        <div class="col-12">
          <p>This company will be removed. Are you sure?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'closeCompanyDetails'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">
            Are you sure that you want to close Company Details? All unsaved changes will be lost.
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'markAsActive'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to activate this company in Jarvis?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'leaveCoverage'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to release this company?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'joinCoverage'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to add this company into your pipeline list?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'changeStatus'">
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Select new status</label>
          <p-dropdown
            optionValue="id"
            optionLabel="label"
            styleClass="w-100"
            [options]="changeCompanyStatusDropdownOptions"
            name="newStatusId"
            [(ngModel)]="newStatusId"
            optionDisabled="inactive"
          >
          </p-dropdown>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'editInvestors'">
      <div class="grid">
        <div class="col-12">
          <textarea
            pInputTextarea
            pInputText
            placeholder="Investors"
            class="w-100"
            rows="4"
            [(ngModel)]="modalParameters.investors"
            name="investors"
            style="min-width: 350px"
            required
          ></textarea>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'flagForICVoting'">
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Check size</label>
          <input
            pInputText
            [(ngModel)]="modalParameters.checkSize"
            required
            name="checkSize"
            [value]="modalParameters.checkSize"
            class="w-100"
            placeholder="Check size"
            type="tel"
            oninput="this.value=(parseInt(this.value)||0)"
            onkeypress="if(this.value.length==3) return false; return !(event.charCode == 45||event.charCode == 46||event.charCode == 43)"
            id="checkSize"
          />
        </div>
        <div class="col-12">
          <label class="w-100">IC Ask</label>
          <textarea
            pInputTextarea
            class="w-100"
            placeholder="IC Ask"
            rows="3"
            [autoResize]="true"
            [(ngModel)]="modalParameters.comment"
            name="comment"
            required
          ></textarea>
        </div>
        <div class="col-12">
          <bpc-custom-dropdown-search
          class="w-full"
          [values]="modalParameters.teamMembers"
          [type]="dropdownTypes.teamMembers"
          [runtimeSearch]="false"
          [label]="'Deal Team'"
          (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.teamMembers)"
          (setFormControl)="setFormControlValue($event, dropdownTypes.teamMembers)"
        ></bpc-custom-dropdown-search>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'addToPortfolio'">
      <div class="grid">
        <div class="col-12">
          <bpc-custom-dropdown-search
            class="custom-dropdown-width"
            [values]="modalParameters.teamMembers"
            [type]="dropdownTypes.teamMembers"
            [runtimeSearch]="false"
            [label]="'Deal Team'"
            (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.teamMembers)"
            (setFormControl)="setFormControlValue($event, dropdownTypes.teamMembers)"
          ></bpc-custom-dropdown-search>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'editNextStep'">
      <div class="grid">
        <div class="col-12">
          <label class="w-100"> Next steps </label>
          <textarea
            pInputTextarea
            pInputText
            [(ngModel)]="modalParameters.nextStep"
            name="editNextStep"
            [value]="modalParameters.nextStep"
            class="w-100"
            rows="4"
            placeholder="Next steps"
            id="editNextStep"
            style="min-width: 350px"
          >
          </textarea>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'icVoting'">
      <div class="grid grid-nogutter mx-500">
        <div class="col-12 stars-row flex-w-auto">
          <button
            *ngFor="let vote of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            [disabled]="vote === 7"
            (click)="submitICVote(vote)"
            class="btn-star {{ getVotingClass(vote) }} {{ getVotingTextClass(vote) }}"
            [ngClass]="{ 'bg-daintree opacity-full-selected': modalParameters.icVote === vote }"
          >
            <!--           
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M30.9217 12.3366C30.7254 11.7028 30.1866 11.2525 29.5491 11.1926L20.8903 10.3722L17.4664 2.00959C17.2139 1.39672 16.6389 1 16.0001 1C15.3613 1 14.7863 1.39672 14.5339 2.01102L11.1099 10.3722L2.44977 11.1926C1.81346 11.254 1.27603 11.7028 1.0785 12.3366C0.880975 12.9705 1.0634 13.6658 1.54475 14.1041L8.08978 20.0938L6.1598 28.9651C6.01858 29.6173 6.2612 30.2916 6.77986 30.6828C7.05864 30.893 7.38481 31 7.71372 31C7.99731 31 8.27861 30.9202 8.53107 30.7626L16.0001 26.1045L23.4664 30.7626C24.0128 31.1056 24.7015 31.0743 25.219 30.6828C25.7379 30.2904 25.9803 29.6159 25.8391 28.9651L23.9091 20.0938L30.4541 14.1053C30.9355 13.6658 31.1193 12.9717 30.9217 12.3366Z"
                stroke="#F13E3E"
              />
            </svg> -->

            <span class="star-value"> {{ vote }}</span>
          </button>
        </div>
        <div class="col-12 text-area flex-w-auto">
          <label class="w-100">Write Comments</label>
          <textarea
            pInputTextarea
            class="w-100"
            placeholder="Please write a comment"
            rows="3"
            maxlength="500"
            [autoResize]="true"
            name="cooment"
            [(ngModel)]="modalParameters.comment"
          >
          </textarea>
        </div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="currentModalAction === 'icVoting'">
      <div class="flex justify-between mb-10">
        <div *ngFor="let vote of [1,2,3,4,5,6,7,8,9,10]" (click)="submitICVote(vote)"
          class="h-8 w-8 bg-gray-300 text-center p-1 rounded-full cursor-pointer opacity-low {{getVotingClass(vote)}} {{getVotingTextClass(vote)}}"
          [ngClass]="{'bg-daintree opacity-full': icVote === vote}">
          {{ vote }}
        </div>
      </div>
      <div>
        <textarea pInputTextarea class="w-full min-h-[3rem] p-1" placeholder="Please write a comment" rows="2"
          maxlength="500" [autoResize]="true" name="cooment" [(ngModel)]="comment">
      </textarea>
      </div>
    </ng-container> -->
    <ng-container *ngIf="currentModalAction === 'editICDateAdded'">
      <div class="grid">
        <div class="col-12">
          <label class="w-100">Select date</label>
          <p-calendar
            name="ICDateAdded"
            styleClass="w-100"
            placeholder="Select date"
            [required]="true"
            [showClear]="true"
            [(ngModel)]="modalParameters.dateAdded"
          >
          </p-calendar>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'editOutReachOwner'">
      <!-- 
      <p-autoComplete name="editOutReachOwner" owners styleClass="coverage-users outreach-owner" [multiple]="false"
        [(ngModel)]="outreachOwner" placeholder="Select outreach owner" [showClear]="true">
      </p-autoComplete> -->
      <div class="grid">
        <div class="col-12">
          <label class="w-100"> Outreach owner </label>

          <p-dropdown
            [options]="users"
            [(ngModel)]="modalParameters.outreachOwner"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="Select outreach owner"
            name="outreachOwner"
            class="p-drop-down-outreachowners w-100"
          >
            <ng-template pTemplate="selectedItem">
              <div class="country-item country-item-value" *ngIf="modalParameters.outreachOwner">
                <div>{{ modalParameters.outreachOwner.name }}</div>
              </div>
            </ng-template>
            <ng-template let-outreact pTemplate="item">
              <div class="country-item">
                <div>{{ outreact.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'removeSavedSearch'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to remove this Saved Search?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'removeCronJob'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to remove this Cron Job?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'extendPriority'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">Are you sure that you want to extend the priority time?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'editCoverage'">
      <div class="grid grid-nogutter">
        <bpc-custom-dropdown-search
          class="w-full"
          [values]="modalParameters.coverageUsers"
          [type]="dropdownTypes.coveragePersonInclude"
          [runtimeSearch]="false"
          [label]="'Coverage'"
          (removeDropdownItem)="removeDropdownValue($event, dropdownTypes.companyLocation)"
          (setFormControl)="setFormControlValue($event, dropdownTypes.companyLocation)"
        ></bpc-custom-dropdown-search>
        <!-- <div class="col-12">
            <label class="w-100">Please explain the reason</label>
            <textarea
              pInputTextarea
              name="editCoverageReason"
              class="w-100"
              placeholder="Why are you editing coverage to these persons?"
              rows="3"
              [autoResize]="true"
              [(ngModel)]="modalParameters.comment"
            >
            </textarea>
          </div> -->
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'enrichData'">
      <div class="grid">
        <div class="col-12">
          <p class="modal-content-msg">
            Marking this company for data enrichment means that an offshore analyst will add BPC Partners’ 1st and 2nd
            degree LinkedIn relationships for the people listed in the ‘Contacts’ section.  Have you edited the contacts
            list to make sure it only contains the most relevant people for breaking into the company (typically the
            CEO, CFO, Founder, Investors and/or Board members)?
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'updateCronJob'">
      <section style="max-width: 400px">
        <div class="grid grid-nogutter">
          <div class="col-12">
            <label class="w-100">Cron Job Name</label>
          </div>
          <div class="col-12">
            <p>
              {{ modalParameters.selectedJobId }}
            </p>
          </div>
          <div class="col-12" style="margin-top: 15px">
            <label class="w-100">Frequency</label>
          </div>
          <div class="col-4">
            <p-radioButton
              name="frequency"
              label="Daily"
              [value]="frequencyType.Daily"
              [(ngModel)]="modalParameters.frequency"
            >
            </p-radioButton>
          </div>
          <div class="col-4">
            <p-radioButton
              name="frequency"
              label="Weekly"
              [value]="frequencyType.Weekly"
              [(ngModel)]="modalParameters.frequency"
            >
            </p-radioButton>
          </div>
          <div class="col-4">
            <p-radioButton
              name="frequency"
              label="Monthly"
              [value]="frequencyType.Monthly"
              [(ngModel)]="modalParameters.frequency"
            >
            </p-radioButton>
          </div>
          <div class="col-12" style="margin-top: 15px" *ngIf="modalParameters.frequency === frequencyType.Weekly">
            <div style="margin-bottom: 10px">
              <label>Day of Week</label>
            </div>
            <div>
              <p-dropdown
                name="recurringWeekly"
                recurringWeekly
                [(ngModel)]="modalParameters.selectedDayOfWeek"
                [ngModelOptions]="{ standalone: true }"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-12" style="margin-top: 15px" *ngIf="modalParameters.frequency === frequencyType.Monthly">
            <div style="margin-bottom: 10px">
              <label>Day of Month</label>
            </div>
            <div>
              <p-dropdown
                name="recurringMonthly"
                recurringMonthly
                [(ngModel)]="modalParameters.selectedDayOfMonth"
                [ngModelOptions]="{ standalone: true }"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-12" style="margin: 15px 0 10px 0">
            <label>Time of Day</label>
          </div>
          <div class="col-12">
            <p-calendar
              *ngIf="displayModal"
              [timeOnly]="true"
              [(ngModel)]="modalParameters.selectedTime"
              [ngModelOptions]="{ standalone: true }"
            >
            </p-calendar>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="(currentModalAction === 'EditUser' || currentModalAction === 'AddUser') && displayModal">
      <section class="user-modal">
        <div class="grid-no-tb-gutter">
          <div class="grid flex flex-w-auto">
            <div [ngClass]="showEditButton ? 'hide-image' : 'col-8'">
              <label class="inline-label col-12 mg-b-12 required">Name <sup class="text-red">*</sup></label>
              <div class="col-12">
                <input [(ngModel)]="modalParameters.name" (input)="generateInitials()" required class="w-100" id="name" placeholder="Name" name="nameInput" pInputText #nameInput="ngModel"/>
                <small class="p-error block error">
                  <ng-container *ngIf="!modalParameters.name && nameInput.touched">
                    Name is required
                  </ng-container>
                  &nbsp;
                </small>
              </div>
              <label class="inline-label col-12 mg-b-12 required">Initials <sup class="text-red">*</sup></label>
              <div class="col-12">
                <input [(ngModel)]="modalParameters.initials" required maxlength="3" class="w-100" id="initials" placeholder="Initials" name="initialsInput" pInputText #initialsInput="ngModel"/>
                <small class="p-error block error">
                  <ng-container *ngIf="!modalParameters.name && nameInput.touched">
                    Initials are required
                  </ng-container>
                  &nbsp;
                </small>
              </div>
              <label class="inline-label col-12 mg-b-12">Email <sup class="text-red">*</sup></label>
              <div class="col-12">
                <input 
                  required
                  [(ngModel)]="modalParameters.email" 
                  [disabled]="currentModalAction === 'EditUser'" 
                  class="w-100" 
                  id="email" 
                  placeholder="Email" 
                  name="emailInput" 
                  #emailInput="ngModel" 
                  type="email" 
                  pInputText
                  email />
                <small class="p-error block error">
                  <ng-container *ngIf="!modalParameters.email && emailInput.touched">
                    Email is required
                  </ng-container>
                  <small *ngIf="emailInput.errors?.['email']">Not a valid Email</small>
                  &nbsp;
                </small>
              </div>
              <!-- <label class="inline-label col-12 mg-b-12">DealCloud ID <sup class="text-red">*</sup></label> -->               
              <label class="inline-label col-12 mg-b-12">DealCloud ID</label>
              <div class="col-12">
                <input 
                [(ngModel)]="modalParameters.externalId" 
                class="w-100" 
                id="dealcloud-id" 
                placeholder="DealCloud ID" 
                pInputText 
                name="externalIdInput"
                pattern="[0-9]*" 
                #externalIdInput="ngModel"/>
                <small class="p-error block error">
                  <!-- <ng-container *ngIf="!modalParameters.externalId && externalIdInput.touched">
                    DealCloud ID is required
                  </ng-container> -->
                  <ng-container *ngIf="modalParameters.externalId && externalIdInput.errors?.['pattern']">
                    Invalid ID. Please enter valid number
                  </ng-container>
                  &nbsp;
                </small>
              </div>
            </div>
            <div [ngClass]="showEditButton ? 'col-12' : 'col-4 row-9 image-section'">
              <bpc-image-uploader
                *ngIf="displayModal"
                [base64Image]="modalParameters.base64Image!"
                [showSaveButton]="showSaveButton"
                [showEditButton]="showEditButton"
                (toggleEditButton)="toggleEditButton($event)"
                (toggleSaveButton)="toggleSaveButton($event)"
                (toggleImageLoading)="toggleImageLoading($event)"
                (base64ImageEmitter)="handleBase64Image($event)"
                (profileImageFileEmitter)="handleProfileImageFile($event)"
              >
              </bpc-image-uploader>
            </div>
          </div>
          <div [ngClass]="showEditButton ? 'hide-image' : 'col-12'">
            <bpc-custom-dropdown-search
              *ngIf="displayModal"
              class="w-full"
              [values]="modalParameters.userRoles"
              [type]="dropdownTypes.userRoles"
              [label]="'Roles'"
              [required]="true"
              [runtimeSearch]="false"
              (removeDropdownItem)="removeRoleDropdownValue($event, dropdownTypes.userRoles)"
              (setFormControl)="setRoleFormControlValue($event, dropdownTypes.userRoles)"
            ></bpc-custom-dropdown-search>
          </div>
          <div [ngClass]="showEditButton ? 'hide-image' : 'col-12'">
            <bpc-custom-dropdown-search
              *ngIf="displayModal"
              class="w-full"
              [values]="filterUserGroups()"
              [type]="dropdownTypes.userGroups"
              [label]="'Groups'"
              [runtimeSearch]="false"
              (removeDropdownItem)="removeGroupsDropdownValue($event, dropdownTypes.userGroups)"
              (setFormControl)="setGroupsFormControlValue($event, dropdownTypes.userGroups)"
            ></bpc-custom-dropdown-search>
          </div>
          <div [ngClass]="showEditButton ? 'hide-image' : 'col-12'">
            <bpc-custom-dropdown-search
              *ngIf="displayModal"
              class="w-full"
              [values]="filterUserPods()"
              [type]="dropdownTypes.userPods"
              [label]="'Pods'"
              [runtimeSearch]="false"
              (removeDropdownItem)="removePodsDropdownValue($event, dropdownTypes.userPods)"
              (setFormControl)="setPodsFormControlValue($event, dropdownTypes.userPods)"
            ></bpc-custom-dropdown-search>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'UserActiveToggle' || currentModalAction === 'UserInactiveToggle'">
      <div class="grid">
        <div class="col-12">
          <p *ngIf="currentModalAction === 'UserActiveToggle'">Are you sure you want to activate this user?</p>
          <p *ngIf="currentModalAction === 'UserInactiveToggle'">Are you sure you want to deactivate this user?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'DeleteChatThread'">
      <div class="grid">
        <div class="col-12">
          <p>Are you sure you want to delete this conversation thread?</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentModalAction === 'UpdateChatThread'">
      <div class="grid">
        <div class="col-12">
          <input [(ngModel)]="modalParameters.title" required class="w-100" id="name" placeholder="Name" name="nameInput" pInputText #nameInput="ngModel"/>
        </div>
      </div>
    </ng-container>
  </form>

  <ng-template pTemplate="footer">
    <div *ngIf="currentModalAction === 'createOrEditNotificationTrigger'">
      <a class="lbl-clear-filter mgr-0" (click)="resetNotificationTriggers()">Clear All</a>
      <div class="grid grid-no-tb-gutter">
        <div class="col">
          <button
            pButton
            (click)="onModalNegativeButtonClick()"
            label="  {{ currentModalAction! | negativeButtonLabel }}"
          ></button>
        </div>
        <div class="col">
          <button
            pButton
            class="button-dark"
            type="submit"
            (click)="onModalPositiveButtonClick()"
            [loading]="modalLoading"
            [disabled]="
              positiveButtonDisabled || (modalParameters.oneTimeCheckBox && modalParameters.triggerOn === null)
            "
            label="{{ currentModalAction! | positiveButtonLabel }}"
          ></button>
        </div>
      </div>
    </div>

    <div *ngIf="currentModalAction === 'enrichData'">
      <button
        pButton
        (click)="onModalNegativeButtonClick()"
        label="{{ currentModalAction! | negativeButtonLabel }}"
      ></button>
      <button
        pButton
        class="button-dark"
        type="submit"
        (click)="onModalPositiveButtonClick()"
        [disabled]="positiveButtonDisabled || dialogForm.invalid"
        [loading]="modalLoading"
        label=" {{ currentModalAction! | positiveButtonLabel }}"
      ></button>
    </div>

    <div *ngIf="currentModalAction !== 'enrichData' && currentModalAction !== 'createOrEditNotificationTrigger'">
      <button
        pButton
        *ngIf="!showEditButton"
        (click)="onModalNegativeButtonClick()"
        label=" {{ currentModalAction! | negativeButtonLabel }}"
      ></button>
      <button
        pButton
        *ngIf="!showEditButton"
        class="button-dark"
        type="submit"
        (click)="onModalPositiveButtonClick()"
        [disabled]="positiveButtonDisabled || dialogForm.invalid || ((currentModalAction === 'EditUser' || currentModalAction === 'AddUser') && (!(modalParameters!.userRoles!.length > 0)||imageLoading))"
        [loading]="modalLoading"
        label=" {{ currentModalAction! | positiveButtonLabel }}"
      ></button>
    </div>
  </ng-template>
</p-dialog>