import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { BasicCompany, Company } from '@models/company';
import { AuthService } from '@core/auth/auth.service';
import { CONVERT_TO_ARRAY_ITEMS } from '@core/services/filter-helper.service';
import { WorkFlow } from '@core/models/auxiliary-company-models';

@Injectable({
  providedIn: 'root'
})
export class WorkflowsCommentsService extends BaseApiService<Company> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/WorkflowsComments', authService, router, messageService);
  }

  getWorkflow(companyWorkFlowId: number): Observable<any> {
    return this.get(`/${companyWorkFlowId}`);
  }

  deleteWorkflowComment(companyWofklowCommentId: number): Observable<any> {
    return this.delete(`/${companyWofklowCommentId}`);
  }

  addWorkflowComment(companyWorkFlowId: number, comment: string): Observable<any>{
    return this.post('/', null, {comment: comment, companyWorkFlowId: companyWorkFlowId});
  }

  updateWorkflowComment(companyWorkflowCommentId: number, comment: string): Observable<any>{
    return this.put(`/${companyWorkflowCommentId}`, null, {comment: comment});
  }

}