import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { BaseApiService } from 'src/app/api/base';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AIPrepRequestBody } from '@core/components/ai-prep-report/ai-prep-report.component';
import { Vote } from '@core/components/voting-stats/voting-stats.component';
import { Prompt } from '@core/models/types';

export type AIPrepCompany = {
  company: string;
  domain: string;
  mainMarket: string;
  subMarket: string;
}

export type LoadConversationType =
  | "rename"
  | "delete"
  | null;

@Injectable({
  providedIn: 'root'
})
export class AIPrepService {

  baseApiUrl = environment.aiPrepApiUrl;
  public aiPrepConversationSubject = new Subject<LoadConversationType>();


  constructor(private authService: AuthService, private router: Router, private messageService: MessageService, private http: HttpClient,) {
  }

  getCompanyNews(query: AIPrepCompany) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    const url = `${this.baseApiUrl}/get_company_news?company=${query.company}&domain=${query.domain}&mainMarket=${query.mainMarket}&subMarket=${query.subMarket}`;
    return this.http.get(url, { headers });
  }

  getCompanyDescription(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_company_description`, body, { headers });
  }

  getJarvisInfo(domain: string) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    const options = { headers: headers };
    return this.http.get(`https://qa.jarvis.bpc.com/api/companies/domain/${domain}`, options).pipe(
      switchMap((res: any) => {
        const id = res.id;
        return this.http.get(`https://qa.jarvis.bpc.com/api/companies/${id}/details`, options);
      })
    );
  }

  getProductOverview(query: AIPrepCompany) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/get_product_overview?company=${query.company}&domain=${query.domain}&mainMarket=${query.mainMarket}&subMarket=${query.subMarket}`, { headers });
  }

  getMarketOverview(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_market_overview`, body, { headers });
  }

  getKeyCompetitors(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_key_competitors`, body, { headers });
  }

  getInvestmentThesis(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_investment_thesis`, body, { headers });
  }

  getDisInvestmentThesis(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_disinvestment_thesis`, body, { headers });
  }

  getKeyRisks(query: AIPrepCompany) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/get_key_risks?company=${query.company}&domain=${query.domain}&mainMarket=${query.mainMarket}&subMarket=${query.subMarket}`, { headers });
  }

  getQuestionsForCEO(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_questions_for_ceo`, body, { headers });
  }

  getRecentNews(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_bing_news`, body, { headers });
  }

  getProducts(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_product_info`, body, { headers });
  }

  getLeadership(body: AIPrepRequestBody) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_leadership_info`, body, { headers });
  }

  generatePDF(body: any) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/generate_pdf`, body, { headers, observe: 'response', responseType: 'blob' });
  }


  uploadToAIPrep(file: FileList) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });

    // Create form data
    const formData = new FormData();

    // loop over file array and add them into form data
    // Angular 7 || 8
    for (let i = 0; i < file.length; i++) {
      formData.append("files", file[i], file[i].name);
    }

    formData.append('username', "default-user");


    // Make http post request over api
    // with formData as req
    return this.http.post(`${this.baseApiUrl}/upload_documents_to_vdb`, formData, { headers });
  }

  uploadToVectorDb(ltmUploadChecked: boolean) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    const formData = new FormData();
    formData.append("ltmUploadChecked", ltmUploadChecked.toString());
    formData.append('username', "default-user");

    return this.http.post(`${this.baseApiUrl}/write_tempdir_to_vector_db`, formData, { headers });
  }

  checkForTransientQA() {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/get_has_transient_qa_chain`, { headers });
  }

  getChatConversations() {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/v2/conversations`, { headers });
  }

  askQuery(body: {
    query: string,
    thread_id: string,
    file_ids?: string[]
  }) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return fetch(`${this.baseApiUrl}/v2/ask_query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user?.token}`
      },
      body: JSON.stringify(body)
    });
  };

  getConversationsByThreadId(threadId: string) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/v2/conversation/${threadId}`, { headers });
  }

  deleteConversationsByThreadId(threadId: string): Observable<any> {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.delete(`${this.baseApiUrl}/v2/conversation/${threadId}`, { headers });
  }

  getUploadedFileIds(body: any) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/upload_files`, body, { headers });
  }

  updateThreadTitle(threadId: string, payload: { title: string }): Observable<any> {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.patch(`${this.baseApiUrl}/v2/conversation/${threadId}`, payload, { headers });
  }

  getVotingSummary(votes: Vote[], companyName: string, groupType: string) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    const body = {
      votes: votes.map((vote) => {
        return {
          name: vote.name,
          score: vote.score,
          comment: vote.comment
        }
      }),
      company: companyName,
      group:  groupType
    };
    return this.http.post<any>(`${this.baseApiUrl}/v2/get_voting_summary`, body, { headers });
  }

  getExtensionPrompt(type_id : Prompt) {
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.get(`${this.baseApiUrl}/v2/get_prompt/${type_id + 1}`, { headers });
  }

  getCommentsSummary(comments: any[]): Observable<any>{
    const user = this.authService.user.getValue();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user?.token}`
    });
    return this.http.post(`${this.baseApiUrl}/v2/get_ic_comments_summary`, {comments: comments}, {headers});
  }
}
