  <div *ngIf="currentTab.type === tabType.UserPanel" class="grid grid-no-tb-gutter flex justify-content-end align-items-center pb-2 pr-3">
    <p-checkbox label="Show All Users" [(ngModel)]="showAllUsers"></p-checkbox>
    <button style="margin-left: 25px" class="button-dark" type="button" pButton (click)="addOrEditUser()">
      Add User
    </button>
  </div>

  <p-table
    #dataTable
    *ngIf="i === currentTabIndex || (isVotingTab && currentTabData.length)"
    [styleClass]="tableClass + ' ' + currentTab?.tableClass || ''"
    [rowTrackBy]="trackBy"
    [value]="currentTabData"
    [(selection)]="selectedItems"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <bpc-grid-loader-line [loading]="loading"></bpc-grid-loader-line>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th pFrozenColumn *ngIf="column.label.includes('Company')">
            <span class="whitespace-nowrap">
              <span
                *ngIf="column.tooltip"
                [overlayPanelTooltip]="column.tooltip === 'score' ? scoreTooltip : tableHeaderTooltip"
                >{{ column.label }}</span
              >
              <span *ngIf="column.label.includes('Company') && !isTablet">
                <p-tableHeaderCheckbox
                  (click)="onSelection()"
                  *ngIf="currentTab.enableDownload"
                  class="th-checkbox"
                ></p-tableHeaderCheckbox>
                &nbsp;
              </span>
              <span *ngIf="!column.tooltip">{{ column.label }}</span>
              <p-overlayPanel #tableHeaderTooltip>
                <span class="text-xs">
                  {{ column.tooltip }}
                </span>
              </p-overlayPanel>
              <p-overlayPanel #scoreTooltip>
                <bpc-company-score></bpc-company-score>
              </p-overlayPanel>
              <span
                *ngIf="column.sortProperty"
                class="fa-solid cursor-pointer ml-2 text-xs"
                (click)="onColumnSortClick(column)"
                [ngClass]="getSortClass(column)"
              >
              </span>
            </span>
          </th>
          <th *ngIf="!column.label.includes('Company')">
            <span class="whitespace-nowrap">
              <span
                *ngIf="column.tooltip"
                [overlayPanelTooltip]="column.tooltip === 'score' ? scoreTooltip : tableHeaderTooltip"
                >{{ column.label }}</span
              >
              <span *ngIf="!column.tooltip">{{ column.label }}</span>
              <p-overlayPanel #tableHeaderTooltip>
                <span class="text-xs">
                  {{ column.tooltip }}
                </span>
              </p-overlayPanel>
              <p-overlayPanel #scoreTooltip>
                <bpc-company-score></bpc-company-score>
              </p-overlayPanel>
              <span
                *ngIf="column.sortProperty"
                class="fa-solid cursor-pointer ml-2 text-xs"
                (click)="onColumnSortClick(column)"
                [ngClass]="getSortClass(column)"
              >
              </span>
            </span>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [ngClass]="companyDetails && companyDetails.id !== company.id ? 'grid-selected-row' : ''">
        <ng-container *ngFor="let column of columns">
          <td pFrozenColumn *ngIf="column.label.includes('Company')">
            <div class="name-wrap">
              <div class="social-wrap flex">
                <p-tableCheckbox
                  (click)="onSelection()"
                  class="social-link"
                  *ngIf="currentTab.enableDownload && !isTablet"
                  [value]="company"
                ></p-tableCheckbox>
                <bpc-social-icon
                *ngFor="let link of company | socialLinks"
                class="social-links"
                style="margin-left: 4px;"
                [socialLink]="link"
                (urlClick)="socialUrlClick($event)"
                >
                </bpc-social-icon>
              </div>
              <div class="company-info" *ngIf="availableGridColumns.includes('Company')">
                <a
                  class="company-link"
                  [routerLink]="company | companyDetailsUrl"
                  [overlayPanelTooltip]="companyDescriptionPanel"
                >
                  {{ company.name }}
                </a>
                <span *ngIf="company.address" class="company-address">
                  {{ company.address.city }}
                  <span *ngIf="company.address.country.code !== 'us'">, {{ company.address.country.name }}</span>
                </span>
                <p-overlayPanel #companyDescriptionPanel>
                  <div [innerHTML]="company.description ? company.description : 'No description available'"></div>
                </p-overlayPanel>
              </div>
            </div>
          </td>
          <td *ngIf="!column.label.includes('Company')">
            <ng-template [ngIf]="column.label.includes('FTE')">
              <div class="w-full">
                <span>{{ company.fullTimeEquivalent | bpcNumber : "1.0-0" : "" }}</span>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Growth%')">
              <img *ngIf="company.fullTimeEquivalentGrowth > 0" src="assets/svg/growth-up-icon.svg" />
              <img *ngIf="company.fullTimeEquivalentGrowth < 0" src="assets/svg/growth-down-icon.svg" />
              &nbsp; {{ company.fullTimeEquivalentGrowth || 0 }}%
            </ng-template>

            <ng-template [ngIf]="column.label.includes('PIC')">
              <!-- [overlayPanelTooltip]="picDescriptionPanel" -->
              <span>
                {{ company.paidInCapital !== null ? (company.paidInCapital | bpcNumber : "1.0-1" : "$") : "-" }}
              </span>
              <p-overlayPanel #picDescriptionPanel>
                <ng-container
                  *ngIf="
                    company?.latestRound?.amount || company?.latestRound?.raiseDate || company?.investors?.length > 0;
                    else noPICData
                  "
                >
                  <div style="margin-bottom: 5px !important">
                    <p *ngIf="company?.latestRound?.amount" style="font-weight: 800">Latest Round</p>
                    <span class="mb-2">{{ company.latestRound?.amount | bpcNumber : "1.0-1" : "$" }}</span>
                    <p *ngIf="company?.latestRound?.raiseDate" style="font-weight: 800">Latest Round Date</p>
                    <span class="mb-2">{{ company.latestRound?.raiseDate | date : "dd MMM y" }}</span>
                  </div>
                  <div *ngIf="company?.investors?.length > 0">
                    <p style="font-weight: 800">Investors</p>
                    <div class="grid cursor-pointer" *ngFor="let investor of extractInvestors(company.investors)">
                      <div class="col" (click)="setInvestorFilter(investor)">{{ investor.name }}</div>
                      <div class="col" *ngIf="investorRole(investor.dominantRole)">
                        {{ investorRole(investor.dominantRole) }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noPICData> No data available </ng-template>
              </p-overlayPanel>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Financials')" class="xl:table-cell md:table-cell max-w-[200px]">
              <div class="w-full">
                <span class="md:text-xs lg:text-sm">
                  {{ company.targetFinancials }}
                </span>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Latest round')">
              <div class="block text-black" *ngIf="company.latestRound">
                <span>{{ company.latestRound?.amount | bpcNumber : "1.0-1" : "$" }}</span>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Latest Round Date')">
              {{ company.latestRound?.raiseDate | date : "dd MMM y" }}
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Investors')">
              <div
                class="flex align-items-center w-full h-full"
                [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
                (click)="editInvestors(company)"
              >
                <div class="flex align-items-center show-icon">
                  <span class="investors-container" style="white-space: pre-line;">{{ company.investorsDetails || "-"}}</span>
                  <i  class="pi pi-pencil mx-2 text-xs cursor-pointer"></i>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('CEO / Founder City')">
              <div
                class="flex align-items-center w-full h-full"
              >
                <div class="flex align-items-center show-icon">
                  <span class="investors-container" style="white-space: pre-line;">{{ company.ceoFounderCity || "-"}}</span>
                </div>
              </div>
            </ng-template>
            <!-- <ng-template [ngIf]="column.label.includes('Investors')">
              <div [overlayPanelTooltip]="investorsPanel" class="xl:max-w-[180px]">
                <div
                  (click)="setInvestorFilter(investor)"
                  class="block text-black truncate cursor-pointer ellipsis-text"
                  *ngFor="let investor of company.investors | investorsFilter"
                >
                  {{ investor.name }}
                </div>
              </div>
              <p-overlayPanel #investorsPanel>
                <div class="grid cursor-pointer" *ngFor="let investor of extractInvestors(company.investors)">
                  <div class="col" (click)="setInvestorFilter(investor)">{{ investor.name }}</div>
                  <div class="col" *ngIf="investorRole(investor.dominantRole)">
                    {{ investorRole(investor.dominantRole) }}
                  </div>
                </div>
              </p-overlayPanel>
            </ng-template> -->

            <ng-template [ngIf]="column.label.includes('Stage')">
              <div
              class="flex align-items-center show-icon h-full"
              [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
              (click)="editStage(company)"
              >
              <span class="md:text-xs lg:text-sm"> {{ company.statusId | status }} <br /> </span>
              <i *ngIf="company?.statusId" class="pi pi-pencil mx-2 text-xs cursor-pointer"></i>
            </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Coverage')">
              <div
              class="flex align-items-center show-icon h-full"
              [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
              (click)="editCoverage(company)"
              >
                <ng-container *ngFor="let user of company.coverageUsers"> {{ user?.name }} <br /> </ng-container>
                <i *ngIf="company?.coverageUsers" class="pi pi-pencil mx-2 text-xs cursor-pointer"></i>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Deal Team')">
              <div
              class="flex align-items-center show-icon h-full"
              >
                <!-- <ng-container *ngFor="let user of company.workflows.companyWorkflowUsers"> {{ user?.user?.name }} <br /> </ng-container> -->
                <ng-container *ngFor="let member of company.workflows | votingTeamMembers">  {{ member?.user?.name }}<br /> </ng-container>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Last Interaction')">
              <div style="display: flex; align-items: center">
                <img
                  class="mr-2"
                  *ngIf="company?.latestInteraction?.interactionType === interactionType.Meeting"
                  src="assets/svg/interation-coffe.svg"
                />
                <img
                  class="mr-2"
                  *ngIf="company?.latestInteraction?.interactionType === interactionType.Phone"
                  src="assets/svg/phone-icon.svg"
                />
                <img
                  class="mr-2"
                  *ngIf="company?.latestInteraction?.interactionType === interactionType.Email"
                  src="assets/svg/email-icon.svg"
                />
                <img
                  class="mr-2"
                  *ngIf="company?.latestInteraction?.interactionType === interactionType.VideoCall"
                  src="assets/svg/interation-video.svg"
                />
                <img
                class="mr-2"
                *ngIf="company?.latestInteraction?.interactionType === interactionType.Notes"
                src="assets/svg/interation-notes.svg"
              />
                <span>{{ company?.latestInteraction?.interactionDate | timeAgo }}</span>
              </div>
            </ng-template>

            <!-- <ng-template [ngIf]="column.label.includes('Contacts')">
              <div class="cursor-pointer h-full" (click)="onContactsClick(company)">
                <p-avatarGroup>
                  <p-avatar
                    label="{{ company.connectionsPrimary }}"
                    size="large"
                    styleClass="cyan"
                    shape="circle"
                  ></p-avatar>
                  <p-avatar
                    label="{{ company.connectionsSecondary }}"
                    size="large"
                    styleClass=" orange"
                    shape="circle"
                  ></p-avatar>
                </p-avatarGroup>
              </div>
            </ng-template> -->

            <ng-template [ngIf]="column.label.includes('Status')">
              <div *ngIf="company.status" class="w-full">
                <span>{{ company.status }}</span>
              </div>
              <ng-container *ngIf="company.workflows">
                <div class="flex items-center">
                  <bpc-notifications-icon (onClick)="showNotificationTriggersAlert(company)" [company]="company">
                  </bpc-notifications-icon>
                  <div>
                    <span
                      *ngIf="company.workflows | enrichmentStatus"
                      [overlayPanelTooltip]="enrichmentStatusPanel"
                      [ngClass]="company.workflows | enrichmentClasses"
                    >
                      {{ company.workflows | enrichmentStatus }}
                    </span>
                  </div>
                </div>
                <p-overlayPanel #enrichmentStatusPanel>
                  <ng-template pTemplate="content">
                    <span class="text-sm"
                      >Added by {{ company.workflows | enrichmentCreator }} -
                      {{ company.workflows | enrichmentCreateDatePipe }}</span
                    >
                    <span class="px-2" *ngIf="company.workflows | enrichmentAssignee">|</span>
                    <em class="text-sm" *ngIf="company.workflows | enrichmentAssignee"
                      >Assignee: {{ company.workflows | enrichmentAssignee }}</em
                    >
                  </ng-template>
                </p-overlayPanel>
              </ng-container>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Activity')">
              <div
                class="cursor-pointer grid grid-no-tb-gutter h-full"
                (mouseenter)="fetchInteractionsData(company)"
                (click)="onActivityClick(company)"
              >
                <div
                  class="col flex-w-auto flex align-items-center"
                  [overlayPanelTooltip]="latestInteractionMeetingsTooltip"
                >
                  <img src="assets/svg/email-icon.svg" class="mr-2" />
                  <span class="ml-2 block">
                    {{ company.meetingsCount + company.callsCount + company.videoCallsCount }}
                  </span>
                  <p-overlayPanel #latestInteractionMeetingsTooltip>
                    <bpc-latest-interaction
                      *ngIf="companyInteractions.get(company.id)?.meetings"
                      [latestInteraction]="companyInteractions.get(company.id)!.meetings!"
                    >
                    </bpc-latest-interaction>
                    <i *ngIf="interactionLoading" class="pi pi-spinner pi-spin"></i>
                    <div *ngIf="!interactionLoading && !companyInteractions.get(company.id)?.meetings">
                      No interactions
                    </div>
                  </p-overlayPanel>
                </div>
                <div class="col flex-w-auto flex align-items-center" [overlayPanelTooltip]="latestInteractionMailTooltip">
                  <img src="assets/svg/email-icon.svg" class="mr-2" />
                  <span class="ml-2">{{ company.emailsCount }}</span>

                  <p-overlayPanel #latestInteractionMailTooltip>
                    <bpc-latest-interaction
                      *ngIf="companyInteractions.get(company.id)?.mail"
                      [latestInteraction]="companyInteractions.get(company.id)!.mail!"
                    >
                    </bpc-latest-interaction>
                    <i *ngIf="interactionLoading" class="pi pi-spinner pi-spin"></i>
                    <div *ngIf="!interactionLoading && !companyInteractions.get(company.id)?.mail">No mails</div>
                  </p-overlayPanel>
                </div>
                <div
                  class="col flex-w-auto flex align-items-center"
                  *ngIf="company.interactions && company.interactions.length > 0"
                >
                  {{ company.interactions[0].interactionDate | timeAgo }}
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Outreach Owner')">
              <div
                class="flex align-items-center show-icon h-full"
                [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
                (click)="editOutReachOwner(company)"
              >
                <span class="md:text-xs lg:text-sm"> {{ company?.outreachOwner?.name }} <br /> </span>
                <i *ngIf="company?.outreachOwner" class="pi pi-pencil mx-2 text-xs cursor-pointer"></i>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Next Steps')">
              <div
                class="flex align-items-center w-full h-full"
                [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
                (click)="editNextStep(company)"
              >
                <div class="flex align-items-center show-icon">
                  <span>
                    {{ company.nextStep || "-" }}
                  </span>
                  <i *ngIf="company?.nextStep" class="pi pi-pencil mx-2 text-xs cursor-pointer"></i>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Ownership')">
              {{ getCompanyTypeName(company.companyType) }}
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Check Size')">
              {{ company.workflows | votingCheckSize }}
            </ng-template>

            <ng-template [ngIf]="column.label.includes('IC Ask')">
              <div class="whitespace-pre-line" [overlayPanelTooltip]="workflows">
                {{ company.workflows | votingDescription }}
              </div>
              <p-overlayPanel #workflows>
                <div [innerHTML]="company.workflows | votingDescription"></div>
              </p-overlayPanel>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('IC Date')">
              <div
                class="flex align-items-center show-icon select-none h-full"
                [ngClass]="{ 'cursor-pointer': isInvestmentCommitteeAdmin }"
                (click)="editICDateAdded(company)"
              >
                <span>
                  {{ (company.workflows | votingDate) || "-" }}
                </span>
                <i *ngIf="company?.nextStep" class="pi pi-calendar mx-2 cursor-pointer"></i>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Voting')">
              <div
                *ngIf="!company.isVoted || isInvestmentCommitteeAdmin; else votingsummary"
                class="h-full flex align-items-center"
              >
                <button
                  *ngIf="currentChildIndex === 0"
                  (click)="voteForCompany(company)"
                  class="btn-vote"
                  [ngClass]="{ '': company.workflows | votingDisabled }"
                  type="button"
                >
                  Vote Now
                </button>
              </div>
              <ng-template #votingsummary>
                <div class="flex items-center justify-between">
                  <div>
                    <div
                      *ngIf="company.workflows | votingSummaryEnable"
                      class="star-tag m-width {{ company.workflows | votingClassesWorkflow }}"
                      (click)="showVotingStats(company)"
                    >
                      <span class="star-circle">
                        <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.51965 1.99998L4.09265 4.36498L5.47865 1.99998L7.61265 1.99998L5.13765 5.92698L7.66765 9.72198H5.47865L3.92765 7.38998L2.56365 9.72198H0.429649L2.88265 5.82798L0.33065 1.99998L2.51965 1.99998Z"
                            fill="white"
                          />
                          <path
                            d="M7.93165 0.899976L0.0666504 0.899975L0.0666504 0.349976L7.93165 0.349976V0.899976Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span class="star-value">
                        {{ company.workflows | votingSummary }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Summary')">
              <div *ngIf="company.isVoted || isInvestmentCommitteeAdmin" class="flex items-center justify-between">
                <div
                  class="star-tag m-width {{ company.workflows | votingClassesWorkflow }}"
                  (click)="showVotingStats(company)"
                >
                  <span class="star-circle">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.51965 1.99998L4.09265 4.36498L5.47865 1.99998L7.61265 1.99998L5.13765 5.92698L7.66765 9.72198H5.47865L3.92765 7.38998L2.56365 9.72198H0.429649L2.88265 5.82798L0.33065 1.99998L2.51965 1.99998Z"
                        fill="white"
                      />
                      <path
                        d="M7.93165 0.899976L0.0666504 0.899975L0.0666504 0.349976L7.93165 0.349976V0.899976Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="star-value">
                    {{ (company.workflows | votingSummary) ? (company.workflows | votingSummary) : "-" }}
                  </span>
                </div>
                <bpc-menu-native-primeng [items]="menuItems" *ngIf="currentTab.showMenu && isInvestmentCommitteeAdmin">
                  <span
                    *ngIf="authService.isInvestor || authService.isAdmin"
                    class="pi pi-ellipsis-v p-2 cursor-pointer ml-6"
                    (click)="onMenuClick(company)"
                  >
                  </span>
                </bpc-menu-native-primeng>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Days in priority')">
              <p-avatarGroup *ngIf="currentTab.route !== 'priority' && !currentTab.route.includes('ic-voting')">
                <p-avatar
                  label="{{ user?.name | initials }}"
                  *ngFor="let user of company | coveragePersonsWithPodLeader"
                  size="large"
                  styleClass="cyan"
                  shape="circle"
                ></p-avatar>
              </p-avatarGroup>

              <ng-container *ngIf="currentTab.route === 'priority'">
                <span>{{ company.priorityDays ?? 0 }}</span>
              </ng-container>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Search Filter Name')">
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Saved Date')">
              {{ company.savedDate | date : "dd MMM y" }}
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Number Of Filters')">
              <div class="grid grid-no-tb-gutter flex align-items-center">
                <div class="col" style="min-width: 150px">{{ company.filterCount }}</div>
                <div class="col flex-w-auto flex align-items-center">
                  <span class="tags" (click)="applySavedSearch(company.id)"> Apply Filter </span>

                  <svg
                    class="icon-delete"
                    (click)="removeSavedSearch(company.id)"
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.7157 19.7493C9.53003 19.7493 8.37415 19.7362 7.23053 19.7125C5.76753 19.6837 4.75515 18.7352 4.58978 17.2372C4.31415 14.7522 3.84253 8.8949 3.83815 8.83628C3.8084 8.4749 4.0779 8.15815 4.43928 8.12928C4.7954 8.11965 5.1174 8.3699 5.14628 8.7304C5.15065 8.7899 5.6214 14.627 5.8944 17.0928C5.98803 17.9442 6.4474 18.3834 7.25765 18.4C9.44515 18.4464 11.6773 18.449 14.0835 18.4053C14.9445 18.3887 15.41 17.9582 15.5063 17.0867C15.7775 14.6419 16.25 8.7899 16.2553 8.7304C16.2842 8.3699 16.6035 8.1179 16.9614 8.12928C17.3228 8.15903 17.5923 8.4749 17.5634 8.83628C17.5582 8.89578 17.0839 14.7679 16.8109 17.231C16.6412 18.7597 15.6314 19.6898 14.1072 19.7178C12.9408 19.7379 11.8155 19.7493 10.7157 19.7493Z"
                      fill="#F13E3E"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.1194 6.61523H3.28113C2.91888 6.61523 2.62488 6.32123 2.62488 5.95898C2.62488 5.59673 2.91888 5.30273 3.28113 5.30273H18.1194C18.4816 5.30273 18.7756 5.59673 18.7756 5.95898C18.7756 6.32123 18.4816 6.61523 18.1194 6.61523Z"
                      fill="#F13E3E"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2602 6.61537C14.2645 6.61537 13.4 5.90575 13.204 4.92925L12.9914 3.86525C12.9467 3.70337 12.7621 3.5625 12.5521 3.5625H8.84823C8.63823 3.5625 8.45361 3.70337 8.40023 3.9055L8.19636 4.92925C8.00123 5.90575 7.13586 6.61537 6.14011 6.61537C5.77786 6.61537 5.48386 6.32137 5.48386 5.95912C5.48386 5.59687 5.77786 5.30287 6.14011 5.30287C6.51286 5.30287 6.83661 5.03687 6.91011 4.67112L7.12273 3.60712C7.33886 2.79162 8.04498 2.25 8.84823 2.25H12.5521C13.3554 2.25 14.0615 2.79162 14.2689 3.56775L14.4911 4.67112C14.5637 5.03687 14.8875 5.30287 15.2602 5.30287C15.6225 5.30287 15.9165 5.59687 15.9165 5.95912C15.9165 6.32137 15.6225 6.61537 15.2602 6.61537Z"
                      fill="#F13E3E"
                    />
                  </svg>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="column.label.includes('Remove')">
              <div class="ml-4" (click)="removeFromIC(company)">
                <i class="pi pi-minus-circle text-red-500 cursor-pointer"></i>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Archive')">
              <div class="ml-4" (click)="moveCompanyToArchive(company)">
                <i class="pi pi-save text-orange-400 cursor-pointer"></i>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Cron Job Name')">
              <div (click)="updateCronJob(company)" style="cursor: pointer;" class="w-full">
                <span>{{ company.id }}</span>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Frequency')">
              <div class="w-full">
                <span>{{ company.frequency }}</span>
                <span>{{getCronFrequency(company)}}</span>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Recipients')">
                <div [overlayPanelTooltip]="recipientsPanel" class="xl:max-w-[180px]">
                  <div
                    class="block text-black truncate cursor-pointer ellipsis-text"
                    *ngFor="let recipient of company.recipients | emailRecipients | slice:0:3"
                  >
                    {{ recipient }}
                  </div>
                </div>
                <p-overlayPanel #recipientsPanel>
                  <div class="grid cursor-pointer" *ngFor="let recipient of company.recipients | emailRecipients">
                    <div class="col">{{ recipient }}</div>
                  </div>
                </p-overlayPanel>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Last Execution')">
              <div class="grid grid-no-tb-gutter flex align-items-center">
                <div  class="col" style="min-width: 100px;">
                  {{ company.lastExecutionDate ? (company.lastExecutionDate | timeAgo) : '-' }}
                </div>
                <div class="col flex-w-auto flex align-items-center">
                  <svg
                  class="icon-delete"
                  (click)="removeCronJob(company.id)"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.7157 19.7493C9.53003 19.7493 8.37415 19.7362 7.23053 19.7125C5.76753 19.6837 4.75515 18.7352 4.58978 17.2372C4.31415 14.7522 3.84253 8.8949 3.83815 8.83628C3.8084 8.4749 4.0779 8.15815 4.43928 8.12928C4.7954 8.11965 5.1174 8.3699 5.14628 8.7304C5.15065 8.7899 5.6214 14.627 5.8944 17.0928C5.98803 17.9442 6.4474 18.3834 7.25765 18.4C9.44515 18.4464 11.6773 18.449 14.0835 18.4053C14.9445 18.3887 15.41 17.9582 15.5063 17.0867C15.7775 14.6419 16.25 8.7899 16.2553 8.7304C16.2842 8.3699 16.6035 8.1179 16.9614 8.12928C17.3228 8.15903 17.5923 8.4749 17.5634 8.83628C17.5582 8.89578 17.0839 14.7679 16.8109 17.231C16.6412 18.7597 15.6314 19.6898 14.1072 19.7178C12.9408 19.7379 11.8155 19.7493 10.7157 19.7493Z"
                  fill="#F13E3E"
                  />
                  <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.1194 6.61523H3.28113C2.91888 6.61523 2.62488 6.32123 2.62488 5.95898C2.62488 5.59673 2.91888 5.30273 3.28113 5.30273H18.1194C18.4816 5.30273 18.7756 5.59673 18.7756 5.95898C18.7756 6.32123 18.4816 6.61523 18.1194 6.61523Z"
                  fill="#F13E3E"
                  />
                  <path
                  fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.2602 6.61537C14.2645 6.61537 13.4 5.90575 13.204 4.92925L12.9914 3.86525C12.9467 3.70337 12.7621 3.5625 12.5521 3.5625H8.84823C8.63823 3.5625 8.45361 3.70337 8.40023 3.9055L8.19636 4.92925C8.00123 5.90575 7.13586 6.61537 6.14011 6.61537C5.77786 6.61537 5.48386 6.32137 5.48386 5.95912C5.48386 5.59687 5.77786 5.30287 6.14011 5.30287C6.51286 5.30287 6.83661 5.03687 6.91011 4.67112L7.12273 3.60712C7.33886 2.79162 8.04498 2.25 8.84823 2.25H12.5521C13.3554 2.25 14.0615 2.79162 14.2689 3.56775L14.4911 4.67112C14.5637 5.03687 14.8875 5.30287 15.2602 5.30287C15.6225 5.30287 15.9165 5.59687 15.9165 5.95912C15.9165 6.32137 15.6225 6.61537 15.2602 6.61537Z"
                    fill="#F13E3E"
                    />
                  </svg>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Picture')">
              <div class="profile-image">
                <img class="profile-image" [src]="company?.profilePictureUrl ? company?.profilePictureUrl : 'assets/no-image.png'"
                alt="{{ company.name }}" />
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Name')">
              <div class="w-full">
                <span>{{ company.name }}</span>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Email')">
              <div [overlayPanelTooltip]="emailPanel" class="block text-black truncate cursor-pointer ellipsis-text">
                <span>{{ company.email }}</span>
              </div>
              <p-overlayPanel #emailPanel>
                <div class="grid cursor-pointer">
                  <div class="col">{{ company.email }}</div>
                </div>
              </p-overlayPanel>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('DealCloud ID')">
              <div class="xl:max-w-[180px]">
                <span>{{ company.externalId }}</span>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Role(s)')">
              <div [overlayPanelTooltip]="rolesPanel" class="xl:max-w-[180px]">
                <div 
                  *ngFor="let role of getUserRoles(company.userRoles) | slice:0:3"
                  class="block text-black truncate cursor-pointer ellipsis-text"
                >
                  {{ role }}
                </div>
              </div>
              <p-overlayPanel #rolesPanel>
                  <div class="grid cursor-pointer" *ngFor="let role of getUserRoles(company.userRoles)">
                    <div class="col">{{ role }}</div>
                  </div>
                </p-overlayPanel>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Group(s)')">
              <div class="grid grid-no-tb-gutter flex align-items-center">
                <div  class="col">
                  <div  [overlayPanelTooltip]="groupsPanel">
                    <div *ngFor="let group of getUserGroups(company.userGroups)"
                      class="ellipsis-text group-text"
                    >
                      {{ group }}
                    </div>
                  </div>
                  <p-overlayPanel #groupsPanel>
                    <div class="grid cursor-pointer" *ngFor="let group of getUserGroups(company.userGroups)">
                      <div class="col">{{ group }}</div>
                    </div>
                  </p-overlayPanel>
                </div>
                <div class="col flex-w-auto align-items-center gap-3 user-grid-action-buttons" >
                  <div class="w-full">
                    <button class="border-none background-none" type="button" pButton (click)="addOrEditUser(company)">
                      <img src="assets/svg/Edit.svg" alt="edit-icon" />
                    </button>
                    <p-inputSwitch class="user-toggle" [(ngModel)]="company.isActive" name="userToggle" onLabel="On" offLabel="Off" (onChange)="handleUserStatus($event, company)">
                    </p-inputSwitch>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="column.label.includes('Pod(s)')">
              <div class="grid grid-no-tb-gutter flex align-items-center">
                <div  class="col">
                  <div  [overlayPanelTooltip]="podsPanel">
                    <div *ngFor="let pod of getUserPods(company.userGroups)"
                      class="ellipsis-text pod-text"
                    >
                      {{ pod }}
                    </div>
                  </div>
                  <p-overlayPanel #podsPanel>
                    <div class="grid cursor-pointer" *ngFor="let pod of getUserPods(company.userGroups)">
                      <div class="col">{{ pod }}</div>
                    </div>
                  </p-overlayPanel>
                </div>
              </div>
            </ng-template>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr *ngIf="!loading; else showSkeleton">
        <td class="no-data-container" [attr.colspan]="availableGridColumns.length">
          <div class="p-table-no-data">
            <!-- <img src="assets/svg/no-data.gif" alt="No Result Found" /> -->

            <img src="assets/svg/search.gif" alt="No Result Found" />
            <h3>No Result Found</h3>
          </div>
        </td>
      </tr>
      <ng-template #showSkeleton>
        <tr *ngFor="let count of skeletonRowCount">
          <td *ngFor="let column of availableGridColumns">
            <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          </td>
        </tr>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="summary">
      <div *ngIf="usePagination(tab)" class="p-paginator-bottom p-paginator p-component">
        <span *ngIf="loading" class="pi pi-spinner pi-spin mr-4"></span>
        <span class="p-paginator-current">Showing {{currentPageTotal}} of {{ currentTotalLabel }}</span>
        <button
          type="button"
          class="pi pi-angle-double-left cursor-pointer p-paginator-first"
          [class.hidden]="currentTotalPages === 1"
          [class.p-disabled]="disableFirstPage"
          (click)="firstPage()"
        ></button>
        <button
          type="button"
          class="pi pi-angle-left cursor-pointer p-paginator-prev"
          [class.hidden]="currentTotalPages === 1"
          [class.p-disabled]="disableFirstPage"
          (click)="previousPage()"
        ></button>
        <span class="p-paginator-pages">
          <button
            type="button"
            class="p-ripple p-element p-paginator-page p-paginator-element p-link"
            (click)="goToPage(1)"
            *ngIf="page != 1"
          >
            1
          </button>
          <span *ngIf="page != 2 && page + 1 != 2">...</span>

          <button
            type="button"
            class="p-ripple p-element p-paginator-page p-paginator-element p-link p-highlight"
            (click)="goToPage(page)"
          >
            {{ page }}
          </button>
          <button
            type="button"
            class="p-ripple p-element p-paginator-page p-paginator-element p-link"
            *ngIf="page < currentTotalPages"
            (click)="goToPage(page + 1)"
          >
            {{ page + 1 }}
          </button>
          <span *ngIf="page + 1 < currentTotalPages">...</span>
          <button
            type="button"
            class="p-ripple p-element p-paginator-page p-paginator-element p-link"
            *ngIf="page + 1 < currentTotalPages"
            (click)="goToPage(currentTotalPages)"
          >
            {{ currentTotalPages }}
          </button>
        </span>
        <button
          type="button"
          class="pi pi-angle-right cursor-pointer p-paginator-next"
          [class.hidden]="currentTotalPages === 1"
          [class.p-disabled]="disableLastPage"
          (click)="nextPage()"
        ></button>
        <button
          type="button"
          class="pi pi-angle-double-right cursor-pointer p-paginator-last"
          [class.hidden]="currentTotalPages === 1"
          [class.p-disabled]="disableLastPage"
          (click)="lastPage()"
        ></button>
        <p-dropdown
          styleClass="grid-pagination"
          (ngModelChange)="pageSizeChanged($event)"
          [autoDisplayFirst]="false"
          [options]="[25, 50, 100]"
          [placeholder]="pageSize.toString()"
          [ngModel]="pageSize"
        >
        </p-dropdown>
      </div>
    </ng-template>
  </p-table>
