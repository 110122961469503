<div class="main-layout">
  <bpc-top-navigation />
  <div class="content-wrapper">
    <div class="content">
      <div class="fluid-container">
        <div class="grid grid-nogutter">
          <div class="col-12">
            <div class="sticky-tab">
              <p-tabView
                class="position-relative"
                [activeIndex]="currentTabIndex"
                (activeIndexChange)="navigateToTab($event)"
              >
                <p-tabPanel
                  *ngFor="let tab of onAvailableUserTabs; let i = index"
                  [disabled]="tab.disabled"
                  [selected]="i === currentTabIndex"
                >
                  <ng-template pTemplate="header" *ngIf="!tab.hidden">
                    <li [ngClass]="{ 'tab-mobile': isMobile && tab.mobileHidden }">
                      <div class="inline-block">
                        <span>{{ tab.label }}</span>
                      </div>
                    </li>
                  </ng-template>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </div>
      <div class="router-outlet-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <bpc-company-details-v2/>
  <bpc-generic-modal></bpc-generic-modal>
  <p-toast position="bottom-center" key="home" [preventOpenDuplicates]="true"></p-toast>
</div>
