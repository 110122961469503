import { Injectable } from '@angular/core';
import { OperationResult } from '@core/models/operation-result';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { ApiResponse } from 'src/app/api/base';
import { GenericPopupOperation } from '@core/models/generic-popup-enums';
import { WorkflowsService } from '../workflows/workflows.service';
import { GenericPopupParameters } from '@core/models/generic-popup-models';

@Injectable({
  providedIn: 'root'
})
export class GenericPopupService {
  private toggleGenericPopupSubject = new BehaviorSubject<boolean | null>(null);
  toggleGenericPopup$: Observable<Boolean | null> = this.toggleGenericPopupSubject.asObservable();

  private sendIcWorkflowEmailSubject = new BehaviorSubject<boolean | null>(null);
  sendIcWorkflowEmail$: Observable<boolean | null> = this.sendIcWorkflowEmailSubject.asObservable();

  operation!: GenericPopupOperation;
  parameters: GenericPopupParameters;

  constructor(
    private messageService: MessageService,
    private workflowsService: WorkflowsService
  ) { 
    this.parameters = {
      companyWorkflowId: undefined,
      companyWorkflowUsers: undefined
    }
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  public positiveCall(operation: GenericPopupOperation ){
    switch(operation){
      case GenericPopupOperation.SendIcWorkflowEmail : {
        this.sendWorkflowEmail();
        break;
      }
    }
  }

  private sendWorkflowEmail() {
    this.workflowsService.sendWorkflowEmail(this.parameters.companyWorkflowId).pipe(
      catchError((error) => {
        this.displayErrorMessage(error, "Error emailing, Please try again!");
        return of(null); 
      })
    ).subscribe(response => {
      if(response?.data.result){
        this.showSuccessToastMessage("Email sent to deal team!");
        this.sendIcWorkflowEmailSubject.next(true);
      }
      else{
        this.showErrorToastMessage("Email could not be sent to deal team, try again!");
        this.sendIcWorkflowEmailSubject.next(false);
      }
    });
  }

  setupPopup(operation: GenericPopupOperation, parameters: GenericPopupParameters){
    this.parameters = parameters;
    this.operation = operation;

    this.toggleGenericPopupSubject.next(true);
  }
}
