import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@core/auth/auth.service";
import { DataFilter } from "@core/components/dynamic-table/dynamic-table.component";
import { Company } from "@core/models/company";
import { StatusPipe } from "@core/pipes/tabs/status.pipe";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { ApiResponse, BaseApiService } from "src/app/api/base";
import { PagerData } from "src/app/services/companies-search/saved-search.service";
import { TimeAgoPipe } from "../pipes/time-ago.pipe";

@Injectable({
  providedIn: "root",
})
export class CompanyV2Service extends BaseApiService<Company> {
  constructor(
    authService: AuthService,
    router: Router,
    messageService: MessageService,
    private statusPipe: StatusPipe,
    private timeAgoPipe: TimeAgoPipe,
  ) {
    super("/v2/companies", authService, router, messageService);
  }

  getCompaniesV2(): Observable<ApiResponse<Company>> {
    return this.get(`/search/active`);
  }

  getActiveCompaniesV2(filter: DataFilter): Observable<ApiResponse<PagerData<Company>>> {
    // Convert filter.assigneeRole from string to number if it exists
    const transformFilter: any = filter;
    if (filter.assigneeRole) {
      transformFilter.assigneeRole = +filter.assigneeRole; // Use unary `+` operator to convert string to number
    }

    return this.post("/search/active", null, transformFilter);
  }

  transformCompanyData(companies: Company[]) {
    return companies.map((company) => {
      const stage = this.statusPipe.transform(company.statusId);
      const coverageUsers = company.coverageUsers.map((item) => item.name).join("<br />");

      return {
        ...company,
        id: company.id,
        title: company.name,
        subtitle: `${company.address?.city} | ${stage}`,
        description: company.description,
        details: [
          { title: "Financials", value: company.targetFinancials },
          { title: "CEO Location", value: company.ceoFounderCity || "-" },
          { title: "Coverage Users", value: coverageUsers },
        ],
        route: `/companies/details/${company.id}`,
        name: {
          name: company.name,
          city: company.address?.city,
          description: company.description,
          route: `/companies/details/${company.id}`,
          socialLinks: [
            { type: "www", sourceId: 0, link: company.website },
            { type: "dealCloud", sourceId: 1, link: null },
            { type: "grata", sourceId: 9, link: `https://search.grata.com/redirect?ref=ext&domain=${company?.domain}` },
          ],
        },
        investorsDetails: company.investorsDetails || "-",
        coverageUsers,
        stage,
      };
    });
  }

  transformInteractionData(companies: Company[]) {
    return companies.map((company) => {
      const stage = this.statusPipe.transform(company.statusId);
      const lastInteraction = this.timeAgoPipe.transform(company.latestInteraction?.interactionDate || "");
      const coverageUsers = company.coverageUsers.map((item) => item.name).join("<br />");

      return {
        ...company,
        id: company.id,
        title: company.name,
        subtitle: `${company.address?.city} | ${stage}`,
        description: company.description,
        details: [
          { title: "Last Interaction", value: lastInteraction },
          { title: "Coverage Users", value: coverageUsers },
        ],
        route: `/companies/details/${company.id}`,
        name: {
          name: company.name,
          city: company.address?.city,
          description: company.description,
          socialLinks: [
            { type: "www", sourceId: 0, link: company.website },
            { type: "dealCloud", sourceId: 1, link: null },
            { type: "grata", sourceId: 9, link: `https://search.grata.com/redirect?ref=ext&domain=${company?.domain}` },
          ],
        },
        coverageUsers,
        stage,
        lastInteraction,
      };
    });
  }
}
