import { Pipe, PipeTransform } from '@angular/core';

import { ModalActions } from '../../models/types';

@Pipe({
  name: 'modalTitle'
})
export class ModalTitlePipe implements PipeTransform {
  transform(modalAction: ModalActions): string {
    switch (modalAction) {
      case 'joinCoverage':
        return 'Add to pipeline list';
      case 'enrichData':
        return 'Enrich Data';
      case 'removeFromJarvis':
        return 'Remove from Jarvis';
      case 'markAsActive':
        return 'Restore to Jarvis'
      case 'addToPriority':
        return 'Add to priority list';
      case 'extendPriority':
        return 'Extend Priority';
      case 'changeStatus':
        return 'Change status';
      case 'editCoverage':
        return 'Edit coverage';
      case 'removeFromPriority':
        return 'Remove from priority list';
      case 'actionTaken':
        return 'Which action did you take?';
      case 'closeCompanyDetails':
        return 'Are you sure?';
      case 'flagForICVoting':
        return 'Add company to IC agenda';
      case 'icVoting':
        return 'Conviction level';
      case 'removeFromIC':
        return 'Remove from IC';
      case 'editICAsk':
        return 'Update IC Ask';
      case 'archiveCompany':
        return 'Move company to voting history?';
      case 'leaveCoverage':
        return 'Release company';
      case 'createOrEditNotificationTrigger':
        return 'Create/edit alert';
      case 'editICDateAdded':
        return 'Change IC date';
      case 'editOutReachOwner':
        return 'Change outreach owner';
      case 'editNextStep':
        return 'Change next steps'
      case 'removeSavedSearch':
        return 'Remove Saved Search';
      case 'removeCronJob':
        return 'Remove Cron Job';
      case 'updateCronJob':
        return 'Update Cron Job';
      case 'AddUser':
        return 'Add User';
      case 'EditUser':
        return 'Edit User';
      case 'UserActiveToggle':
        return 'Active User';
      case 'UserInactiveToggle':
        return 'Inactive User';
      case 'DeleteChatThread':
        return 'Delete Chat Thread';
      case 'UpdateChatThread':
        return 'Update Chat Thread';
      case 'editInvestors':
          return 'Edit Investors';
      case 'addToPortfolio':
          return 'Add To Portfolio'
    }
  }
}