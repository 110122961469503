import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, shareReplay } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { BaseUser, User } from '@models/user';
import { AuthService } from '@core/auth/auth.service';
import { OperationResult } from '@models/operation-result';
import { Pod } from '@models/pod-filter';
import { PagerData } from '../companies-search/companies-search.service';
import { Company } from '@core/models/company';
import { MainFilterRaw } from '@core/models/main-filter-view';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseApiService<User> {

  allUsers: Observable<ApiResponse<User[]>> = this.get<User[]>().pipe(shareReplay(1));
  activeUsers: Observable<ApiResponse<User[]>> = this.get<User[]>('/true').pipe(shareReplay(1));

  constructor(authService: AuthService, router: Router, messageService: MessageService, private httpClient: HttpClient) {
    super('/users', authService, router, messageService);
  }

  getAllUsers(): Observable<ApiResponse<User[]>> {
    return this.allUsers;
  }

  getUsers(mainFilterRaw: MainFilterRaw): Observable<ApiResponse<PagerData<Company>>> {
    if(mainFilterRaw.showAllUsers === "true") {
      return this.get('/details');
    }
    return this.get('/details?isActive=true');
  }

  getAllUsersObserver(): Observable<ApiResponse<User[]>> {
    return  this.get<User[]>();
  }

  getActiveUsers(): Observable<ApiResponse<User[]>> {
    return this.activeUsers;
  }

  getPods(): Observable<ApiResponse<Pod[]>> {
    return this.get<Pod[]>('/groups/details');
  }

  login(email: string, password: string): Observable<ApiResponse<OperationResult<User>>> {
    return this.post('/authenticate', null, { email, password });
  }

  getCurrentUser(accessToken: string): Observable<ApiResponse<User>> {
    const headers = { Authorization: `Bearer ${ accessToken }` };
    return this.get('/current', '', headers);
  }

  getCurrentUserDetails(): Observable<ApiResponse<BaseUser>> {
    return this.get('/current/details');
  }

  AddUser(userData: any) {
    return this.post('', null, userData);
  }

  EditUser(userData: any, userId: number) {
    return this.patch(`/${userId}`, null, userData);
  }

  UpdateProfilePicture(userPictureData: any) {
    return this.patch('/profile-picture', null, userPictureData)
  }

  updateUserStatus(userId: number, status: boolean): Observable<any> {
    return this.patch(`/${userId}/active/${status}`)
  }

  BeginProfilePictureUpload(userId: number): Observable<any>{
    return this.post(`/profile-picture/${userId}/begin`)
  }

  UploadProfilePicture(signedUrl: any, file: any): Observable<any>{
    const headers = new HttpHeaders({
      'x-ms-blob-type': 'BlockBlob'
    });

    return this.httpClient.put(signedUrl, file, { headers });
  }

  UploadProfilePictureFetch(signedUrl: any, file: any): Promise<any> {
    return fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      },
      body: file
    }).then(response => {
      if (!response.ok) {
        throw new Error('Failed to upload the file');
      }
      return response;
    });
  }

  CompleteProfilePictureUpload(userId: number): Observable<any>{
    return this.post(`/profile-picture/${userId}/complete`)
  }
}
