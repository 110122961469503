<div class="submenu-wrapper" id="filter-wrapper">
  <div class="col flex align-content-center flex-w-auto">
    <div class="grid align-content-center p-30">
      <div class="col flex align-content-center flex-w-auto">
        <div *ngFor="let filter of filters" class="menu">
          <div class="col flex align-content-center flex-wrap flex-w-auto">
            <p-dropdown [styleClass]="'no-padd sub-dropdown ' + filter.classes" optionDisabled="inactive" optionLabel="name"
              scrollHeight="200px" (onChange)="onDropdownChange($event)" [autoDisplayFirst]="false" 
              [options]="filter.options" [style]="{ width: '100%' }" [(ngModel)]="selectedFilter[FilterType[filter.filterType]]">
            </p-dropdown>
          </div>
      </div>
    </div>
  </div>
</div>
