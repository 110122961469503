<div>
  <p-dialog
    [modal]="true"    
    [resizable]="false"
    [closeOnEscape]="true"
    header="Are you sure you want to remove this comment"
    [visible]="visible"
    >
    <section id="vote-stats-container" class="voting-modal-wrapper">
      <div class="grid grid-no-tb-gutter align-items-center list-view">
        <div class="col">
          <div *ngIf="comment?.user" class="grid grid-no-tb-gutter align-items-center">
            <div class="col flex-w-auto">
              <span
              >
                <img
                  class="cursor-pointer"
                  [src]="comment.user?.profilePictureUrl ? comment.user?.profilePictureUrl : 'assets/no-image.png'"
                  style="width: 50px; height: 50px;"
                  alt="{{ comment.user?.name }}"
                />
              </span>
            </div>
            <div class="col flex-w-auto list-info">
              <h2>{{ comment.user?.name }}</h2>
              <p *ngIf="comment!=null">
                <span>{{ (comment.modifyDate ? comment.modifyDate : comment.createDate) | date : "dd MMM y" }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="comment">{{ comment?.comment }}</p>
        </div>
      </div>
    </section>
    <ng-template pTemplate="footer">
      <div>
        <button
          pButton
          label="Cancel"
          (click)="onNegativeClick()"
        ></button>
        <button
          pButton
          class="button-dark"
          type="submit"
          label="Yes"
          [loading]="modalLoading"
          [disabled]="positiveButtonDisabled"
          (click)="onPositiveClick()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</div>
