import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApiResponse, BaseApiService } from '../../api/base';
import { BasicCompany, Company } from '@models/company';
import { AuthService } from '@core/auth/auth.service';
import { CONVERT_TO_ARRAY_ITEMS } from '@core/services/filter-helper.service';
import { WorkFlow } from '@core/models/auxiliary-company-models';

@Injectable({
  providedIn: 'root'
})
export class WorkflowsService extends BaseApiService<Company> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/Workflows', authService, router, messageService);
  }

  updateWorkflowSummary(companyWorkflowId: number|undefined, summary: string|undefined): Observable<any> {
    return this.put(`/${companyWorkflowId}/summary`, null, {summary: summary})
  }

  sendWorkflowEmail(companyWorkflowId: number|undefined): Observable<any>{
    return this.post(`/${companyWorkflowId}/email`)
  }
}