import { GlobalModalService } from "./../../services/global-modal.service";
import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Company } from "@core/models/company";
import { UserRole } from "@core/models/user";
import { TabData } from "@core/static/tabs";
import { MessageService } from "primeng/api";
import { CompanyPortfolioService } from "@core/services/company.portfolio.service";
import moment from "moment";
import { CompanyType } from "@core/models/company-type";
import { CompanyTypeService } from "@core/services/company-type.service";
import { Observable, Subject, catchError, concatMap, debounceTime, distinctUntilChanged, of, takeUntil } from "rxjs";
import { DestroyObservable } from "src/app/rxjs/DestroyObservable";
import { TabUtilsService } from "src/app/services/tab-utils.service";
import { NgModel } from "@angular/forms";
import { Editor, EditorTextChangeEvent } from "primeng/editor";
import { AuthService } from "@core/auth/auth.service";
import { GroupType } from "@core/models/auxiliary-company-models";
import { GenericUpdatesService } from "@core/services/generic-updates.service";
import { UpdatesService } from "@core/services/updates.service";
import { GenericPopupOperation } from "@core/models/generic-popup-enums";
import { GenericPopupService } from "src/app/services/generic-popup/generic-popup.service";
import { GenericPopupParameters } from "@core/models/generic-popup-models";
import { DeviceDetectorService } from "ngx-device-detector";

export type Portfolio = {
  company: Company;
  updates: string;
  id: number;
  isSubmitLoading: boolean;
  isUpdateEmpty: boolean;
  portfolioCompany: any;
  previousUpdates: string;
  updatesAttachments: any[];
  fileUploading: boolean;
  fileDeleting: boolean;
};
export type GenericUpdateType = {
  genericUpdateTypeId: number;
  name: string;
  description: string;
  id: number;
};
export type OpenSearch = {
  genericUpdateId: number;
  genericUpdateType: GenericUpdateType;
  updates: string;
  previousUpdates: string;
  publishedTimeStam: Date;
  id: number;
  isUpdateEmpty: boolean;
  isSubmitLoading: boolean;
  updatesAttachments: any[];
  fileUploading: boolean;
  fileDeleting: boolean;
};

@Component({
  selector: "portfolio-update",
  templateUrl: "./portfolio-update.component.html",
  styleUrls: ["./portfolio-update.component.scss"],
})
export class PortfolioUpdateComponent {
  products: any[] = [
    { name: "Portfolio", category: "Portfolio", price: 10 },
    { name: "Prospects", category: "Prospects", price: 20 },
    { name: "IR", category: "IR", price: 30 },
    { name: "Daily News", category: "Daily News", price: 40 },
  ];

  isPublishLoading: boolean = false;
  isPublishOpenSearchLoading: boolean = false;
  loading: boolean = false;
  loadingOpenSearches: boolean = false;
  @Input() tabs!: Record<UserRole, TabData<Company>[]>;
  value: string | null = null;
  companyPortfolios: Portfolio[] = [];
  orderedCompanyPortfolios: Portfolio[] = [];
  draggedPortfolio!: Portfolio;
  publishedDates: any = [];
  selectedPublishedDate: Date | null = null;
  companyTypes: CompanyType[] = [];
  @ViewChild("pdfDiv", { static: false }) pdfDiv!: ElementRef<HTMLDivElement>;
  @ViewChildren(Editor) editors!: QueryList<Editor>;
  visible: boolean = false;
  previewObj: any;
  modalLoading: boolean = false;
  private updateQueue: Subject<{ index: number; updates: string }> = new Subject();
  private updateQueueOpenSearches: Subject<{ index: number; updates: string }> = new Subject();
  companyOpenSearches: OpenSearch[] = [];
  selectedFile: File | null = null;
  fileUploading: boolean = false;
  fileDeleting: boolean = false;
  fileGroupId: number = -1;
  openAccordionCount = 0; // Number of open accordion tabs
  genericPopupVisible: boolean = false;
  genericPopupOperation!: GenericPopupOperation;

  onAccordionOpen(): void {
    this.openAccordionCount++;
  }

  onAccordionClicked(portfolio: Portfolio) {
    this.draggedPortfolio = portfolio;
  }

  onAccordionClose(): void {
    this.openAccordionCount--;
  }

  async fileChangeEvent(event: any, id: number) {
    this.fileGroupId = id;
    if (this.fileUploading) {
      this.displayToastMessage("File upload in progress wait", "Success");
      return;
    }
    const input = event.target as HTMLInputElement;
    this.fileUploading = true;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      // const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
      // if (this.selectedFile.size > maxSizeInBytes) {
      //   this.displayToastMessage("File size must be less than 2MB.", "Error");
      //   this.fileUploading = false;
      //   return;
      // }
      var fileExtension = this.selectedFile.name.includes(".")
        ? this.selectedFile.name.split(".").pop()?.toLowerCase()
        : "";

      if (!fileExtension) {
        fileExtension = "";
      } else {
        fileExtension = "." + fileExtension;
      }

      this.updatesService
        .getPortfolioCompanyUpdatesFileUploadURL(id, this.selectedFile.name, fileExtension)
        .pipe(
          catchError((error) => {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
            return of(null);
          }),
        )
        .subscribe((response: any) => {
          if (response.data) {
            if (this.selectedFile) {
              this.uploadFile(this.selectedFile, response.data.fileUrl, response.data.uniqueId, id);
            }
          }
        });
    }
  }

  async genericUpdateFileChangeEvent(event: any, id: number) {
    this.fileGroupId = id;
    if (this.fileUploading) {
      this.displayToastMessage("File upload in progress wait", "Success");
      return;
    }
    const input = event.target as HTMLInputElement;
    this.fileUploading = true;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      // const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
      // if (this.selectedFile.size > maxSizeInBytes) {
      //   this.displayToastMessage("File size must be less than 2MB.", "Error");
      //   this.fileUploading = false;
      //   return;
      // }
      var fileExtension = this.selectedFile.name.includes(".")
        ? this.selectedFile.name.split(".").pop()?.toLowerCase()
        : "";

      if (!fileExtension) {
        fileExtension = "";
      } else {
        fileExtension = "." + fileExtension;
      }

      this.updatesService
        .getGenericUpdatesFileUploadURL(id, this.selectedFile.name, fileExtension)
        .pipe(
          catchError((error) => {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
            return of(null);
          }),
        )
        .subscribe((response: any) => {
          if (response.data) {
            if (this.selectedFile) {
              this.genericUpdateUploadFile(this.selectedFile, response.data.fileUrl, response.data.uniqueId, id);
            }
          }
        });
    }
  }

  async uploadFile(file: File, signedUrl: string, uniqueId: string, portfolioCompanyId: number) {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
        },
        body: file,
      });
      if (!response.ok) {
        this.displayToastMessage("Error uploading file");
        this.fileUploading = false;
        return false;
      }

      this.updatesService
        .completeUpload(uniqueId)
        .pipe(
          catchError((error) => {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response) {
            this.displayToastMessage("File uploaded successfully", "success");
            this.portfolioService
              .getUpdates()
              .pipe(
                catchError((error) => {
                  this.displayToastMessage("Refresh page.");
                  this.fileUploading = false;
                  return of(null);
                }),
              )
              .subscribe((response: any) => {
                if (response.data.length > 0) {
                  var companyPortfolios: Portfolio[] = response.data.map((item: any) => {
                    const isUpdateEmpty = item.updates === "" || item.updates === null;
                    return {
                      ...item,
                      isSubmitLoading: false,
                      isUpdateEmpty: isUpdateEmpty,
                      previousUpdates: item.updates,
                    };
                  });

                  companyPortfolios.forEach((portfolio, index) => {
                    if (portfolio.id == portfolioCompanyId) {
                      this.companyPortfolios[index].updatesAttachments = portfolio.updatesAttachments;
                    }
                  });
                  this.orderedCompanyPortfolios = [...this.companyPortfolios];
                }

                this.fileUploading = false;
              });
          } else {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
          }
        });

      return true;
    } catch (error) {
      this.displayToastMessage("Error uploading file");
      this.fileUploading = false;
      return false;
    }
  }

  async genericUpdateUploadFile(file: File, signedUrl: string, uniqueId: string, genericUpdateId: number) {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
        },
        body: file,
      });
      if (!response.ok) {
        this.displayToastMessage("Error uploading file");
        this.fileUploading = false;
        return false;
      }

      this.updatesService
        .completeUpload(uniqueId)
        .pipe(
          catchError((error) => {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response) {
            this.displayToastMessage("File uploaded successfully", "success");

            this.genericUpdatesService
              .getOpenSearches(GroupType.OpenSearches)
              .pipe(
                catchError((error) => {
                  this.displayToastMessage("Refresh page.");
                  this.fileUploading = false;
                  return of(null);
                }),
              )
              .subscribe((response: any) => {
                if (response.data.length > 0) {
                  var companyOpenSearches: OpenSearch[] = response.data.map((item: any) => {
                    const isUpdateEmpty = item.updates === "" || item.updates === null;
                    return {
                      ...item,
                      isSubmitLoading: false,
                      isUpdateEmpty: isUpdateEmpty,
                      previousUpdates: item.updates,
                    };
                  });

                  companyOpenSearches.forEach((openSearch, index) => {
                    if (openSearch.id == genericUpdateId) {
                      this.companyOpenSearches[index].updatesAttachments = openSearch.updatesAttachments;
                    }
                  });
                  
                }

                this.fileUploading = false;
              });
          } else {
            this.displayToastMessage("Error uploading file");
            this.fileUploading = false;
          }
        });

      return true;
    } catch (error) {
      this.displayToastMessage("Error uploading file");
      this.fileUploading = false;
      return false;
    }
  }

  deleteAttachment(portfolioCompanyUpdateId: number, uniqueId: string) {
    this.fileGroupId = portfolioCompanyUpdateId;
    this.fileDeleting = true;
    this.updatesService
      .deleteUpdatesAttachment(uniqueId)
      .pipe(
        catchError((error) => {
          this.displayToastMessage("Error detleting file");
          this.fileDeleting = false;
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response) {
          this.displayToastMessage("File deleted successfully", "success");

          this.portfolioService
            .getUpdates()
            .pipe(
              catchError((error) => {
                this.displayToastMessage("Refresh page.");
                this.fileDeleting = false;
                return of(null);
              }),
            )
            .subscribe((response: any) => {
              if (response.data.length > 0) {
                var companyPortfolios: Portfolio[] = response.data.map((item: any) => {
                  const isUpdateEmpty = item.updates === "" || item.updates === null;
                  return {
                    ...item,
                    isSubmitLoading: false,
                    isUpdateEmpty: isUpdateEmpty,
                    previousUpdates: item.updates,
                  };
                });
                companyPortfolios.forEach((portfolio, index) => {
                  if (portfolio.id == portfolioCompanyUpdateId) {
                    this.companyPortfolios[index].updatesAttachments = portfolio.updatesAttachments;
                  }
                });
                this.orderedCompanyPortfolios = [...this.companyPortfolios];
                this.fileDeleting = false;
              }
            });
        } else {
          this.displayToastMessage("Error uploading file");
          this.fileUploading = false;
        }
      });
  }

  deleteGenericUpdateAttachment(genericUpdateId: number, uniqueId: string) {
    this.fileGroupId = genericUpdateId;
    this.fileDeleting = true;
    this.updatesService
      .deleteUpdatesAttachment(uniqueId)
      .pipe(
        catchError((error) => {
          this.displayToastMessage("Error detleting file");
          this.fileDeleting = false;
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response) {
          this.displayToastMessage("File deleted successfully", "success");

          this.genericUpdatesService
            .getOpenSearches(GroupType.OpenSearches)
            .pipe(
              catchError((error) => {
                this.displayToastMessage("Refresh page.");
                this.fileDeleting = false;
                return of(null);
              }),
            )
            .subscribe((response: any) => {
              if (response.data.length > 0) {
                var companyOpenSearches: OpenSearch[] = response.data.map((item: any) => {
                  const isUpdateEmpty = item.updates === "" || item.updates === null;
                  return {
                    ...item,
                    isSubmitLoading: false,
                    isUpdateEmpty: isUpdateEmpty,
                    previousUpdates: item.updates,
                  };
                });

                companyOpenSearches.forEach((openSearch, index) => {
                  if (openSearch.id == genericUpdateId) {
                    this.companyOpenSearches[index].updatesAttachments = openSearch.updatesAttachments;
                  }
                });
                this.fileDeleting = false;
              }
            });
        } else {
          this.displayToastMessage("Error uploading file");
          this.fileUploading = false;
        }
      });
  }

  getIsSubmitDisabled(index: number, updates: NgModel) {
    if (this.selectedPublishedDate) {
      const selectedDate = new Date(this.selectedPublishedDate);
      return selectedDate != new Date() && !this.companyPortfolios[index].isSubmitLoading;
    }
    return !updates.dirty;
  }

  getCompanyType(index: number): string | undefined {
    if (!this.companyPortfolios[index].company) return undefined;
    return this.companyTypes.find((x) => x.id === this.companyPortfolios[index].company?.companyType)?.name;
  }

  get isInvestmentCommitteeAdmin() {
    return this.authService.investmentcommitteeadmin;
  }

  get isPublishButtonVisible() {
    const user = this.authService.user.getValue();
    if (user) {
      return user.email === "jarvis@bpc.com";
    }
    return false;
  }

  get isPublishDisabled() {
    // Return true if all the updates are empty
    return (this.companyPortfolios.every((portfolio) => !portfolio.updates) &&
      this.companyOpenSearches.every((openSearches) => !openSearches.updates)) ||
      this.selectedPublishedDate
      ? new Date(this.selectedPublishedDate!) != new Date()
      : false || this.isPublishLoading;
  }

  get isHistory() {
    return this.selectedPublishedDate ? new Date(this.selectedPublishedDate!) != new Date() : false;
  }

  constructor(
    protected router: Router,
    public portfolioService: CompanyPortfolioService,
    private messageService: MessageService,
    private companyTypeService: CompanyTypeService,
    private readonly destroy$: DestroyObservable,
    private tabUtilsService: TabUtilsService,
    private authService: AuthService,
    private genericUpdatesService: GenericUpdatesService,
    private updatesService: UpdatesService,
    private genericPopupService: GenericPopupService,
    private deviceDetectorService: DeviceDetectorService,
    private globalModalService: GlobalModalService,
  ) {
    this.companyTypeService.companyTypes$.pipe(takeUntil(destroy$)).subscribe((response) => {
      this.companyTypes = response.data ?? [];
    });
  }
  ngOnInit() {
    this.getPublishedTimeStamps();
    this.getPortfolioUpdates();
    this.getOpenSearches();
    this.updateQueue
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        concatMap(({ index, updates }) => this.executeUpdatePortfolio(index, updates)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.updateQueueOpenSearches
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        concatMap(({ index, updates }) => this.executeUpdateOpenSearches(index, updates)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onDetailsClick(companyId: string) {
    this.globalModalService.openCompanyModal(companyId);
  }

  private executeUpdatePortfolio(index: number, updates: string) {
    return new Observable((observer) => {
      this.companyPortfolios[index].isSubmitLoading = true;
      this.portfolioService
        .updatePortfolio(this.companyPortfolios[index].id, updates, this.companyPortfolios[index].previousUpdates)
        .pipe(
          catchError((error) => {
            this.companyPortfolios[index].isSubmitLoading = false;
            this.displayToastMessage("error updating portfolio. Please try again later", "error");
            return of(null);
          }),
        )
        .subscribe((response: any) => {
          console.log(this.companyPortfolios);
          if (response.data?.result) {
            this.companyPortfolios[index].previousUpdates = updates;
            this.companyPortfolios[index].isUpdateEmpty = false;
            this.companyPortfolios[index].isSubmitLoading = false;
            this.displayToastMessage("Portfolio updated successfully", "success");
            observer.next();
            observer.complete();
          } else if (response.error) {
            this.companyPortfolios[index].isSubmitLoading = false;
            this.displayToastMessage(
              response.error.response?.message ?? "error updating portfolio. Please try again later",
              "error",
            );
            observer.next();
            observer.complete();
          }
        });
    });
  }

  private executeUpdateOpenSearches(index: number, updates: string) {
    return new Observable((observer) => {
      this.companyOpenSearches[index].isSubmitLoading = true;
      this.genericUpdatesService
        .postGenericUpdate(this.companyOpenSearches[index].id, this.companyOpenSearches[index])
        .pipe(
          catchError((error) => {
            this.companyOpenSearches[index].isSubmitLoading = false;
            this.displayToastMessage("error updating open searches. Please try again later", "error");
            return of(null);
          }),
        )
        .subscribe((response: any) => {
          if (response.data?.result) {
            this.companyOpenSearches[index].previousUpdates = updates;
            this.companyOpenSearches[index].isUpdateEmpty = false;
            this.companyOpenSearches[index].isSubmitLoading = false;
            this.displayToastMessage("Open searches updated successfully", "success");
            observer.next();
            observer.complete();
          } else if (response.error) {
            this.companyOpenSearches[index].isSubmitLoading = false;
            this.displayToastMessage(
              response.error.response?.message ?? "error updating open searches. Please try again later",
              "error",
            );
            observer.next();
            observer.complete();
          }
        });
    });
  }

  getPublishedTimeStamps() {
    this.portfolioService.getPublishedDateTimes().subscribe((response: any) => {
      this.publishedDates = [{ label: "Current", value: null }];
      if (response.data && response.data.data.length > 0) {
        let dates = response.data.data.map((item: any) => {
          return {
            label: moment(item.publishedTimestamp).format("MM/DD/YYYY"),
            value: item.publishedTimestamp,
          };
        });
        dates = dates.sort((a: any, b: any) => {
          return new Date(b.value).getTime() - new Date(a.value).getTime();
        });
        this.publishedDates = [...this.publishedDates, ...dates];
      }
    });
  }

  getPortfolioUpdates(date?: Date) {
    this.loading = true;
    this.portfolioService
      .getUpdates(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response.data.length > 0) {
          this.companyPortfolios = response.data.map((item: any) => {
            const isUpdateEmpty = item.updates === "" || item.updates === null;
            return { ...item, isSubmitLoading: false, isUpdateEmpty: isUpdateEmpty, previousUpdates: item.updates };
          });
          this.openAccordionCount = 0;
          this.orderedCompanyPortfolios = [...this.companyPortfolios];
        }
        this.loading = false;
      });
  }

  getOpenSearches(date?: Date) {
    this.loadingOpenSearches = true;
    this.genericUpdatesService
      .getOpenSearches(GroupType.OpenSearches, date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response.data) {
          this.companyOpenSearches = response.data.map((item: any) => {
            const isUpdateEmpty = item.updates === "" || item.updates === null;
            return { ...item, isSubmitLoading: false, isUpdateEmpty: isUpdateEmpty, previousUpdates: item.updates };
          });
        }
        this.loadingOpenSearches = false;
      });
  }

  onPublishDateChange(event: any) {
    this.getPortfolioUpdates(event.value);
    this.getOpenSearches(event.value);
  }

  publishPortfolio() {
    if (
      this.companyPortfolios.every((portfolio) => portfolio.isUpdateEmpty) &&
      this.companyOpenSearches.every((openSearch) => openSearch.isUpdateEmpty)
    ) {
      this.displayToastMessage("There are no updates to publish. Please save updates first", "error");
      return;
    }
    this.isPublishLoading = true;
    this.isPublishOpenSearchLoading = true;
    this.portfolioService
      .publishPortfolio()
      .pipe(
        catchError((error) => {
          this.isPublishLoading = false;
          this.isPublishOpenSearchLoading = false;
          this.displayToastMessage("error publishing updates and open searches. Please try again later", "error");
          return of(null);
        }),
      )
      .subscribe((response: any) => {
        if (response.data) {
          this.isPublishLoading = false;
          this.isPublishOpenSearchLoading = false;
          this.visible = false;
          this.displayToastMessage("Portfolio and open searches published successfully", "success");
          this.getPortfolioUpdates();
          this.getOpenSearches();
          this.getPublishedTimeStamps();
        }
      });
  }

  isImageSizeValid(imgTag: string) {
    const maxSizeInBytes = 500 * 1024; // 1MB
    const base64Match = imgTag.match(/src="data:image\/([a-zA-Z]*);base64,([^"]*)"/); // Extract base64 data
    if (base64Match) {
      const base64Data = base64Match[2];
      const binaryString = atob(base64Data);
      const fileSizeInBytes = binaryString.length;
      if (fileSizeInBytes > maxSizeInBytes) {
        return false;
      }
      return true;
    }
    return false;
  }

  limitText(event: EditorTextChangeEvent, portfolio: Portfolio) {
    var index = this.companyPortfolios.findIndex((item) => item == portfolio);
    const limit = 10000;
    if (event.htmlValue) {
      let flag = 0;
      const imgTags = event.htmlValue.match(/<img[^>]+>/g); // Find all img tags
      if (imgTags) {
        imgTags.forEach((imgTag: string) => {
          const isValid = this.isImageSizeValid(imgTag);
          if (!isValid) {
            this.displayToastMessage("Image size exceeds 400kB. Please try again with smaller image", "error");
            event.htmlValue = event.htmlValue.replace(imgTag, "");
            flag = 1;
          }
        });
        if (flag == 1) {
          const editor = this.editors.toArray()[index];
          const quill = editor.getQuill();
          const delta = quill.clipboard.convert(event.htmlValue);
          quill.setContents(delta, "silent");
          return;
        }
      }
    }
    if (event.htmlValue && event.htmlValue.length > limit) {
      this.companyPortfolios[index].updates = `${event.htmlValue.substring(0, limit)}`;
      const editor = this.editors.toArray()[index];
      const quill = editor.getQuill();
      quill.deleteText(limit, quill.getLength());
    }

    this.updateQueue.next({ index, updates: event.htmlValue });
  }

  limitTextOpenSearches(event: EditorTextChangeEvent, index: number) {
    const limit = 10000;
    if (event.htmlValue) {
      let flag = 0;
      const imgTags = event.htmlValue.match(/<img[^>]+>/g); // Find all img tags
      if (imgTags) {
        imgTags.forEach((imgTag: string) => {
          const isValid = this.isImageSizeValid(imgTag);
          if (!isValid) {
            this.displayToastMessage("Image size exceeds 400kB. Please try again with smaller image", "error");
            event.htmlValue = event.htmlValue.replace(imgTag, "");
            flag = 1;
          }
        });
        if (flag == 1) {
          const editor = this.editors.toArray()[index];
          const quill = editor.getQuill();
          const delta = quill.clipboard.convert(event.htmlValue);
          quill.setContents(delta, "silent");
          return;
        }
      }
    }
    if (event.htmlValue && event.htmlValue.length > limit) {
      this.companyPortfolios[index].updates = `${event.htmlValue.substring(0, limit)}`;
      const editor = this.editors.toArray()[index];
      const quill = editor.getQuill();
      quill.deleteText(limit, quill.getLength());
    }

    this.updateQueueOpenSearches.next({ index, updates: event.htmlValue });
  }

  updatePortfolio(index: number, updates: string) {
    // if (this.companyPortfolios[index].updates) {

    // } else {
    //   this.companyPortfolios[index].isSubmitLoading = false;
    //   this.displayToastMessage("Updates are not valid", "error");
    // }

    if (
      this.companyPortfolios[index].updates &&
      !this.companyPortfolios[index].updates.includes("<img src") &&
      this.companyPortfolios[index].updates.replace(/<[^>]*>/g, "") == ""
    ) {
      this.companyPortfolios[index].updates = this.companyPortfolios[index].updates.replace(/<[^>]*>/g, "");
    }

    this.companyPortfolios[index].isSubmitLoading = true;
    this.portfolioService
      .updatePortfolio(
        this.companyPortfolios[index].id,
        this.companyPortfolios[index].updates,
        this.companyPortfolios[index].previousUpdates,
      )
      .pipe(
        catchError((error) => {
          this.companyPortfolios[index].isSubmitLoading = false;
          this.displayToastMessage("error updating portfolio. Please try again later", "error");
          return of(null);
        }),
      )
      .subscribe((response: any) => {
        console.log(this.companyPortfolios);
        if (response.data?.result) {
          this.companyPortfolios[index].previousUpdates = this.companyPortfolios[index].updates;
          // updates.control.markAsPristine();
          this.companyPortfolios[index].isUpdateEmpty = false;
          this.companyPortfolios[index].isSubmitLoading = false;
          this.displayToastMessage("Portfolio updated successfully", "success");
        } else if (response.error) {
          this.companyPortfolios[index].isSubmitLoading = false;
          this.displayToastMessage(
            response.error.response?.message ?? "error updating portfolio. Please try again later",
            "error",
          );
        }
      });
  }

  updateOpenSearches(index: number, updates: string) {
    // if (this.companyPortfolios[index].updates) {

    // } else {
    //   this.companyPortfolios[index].isSubmitLoading = false;
    //   this.displayToastMessage("Updates are not valid", "error");
    // }

    if (
      this.companyOpenSearches[index].updates &&
      !this.companyOpenSearches[index].updates.includes("<img src") &&
      this.companyOpenSearches[index].updates.replace(/<[^>]*>/g, "") == ""
    ) {
      this.companyOpenSearches[index].updates = this.companyOpenSearches[index].updates.replace(/<[^>]*>/g, "");
    }

    this.companyOpenSearches[index].isSubmitLoading = true;
    this.genericUpdatesService
      .postGenericUpdate(this.companyOpenSearches[index].id, this.companyOpenSearches[index])
      .pipe(
        catchError((error) => {
          this.companyOpenSearches[index].isSubmitLoading = false;
          this.displayToastMessage("error updating Open searches. Please try again later", "error");
          return of(null);
        }),
      )
      .subscribe((response: any) => {
        if (response.data?.result) {
          this.companyOpenSearches[index].previousUpdates = this.companyPortfolios[index].updates;
          // updates.control.markAsPristine();
          this.companyOpenSearches[index].isUpdateEmpty = false;
          this.companyOpenSearches[index].isSubmitLoading = false;
          this.displayToastMessage("Open searches updated successfully", "success");
        } else if (response.error) {
          this.companyOpenSearches[index].isSubmitLoading = false;
          this.displayToastMessage(
            response.error.response?.message ?? "error updating Open searches. Please try again later",
            "error",
          );
        }
      });
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "home-bc",
      severity,
      detail: message,
      life,
    });
  }

  onHide() {
    this.visible = false;
  }

  openModal() {
    this.visible = true;
    this.modalLoading = true;
    this.portfolioService
      .getEmailPreview()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.modalLoading = false;
        if (response.data?.result) {
          this.previewObj = response.data;
          let iframe: HTMLIFrameElement | null = document.getElementById("myIframe") as HTMLIFrameElement;
          if (iframe) {
            // TODO: this is a work around to fix the issue with iframe content styling and needs to be fixed
            let x: string = this.previewObj.data;
            // x = x.replace(/<h1>/g,"<h1 style='margin-bottom: 25px;'>");
            x = x.replace(/<h2>/g, "<h2 style='margin-bottom: 10px; margin-top: 12px;'>");
            x = x.replace(/<h4>/g, "<h4 style='margin-bottom: 6px; margin-top: 8px;'>");
            x = x.replace(/<body>/g, "<body style='line-height: 125%'>");
            iframe.srcdoc = x;
          }
        } else if (response.error) {
          this.displayToastMessage(
            response.error.response?.message ?? "error getting email preview. Please try again later",
            "error",
          );
        }
      });
  }

  onReorder(event: any): void {
    // Check if a dragged portfolio exists
    if (!this.draggedPortfolio) {
      return;
    }

    const originalIndex = this.orderedCompanyPortfolios.findIndex((item) => item.id === this.draggedPortfolio.id);
    const newIndex = this.companyPortfolios.findIndex((item) => item.id === this.draggedPortfolio.id);
    if (originalIndex !== newIndex) {
      const originalOrderedList = [...this.orderedCompanyPortfolios];

      this.portfolioService.updateCompanyOrdinal(this.draggedPortfolio.portfolioCompany.id, newIndex + 1).subscribe({
        next: (response) => {
          console.log(response);
          if (!response?.data?.result) {
            this.companyPortfolios = originalOrderedList;
            this.displayToastMessage("Could not reorder, try again!", "error");
          }
          else{
            this.orderedCompanyPortfolios = [...this.companyPortfolios];
          }
        },
        error: () => {
          this.companyPortfolios = originalOrderedList;
          this.displayToastMessage("Could not reorder, try again!", "error");
        },
      });
    }
  }

  deleteCompanyPortfolio(portfolioCompanyId: number) {
    var parameters: GenericPopupParameters = {
      portfolioCompanyId: portfolioCompanyId,
    };
    this.genericPopupService.setupPopup(GenericPopupOperation.DeletePortfolioCompany, parameters);
    this.genericPopupVisible = true;
  }

  toggleGenericPopupVisible(result: any) {
    this.genericPopupVisible = false;
    if (result) {
      this.getPortfolioUpdates();
    }


  }
  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }
  
  getInitials(name: string): string {
    if (!name) return "";

    const words = name.trim().split(/\s+/);
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");

    return initials.length > 2 ? initials.slice(0, 2) : initials;
  }
}
