import { Pipe, PipeTransform } from '@angular/core';

import { ModalActions } from '../../models/types';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'negativeButtonLabel'
})
export class NegativeButtonLabelPipe implements PipeTransform {

  transform(modalAction: ModalActions): Nullable<string> {
    switch (modalAction) {
      case 'joinCoverage':
      case 'removeFromJarvis':
      case 'markAsActive':
      case 'addToPriority':
      case 'extendPriority':
      case 'closeCompanyDetails':
      case 'leaveCoverage':
      case 'DeleteChatThread':
        return 'No';
      case 'changeStatus':
      case 'editCoverage':
      case 'removeFromPriority':
      case 'actionTaken':
      case 'icVoting':
      case 'removeFromIC':
      case 'flagForICVoting':
      case 'editICAsk':
      case 'editICDateAdded':
      case 'editOutReachOwner':
      case 'editNextStep':
      case 'archiveCompany':
      case 'removeSavedSearch':
      case 'removeCronJob':
      case 'updateCronJob':
      case 'createOrEditNotificationTrigger':
      case 'AddUser':
      case 'EditUser':
      case 'UserInactiveToggle':
      case 'UserActiveToggle':
      case 'UpdateChatThread':
      case 'addToPortfolio':
        return 'Cancel';
      case 'enrichData':
        return 'Cancel';
      case 'editInvestors':
          return 'Cancel';
      
    }
  }
}