<p-toast position="bottom-center" key="bc"></p-toast>

<bpc-chatbot #chatbotExtensionRef *ngIf="isExtension">
</bpc-chatbot>
              
<bpc-company-details
    *ngIf="displayCompanyDetails"
    [tabs]="tabs"
    [currentTabIndex]="currentTabIndex"
    [currentChildIndex]="currentChildIndex"
    [mainFilterRaw]="mainFilterRaw"
    (closePanel)="closeCompanyDetailsPanel($event)"
    (closePanelWithInvestor)="closeCompanyDetailsInvestorPanel($event)"
    (upsertDeletedTags)="upsertDeletedTags($event)"
    (enrichmentStatusChange)="updateEnrichmentStatus($event)"
    (navigateThruCompanies)="navigateThruCompanies($event)"
    (emitWeeklySubscription)="emitWeeklySubscription($event)"
    [enableNavigation]="enableCompaniesNavigation"
    [enableEnrichment]="!disableEnrich"
    [enableICVoting]="!disableICVoting"
    [_companyDetails]="companyDetails"
    [loading]="companyDetailsLoading"
    [weeklySubscription]="weeklySubscription"
    [dataView]="dataView"
  >
  </bpc-company-details>
  <!-- <bpc-ai-prep-report #aiPrepReportExtensionRef *ngIf="isExtension"
    [currentTabIndex]="currentTabIndex" [currentChildIndex]="currentChildIndex">
  </bpc-ai-prep-report> -->

<section class="container" *ngIf="!isExtension" appPreventBounce>
  <ng-container *ngIf="!isExtension">
    <aside [ngClass]="{ expanding: isExpanding, extenisonView: isExtension }" *ngIf="currentTab.hasFilters">
      <!-- <a class="btn-drawer" (click)="toggleSideBar()">
        <span class="icon-drawer"><span class="path1"></span><span class="path2"></span></span>
      </a> -->
      <bpc-main-filter
        #mainFilterChildRef
        (mainFilterRawChange)="beforeApplyMainFilterRaw($event)"
        [mainFilterRaw]="mainFilterRaw"
        [currentTabIndex]="currentTabIndex"
        [isExpanding]="isExpanding"
      ></bpc-main-filter>
    </aside>
  </ng-container>
  <div class="content-wrapper" [ngClass]="{ expandingContent: isExpanding }">
    <div class="content">
      <bpc-grid-tabs
        #gridTabRef
        [currentTabIndex]="currentTabIndex"
        [currentChildIndex]="currentChildIndex"
        [loading]="loading"
        (clearFiltersEmitter)="clearAllFilters()"
        (menuClickEmitter)="onMenuClick($event)"
        [companyDetails]="companyDetails"
        [tabType]="tabType"
        (DataViewEmitter)="onDataView($event)"
        [companyDetailsLoading] = "companyDetailsLoading"
      >
      </bpc-grid-tabs>
    </div>
  </div>
</section>

<button
  *ngIf="showBackToTopButton$ | async"
  @inOut
  class="fixed bottom-16 right-8 w-12 h-12 bg-gray-300 rounded-sm hover:opacity-80"
  (click)="scrollToTop()"
>
  <img class="h-6 mx-auto" src="assets/icons/top-arrow.svg" alt="Top" />
</button>



<!-- <p-dialog [(visible)]="votingService.displayVotingStats" [showHeader]="false" contentStyleClass="header-less">
  <bpc-voting-stats *ngIf="votingService.displayVotingStats && votingService.votingStats"></bpc-voting-stats>
</p-dialog> -->

<!-- <bpc-custom-modal [visible]="votingService.displayVotingStats"> -->
<bpc-voting-stats
  *ngIf="votingService.displayVotingStats && votingService.votingStats"
  [visible]="votingService.displayVotingStats"
  [tabs]="tabs"
  [currentTabIndex]="currentTabIndex"
  [currentChildIndex]="currentChildIndex"
></bpc-voting-stats>

<bpc-comment-section   
[currentTabIndex]="currentTabIndex"
[currentChildIndex]="currentChildIndex"
></bpc-comment-section>
<!-- </bpc-custom-modal> -->

<!-- Mobile Tab Data ( show only in mobile devices) -->
<!-- 
<bpc-mobile-tabs
*ngIf="isMobile"
></bpc-mobile-tabs> -->
