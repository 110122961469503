<div class="responsive-container">
  <bpc-generic-listing [title]="'Companies'" [columns]="companiesColumns" [hasPod]="true" [barType]="HorizontalBarType.Stage" [data]="companies" [totalCount]="totalCount" [isLoading]="isLoading" (onFilterChange)="onFilterChange($event)" (onLoadMore)="loadMoreData()">
    <div slot="header-options" class="col flex align-content-center flex-wrap flex-w-auto">
      <div class="col flex align-content-center flex-wrap flex-w-auto">
        <button 
          (click)="toggleDataView('table')"
          [class.bg-orange-500]="dataView === 'table'" 
          [class.text-white]="dataView === 'table'" 
          class="px-3 py-2 text-orange-500 border-orange-500 border-1 button-radius-left">
          <i class="pi pi-table"></i>
        </button>
        <button style="margin-left: -2px;" 
          (click)="toggleDataView('chart')"
          [class.bg-orange-500]="dataView === 'chart'" 
          [class.text-white]="dataView === 'chart'" 
          class="px-3 py-2 bg-orange-500 border-orange-500 border-1 text-orange-500 button-radius-right">
          <i class="pi pi-chart-bar"></i>
        </button>
      </div>
      <div class="col flex align-content-center flex-wrap flex-w-auto">
        <bpc-circular-download-progress [loading]="true" />
      </div>
    </div>
  </bpc-generic-listing>

  <!--<bpc-company-details-v2 [companyId]="companyId"/>-->
</div>
