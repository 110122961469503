import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CompaniesService } from "../../services/companies/companies.service";
import { Company } from "../../core/models/company";
import { ActivatedRoute } from "@angular/router";
import { TabUtilsService } from "../../services/tab-utils.service";
import { GlobalModalService } from "../../core/services/global-modal.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "bpc-company-details-v2",
  templateUrl: "./company-details-v2.component.html",
  styleUrl: "./company-details-v2.component.scss",
})
export class CompanyDetailsV2Component implements OnInit, OnChanges {
  company: Company | null = null;
  @Input() companyId: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    public tabUtilsService: TabUtilsService,
    private globalModalService: GlobalModalService,
    private deviceService: DeviceDetectorService,
  ) {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id"); // Retrieve 'id' parameter
      if (id) {
        this.fetchCompanyDetails(+id); // Convert to number if needed
      }
    });
    this.globalModalService.companyId$.subscribe((companyId) => {
      if (companyId) {
        this.companyId = companyId;
        this.fetchCompanyDetails(+companyId);
      } else {
        this.companyId = undefined;
        this.company = null;
      }
    });
  }

  ngOnInit(): void {
    if (this.companyId) {
      this.fetchCompanyDetails(+this.companyId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["companyId"] && changes["companyId"].currentValue && this.companyId) {
      this.fetchCompanyDetails(+this.companyId);
    }
  }

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get displayCompanyDetails(){
    return this.globalModalService.displayCompanyDetails;
  }

  fetchCompanyDetails(companyId: number) {
    this.globalModalService.displayCompanyDetails = true;
    this.companiesService.getCompanyDetails(companyId).subscribe((response) => {
      if (response.data)
        this.company = response.data;
    });
  }

  closeCompanyDetailsPanel(e: any) {
    this.globalModalService.displayCompanyDetails = false;
    this.companyId = undefined;
    this.company = null;
    this.globalModalService.close();
  }
}
