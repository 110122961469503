import { Environment } from '@models/environment';

export const environment: Environment = {
  production: false,
  baseApiUrl: '/api',
  aiPrepApiUrl: "/ai-prep",
  firmWideElevationStatus: 13,
  weeklyElevationTag: '103018',
  mixpanelToken: "ed5185bd7e02208d66b5ac7eecd34a74",
  mixpanelDebug: true,
  config: {
    supportEmail: 'jarvis@folio3.com',
    appTitle: 'BPC Jarvis [Development]',
    navigateThruCompanies: true,
    loginType: 'mixed',
    adConfiguration: {
      authority: 'https://login.microsoftonline.com/4340cbe8-e55a-4c7a-a9e6-b510f34d1888/v2.0',
      clientId: 'c7cc18de-be64-43be-ae2e-649257754234',
      scope: 'openid api://03aa03bd-c2fd-4ad3-ae1f-f33c7c54d14d/api-scope'
    }
  }
};
