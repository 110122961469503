<div class="p-paginator-bottom p-paginator p-component">
    <span class="p-paginator-current">{{paginationText}}</span>
    <button
      [disabled]="loading"
      type="button"
      class="pi pi-angle-double-left cursor-pointer p-paginator-first"
      [class.hidden]="currentTotalPages === 1"
      [class.p-disabled]="disableFirstPage"
      (click)="goToPage(1)"
    ></button>
    <button
      [disabled]="loading"
      type="button"
      class="pi pi-angle-left cursor-pointer p-paginator-prev"
      [class.hidden]="currentTotalPages === 1"
      [class.p-disabled]="disableFirstPage"
      (click)="goToPage(currentPage === 1 ? currentPage: currentPage - 1)"
    ></button>
    <span class="p-paginator-pages">
      <button
        [disabled]="loading"
        type="button"
        class="p-ripple p-element p-paginator-page p-paginator-element p-link"
        (click)="goToPage(1)"
        *ngIf="currentPage != 1"
      >
        1
      </button>
      <span *ngIf="currentPage != 2 && currentPage + 1 != 2">...</span>

      <button
        [disabled]="loading"
        type="button"
        class="p-ripple p-element p-paginator-page p-paginator-element p-link p-highlight"
        (click)="goToPage(currentPage)"
      >
        {{ currentPage }}
      </button>
      <button
        [disabled]="loading"
        type="button"
        class="p-ripple p-element p-paginator-page p-paginator-element p-link"
        *ngIf="currentPage < currentTotalPages"
        (click)="goToPage(currentPage + 1)"
      >
        {{ currentPage + 1 }}
      </button>
      <span *ngIf="currentPage + 1 < currentTotalPages">...</span>
      <button
        [disabled]="loading"
        type="button"
        class="p-ripple p-element p-paginator-page p-paginator-element p-link"
        *ngIf="currentPage + 1 < currentTotalPages"
        (click)="goToPage(currentTotalPages)"
      >
        {{ currentTotalPages }}
      </button>
    </span>
    <button
      [disabled]="loading"
      type="button"
      class="pi pi-angle-right cursor-pointer p-paginator-next"
      [class.hidden]="currentTotalPages === 1"
      [class.p-disabled]="disableLastPage"
      (click)="goToPage(currentPage === currentTotalPages? currentTotalPages: currentPage + 1)"
    ></button>
    <button
      [disabled]="loading"  
      type="button"
      class="pi pi-angle-double-right cursor-pointer p-paginator-last"
      [class.hidden]="currentTotalPages === 1"
      [class.p-disabled]="disableLastPage"
      (click)="goToPage(currentTotalPages)"
    ></button>
    <p-dropdown
      [disabled]="loading"
      styleClass="grid-pagination"
      (ngModelChange)="pageSizeChanged($event)"
      [autoDisplayFirst]="false"
      [options]="[25, 50, 100]"
      [placeholder]="pageSize.toString()"
      [ngModel]="pageSize"
    >
    </p-dropdown>
  </div>