import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { GenericListingService } from "../../services/generic-listing.service";
import { AssigneeRole } from "../../models/main-filter-view";
import { PodsDropdownComponent } from "../pods-dropdown/pods-dropdown.component";
import { ColumnMapping } from "../dynamic-table/dynamic-table.component";
import { Router } from "@angular/router";

export enum HorizontalBarType {
  None = "none",
  Stage = "stage",
  Interaction = "interaction",
}

enum FilterType {
  Bar = "bar",
  Pod = "pod",
}

export interface SubRoute {
  label: string;
  route: string;
}

@Component({
  selector: "bpc-generic-listing",
  templateUrl: "./generic-listing.component.html",
  styleUrl: "./generic-listing.component.scss",
})
export class GenericListingComponent implements OnInit {
  HorizontalBarType = HorizontalBarType;
  FilterType = FilterType;
  @ViewChild("podsDropdown") podsDropdown!: PodsDropdownComponent;

  @Input() isLoading = false;
  @Input() title = "Listing";
  @Input() hasPod = false;
  @Input() barType = HorizontalBarType.None;

  currentRoute = "";
  hasPodSelected = false;
  barData: any = [];
  subRoutes: any = [
    { label: "Companies", route: "/v2/companies" },
    { label: "Interactions", route: "/v2/companies/interactions" },
  ];

  @Input() columns: ColumnMapping[] = [];
  @Input() data = [];
  @Input() totalCount = 0;

  @Output() onFilterChange = new EventEmitter<{}>();
  @Output() onLoadMore = new EventEmitter<boolean>();

  constructor(
    private route: Router,
    private deviceDetectorService: DeviceDetectorService,
    private genericListingService: GenericListingService,
  ) {
    this.route.events.subscribe(() => {
      this.currentRoute = this.route.url;
    });
  }

  ngOnInit(): void {
    this.fetchBarData(undefined);
  }

  fetchBarData(ids: number[] | undefined) {
    if (this.barType !== HorizontalBarType.None) {
      this.genericListingService.fetchBarData(this.barType, ids).subscribe((data) => {
        this.barData = data;
        /*
        if (data) {
          const stages = data;

          this.barData = [];
          stages.forEach((stage) => {
            if (stage.dispalyName === "Perl Company") stage.dispalyName = "Pearl";
            stage.selected = false;
            this.barData.push(stage);
          });

          this.barData.sort((a: any, b: any) => b.ordinal - a.ordinal);

          this.barData[0].dispalyName = `${this.barData[0].dispalyName} / ${this.barData[1].dispalyName} / ${this.barData[2].dispalyName}`;
          this.barData[0].count += this.barData[1].count + this.barData[2].count;
          //this.checkBar();
        }
        */
      });
    }
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  filterData(e: any, type: string) {
    const rawValueMapping: any = {};
    const filters: any = {};

    if (type === FilterType.Bar) {
      if (this.barType === HorizontalBarType.Stage) {
        filters.statusesInclude = e.value.id !== undefined ? [e.value.id] : undefined;
        rawValueMapping["statusesInclude"] = filters.statusesInclude;
      } else if (this.barType === HorizontalBarType.Interaction) {
        filters.interactionTypes = e.value.id !== undefined ? [e.value.id, -1] : undefined;
        rawValueMapping["interactionTypes"] = filters.interactionTypes;
        //filters.rawValueMapping =
        //('{"dcCompanyTypes":[{"id":6,"name":"Target"}],"interactionTypes":[{"id":0,"name":"Notes"}]}');
      }
    } else if (type === FilterType.Pod) {
      const includeString =
        this.barType === HorizontalBarType.Stage ? "assigneesInclude" : "interactionInitiatorsInclude";
      filters[includeString] = e.members.map((m: any) => m.userId);
      rawValueMapping[includeString] = filters[includeString];
      filters.assigneeRole = AssigneeRole.COVERAGE;

      if (filters[includeString]?.length === 0) {
        delete filters[includeString];
        this.fetchBarData(undefined);
      } else {
        this.fetchBarData(filters[includeString]);
      }
    }

    this.onFilterChange.emit(filters);
  }

  loadMoreData() {
    this.onLoadMore.emit(true);
  }

  toggleFilter() {
    this.hasPodSelected = !this.hasPodSelected;
    if (!this.hasPodSelected) {
      this.onFilterChange.emit({
        assigneesInclude: undefined,
        interactionInitiatorsInclude: undefined,
      });
      this.fetchBarData(undefined);
      this.podsDropdown.resetActivePod();
    }
  }

  onPageChange(pageNumber: number) {
    this.onFilterChange.emit({ pageNumber });
  }

  onPageSizeChange(pageSize: number) {
    this.onFilterChange.emit({ pageNumber: 1, pageSize });
  }
}
