<bpc-custom-modal
    [modal]="true"    
    [resizable]="false"
    [closeOnEscape]="true"
    [visible]="visible"
    (onHide)="onNegativeClick()"
    >
    <ng-container modal-header>
      <span class="p-dialog-title" style="width: 600px">
        {{header}}
      </span>
    </ng-container>
    <ng-container modal-content>
      <section id="workflow-email-container" class="voting-modal-wrapper" *ngIf="operation == 0" >
        <div class="dealteam-header">
          <h4 >
            Deal Team
          </h4>
        </div>
        <section id="dealteam-wrapper" class="listview-wrapper">
          <div class="grid grid-no-tb-gutter">
            <div class="col flex-w-auto" *ngFor="let user of parameters.companyWorkflowUsers">
              <span
                class="cursor-pointer voting-members  space"
                [overlayPanelTooltip]="VOTEDATA"
              >
                <div class="image-container">
                  <img
                    class="profile-image cursor-pointer"
                    [src]="user?.user?.profilePictureUrl ? user?.user?.profilePictureUrl : 'assets/no-image.png'"
                    alt="{{ user.user.name }}"
                  />
                </div>
              </span>
              <p-overlayPanel #VOTEDATA>
                <ng-template pTemplate="content">
                  <div class="flex items-center justify-between border-b w-72 pb-4 mt-2">
                    <div>
                      <div class="font-bold text-xl mb-1">{{ user.user.name }}</div>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </div>
          </div>
        </section>
      </section>
    </ng-container>
    <ng-container modal-footer >
      <div>
        <button
          pButton
          label="Cancel"
          (click)="onNegativeClick()"
        ></button>
        <button
          pButton
          class="button-dark"
          type="submit"
          label="Yes"
          [loading]="operationStatus"
          [disabled]="positiveButtonDisabled"
          (click)="onPositiveClick()"
        ></button>
      </div>
    </ng-container>
</bpc-custom-modal>