<ng-container *ngIf="displayCompanyDetails">
  <bpc-company-details
    [tabs]="tabs"
    [currentTabIndex]="1"
    [currentChildIndex]="2"
    [enableNavigation]="false"
    [enableEnrichment]="false"
    [enableICVoting]="false"
    [_companyDetails]="company"
    [loading]="true"
    [weeklySubscription]="false"
    [dataView]="'updates'"
    (closePanel)="closeCompanyDetailsPanel($event)">
  </bpc-company-details>
</ng-container>
