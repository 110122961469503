import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'easternTime'
})
export class EasternTimePipe implements PipeTransform {

  transform(value: string | Date): string {
    if (!value) {
      return '';
    }

    // Treat the input value as UTC
    const utcTime = moment.utc(value);

    // Convert to Eastern Time
    const easternTime = utcTime.tz('America/New_York');

    // Format the date (e.g., "MMM DD, YYYY h:mm A")
    return easternTime.format('MMM DD, YYYY h:mm A');
  }
}
