<div class="responsive-container">
  <!-- Table View -->

  
  <ng-container *ngIf="!isMobile; else mobileView">
    <div class="submenu-wrapper filter-container-responsive">
      <div class="grid grid-no-tb-gutter flex justify-content-between">
        <div class="col flex align-content-center flex-wrap flex-w-auto filter-pod-container">
          <ng-container *ngFor="let subroute of subRoutes">
            <a class="vote-tab-buttons" [routerLink]="subroute.route" [ngClass]="{'active-ic-vote-tab': subroute.route === currentRoute}">
              {{subroute.label}}
            </a>
          </ng-container>
        </div>
        
        <div class="col flex align-content-center flex-w-auto">
          <div class="grid align-content-center p-30">
            <div *ngIf="hasPod">
              <bpc-pods-dropdown #podsDropdown [includeCompaniesPod]="true" (onChange)="filterData($event, FilterType.Pod)" />
            </div>
            <ng-content select="[slot=header-options]"></ng-content>
          </div>
        </div>
      </div>
    </div>

    <bpc-horizontal-bar *ngIf="barType !== HorizontalBarType.None && barData.length > 0" [useOldVersion]="false" [data]="barData" (onSelection)="filterData($event, FilterType.Bar)" />

    <bpc-dynamic-table [data]="data" [columns]="columns" [pageSize]="100" [totalCount]="totalCount" [loading]="isLoading">
      <bpc-paginator [pageSize]="100" [totalCount]="totalCount" [loading]="isLoading" (onPageChange)="onPageChange($event)" (onPageSizeChange)="onPageSizeChange($event)"/>
    </bpc-dynamic-table>
  </ng-container>
  

  <!-- Card View -->
  <ng-template #mobileView>
    <div class="sticky-bar">
      <div class="sticky-bar-left">
        <span>{{title}}</span>
      </div>
      <div *ngIf="hasPod" class="sticky-bar-right">
        <i class="pi pi-filter filter-icon" [ngClass]="hasPodSelected? 'active': 'inactive'"
          (click)="toggleFilter()"></i>
      </div>
    </div>

    <div class="filters">
      <bpc-horizontal-bar *ngIf="barType !== HorizontalBarType.None && barData.length > 0" [useOldVersion]="false" [data]="barData" (onSelection)="filterData($event, FilterType.Bar)" />
      <div [hidden]="!hasPodSelected">
        <div class="filter-container stage-dropdown-container">
          <bpc-pods-dropdown #podsDropdown [includeCompaniesPod]="true" (onChange)="filterData($event, FilterType.Pod)" />
        </div>
      </div>
    </div>

    <bpc-dynamic-cards [data]="data" [loading]="isLoading" />

    <span *ngIf="totalCount > data.length" class="load-more" (click)="loadMoreData()">
      <h1 *ngIf="!isLoading">Load More...</h1>
    </span>
  </ng-template>
</div>
