<section class="portfolio-container">
  <div class="grid grid-no-tb-gutter flex justify-content-between align-items-center">
    <!-- Left Side: Portfolio Button -->
    <div class="col flex align-content-center flex-wrap flex-w-auto vote-tab-container">
      <ng-content></ng-content>
    </div>

    <!-- Right Side: Dropdown and Publish Button -->
    <div class="col flex-w-auto flex justify-content-end align-items-center">
      <div class="col flex-w-auto" style="padding-left: 0px; padding-right: 0px;">
        <p-dropdown [options]="publishedDates" [(ngModel)]="selectedPublishedDate" optionLabel="label"
          (onChange)="onPublishDateChange($event)" placeholder="Select a publish date" styleClass="w-100"
          optionValue="value">
        </p-dropdown>
      </div>
      <div class="col flex-w-auto" *ngIf="isPublishButtonVisible && !isMobile" style="padding-right: 0px;">
        <button pButton class="mr-2 button-dark" [disabled]="isPublishDisabled"
          (click)="openModal()">
          Publish
          <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
        </button>
      </div>
    </div>
    <div class="col flex-w-auto flex justify-content-end align-items-center" *ngIf="isMobile">
      <div class="col flex-w-auto" *ngIf="isPublishButtonVisible" style="padding-left: 0px; padding-right: 0px;">
        <button pButton class="mr-2 button-dark" [disabled]="isPublishDisabled"
          (click)="openModal()">
          Publish
          <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
        </button>
      </div>
    </div>
  </div>
  <section class="report-sections" *ngIf="!(loading || loadingOpenSearches); else showLoader">
    <p-orderList 
    [value]="companyOpenSearches" 
    [listStyle]="{ background: '#faf8f8' }"
    [dragdrop]="false">
      <ng-template let-openSearch pTemplate="item">
        <div>
          <p-accordion>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <div>
                  <div class="grid grid-no-tb-gutter flex justify-content-end align-items-center">
                    <div class="col" style="padding-left: 12px;">
                      <h2 class="heading">
                        {{ openSearch.genericUpdateType.description }}
                      </h2>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="grid-no-tb-gutter company-container">
                <div class="col-12 mt-2">
                  <p-editor [(ngModel)]="openSearch.updates" [style]="{ height: '180px' }" [readonly]="isHistory"
                    (onTextChange)="limitTextOpenSearches($event, 0)">
                    <p-header *ngIf="isHistory" style="display: none;"></p-header>
                    <p-header *ngIf="!isHistory">
                      <span class="ql-formats">
                        <select class="ql-header" tabindex="-1">
                          <option value="3" tabindex="-1">Heading</option>
                          <option selected tabindex="-1">Normal</option>
                        </select>
                        <select class="ql-font" tabindex="-1">
                          <option selected tabindex="-1">Sans Serif</option>
                          <option value="serif" tabindex="-1">Serif</option>
                          <option value="monospace" tabindex="-1">Monospace</option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-bold" aria-label="Bold" tabindex="-1" tooltipPosition="bottom" placeholder="Bottom"
                          pTooltip="Bold"></button>
                        <button class="ql-italic" aria-label="Italic" tabindex="-1" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Italic"></button>
                        <button class="ql-underline" aria-label="Underline" tabindex="-1" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Underline"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color" tabindex="-1"></select>
                        <select class="ql-background" tabindex="-1"></select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                        <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                        <select class="ql-align" tabindex="-1">
                          <option selected tabindex="-1"></option>
                          <option value="center" tabindex="-1"></option>
                          <option value="right" tabindex="-1"></option>
                          <option value="justify" tabindex="-1"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-link" aria-label="Insert Link" tabindex="-1"></button>
                        <input type="file" #fileInput (change)="genericUpdateFileChangeEvent($event, openSearch.id)"
                          style="display: none" [disabled]="fileUploading" />
                        <button class="fas fa-image gray-icon" style="color: gray;" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Add Attachment" (click)="fileInput.click()"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                      </span>
                    </p-header>
                  </p-editor>
                </div>
                <div class="col mtb-10">
                  <div class="tags-wrap-labels card-container"
                    *ngIf="!(fileUploading||fileDeleting) || !(openSearch.id == fileGroupId) else uploadingLoader">
                    <span *ngFor="let attachment of openSearch.updatesAttachments" class="tags">
                      <a [href]="attachment.fileUrl" target="_blank" rel="noopener noreferrer"
                        class="attachment-text">{{attachment.fileName}}</a>
                      <i *ngIf="!isHistory" (click)="deleteGenericUpdateAttachment(openSearch.id, attachment.uniqueId)"
                        class="pi pi-times"></i>
                    </span>
                  </div>
                  <ng-template #uploadingLoader>
                    <section>
                      <div *ngFor="let _ of [].constructor(1)" style="display: flex; flex-direction: column;">
                        <div style="margin-top: 15px; margin-bottom: 15px;">
                          <p-skeleton styleClass="mb-2"></p-skeleton>
                          <p-skeleton styleClass="mb-2"></p-skeleton>
                        </div>
                      </div>
                    </section>
                  </ng-template>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </ng-template>
    </p-orderList>
    <div style="padding-bottom: 50px;">
      <p-orderList 
      [value]="companyPortfolios" 
      [listStyle]="{ background: '#faf8f8', }"
      [dragdrop]="openAccordionCount <= 0 && !isHistory && !isMobile"
      (onReorder)="onReorder($event)">
        <ng-template let-portfolio pTemplate="item">
          <div style="padding: 1px 0" (mousedown)="onAccordionClicked(portfolio)" (touchstart)="onAccordionClicked(portfolio)">
            <p-accordion (onOpen)="onAccordionOpen()" (onClose)="onAccordionClose()">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div>
                    <div class="grid grid-no-tb-gutter flex justify-content-end align-items-center">
                      <div class="col" style="padding-left: 12px;">
                        <h2 class="heading">
                          {{ portfolio.company.name }}
                        </h2>
                      </div>
                      <div class="col flex-w-auto">
                        <p *ngIf="portfolio.isSubmitLoading">
                          Saving...
                          <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <div>
                  <div style="display: flex; align-items: center; gap:0.3rem; margin-bottom: 5px; margin-left: 2px;">
                    <bpc-social-icon *ngFor="let link of portfolio.company | socialLinks" class="social-links"
                      [socialLink]="link">
                    </bpc-social-icon>
                    <a *ngIf="portfolio.portfolioCompany.iLevelUrl" [href]="portfolio.portfolioCompany.iLevelUrl" target="_blank">
                      <img src="assets/svg/ilevel.svg" alt="social-icon" />
                    </a>

                    <img *ngIf="!portfolio.portfolioCompany.iLevelUrl" class="opacity-30" pTooltip="Unavailable" tooltipPosition="top"
                      src="assets/svg/ilevel.svg" alt="social-icon" />
                    <a *ngIf="!isMobile" class="company-link underline" (click)="onDetailsClick(portfolio.company.id)" [routerLink]="undefined">Open Details</a>
                    <a *ngIf="isMobile"
                    class="company-link-underline"
                    [routerLink]="['/m/companies/details/' + portfolio.company.id]"         
                    >Open Details</a>               
                    <!-- Dustbin Icon -->
                    <i *ngIf="!isHistory" class="pi pi-trash" style="margin-left: auto; color: gray; cursor: pointer;" pTooltip="Delete" (click)="deleteCompanyPortfolio(portfolio.portfolioCompany.id)"></i>
                  </div>
                  <div style="margin-bottom: 10px">
                    <p-avatarGroup >
                      <p-avatar
                        *ngFor="let user of portfolio.portfolioCompany.members; let j=index"
                        [overlayPanelTooltip]="userNameTooltip"
                        label="{{ getInitials(user?.user?.name) }}"
                        size="large"
                        [styleClass]="'cyan cursor-pointer'"
                        shape="circle"
                      >
                        <p-overlayPanel styleClass="tool-tip-class" #userNameTooltip>
                          <p>{{ user?.user?.name }}</p>
                        </p-overlayPanel>
                      </p-avatar>
                    </p-avatarGroup>
                  </div>
                  <p-editor [(ngModel)]="portfolio.updates" [style]="{ height: '180px' }" [readonly]="isHistory"
                    (onTextChange)="limitText($event, portfolio)">
                    <p-header *ngIf="isHistory" style="display: none;"></p-header>
                    <p-header *ngIf="!isHistory">
                      <span class="ql-formats">
                        <select class="ql-header" tabindex="-1">
                          <option value="3" tabindex="-1">Heading</option>
                          <option selected tabindex="-1">Normal</option>
                        </select>
                        <select class="ql-font" tabindex="-1">
                          <option selected tabindex="-1">Sans Serif</option>
                          <option value="serif" tabindex="-1">Serif</option>
                          <option value="monospace" tabindex="-1">Monospace</option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-bold" aria-label="Bold" tabindex="-1" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Bold"></button>
                        <button class="ql-italic" aria-label="Italic" tabindex="-1" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Italic"></button>
                        <button class="ql-underline" aria-label="Underline" tabindex="-1" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Underline"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color" tabindex="-1"></select>
                        <select class="ql-background" tabindex="-1"></select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                        <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                        <select class="ql-align" tabindex="-1">
                          <option selected tabindex="-1"></option>
                          <option value="center" tabindex="-1"></option>
                          <option value="right" tabindex="-1"></option>
                          <option value="justify" tabindex="-1"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <input type="file" #fileInput (change)="fileChangeEvent($event, portfolio.id)"
                          style="display: none" [disabled]="fileUploading" />
                        <button class="fas fa-image gray-icon" style="color: gray;" tooltipPosition="bottom"
                          placeholder="Bottom" pTooltip="Add Attachment" (click)="fileInput.click()"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                      </span>
                    </p-header>
                  </p-editor>
                </div>
                <div class="col mtb-10" *ngIf="portfolio.updatesAttachments.length > 0 || fileUploading || fileDeleting">
                  <div class="tags-wrap-labels card-container"
                    *ngIf="!(fileUploading || fileDeleting) || !(portfolio.id == fileGroupId) else uploadingLoader">
                    <span *ngFor="let attachment of portfolio.updatesAttachments" class="tags">
                      <a [href]="attachment.fileUrl" target="_blank" rel="noopener noreferrer" class="attachment-text">{{
                        attachment.fileName }}</a>
                      <i *ngIf="!isHistory" (click)="deleteAttachment(portfolio.id, attachment.uniqueId)"
                        class="pi pi-times"></i>
                    </span>
                  </div>
                  <ng-template #uploadingLoader>
                    <section>
                      <div *ngFor="let _ of [].constructor(1)" style="display: flex; flex-direction: column;">
                        <div style="margin-top: 15px; margin-bottom: 15px;">
                          <p-skeleton styleClass="mb-2"></p-skeleton>
                          <p-skeleton styleClass="mb-2"></p-skeleton>
                        </div>
                      </div>
                    </section>
                  </ng-template>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </ng-template>
      </p-orderList>
    </div>
  </section>

  <ng-template #showLoader>
    <section>
      <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
        <div style="margin-top: 15px; margin-bottom: 15px;">
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton width="50rem" styleClass="mb-2"></p-skeleton>
        </div>
      </div>
    </section>
  </ng-template>
</section>

<bpc-custom-modal *ngIf="visible" [visible]="visible" [styleClass]="'portfolio-wrapper'"
  [contentStyleClass]="'portfolio-preview-container'" (onHide)="onHide()">
  <ng-container modal-header>
    <h1 class="f-18">Mail Preview</h1>
  </ng-container>
  <ng-container modal-content>
    <div class="modal-content-container" >
      <div *ngIf="modalLoading">
        <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
          <div style="margin-top: 15px; margin-bottom: 15px;">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="45%" styleClass="mb-2"></p-skeleton>
          </div>
        </div>
      </div>
      <!-- <span *ngIf="!modalLoading" [innerHTML]="previewObj.data"></span> -->
        <iframe style="width: 100%; height: 100%;" id="myIframe"></iframe>
      <!-- <iframe  id="myIframe" srcdoc="<html><head><title>My Title</title></head><body><section id='myIframe'></section></body></html>" width="700" height="500"></iframe> -->
    </div>
  </ng-container>

  <ng-container modal-footer>
    <button pButton class="button-light" (click)="onHide()">Cancel</button>
    <button pButton class="button-dark" [disabled]="isPublishDisabled" (click)="publishPortfolio()">
      Publish
      <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
    </button>
  </ng-container>
</bpc-custom-modal>

<!-- <p-dialog contentStyleClass="modal-size" (onHide)="onHide()" [(visible)]="visible">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Mail Preview</span>
  </ng-template>
  <div class="modal-content-container">
    <div *ngIf="modalLoading">
      <div *ngFor="let _ of [].constructor(3)" style="display: flex; flex-direction: column;">
        <div style="margin-top: 15px; margin-bottom: 15px;">
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="25rem" styleClass="mb-2"></p-skeleton>
        </div>
      </div>
    </div>
    <iframe *ngIf="!modalLoading" style="width: 100%; height: 100%;" id="myIframe"></iframe>
    
  </div>

  <div style="display: flex;flex-direction: row; justify-content: flex-end;">
    <button pButton class="button-light mr-2" (click)="onHide()">Cancel</button>
    <button pButton class="button-dark" [disabled]="isPublishDisabled" (click)="publishPortfolio()">Publish
      <i *ngIf="isPublishLoading" class="pi pi-spin pi-spinner ml-2" style="font-size: 1rem"></i>
    </button>
  </div>
</p-dialog> -->
<bpc-generic-popup
  [visible]="genericPopupVisible"
  (toggleVisible)="toggleGenericPopupVisible($event)"
></bpc-generic-popup>
