import { Component, Input, OnInit } from "@angular/core";
import { VotingService } from "@core/services/voting.service";
import { VotingStats } from "@models/voting";
import { getActiveOrLastICVotingWorkflow, getVotingBackgroundClasses, getVotingBorderClasses } from "../../../utils/company-utils";
import { Nullable } from "@models/nullable";
import { AIPrepService } from "@core/services/ai-prep.service";
import { AuthService } from "@core/auth/auth.service";
import { CompaniesService } from "src/app/services/companies/companies.service";
import { MessageService } from "primeng/api";
import { ApiResponse } from "src/app/api/base";
import { OperationResult } from "@core/models/operation-result";
import { BaseUser } from "@core/models/user";
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, bufferCount } from 'rxjs/operators';
import { TabUtilsService } from "src/app/services/tab-utils.service";
import { Tab } from "@core/models/types";
import { Router } from "@angular/router";
import { GenericPopupParameters } from "@core/models/generic-popup-models";
import { GenericPopupOperation } from "@core/models/generic-popup-enums";
import { GenericPopupService } from "src/app/services/generic-popup/generic-popup.service";

@Component({
  selector: "bpc-voting-stats",
  templateUrl: "./voting-stats.component.html",
  styleUrls: ["./voting-stats.component.scss"],
})
export class VotingStatsComponent implements OnInit {
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  selectedGroup: number = 1;
  selectedGroupType: string = 'All';
  votes: Vote[] = [];
  sortedVotes: Vote[] = [];
  filteredVotes: Vote[] = [];
  detailedViewToggle = false;
  @Input() visible!: boolean;
  votingSummary: Nullable<{
    summary: string;
    keywords: string[];
    groupType: string;
  }>[] = [];
  votingSummaryLoading : any = {};;
  votingStatsLoading: boolean = false;
  allVotingSummaryLoading: boolean = false;
  filterGroups: {id: number, groupType: string}[] = [];

  companyId: Nullable<number> = null;
  deletePopupVisible: boolean = false;
  voteToRemove: Nullable<Vote> = null;

  companyWorkflowUsersIds: number[] = [];
  checked: boolean = false;
  allVotes: Vote[] = [];
  nonDealTeamVotes: Vote[] = [];
  scoreWithoutDealTeam!: number;

  isSummaryOpen: boolean = false;

  
  genericPopupVisible: boolean = false;
  genericPopupOperation!: GenericPopupOperation;

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get votingStats(): VotingStats {
    return this.votingService.votingStats!;
  }

  get ICGroupSelected(): boolean {
    return this.selectedGroup === 1 || this.selectedGroup === 6;
  }

  get companyName(): Nullable<string> {
    return this.votingService.companyName;
  }

  get companyLocation(): Nullable<string> {
    return this.votingService.companyLocation;
  }

  get isVotingSummaryVisible(): boolean {
    return this.authService.investmentcommitteeadmin || this.authService.isPartner;
  }

  get isICAdmin(): boolean{
    return this.authService.investmentcommitteeadmin;
  }

  get isCrossIconVisible(): boolean{
    return this.isICAdmin && this.currentChildIndex == 0;
  }

  get filteredVotingSummary(): any{
    return this.votingSummary?.find(summary => summary?.groupType==this.selectedGroupType);
  }

  get getSummaryStatus(): boolean{
    return this.votingSummaryLoading[this.selectedGroup];
  }



  constructor(
    private router: Router,
    private votingService: VotingService,
    private aiPrepService: AIPrepService,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private messageService: MessageService,
    private tabUtilsService: TabUtilsService,
    private genericPopupService : GenericPopupService
  ) { }

  ngOnInit(): void {
    this.votingStats.groupResults.forEach(result =>{
      this.votingSummaryLoading[result.group.id] = false;
      this.filterGroups.push({id: result.group.id, groupType: result.group.name});
    });
    this.filterGroups.push({id: 1, groupType: "All", }, {id: 6, groupType: "Team Members"});
    this.generateData();
    
  }

  setDisplayToogle(toggle: boolean) {
    this.detailedViewToggle = toggle;
  }
  close() {
    this.votingService.hideVotingStats();
    if (this.currentChildIndex == 0 && this.isICAdmin)
      this.router.navigate(this.tabUtilsService.getCurrentRoute(this.tabs, this.currentTabIndex, this.currentChildIndex));
  }

  trackBy(index: number, vote: Vote): number {
    return vote.id;
  }

  transform(index: number): string {
    const x = (index % 8) * 80;
    const y = Math.floor(index / 8) * 75;

    return `translate(${x}px, ${y}px)`;
  }

  filterByGroup(name : string = "All") {
    this.selectedGroupType = name;

    if (this.selectedGroup === 1) {
      this.sortedVotes = this.sortedVotes
        .sort((a, b) => b.score - a.score)
        .map((vote, index) => ({
          ...vote,
          order: index,
          class: getVotingBackgroundClasses(vote.score),
          borderClass: getVotingBorderClasses(vote.score),
          disabled: false,
        }));

      this.filteredVotes = this.sortedVotes;
      return;
    }
    //console.log(this.votes)

    const filtered: Vote[] = this.selectedGroup !== 6 
      ? this.votes.filter((vote) => vote.group === this.selectedGroup)
      : this.votes.filter((vote) => this.companyWorkflowUsersIds.includes(vote.user!.id));

    const rest: Vote[] = this.selectedGroup !== 6 
    ? this.votes
        .filter((vote) => vote.group !== this.selectedGroup)
        .map((vote) => ({
          ...vote,
          class: "bg-gray-400",
          disabled: true,
        }))
    : this.votes
        .filter((vote) => !this.companyWorkflowUsersIds.includes(vote.user!.id))
        .map((vote) => ({
          ...vote,
          class: "bg-gray-400",
          disabled: true,
        }));

    const tempFilter = [...filtered, ...rest];

    this.sortedVotes = this.sortedVotes.map((x) => {
      return {
        ...x,
        order: tempFilter.findIndex((y) => x.id === y.id),
        class: tempFilter.find((y) => x.id === y.id)?.class ?? "",
        disabled: tempFilter.find((y) => x.id === y.id)?.disabled,
      };
    });
    this.sortedVotes = this.sortedVotes.sort((a, b) => a.order - b.order);
    // this.filteredVotes = filtered.sort((a, b) => b.score - a.score && a.order - b.order);
    //console.log(this.sortedVotes);
  }

  generateData() {
    let votes: Vote[] = [];
    this.votingStats.companyWorkflowUsers.forEach((user)=>{
      this.companyWorkflowUsersIds.push(user.user.id);
    });
    this.votingStats.groupResults.forEach((result) => {
      result.votes.forEach((vote) => {
        votes.push({
          id: vote.id,
          order: 0,
          group: result.group.id,
          groupName: result.group.name,
          name: vote.user.name,
          profilePictureUrl: vote.user.profilePictureUrl,
          score: vote.vote,
          class: "",
          createDate: vote.createDate,
          modifyDate: vote.modifyDate,
          comment: vote.comment,
          user: vote.user
        });
      });
    });
    this.votingSummary = JSON.parse(this.votingStats.roundSummary);

    if (this.votingSummary==null){
      this.votingSummary = [];
    }
    // Just for a while.
    if (!Array.isArray(this.votingSummary)){
      var summary : any = this.votingSummary;
      summary["groupType"] = "All";
      this.votingSummary = [];
      this.votingSummary.push(summary);
    }
    
    this.votes = votes
      .sort((a, b) => b.score - a.score)
      .map((vote, index) => {
        return {
          ...vote,
          order: index,
          class: getVotingBackgroundClasses(vote.score),
          borderClass: getVotingBorderClasses(vote.score),
          disabled: false,
        };
      });

    this.allVotes = this.votes;
    this.nonDealTeamVotes = this.allVotes.filter(vote => {return !this.companyWorkflowUsersIds.includes(vote.user!.id)});
    const length = this.nonDealTeamVotes.length;
    var sum = 0; 
    this.nonDealTeamVotes.forEach(vote=> {
      sum += vote.score});
    this.scoreWithoutDealTeam = this.nonDealTeamVotes != null && this.nonDealTeamVotes.length > 0 ? parseFloat((sum/length).toFixed(2)) : 0;
    if (this.checked){
      this.votes = this.allVotes;
      this.sortedVotes = this.allVotes;
    }
    else{
      this.votes = this.nonDealTeamVotes;
      this.sortedVotes = this.votes;
    }
  }

  generateSummary() {
    let activeGroup = this.selectedGroup;
    let activeGroupType = this.selectedGroupType;
    this.allVotingSummaryLoading = true;
    this.filterGroups.forEach(group=>{
      this.votingSummaryLoading[group.id] = true;
    })
    this.votingSummary = [];

    var votes: Vote[];
    if (activeGroup != 1){
      votes = activeGroup != 6 ? this.allVotes.filter((vote) => {
        return vote.group === activeGroup;
      }) : this.allVotes.filter((vote) => {return this.companyWorkflowUsersIds.includes(vote.user!.id)});
    }
    else{
      votes = this.allVotes;
    }
    this.aiPrepService.getVotingSummary(votes, this.companyName!, activeGroupType).pipe(
      catchError((error) => {
        return of({
          summary: "Error generating summary. Please try again.",
          keywords: [],
        });
      })).subscribe((response) => {
        response.groupType = activeGroupType;
        this.votingSummary?.push(response);
        this.votingSummaryLoading[activeGroup] = false;

        this.filterGroups.forEach(group => {
          
          if (group.id != activeGroup){
            var filtered: Vote[];
            if (group.id != 1){
              filtered = group.id != 6 ? this.allVotes.filter((vote) => {
                return vote.group === group.id;
              }) : this.allVotes.filter((vote) => {return this.companyWorkflowUsersIds.includes(vote.user!.id)});
            }
            else{
              filtered = this.allVotes;
            }
            // console.log(group.id);
            // console.log(filtered);
            this.aiPrepService.getVotingSummary(filtered, this.companyName!, group.groupType).pipe(
              catchError((error) => {
                return of({
                  summary: "Error generating summary. Please try again.",
                  keywords: [],
                });
              }))
              .subscribe((response) => {
                response.groupType = group.groupType;
                this.votingSummary?.push(response);
                this.votingSummaryLoading[group.id] = false;
                if(this.votingSummary?.length==this.filterGroups.length){
                  this.updateVotingSummary(this.votingSummary);
                }
              });
            }
          });
      });

    

  };

  onRefreshClick() {
    this.votingStatsLoading = true;
    const company = this.votingService.company
    const workflow = company ? getActiveOrLastICVotingWorkflow(company.workflows) : null;
    if(company && workflow && workflow.id) {
      this.companiesService.getVotingStats(workflow.id).pipe(
        catchError((error) => {
          this.displayErrorMessage(error, "Error refreshing voting stats. Please try again.");
          return of(null);
        })
      ).subscribe(response => {
        this.votingService.resetLocalData();
        if (response?.data) {
          this.votingService.companyName = company.name;
          this.votingService.companyLocation = `${company.address?.city},  ${company.address?.country.name}`;
          this.votingService.votingStats = response.data;
          this.generateData();
          this.filterByGroup(this.selectedGroupType);
          if(this.authService.investmentcommitteeadmin) this.generateSummary();
          this.votingStatsLoading = false;
        }
      });
    }
  };

  updateVotingSummary(summary: any) {
    this.allVotingSummaryLoading = false;
    this.companiesService.updateRoundSummary(this.votingStats.companyWorkflowId, this.votingStats.id, JSON.stringify(summary)).pipe(
      catchError((error) => {
        this.displayErrorMessage(error, "Error updating voting summary. Please try again.");
        return of(null);
      })
    ).subscribe();
  };

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  showDeleteVotePopup(vote: Vote){
    this.deletePopupVisible = true;
    this.companyId = this.votingService.company?.id ?? null;
    this.voteToRemove = vote;
  }

  toggleDeleteVotePopupVisible(deletePopupVisible: boolean){
    this.deletePopupVisible = deletePopupVisible;
  }

  refreshVotes(){
    this.votingStatsLoading = true;
    const company = this.votingService.company
    const workflow = company ? getActiveOrLastICVotingWorkflow(company.workflows) : null;
    if(company && workflow && workflow.id) {
      this.companiesService.getVotingStats(workflow.id).pipe(
        catchError((error) => {
          this.displayErrorMessage(error, "Error refreshing voting stats. Please try again.");
          return of(null);
        })
      ).subscribe(response => {
        this.votingService.resetLocalData();
        if (response?.data) {
          this.votingService.companyName = company.name;
          this.votingService.companyLocation = `${company.address?.city},  ${company.address?.country.name}`;
          this.votingService.votingStats = response.data;
          this.generateData();
          this.filterByGroup(this.selectedGroupType);
          this.votingStatsLoading = false;
        }
      });
    }
  }

  handleTeamMembersToggleChange(){
    if (this.checked){
      this.votes = this.allVotes;
      this.sortedVotes = this.allVotes;
    }
    else{
      if (this.selectedGroup==6){
        this.selectedGroup = 1;
        this.selectedGroupType = "All";
      }
      this.votes = this.nonDealTeamVotes;
      this.sortedVotes = this.votes;
    }
    this.filterByGroup(this.selectedGroupType);
    //this.onRefreshClick();
  }

  toggleSummary(): void {
    this.isSummaryOpen = !this.isSummaryOpen;
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  showSendIcWorkflowEmailPopup(){
    var parameters: GenericPopupParameters = {
      companyWorkflowId: this.votingStats.companyWorkflowId,
      companyWorkflowUsers: this.votingStats.companyWorkflowUsers
    }
    this.genericPopupService.setupPopup(GenericPopupOperation.SendIcWorkflowEmail, parameters);
    this.genericPopupVisible = true;
  }

  toggleGenericPopupVisible(genericPopupVisible: boolean){
    this.genericPopupVisible = genericPopupVisible;
  }

}

export interface Vote {
  id: number;
  order: number;
  group: number;
  groupName: string;
  name: string;
  score: number;
  class: string;
  createDate: string;
  modifyDate: Nullable<string>;
  comment: string;
  profilePictureUrl?: string;
  borderClass?: string;
  disabled?: boolean;
  user?: {id: number, name: string, email: string};
}

export interface companyWorkflowUser{
  id: number;
  userId: number;
  companyWorkflowId: number;
  user: BaseUser;
}
