import { GlobalModalService } from "./../../core/services/global-modal.service";
import { Component } from "@angular/core";
import { TabUtilsService } from "../../services/tab-utils.service";
import { TabRoute } from "@core/static/tabs";
import { Router } from "@angular/router";
import { RouterService } from "src/app/services/router/router.service";
import { Tab } from "@core/models/types";

@Component({
  selector: "bpc-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrl: "./main-layout.component.scss",
})
export class MainLayoutComponent {
  navigateToTab(tab: Tab) {
    this.routerService.navigateToTab(tab);
  }

  get currentTabIndex() {
    return this.routerService.currentTabIndex;
  }

  get isMobile(): boolean {
    return false;
  }

  get onAvailableUserTabs() {
    return this.routerService.onAvailableUserTabs();
  }

  constructor(private routerService: RouterService, public globalModalService: GlobalModalService) {}
}
