import { Zone } from './zone';
import { DropdownOption, StatusOptions } from '@models/forms';
import { Industry, Investor, WorkFlowId } from '@models/auxiliary-company-models';
import { User } from '@models/user';
import { BasePagingFilter, BaseSortingFilter } from '../../services/companies-search/companies-search.service';
import { Tag } from '@models/tag';
import { CompanyType } from './company-type';
import { DCCompanyType } from 'src/app/services/companies/companies.service';

export enum AssigneeFilterType {
  NONE = '1',
  INCLUDE = '2',
  EXCLUDE = '3'
}

export enum AssigneeRole {
  COVERAGE = '1',
  LEADER = '2'
}

export interface FilterLabel {
  key: string;
  value: string;
  rawValue?: string;
  showLoader?: boolean
}

export interface MainFilter extends BasePagingFilter, BaseSortingFilter {
  companyName?: string[];
  companyDescription?: string[];
  statusesExclude?: string;
  industriesExclude?: string;
  investorsExclude?: string;
  paidInCapitalMinTotal?: string;
  paidInCapitalMaxTotal?: string;
  fullTimeEquivalentMinTotal?: string;
  fullTimeEquivalentMaxTotal?: string;
  fullTimeEquivalentMinGrowth?: string;
  fullTimeEquivalentMaxGrowth?: string;
  revenueMinTotal?: string;
  revenueMaxTotal?: string;
  revenueMinGrowth?: string;
  revenueMaxGrowth?: string;
  excludeLatestRoundDateTo?: boolean;
  interactionFilterType?: Interaction;
  interactionExclude?: string;
  interactionCountFilterType?: string;
  interactionMinTotal?: string;
  interactionMaxTotal?: string;
  assigneeFilterType?: AssigneeFilterType;
  tagsExclude?: string;
  assigneeRole?: AssigneeRole;
  isActive?: string;
  hasNotificationTriggers?: string;
  fundingForm?: any;
  investorsForm?: any;
  bpcCoverageForm?: any;
  tagsForm?: any;
  productsServicesForm?: any;
  endMarketsForm?: any;
  estimatedRevenueForm?: any
  // flagtoMFD?: boolean;
}

export interface MainFilterReal extends MainFilter {
  latestRoundDateTo?: string;
  interactionDate?: number;
  statuses?: Array<DropdownOption | StatusOptions>;
  companyTypesInclude?: CompanyType[];
  companyTypeExclude?: CompanyType[];
  locationsInclude?: Zone[];
  locationsExclude?: Zone[];
  investors?: Investor[];
  industries?: Industry[];
  assignees?: User[];
  tags?: Tag[];
  workflows?: boolean;
  companyOverviewFoundingDateStart?: any;
  companyOverviewFoundingDateEnd?: any;
  keywordsGroup?: any;
  companyOverviewForm?: any;
  ownershipForm?: any;
  fundingForm?: any;
  investorsForm?: any;
  endMarketsForm?: any;
  productsServicesForm?: any;
  estimatedRevenueForm?: any;
  tagsForm?: any;
  bpcCoverageForm?: any;
}

export interface MainFilterRaw extends MainFilter {
  companyId?: number;
  companyDomain?: string;
  savedSearchId?: number;
  latestRoundDateTo?: string;
  interactionDateFrom?: string;
  interactionDateTo?: string;
  statuses?: string[];
  locationsInclude?: string[];
  locationsExclude?: string[];
  companyTypesInclude?: number[];
  companyTypesExclude?: string[];
  investors?: string[];
  industries?: string[];
  workflows?: WorkFlowId;
  tags?: string[];
  isMyElevation?: string;
  companyOverviewForm?: string;
  filters?: string;
  filtersString?: string;
  investorsInclude?: string;
  investorsExclude?: string;
  tagsInclude?: string[];
  tagsExclude?: string;
  selectedCompanies?: number[];
  // newly added
  industriesInclude?: number[];
  endMarketsInclude?: number[];
  endMarketsExclude?: number[];
  productsInclude?: number[];
  productsExclude?: number[];
  assigneeNone?: boolean;
  assigneesInclude?: number[];
  assigneesExclude?: number[];
  statusesInclude?: number[];
  interactionType?: number[];
  latestRoundAmountMin?: string | undefined;
  latestRoundAmountMax?: string | undefined;
  latestRoundDateFrom?: string;
  foundingDateFrom?: string;
  foundingDateTo?: string;
  rawValueMapping?: string;
  showAllUsers?: string;
  interactionTypes?: number[];
  interactionInitiatorsInclude?: number[];
  dcCompanyTypes?: DCCompanyType[];
}

export interface MainFilterRawMeta {
  companyName?: IdNamePair;
  companyTypesInclude?: IdNamePair[];
  companyTypesExclude?: IdNamePair[];
  companyDescription?: IdNamePair;
  industriesInclude?: IdNamePair[];
  industriesExclude?: IdNamePair[];
  fullTimeEquivalentMinTotal?: IdNamePair;
  fullTimeEquivalentMaxTotal?: IdNamePair;
  fullTimeEquivalentMinGrowth?: IdNamePair;
  fullTimeEquivalentMaxGrowth?: IdNamePair;
  foundingDateFrom?: IdNamePair;
  foundingDateTo?: IdNamePair;
  locationsInclude?: IdNamePair[];
  investorsInclude?: IdNamePair[];
  investorsExclude?: IdNamePair[];
  endMarketsInclude?: IdNamePair[];
  endMarketsExclude?: IdNamePair[];
  productsInclude?: IdNamePair[];
  productsExclude?: IdNamePair[];
  tagsInclude?: IdNamePair[];
  tagsExclude?: IdNamePair[];
  assigneeNone?: IdNamePair;
  assigneeRole?: IdNamePair;
  assigneesInclude?: IdNamePair[];
  assigneesExclude?: IdNamePair[];
  statusesInclude?: IdNamePair[];
  statusesExclude?: IdNamePair[];
  interactionDateFrom?: IdNamePair;
  interactionDateTo?: IdNamePair;
  interactionType?: IdNamePair;
  interactionTypes?: IdNamePair;
  revenueMinTotal?: IdNamePair;
  revenueMaxTotal?: IdNamePair;
  revenueMinGrowth?: IdNamePair;
  revenueMaxGrowth?: IdNamePair;
  paidInCapitalMinTotal?: IdNamePair;
  paidInCapitalMaxTotal?: IdNamePair;
  latestRoundAmountMin?: IdNamePair;
  latestRoundAmountMax?: IdNamePair;
  latestRoundDateFrom?: IdNamePair;
  latestRoundDateTo?: IdNamePair;
  interactionMinTotal?: IdNamePair;
  interactionMaxTotal?: IdNamePair;
  interactionCountFilterType?: IdNamePair;
  interactionFilterType?: IdNamePair;
  workflows?: IdNamePair;
  isActive?: IdNamePair;
  hasNotificationTriggers?: IdNamePair;
  filters?: IdNamePair;
  interationTypes?: IdNamePair[];
  interactionInitiatorsInclude?: IdNamePair[];
  [key: string]: IdNamePair | IdNamePair[] | undefined;
  dcCompanyTypes?: IdNamePair[];
}

export interface IdNamePair {
  id: number | null;
  name: any;
  code?: string;
}

export enum FilterPriority {
  NONE = 0,
  TOP = 1,
  SECOND = 2,
  THIRD = 3,
}

export enum Interaction {
  NONE = '1',
  ANY = '2',
  RECENT = '3'
}

export enum InteractionCountType {
  EMAIL = '1',
  PERSONAL = '2'
}

export enum LogicalOperator {
  AND = 1,
  OR = 2,
}

export enum IncludeOrExclude {
  INCLUDE = 1,
  EXCLUDE = 2,
}