import { FilterModel } from "./analytics-dashboard-models";

export const lastn: FilterModel[] = [
  { id: 7, name: "Last Week", label: "lastWeek" },
  { id: 30, name: "Last Month", label: "lastMonth" },
  { id: 90, name: "Last Quarter", label: "lastQuarter" },
  { id: 365, name: "Last Year", label: "lastYear" },
];

export const topn: FilterModel[] = [
  { id: 5, name: "Top 5 Employees", label: "topFiveEmployees" },
  { id: 10, name: "Top 10 Employees", label: "topTenEmployees" },
];
