<bpc-widget [title]="title" [filters]="filters" (onFilterChange)="onFilterChange($event)">
  <div class="users-container">
    <section class="users-container" *ngIf="!loading else showLoaders">
      <div *ngFor="let contributor of getTopICContributerData()" (click)="onContributorClick(contributor)">
        <img class="profile-image cursor-pointer" [src]="contributor.profilePictureUrl || 'assets/no-image.png'" />
        <h2>{{ contributor.name }}</h2>
        <span>{{ contributor.score }}</span>
      </div>
      <div *ngIf="getTopICContributerData().length === 0" class="no-data">
        <h2>No data available</h2>
      </div>
    </section>
  
    <ng-template #showLoaders>
      <bpc-dynamic-skeleton-loader [numberOfSkeletons]="loaders"></bpc-dynamic-skeleton-loader>
    </ng-template>
  </div>
</bpc-widget>