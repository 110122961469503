import { Component, OnInit } from "@angular/core";
import { ColumnMapping, DataFilter } from "../../core/components/dynamic-table/dynamic-table.component";
import { CardItemMapping } from "@core/components/dynamic-cards/dynamic-cards.component";
import { CompanyV2Service } from "@core/services/company-v2.service";
import { HorizontalBarType } from "../../core/components/generic-listing/generic-listing.component";
import { isSafari } from "../../utils/utils";

@Component({
  selector: "bpc-pipeline",
  templateUrl: "./pipeline.component.html",
  styleUrl: "./pipeline.component.scss",
})
export class PipelineComponent implements OnInit {
  HorizontalBarType = HorizontalBarType;
  isLoading = false;

  companies: CardItemMapping[] | any = [];
  companiesColumns: ColumnMapping[] = [
    { header: "Company", showHeader: false, mappedTo: "name", case: "company", sortable: true },
    { header: "Financials", showHeader: true, mappedTo: "targetFinancials" },
    { header: "Coverage", showHeader: true, mappedTo: "coverageUsers" },
    { header: "Investors", showHeader: true, mappedTo: "investorsDetails" },
    { header: "CEO / Founder City	", showHeader: true, mappedTo: "ceoFounderCity" },
    { header: "Stage", showHeader: true, mappedTo: "stage" },
  ];

  companiesFilter: DataFilter = {
    pageNumber: 1,
    pageSize: 100,
    sortDirection: "asc",
    sortProperty: "status",
    thenSortDirection: "asc",
    thenSortProperty: "name",
  };
  totalCount = 0;

  constructor(private companyV2Service: CompanyV2Service) {}

  ngOnInit(): void {
    this.fetchCompanies();

    if (isSafari()) {
      setTimeout(() => window.scrollTo(0, 1), 100); // Trigger a repaint
    }
  }

  // Fetch companies listing data
  fetchCompanies(refreshData = true) {
    if (this.isLoading) {
      return; // Prevent multiple simultaneous calls
    }

    this.isLoading = true;

    this.companyV2Service.getActiveCompaniesV2(this.companiesFilter).subscribe({
      next: (response) => {
        if (response?.data?.data) {
          const newCompanies = this.companyV2Service.transformCompanyData(response.data.data);
          if (refreshData) {
            this.companies = [...newCompanies];
          } else this.companies.push(...newCompanies);

          this.totalCount = response.data.totalCount;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error("Error fetching companies:", error);
        this.isLoading = false;
        if (this.companiesFilter.pageNumber) this.companiesFilter.pageNumber = this.companiesFilter.pageNumber - 1;
      },
      complete: () => {
        console.log("Fetch companies completed");
      },
    });
  }

  loadMoreData() {
    if (this.companiesFilter.pageNumber) {
      this.companiesFilter.pageNumber = this.companiesFilter.pageNumber + 1;
      this.fetchCompanies(false);
    }
  }

  onFilterChange(filters: any) {
    this.companies = [];
    this.companiesFilter = { ...this.companiesFilter, ...filters };
    this.companiesFilter.pageNumber = 1;
    this.fetchCompanies();
  }

  // Todo: handle chart and company details
  dataView = "table";
  companyId: any = undefined;
  toggleDataView(view: string) {
    this.companyId = "2784281";
    this.dataView = view;
  }
}
