import { Component, Input, OnDestroy } from "@angular/core";
import * as Highcharts from "highcharts";
import { AnalyticsWidgetService } from "src/app/services/analytics-widget/analytics-widget.service";
import { BpcChartType, FilterType, WidgetFilter } from "@core/models/analytics-dashboard-enums";
import { Subscription } from "rxjs"; // Import Subscription
import { lastn } from "@core/models/analytics-dashboard-constants";
import { firmPod } from "@core/static/tabs";
import { MOST_ACTIVE_EMPLOYEES_CHART_OPTIONS } from "@core/models/analytics-dashboard-highcharts";

@Component({
  selector: "bpc-chartwidget",
  templateUrl: "./chartwidget.component.html",
  styleUrls: ["./chartwidget.component.scss"],
})
export class ChartwidgetComponent {
  // Implement OnDestroy
  @Input() title!: string;
  @Input() loaders!: number | undefined;
  @Input() chartType!: BpcChartType | undefined;

  filters: WidgetFilter[] = [];

  selectedFilters: any = [];

  loading = true;

  ChartTypes = BpcChartType;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "chart";
  chartOptions = {};

  constructor(private analyticsWidgetService: AnalyticsWidgetService) {}

  ngOnInit() {
  this.analyticsWidgetService.getPods().subscribe((pods) => {
      this.filters = [
        { filterType: FilterType.pods, options: pods, default: pods[0] },
        { filterType: FilterType.last_n, options: lastn, default: lastn[1] },
      ];
      this.selectedFilters = {
        last_n: lastn[1],
        pods: pods[0],
      };
      this.getData();
    });
  }

  getData() {
    this.loading = true;
    this.analyticsWidgetService
      .getMostActiveUsers(this.selectedFilters.last_n, this.selectedFilters.pods)
      .subscribe((data: any) => {
        if (data) {
          this.chartOptions = this.getChartOptions(data);
          this.loading = false;
        }
      });
  }

  getChartOptions(data: any): Highcharts.Options {
    return MOST_ACTIVE_EMPLOYEES_CHART_OPTIONS(
      data.categories,
      data.emailData,
      data.notesData,
      data.callData,
      data.meetingData,
      data.videoCallData,
    );
  }

  onFilterChange(selectedFilters: any) {
    this.selectedFilters = selectedFilters;
    this.getData();
  }
}
