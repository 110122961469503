import { Component, Input } from "@angular/core";
import { BpcChartType, FilterType, WidgetFilter } from "../../../../core/models/analytics-dashboard-enums";
import { AnalyticsWidgetService } from "../../../../services/analytics-widget/analytics-widget.service";
import { DEFAULT_PIE_CHART_OPTIONS } from "../../../../core/models/analytics-dashboard-highcharts";
import { lastn } from "../../../../core/models/analytics-dashboard-constants";

export interface DealDropReason {
  reason?: string;
  reasonId?: number;
  color?: string;
}

@Component({
  selector: "bpc-deal-drop-widget",
  templateUrl: "./deal-drop-widget.component.html",
  styleUrl: "./deal-drop-widget.component.scss",
})
export class DealDropWidgetComponent {
  @Input() title!: string;
  @Input() loaders!: number | undefined;

  filters: WidgetFilter[] = [{ filterType: FilterType.last_n, options: lastn, default: lastn[2] }];

  loading: boolean = true;
  chartOptions: Highcharts.Options = {};
  colors = ["#fbafa3", "#fdd966", "#85e0a3", "#80caff", "#b698bb"];
  data: any = [];

  showCompaniesModal = false;
  dealDropReason: DealDropReason = {};


  constructor(private analyticsWidgetService: AnalyticsWidgetService) {
    this.loadCharts({});
  }

  onFilterChange(selectedFilters: any) {
    this.loadCharts(selectedFilters);
  }

  setChartConfig(data: any) {
    const myChartOptions = DEFAULT_PIE_CHART_OPTIONS([{ type: BpcChartType.Pie, name: "Reasons", data }]);
    myChartOptions.title = {
      text: "Deal drops",
      verticalAlign: "middle",
      y: 15,
    };
    myChartOptions.plotOptions = {
      pie: {
        innerSize: "50%",
        depth: 45,
        point: {
          events: {
            click: (e: any) => {
              this.onChartReasonClick(e.point, e.point.index);
            },
          },
        },
        dataLabels: {
          enabled: true,
          crop: false,
          distance: "-30%",
          style: {
            fontSize: "12px",
          },
          connectorWidth: 0,
          formatter: function () {
            return this.percentage.toFixed(1) + "%"; // Display percentage with one decimal place
          },
        },
      },
    };
    myChartOptions.colors = this.colors;

    this.chartOptions = myChartOptions;
  }

  loadCharts(selectedFilters: any) {
    this.loading = true;
    this.analyticsWidgetService.getDealdropReasons(selectedFilters).subscribe((data) => {
      this.data = data;
      this.setChartConfig(this.data);
      this.loading = false;    
    });
  }

  onChartReasonClick(reason: any, index: number) {
    this.dealDropReason = { reason: reason.name, reasonId: index, color: this.colors[index] };
    this.showCompaniesModal = true;
  }

  onModalClosed() {
    this.showCompaniesModal = false;
  }
}
