import { Component, Input, OnInit } from "@angular/core";
import { CommentService } from "../../services/comment.service";
import { Company } from "../../models/company";
import { catchError, of } from "rxjs";
import { AuthService } from "@core/auth/auth.service";
import { MessageService } from "primeng/api";
import { ApiResponse } from "src/app/api/base";
import { OperationResult } from "@core/models/operation-result";
import { TabUtilsService } from "src/app/services/tab-utils.service";
import { TabData } from "@core/static/tabs";
import { Tab } from "@core/models/types";
import { Nullable } from "primeng/ts-helpers";
import { MainFilterRaw } from "@core/models/main-filter-view";
import { GenericPopupParameters } from "@core/models/generic-popup-models";
import { GenericPopupOperation } from "@core/models/generic-popup-enums";
import { GenericPopupService } from "src/app/services/generic-popup/generic-popup.service";

@Component({
  selector: "bpc-comment-section",
  templateUrl: "./comment-section.component.html",
  styleUrls: ["./comment-section.component.scss"],
})
export class CommentSectionComponent implements OnInit {
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  addCommentViewToggle = false;
  isCommentOnEdit = false;
  commentInput = "";
  detailedViewToggle = false;
  deletePopupVisible = false;
  commentToRemove: any;
  companyWorkflowCommentId: number = 0;
  isSummaryOpen: boolean = false;
  genericPopupVisible: boolean = false;
  genericPopupOperation!: GenericPopupOperation;

  get showComments() {
    return this.commentService.displayComment;
  }

  get company(): Company | null {
    return this.commentService.selectedCompany;
  }

  get loading(): boolean {
    //console.log(this.commentService.loading);
    return this.commentService.loading;
  }

  get getSummaryLoading(): boolean {
    //console.log(this.commentService.loading);
    return this.commentService.summaryLoading;
  }

  get summary(): string | undefined {
    return this.commentService.workflow?.summary;
  }

  get comments(): any[] | undefined {
    return this.commentService.workflow?.companyWorkflowComments;
  }
  get isICAdmin(): boolean {
    return this.authService.investmentcommitteeadmin;
  }

  get loggedInUserId() {
    return this.authService.userId;
  }

  get currentTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[this.currentTabIndex];
    return tab.children?.[this.currentChildIndex!] ?? tab;
  }

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.currentTab.mainFilterRaw!;
  }

  isCrossIconVisible(comment: any): boolean {
    return this.isICAdmin || this.loggedInUserId == comment.userId;
  }

  hasUserMadeComment() {
    return this.comments?.find((comment) => comment.userId == this.loggedInUserId) != null;
  }

  constructor(
    private commentService: CommentService,
    private authService: AuthService,
    private messageService: MessageService,
    private tabUtilsService: TabUtilsService,
    private genericPopupService: GenericPopupService
  ) {}

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  ngOnInit(): void {
  }

  close() {
    this.isCommentOnEdit = false;
    this.addCommentViewToggle = false;
    this.commentInput = "";
    this.commentService.clean();
    this.tabUtilsService.navigateToCurrentTab(
      this.mainFilterRaw,
      this.tabs,
      this.currentTabIndex,
      this.currentChildIndex!,
    );
  }

  setDisplayToogle(value: boolean) {
    this.detailedViewToggle = value;
  }

  refreshComments() {
    this.isCommentOnEdit = false;
    this.addCommentViewToggle = false;
    this.commentInput = "";
    this.commentService.refreshComments();
  }

  showDeleteCommentPopup(comment: any) {
    if (this.currentChildIndex == 1){
      this.showErrorToastMessage("This is an archived company. You cannot delete comments!");
      return;
    }
    this.deletePopupVisible = true;
    this.companyWorkflowCommentId = comment.id;
    this.commentToRemove = comment;
  }

  toggleDeleteVotePopupVisible(deletePopupVisible: boolean) {
    this.deletePopupVisible = deletePopupVisible;
  }

  toggleAddComment(state: boolean, comment = "", onEdit = false, companyWorkflowCommentId: number = 0) {
    this.addCommentViewToggle = state;
    this.isCommentOnEdit = onEdit;
    this.commentInput = comment;
    if (onEdit) {
      this.companyWorkflowCommentId = companyWorkflowCommentId;
    }
  }

  addEditComment() {
    if (this.currentChildIndex == 1){
      this.showErrorToastMessage("This is an archived company. You cannot add or update comments!");
      return;
    }
    this.commentService
      .addEditComment(this.commentInput, this.isCommentOnEdit, this.companyWorkflowCommentId)
      .pipe(
        catchError((error) => {
          this.isCommentOnEdit
            ? this.displayErrorMessage(error, "Error updating comment. Please Try again!")
            : this.displayErrorMessage(error, "Error adding comment. Please Try again!");
          this.refreshComments();
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response.data.result) {
          this.isCommentOnEdit
            ? this.showSuccessToastMessage("Comment updated!")
            : this.showSuccessToastMessage("Comment added!");
        } else {
          this.isCommentOnEdit
            ? this.showErrorToastMessage(response.data.message)
            : this.showErrorToastMessage(response.data.message);
        }
        this.refreshComments();
      });
  }

  onRefreshClick() {
    this.isCommentOnEdit = false;
    this.addCommentViewToggle = false;
    this.commentInput = "";
    this.commentService.refresh();
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  toggleSummary(): void {
    this.isSummaryOpen = !this.isSummaryOpen;
  }

  
  showSendIcWorkflowEmailPopup(){
    var parameters: GenericPopupParameters = {
      companyWorkflowId: this.commentService?.workflow?.companyWorkflowId,
      companyWorkflowUsers: this.commentService?.workflow?.companyWorkflowUsers
    }
    this.genericPopupService.setupPopup(GenericPopupOperation.SendIcWorkflowEmail, parameters);
    this.genericPopupVisible = true;
  }

  toggleGenericPopupVisible(genericPopupVisible: boolean){
    this.genericPopupVisible = genericPopupVisible;
  }
}
