<ng-container *ngIf="!mapLoading else showLoaders">
    <div class="chart-heatmap-container">
      <div class="chart-container" ngClass="full-width">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructor"
          [options]="options"
          style="width: 100%; height: 600px; display: block; background-color: #F5FAFF; padding-bottom: 35px;">
        </highcharts-chart>
        </div>
    </div>
    <bpc-pie-chart
      *ngIf="visible"
      [visible]="visible"
      [selectedCompany]="selectedCompany"
      (toggleVisible)="toggleVisible($event)">
    </bpc-pie-chart>
</ng-container>
<ng-template #showLoaders>
<div class="loading-container">
    <p-progressSpinner
    class="spinner">
    </p-progressSpinner>
    <p>Loading chart, please wait...</p>
</div>
</ng-template>
