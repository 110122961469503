import { Injectable } from '@angular/core';
import { OperationResult } from '@core/models/operation-result';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, Observable, of, Subject } from 'rxjs';
import { ApiResponse } from 'src/app/api/base';
import { GenericPopupOperation } from '@core/models/generic-popup-enums';
import { WorkflowsService } from '../workflows/workflows.service';
import { GenericPopupParameters } from '@core/models/generic-popup-models';
import { UpdatesService } from '@core/services/updates.service';
import { CompanyPortfolioService } from '@core/services/company.portfolio.service';

@Injectable({
  providedIn: 'root'
})
export class GenericPopupService {
  private toggleGenericPopupSubject = new BehaviorSubject<boolean | null>(null);
  toggleGenericPopup$: Observable<Boolean | null> = this.toggleGenericPopupSubject.asObservable();

  private callCompleteSubject = new Subject<boolean | null>();
  callComplete$: Observable<boolean | null> = this.callCompleteSubject.asObservable();

  operation!: GenericPopupOperation;
  parameters: GenericPopupParameters;

  constructor(
    private messageService: MessageService,
    private workflowsService: WorkflowsService,
    private companyPortfolioService: CompanyPortfolioService,
  ) { 
    this.parameters = {
      companyWorkflowId: undefined,
      companyWorkflowUsers: undefined,
      portfolioCompanyId: undefined
    }
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  private displayToastMessage(message: string, severity = "error", life = 3000) {
    this.messageService.add({
      key: "home-bc",
      severity,
      detail: message,
      life,
    });
  }

  public positiveCall(operation: GenericPopupOperation ){
    switch(operation){
      case GenericPopupOperation.SendIcWorkflowEmail : {
        this.sendWorkflowEmail();
        break;
      }
      case GenericPopupOperation.DeletePortfolioCompany : {
        this.deletePortfolioCompany();
        break;
      }
    }
  }

  private sendWorkflowEmail() {
    this.workflowsService.sendWorkflowEmail(this.parameters.companyWorkflowId).pipe(
      catchError((error) => {
        this.displayErrorMessage(error, "Error emailing, Please try again!");
        return of(null); 
      })
    ).subscribe(response => {
      if(response?.data.result){
        this.showSuccessToastMessage("Email sent to deal team!");
        this.callCompleteSubject.next(true);
      }
      else{
        this.showErrorToastMessage("Email could not be sent to deal team, try again!");
        this.callCompleteSubject.next(false);
      }
    });
  }

  private deletePortfolioCompany() {
    this.companyPortfolioService.deletePortfolioCompany(this.parameters.portfolioCompanyId).pipe(
      catchError((error) => {
        this.displayToastMessage("Error deleting portfolio company, Please try again!");
        return of(null); 
      })
    ).subscribe(response => {
      if(response?.data){
        this.displayToastMessage("Portfolio Company Deleted!", "success");
        this.callCompleteSubject.next(true);
      }
      else{
        this.displayToastMessage("Error deleting portfolio company, Please try again!");
        this.callCompleteSubject.next(false);
      }
    });
  }

  setupPopup(operation: GenericPopupOperation, parameters: GenericPopupParameters){
    this.parameters = parameters;
    this.operation = operation;

    this.toggleGenericPopupSubject.next(true);
  }
}
