import { ChromeExtensionService } from "src/app/services/chrome-extension/chrome-extension.service";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { copyToClipboard } from "../../../utils/copy-to-clipboard";
import { MessageService } from "primeng/api";
import { ActivatedRoute, Router } from "@angular/router";
import { DestroyObservable } from "../../../rxjs/DestroyObservable";
import { asyncScheduler, filter, MonoTypeOperatorFunction, observeOn, pipe, takeUntil, tap } from "rxjs";
import { AuthService } from "@core/auth/auth.service";
import { InvestorTab, ModalActions, Tab } from "@models/types";
import { environment } from "../../../../environments/environment";
import { CompaniesService } from "../../../services/companies/companies.service";
import { ApiResponse } from "../../../api/base";
import { OperationResult } from "@models/operation-result";
import { SupportService } from "@core/services/support.service";
import { BasicCompany, Company } from "@models/company";
import {
  getAllICVotingWorkflows,
  getEnrichmentStatusId,
  getEnrichmentWorkflow,
  updateEnrichmentStatus,
} from "../../../utils/company-utils";
import {
  CompanyProductScore,
  Investor,
  NavigateCompany,
  WorkFlow,
  WorkFlowStatusId,
} from "@models/auxiliary-company-models";
import { DeviceDetectorService } from "ngx-device-detector";
import { VotingService } from "@core/services/voting.service";
import { Nullable } from "@models/nullable";
import { User, UserRole } from "@models/user";
import { CompanyTypeService } from "../../services/company-type.service";
import { CompanyType } from "@models/company-type";
import { Tag, TagType } from "@models/tag";
import { EditCustomTagMode } from "@core/components/edit-custom-tag/edit-custom-tag.component";
import { cloneDeep } from "../../../utils/utils";
import { MetaService } from "@core/services/meta.service";
import { FilterHelperService } from "@core/services/filter-helper.service";
import { CompanyHelperService } from "../../services/company-helper.service";
import { TagsService } from "@core/services/tags.service";
import { APP_VERSION, DEV_APP_VERSION } from "./package-constants";
import { ModalUtilsService } from "src/app/services/modal-utils.service";
import { ModalParameters } from "src/app/components/generic-modal/generic-modal.component";
import { MainFilterRaw } from "@core/models/main-filter-view";
import { TabData } from "@core/static/tabs";
import moment from "moment";
import { CompanyNewsletterService } from "@core/services/company-newsletter.service";
import { MixpanelService, SocialLinkType, TopBarButtonActionType } from "@core/services/mixpanel.service";
import { SocialLink } from "@core/models/social-link";
import { MenuItem } from 'primeng/api';

enum Edit {
  VIEWMODE,
  CONNECTIONS,
}

@Component({
  selector: "bpc-company-details",
  templateUrl: "./company-details.component.html",
  styleUrls: ["./company-details.component.scss"],
  viewProviders: [DestroyObservable],
})
export class CompanyDetailsComponent implements OnInit, AfterViewInit {
  @Input() set _companyDetails(value: Company | null) {
    if(value) {
      this.companyDetails = value;
      this.companyDetails.tags.sort((a) => (a.type === TagType.User ? 0 : a.type === TagType.System ? 1 : 2));
      if (this.previousCompanyDetails?.domain !== this.companyDetails.domain || this.previousCompanyDetails === null) {
        this.previousCompanyDetails = this.companyDetails;
        this.mixpanelService.trackPageView();
      }
      this.initializeMenuItems();
    }
  }
  @Input() enableNavigation!: boolean;
  @Input() enableEnrichment!: boolean;
  @Input() enableICVoting!: boolean;
  @Input() loading: boolean = false;
  @Input() mainFilterRaw!: MainFilterRaw;
  @Input() tabs!: Record<UserRole, TabData<Company>[]>;
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() weeklySubscription!: boolean;
  @Input() dataView: string = 'table';
  @Output() closePanel = new EventEmitter<Tag | undefined>();
  @Output() closePanelWithInvestor = new EventEmitter<Investor>();
  @Output() companyActions = new EventEmitter<ModalActions>();
  @Output() enrichmentStatusChange = new EventEmitter<WorkFlowStatusId>();
  @Output() navigateThruCompanies = new EventEmitter<NavigateCompany>();
  @Output() upsertDeletedTags = new EventEmitter<string>();
  @Output() emitWeeklySubscription = new EventEmitter<boolean>();
  displayTagsModal: boolean = false;
  MAX_TAGS = 6;
  editTagHeader: string = "";
  editMode: Edit = Edit.VIEWMODE;
  config = environment.config;
  companyTypes: CompanyType[] = [];
  buildVersion: string | undefined = environment.prodBuild ? APP_VERSION : DEV_APP_VERSION;
  extensionVersion: string | null = localStorage.getItem("extensionVersion");
  displayBuildVersion: boolean = false;
  filterTags = true;
  tag: Tag = this.getNewTag();
  selectedTagCompanies: BasicCompany[] = [];
  displayEditCustomTag = false;
  editCustomTagMode: EditCustomTagMode = "create";
  savingTags = false;
  activeIndex: number = 0;
  companyDetails: Company | null = null;
  previousCompanyDetails: Company | null = null;
  @ViewChild("cdContainer") cdContainer!: ElementRef;
  items: MenuItem[] | undefined;
  
  get canAcceptChanges(): boolean {
    if (!this.companyDetails) return false;
    const workflow = getEnrichmentWorkflow(this.companyDetails.workflows);
    return (
      workflow?.statusId === WorkFlowStatusId.Done &&
      (workflow.owner.id === this.user?.id || this.authService.isAdmin) &&
      this.isCompanyActive
    );
  }

  get canMarkAsDone(): boolean {
    if (!this.companyDetails) return false;
    const enrichStatusId = getEnrichmentStatusId(this.companyDetails.workflows);
    return (
      (this.authService.isAnalyst || this.authService.isAdmin) &&
      (enrichStatusId === 1 || enrichStatusId === 4) &&
      this.isCompanyActive
    );
  }

  get editModeConnections(): boolean {
    return this.editMode === Edit.CONNECTIONS && this.isCompanyActive;
  }

  get isPartOfCoverageUsers(): boolean {
    if (!this.companyDetails) return false;
    return !!this.companyDetails.coverageUsers?.find((user) => user.id === this.user?.id);
  }

  get user() {
    return this.authService.user.getValue();
  }

  get companiesNavigationEnabled(): boolean {
    return this.editMode === Edit.VIEWMODE && this.enableNavigation;
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  get icVotingWorkflows(): WorkFlow[] {
    if (!this.companyDetails) return [];
    return getAllICVotingWorkflows(this.companyDetails.workflows);
  }

  get latestICVotingWorkflow(): Nullable<WorkFlow> {
    return this.icVotingWorkflows.length ? this.icVotingWorkflows[0] : null;
  }

  get coverageUsers(): Nullable<User[]> {
    if (!this.companyDetails) return null;
    if (this.companyDetails.coverageUsers?.length) {
      return this.companyDetails.coverageUsers;
    }

    return null;
  }

  get podLeader(): Nullable<User> {
    if (!this.companyDetails) return null;
    if (this.companyDetails.assignee) {
      return { ...this.companyDetails.assignee, isPodLeader: true };
    }
    return null;
  }

  get coverageBtnLabel(): string {
    return this.coverageUsers?.length ? "Join Coverage" : "Start Coverage";
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  get isCompanyActive(): boolean {
    if (!this.companyDetails) return false;
    return this.companyDetails.isActive;
  }

  get companyType(): string | undefined {
    if (!this.companyDetails) return undefined;
    return this.companyTypes.find((x) => x.id === this.companyDetails?.companyType)?.name;
  }

  get companyId(): number {
    if (!this.companyDetails) return 0;

    return this.companyDetails.id;
  }

  get tags(): Tag[] {
    if (!this.companyDetails) return [];

    // Filtering tags that come from sourcescrub.
    return this.companyDetails.tags?.filter(tag=>tag.type != 4);
  }
  get g2Exists(): boolean {
    if (!this.companyDetails) return false;
    return !(this.companyDetails.companyScore == null);
  }
  get g2ProductScores(): Nullable<CompanyProductScore[]> {
    if (!this.companyDetails) return null;
    if (this.companyDetails.companyScore == null) return null;

    return this.companyDetails.companyScore.productScores;
  }

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private companiesService: CompaniesService,
    public supportService: SupportService,
    private deviceService: DeviceDetectorService,
    private readonly destroy$: DestroyObservable,
    public votingService: VotingService,
    private companyTypeService: CompanyTypeService,
    public chromeExtensionService: ChromeExtensionService,
    private metaService: MetaService,
    private filterHelperService: FilterHelperService,
    private companyHelperService: CompanyHelperService,
    private tagsService: TagsService,
    private router: Router,
    private modalUtilsService: ModalUtilsService,
    private newsletterService: CompanyNewsletterService,
    private mixpanelService: MixpanelService,
  ) {
    this.companyTypeService.companyTypes$.pipe(takeUntil(destroy$)).subscribe((response) => {
      this.companyTypes = response.data ?? [];
    });
  }

  ngOnInit() {
    this.subscribeToNavigation();

    if (this.companyDetails) {
      this.companyDetails.tags.sort((a) => (a.type === TagType.User ? 0 : a.type === TagType.System ? 1 : 2));
    }
    if(this.isMobile) {
      setTimeout(() => {
        window.scrollTo(0, -500);
        document.body.scrollTop = 0;
      }, 100);
    }
  }

  ngAfterViewInit() {
    this.route.fragment.pipe(takeUntil(this.destroy$), observeOn(asyncScheduler, 50)).subscribe((fragment) => {
      if (this.currentTabIndex == InvestorTab.COMPANIES && this.currentChildIndex == 1){
        this.activeIndex = 2;
      }
      if (fragment) {
        if (fragment === "relationship") {
          this.activeIndex = 2;
        }
        const activityData = document.getElementById(fragment);
        if (activityData != null) {
          activityData.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
    this.addSwipeEvents();
  }

  @HostListener("window:keyup.ArrowLeft", ["$event"])
  onKeyupArrowLeft() {
    this.goToPrevious();
  }

  @HostListener("window:keyup.ArrowRight")
  onKeyupArrowRight() {
    this.goToNext();
  }

  subscribeToNavigation() {
    this.modalUtilsService.toggleNavigation.subscribe((event) => {
      this.enableNavigation = event;
    });
  };

  showBuildVersion() {
    this.displayBuildVersion = !this.displayBuildVersion;
  }

  showBuildVersionIpad() {
    this.displayBuildVersion = !this.displayBuildVersion;
  }

  onEditConnections(event: boolean) {
    this.editMode = event ? Edit.CONNECTIONS : Edit.VIEWMODE;
  }

  onToggleCompanyNavigation(event: boolean) {
    this.enableNavigation = event;
  }

  close() {
    this.closePanel.emit();
  }

  onCopyUrl() {
    this.mixpanelService.trackEvent("Company_Details_Topbar_Button_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: 'copyURL',
    });
    copyToClipboard(document.URL)
      .then(() => this.displayToastMessage("URL copied to clipboard."))
      .catch(() => this.displayToastMessage("Couldn't copy to clipboard. Please check the permissions."));
  }

  nextDay(): Date {
    return moment().add(1, 'day').startOf('day').toDate();
  }

  handleSubscriptionChange(event: any) {
    this.newsletterService.toggleSubscription(this.companyId, event.checked).subscribe((response) => {
      if (response.data?.result) {
        this.weeklySubscription = event.checked;
        this.emitWeeklySubscription.emit(this.weeklySubscription);
        this.messageService.add({ key: "cd", severity: "success", detail: response.data.message });
        this.mixpanelService.trackEvent("News_Alerts_Toggle", {
          companyName: this.companyDetails?.name,
          isExtension: this.isExtension,
          toggle: event.checked,
        })
      } else {
        this.messageService.add({ key: "cd", severity: "error", detail: response.message });
      }
    });
  }

  emitCompanyAction(value: ModalActions) {
    // this.companyActions.emit(value);
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = value;
    modalParameters.selectedCompany = this.companyDetails;
    modalParameters.companyDetails = this.companyDetails;
    modalParameters.displayCompanyDetails = true;
    modalParameters.mainFilterRaw = this.mainFilterRaw;
    modalParameters.tabs = this.tabs;
    modalParameters.currentTabIndex = this.currentTabIndex;
    modalParameters.currentChildIndex = this.currentChildIndex;
    modalParameters.coverageUsers = []
    this.coverageUsers?.forEach(coverageUser=>{
      modalParameters.coverageUsers?.push(coverageUser);
    })
    modalParameters.teamMembers = [];

    if (modalParameters.currentModalAction === "createOrEditNotificationTrigger") {
      modalParameters.minTriggerDate = this.nextDay();
      modalParameters = { ...this.modalUtilsService.setUpNotificationTriggers(modalParameters) };
    }

    this.modalUtilsService.toggalModal.next(modalParameters);
    this.enableNavigation = false;
  }

  onInvestorClick(investor: Investor) {
    this.closePanelWithInvestor.emit(investor);
  }

  extractCompaniesIds(companies: BasicCompany[]) {
    return companies.map((company) => company.id);
  }

  markAsDone() {
    this.companiesService
      .updateEnrichmentStatus(this.companyId, {
        status: WorkFlowStatusId.Done,
        assigneeId: this.user?.id,
      })
      .pipe(this.handleResponse("Relationships data pushed for review", "Error updating relationships data"))
      .subscribe(() => {
        updateEnrichmentStatus(this.companyDetails!, WorkFlowStatusId.Done);
        this.enrichmentStatusChange.emit(WorkFlowStatusId.Done);
      });
  }

  acceptChanges() {
    this.companiesService
      .updateEnrichmentStatus(this.companyId, { status: WorkFlowStatusId.Accepted })
      .pipe(this.handleResponse("Changes accepted", "Error accepting changes"))
      .subscribe(() => {
        updateEnrichmentStatus(this.companyDetails!, WorkFlowStatusId.Accepted);
        this.enrichmentStatusChange.emit(WorkFlowStatusId.Accepted);
      });
  }

  declineChanges() {
    this.companiesService
      .updateEnrichmentStatus(this.companyId, { status: WorkFlowStatusId.RevisionRequired })
      .pipe(this.handleResponse("Changes declined", "Error declining changes"))
      .subscribe(() => {
        updateEnrichmentStatus(this.companyDetails!, WorkFlowStatusId.RevisionRequired);
        this.enrichmentStatusChange.emit(WorkFlowStatusId.RevisionRequired);
      });
  }

  goToPrevious() {
    if (this.companiesNavigationEnabled) {
      this.navigateThruCompanies.emit(NavigateCompany.PREVIOUS);
    }
  }

  goToNext() {
    if (this.companiesNavigationEnabled) {
      this.navigateThruCompanies.emit(NavigateCompany.NEXT);
    }
  }

  showVoteStats(workflow: WorkFlow) {
    if (workflow?.id) {
      this.votingService.showVotingStats(
        workflow.id,
        this.companyDetails!.name,
        `${this.companyDetails!.address?.city},  ${this.companyDetails!.address?.country.name}`
      );
    }
  }

  openInJarvis() {
    this.mixpanelService.trackEvent("Company_Details_Topbar_Button_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: 'openInJarvis',
    });
    window.open(window.location.href);
  }

  openCreateNewTagModal() {
    this.tag = this.getNewTag();
    this.displayEditCustomTag = true;
    this.enableNavigation = false;
    this.selectedTagCompanies.push(this.companyDetails!);
    this.editCustomTagMode = "create";
    this.editTagHeader = "Add new Tag";
  }

  openEditTagModal(tag: Tag) {
    this.tag = tag;
    this.displayEditCustomTag = true;
    this.enableNavigation = false;
    this.selectedTagCompanies.push(this.companyDetails!);
    this.editCustomTagMode = "edit";
    this.editTagHeader = `Edit tag <strong>${this.tag.name}</strong>`;
  }

  resetTags() {
    this.displayEditCustomTag = false;
    this.enableNavigation = true;
    this.selectedTagCompanies = [];
    this.savingTags = false;
    this.tag = this.getNewTag();
  }

  onTagClick(tag: Tag) {
    if (!this.isExtension) {
      this.closePanel.emit(tag);
    }
  }

  onEditTagClick(tag: Tag) {
    this.openEditTagModal(tag);
  }

  onRemoveTagClick(tag: Tag) {
    this.companiesService
      .removeTag(this.companyId, tag)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, error }) => {
        if (data?.result) {
          const tagToRemoveIndex = this.companyDetails!.tags.findIndex(({ id }) => id === tag.id);
          if (tagToRemoveIndex !== -1) {
            this.companyDetails!.tags.splice(tagToRemoveIndex, 1);
          }
          this.tagsService.fetchUpdatedTags();
          this.resetTags();
        } else if (error) {
          this.displayErrorMessage(error, "An error happened while removing tag.");
        }
      });
  }

  deleteTag() {
    this.metaService
      .removeTag(this.tag.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, error }) => {
        if (data?.result) {
          this.upsertDeletedTags.emit(this.tag.id.toString());
          const tagToRemoveIndex = this.companyDetails!.tags.findIndex(({ id }) => id === this.tag.id);
          if (tagToRemoveIndex !== -1) {
            this.companyDetails!.tags.splice(tagToRemoveIndex, 1);
          }
          this.metaService.changeMessage("refresh-tags");
          this.tagsService.fetchUpdatedTags();
          this.resetTags();
        } else if (error) {
          this.displayErrorMessage(error, "An error happened while removing tag.");
        }
      });
  }

  saveTag() {
    const isNew = !this.tag.id;
    const upsertObs$ = isNew
      ? this.companiesService.createTagForSingleCompany(this.companyId, this.tag)
      : this.companiesService.updateTagForSingleCompany(this.companyId, this.tag);
    upsertObs$
      .pipe(tap({ subscribe: () => (this.savingTags = true) }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, error }) => {
        this.savingTags = false;
        if (data?.result) {
          this.tag.id = data.data; // data is an ID of the newly created tag
          this.tag.createBy = this.user!.id;
          if (!isNew) {
            this.companyDetails!.tags = this.companyDetails!.tags.filter((tag) => tag.id !== this.tag.id);
          }

          this.companyDetails!.tags.push(cloneDeep(this.tag));
          this.filterHelperService.upsertTags([cloneDeep(this.tag)]);
          this.showSuccessToastMessage(data?.message);
          this.companyHelperService.changeMessage("refresh-details");
          this.tagsService.fetchUpdatedTags();
          this.metaService.changeMessage("refresh-tags");
          this.resetTags();
          const mixpanelEventName = isNew ? "Company_Details_Tag_Added" : "Company_Details_Tag_Updated";
          this.mixpanelService.trackEvent(mixpanelEventName, {
            companyName: this.companyDetails?.name,
            isExtension: this.isExtension,
          });
        } else if (error) {
          this.displayErrorMessage(error.response, "An error happened while saving tag.");
        }
      });
  }

  trackByTag(index: number, { id }: Tag): number {
    return id;
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  private addSwipeEvents() {
    if (this.isMobile && this.cdContainer && this.cdContainer.nativeElement) {
      const hammer = new Hammer(this.cdContainer.nativeElement);
      hammer.on("swipeleft", () => this.goToNext());
      hammer.on("swiperight", () => this.goToPrevious());
    }
  }

  private displayToastMessage(message: string) {
    this.messageService.add({ key: "cd", severity: "info", detail: message });
  }

  private handleResponse(
    successMessage: string,
    errorMessage: string
  ): MonoTypeOperatorFunction<ApiResponse<OperationResult>> {
    return pipe(
      tap((response) => {
        if (response.error || !response.data?.result) {
          this.displayErrorMessage(response, errorMessage);
        }
      }),
      filter((value) => !!value.data?.result),
      tap(() => this.messageService.add({ key: "cd", severity: "success", detail: successMessage }))
    );
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  private getNewTag(): Tag {
    return {
      type: TagType.User,
    } as Tag;
  }

  showMoreTags() {
    this.displayTagsModal = true;
    this.enableNavigation = false;
  }

  resetTagsModalItems() {
    this.displayTagsModal = false;
    this.enableNavigation = true;
  }

  onAISummaryClick() {
    this.mixpanelService.trackEvent("Company_Details_Topbar_Button_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: 'AiPrep',
    });
    this.router.navigate(["/ai-summary"], { queryParams: { companyDomain: this.companyDetails?.website, companyId: this.companyDetails?.id } });
  }

  socialUrlClick(event: SocialLink) {
    if (event) {
      const socialLinkType = SocialLinkType[event.type];
      this.mixpanelService.trackEvent("Company_Details_Social_Link_Clicked", {
        companyName: this.companyDetails?.name,
        socialLinkType: socialLinkType,
        isExtension: this.isExtension,
      });
    }
  };

  supportIconClick(event: any) {
    // event.stopPropagation();
    this.mixpanelService.trackEvent('Company_Details_Topbar_Button_Clicked', {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: 'support',
    });
  };

  onTabViewClick() {
    let menuItem = '';
    switch (this.activeIndex) {
      case 0:
        menuItem = 'Overview';
        break;
      case 1:
        menuItem = 'Funding';
        break;
      case 2:
        menuItem = 'Contacts';
        break;
      case 3:
        menuItem = 'Interaction History';
        break;
      default:
        menuItem = 'Unknown';
    }
    this.mixpanelService.trackEvent("Company_Details_SubMenu_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      menuItem: menuItem
    });
  }

  onTopBarClick(value: ModalActions) {
    const actionType = TopBarButtonActionType[value as keyof typeof TopBarButtonActionType]
    this.mixpanelService.trackEvent("Company_Details_Topbar_Button_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: actionType,
    });
    this.emitCompanyAction(value);
  };

  onCoverageButtonClick(value: ModalActions) {
    const actionType = TopBarButtonActionType[value as keyof typeof TopBarButtonActionType]
    this.mixpanelService.trackEvent("Company_Details_Join_Coverage_Button_Clicked", {
      companyName: this.companyDetails?.name,
      isExtension: this.isExtension,
      button: actionType,
    });
    this.emitCompanyAction(value);
  };

  initializeMenuItems() {
    this.items = [
      {
        items: [
          {
            label: 'Copy Url',
            icon: 'pi pi-copy',
            command: () => {
              this.onCopyUrl();
            }
          // }, {
          //   label: 'Support',
          //   icon: 'icon-mail',
          //   command: (event) => {
          //     this.supportIconClick(event);
          //     const supportEmail = this.supportService.supportEmail();
          //     window.open('mailto:' + supportEmail);
          //   }
          }, 
          {
            label: 'Close',
            icon: 'pi pi-times',
            command: () => {
              this.close();
            }
          }
        ]
      }
    ];
    
    if (this.authService.isInvestor || this.authService.isAdmin) {
      // if(this.enableICVoting) {
      //   this.items[0].items?.push({
      //     label: 'Flag for IC Voting',
      //     icon: 'icon-thumbs-up',
      //     command: () => {
      //       this.onTopBarClick('flagForICVoting');
      //     }
      //   });
      // }

      // this.items[0].items?.push({
      //   label: 'Create/Edit Alert',
      //   icon: 'icon-notification',
      //   command: () => {
      //     this.onTopBarClick('createOrEditNotificationTrigger');
      //   }
      // });

      // if(this.isCompanyActive) {
      //   this.items[0].items?.push({
      //     label: 'Edit Coverage',
      //     icon: 'icon-edit',
      //     command: () => {
      //       this.onTopBarClick('editCoverage');
      //     }
      //   });
      // }

      // if (this.isPartOfCoverageUsers && this.isCompanyActive) {
      //   this.items[0].items?.push({
      //     label: 'Leave Coverage',
      //     icon: 'icon-remove-coverage',
      //     command: () => {
      //       this.onTopBarClick('leaveCoverage');
      //     }
      //   });
      // }

      // if(this.isCompanyActive) {
      //   this.items[0].items?.push({
      //     label: 'Change Status',
      //     icon: 'icon-status',
      //     command: () => {
      //       this.onTopBarClick('changeStatus');
      //     }
      //   });
      // }

      // if (!this.isPartOfCoverageUsers && this.isCompanyActive) {
      //   this.items[0].items?.push({
      //     label: 'Join Coverage',
      //     icon: 'icon-add-coverage',
      //     command: () => {
      //       this.onTopBarClick('joinCoverage');
      //     }
      //   });
      // }

      if ((this.companyDetails?.priority === 0 || this.companyDetails?.priority === 2) && (this.isPartOfCoverageUsers)) {
        this.items[0].items?.push({
          label: 'Add to Priority',
          icon: 'icon-add-priority',
          command: () => {
            this.onTopBarClick('addToPriority');
          }
        });
      }
      
      if ((this.companyDetails?.priority === 3) && (this.isCompanyActive && this.isPartOfCoverageUsers)) {
        this.items[0].items?.push({
          label: 'Remove from Priority',
          icon: 'icon-remove-priority',
          command: () => {
            this.onTopBarClick('removeFromPriority');
          }
        });
      }
    
      // Enrich data icon is intentionally hidden for experimentation
    
      // if (!this.enableEnrichment) {
      //   this.items[0].items?.push({
      //     label: 'Enrich Data',
      //     icon: 'icon-enrich',
      //     command: () => {
      //       this.onTopBarClick('enrichData');
      //     }
      //   });
      // }

      // this.items[0].items?.push({
      //   label: 'AI Generated Summary',
      //   icon: 'fa-regular fa-file-lines',
      //   command: () => {
      //     this.onAISummaryClick();
      //   }
      // });
    }
    // if(this.config.navigateThruCompanies && this.companiesNavigationEnabled) {
    //   this.items[0].items?.push({
    //     label: 'Back',
    //     icon: 'icon-circle-left',
    //     command: () => {
    //       this.goToPrevious();
    //     }
    //   }, {
    //     label: 'Forward',
    //     icon: 'icon-circle-right',
    //     command: () => {
    //       this.goToNext();
    //     }
    //   });
    // }
  }
}
