import { Injectable } from '@angular/core';
import { CompaniesService } from '../../services/companies/companies.service';
import { Nullable } from '@models/nullable';
import { VotingStats } from '@models/voting';
import { MessageService } from 'primeng/api';
import { Company } from '@core/models/company';
import { catchError, of } from 'rxjs';
import { WorkflowsService } from 'src/app/services/workflows/workflows.service';
import { ApiResponse } from 'src/app/api/base';
import { OperationResult } from '@core/models/operation-result';

@Injectable({
  providedIn: 'root'
})
export class VotingService {

  displayVotingStats = false;
  votingStats: Nullable<VotingStats> = null;
  companyName: Nullable<string> = null;
  companyLocation: Nullable<string> = null;
  company: Nullable<Company> = null;

  constructor(
    private companiesService: CompaniesService,
     private messageService: MessageService,
     private workflowsService: WorkflowsService,) {
  }

  showVotingStats(workflowId: number, name: string, location: string) {
    this.companiesService.getVotingStats(workflowId).subscribe(response => {
      this.resetLocalData();
      if (response?.data) {
        this.companyName = name;
        this.companyLocation = location;
        this.votingStats = response.data;
        this.displayVotingStats = true;
      } else {
        this.showInfoToastMessage('Company voting has not started yet.');
      }
    });
  }

  hideVotingStats() {
    this.resetLocalData();
    this.company = null;
    this.displayVotingStats = false;
  }

  resetLocalData() {
    this.votingStats = null;
    this.companyName = null;
    this.companyLocation = null;
  }

  showInfoToastMessage(message: string) {
    this.messageService.add({ key: 'bc', severity: 'info', detail: message });
  }

  

}
