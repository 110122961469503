import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { OpenSearch } from '@core/components/portfolio-update/portfolio-update.component';
import { GroupType } from '@core/models/auxiliary-company-models';
import { BasicCompany } from '@core/models/company';
import { MessageService } from 'primeng/api';
import { BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService extends BaseApiService<BasicCompany[]> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/UpdatesAttachment', authService, router, messageService);
  }

  getGroupUpdatesFileUploadURL(groupUpdateId : number, fileName: string, fileExtension: string){
    const body = {
      fileName: fileName,
      fileExtension: fileExtension,
      groupUpdateId: groupUpdateId,
    }
    return this.post(`/group-updates/begin`, null, body);
  }

  completeUpload(uniqueId: string){
    return this.post(`/${uniqueId}/complete`);
  }

  getPortfolioCompanyUpdatesFileUploadURL(portfolioCompanyUpdateId : number, fileName: string, fileExtension: string){
    const body = {
      fileName: fileName,
      fileExtension: fileExtension,
      portfolioCompanyUpdateId: portfolioCompanyUpdateId,
    }
    return this.post(`/portfolio-company-updates/begin`, null, body);
  }

  getGenericUpdatesFileUploadURL(genericUpdateId : number, fileName: string, fileExtension: string){
    const body = {
      fileName: fileName,
      fileExtension: fileExtension,
      genericUpdateId: genericUpdateId,
    }
    return this.post(`/generic-updates/begin`, null, body);
  }

  deleteUpdatesAttachment(uniqueId: string){
    return this.delete(`/${uniqueId}`);
  }
}
