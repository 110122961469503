import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DataFilter } from '../dynamic-table/dynamic-table.component';

@Component({
  selector: 'bpc-paginator',
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss'
})
export class PaginatorComponent implements OnChanges {
  @Input() pageSize = 100;
  @Input() totalCount: number = 10;
  @Input() loading = false;

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onPageSizeChange = new EventEmitter<number>();

  currentTotalPages = 1;
  disableFirstPage = true;
  disableLastPage = true;
  currentPage = 1
  paginationText = '';

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['totalCount']) {
      if(this.pageSize) {
        this.currentTotalPages = Math.ceil(this.totalCount / this.pageSize)
        this.disableFirstPage = this.currentTotalPages === 1;
        this.disableLastPage = this.currentTotalPages === 1;
      }
    }

    this.getPaginationText();
  }

  getPaginationText() {
    const startRecord = (this.currentPage - 1) * this.pageSize + 1;
    const endRecord = Math.min(this.currentPage * this.pageSize, this.totalCount);

    this.paginationText = `Showing ${startRecord}-${endRecord} of ${this.totalCount} records`;
  }

  pageSizeChanged(e: any) {
    this.pageSize = e;
    this.currentPage = 1;
    this.onPageSizeChange.emit(e);
  }

  goToPage(e: any) {
    this.currentPage = e;
    this.onPageChange.emit(e);
  }
}
