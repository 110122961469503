import { Injectable } from "@angular/core";
import { Company } from "../models/company";
import { Nullable } from "../models/nullable";
import { WorkflowsCommentsService } from "src/app/services/workflows-comments/workflows-comments.service"; 
import { catchError, of } from "rxjs";
import { WorkFlow } from "@core/models/auxiliary-company-models";
import { MessageService } from "primeng/api";
import { ApiResponse } from "src/app/api/base";
import { OperationResult } from "@core/models/operation-result";
import { AuthService } from "@core/auth/auth.service";
import { WorkflowsService } from "src/app/services/workflows/workflows.service";
import { AIPrepService } from "./ai-prep.service";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  displayComment = false;
  selectedCompany: Nullable<Company> = null;
  workflow: Nullable<WorkFlow> = null;
  loading: boolean = true;
  summaryLoading: boolean = false;

  get isICAdmin(): boolean {
    return this.authService.investmentcommitteeadmin;
  }

  constructor(
    private workflowsCommentsService: WorkflowsCommentsService,
    private messageService: MessageService,
    private authService: AuthService,
    private workflowsService: WorkflowsService,
    private aiPrepService: AIPrepService
  ) {}

  showComments(company: Company) {
    this.loading = true;
    this.displayComment = true;
    this.selectedCompany = company;
    this.refreshComments();
  }

  clean() {
    this.displayComment = false;
    this.selectedCompany = null;
  }

  refresh() {
    if (this.isICAdmin){
      this.loading = true;
      this.workflowsCommentsService
        .getWorkflow(this.selectedCompany?.workflows[0].companyWorkflowId ?? 0)
        .pipe(
          catchError((error) => {
            this.displayErrorMessage(error, "Error refreshing comments. Please try again.");
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response?.data) {
            response.data?.companyWorkflowComments.sort((comment1: any, comment2: any) => new Date(comment2.createDate).getTime() - new Date(comment1.createDate).getTime());
            this.workflow = response.data;
            this.loading = false;
            this.summaryLoading = true;
            // Generate new summary here.
            if (this.workflow != null){
              const comments: any[] = [];
              this.workflow.companyWorkflowComments?.forEach(comment=>{
                comments.push({name: comment.user.name, comment: comment.comment});
              })
              if (comments.length == 0){
                this.summaryLoading = false;
                this.showErrorToastMessage("No comments. Cannot generate summary!");
                this.workflow.summary = "No summary generated."
                this.workflowsService.updateWorkflowSummary(this.workflow?.companyWorkflowId, this.workflow?.summary)
                  .pipe(
                    catchError((error) => {
                      this.displayErrorMessage(error, "Error saving summary. Please refresh again.");
                      return of(null);
                    }),
                  )
                  .subscribe((response) => {
                  });
                return;
              }
              this.aiPrepService.getCommentsSummary(comments)
              .pipe(
                catchError((error) => {
                  this.displayErrorMessage(error, "Error generating summary. Please refresh again.");
                  this.summaryLoading = false;
                  return of(null);
                }),
              )
              .subscribe((response) => {
                if (response){
                  this.summaryLoading = false;
                  if (this.workflow!=null) this.workflow.summary = response.summary;
                  this.workflowsService.updateWorkflowSummary(this.workflow?.companyWorkflowId, this.workflow?.summary)
                  .pipe(
                    catchError((error) => {
                      this.displayErrorMessage(error, "Error saving summary. Please refresh again.");
                      return of(null);
                    }),
                  )
                  .subscribe((response) => {
                  });
                }
              });
            }
            
          } else {
            this.displayErrorMessage(response, "Error refreshing comments. Please try again.");
          }
        });
    }
    else{
      this.refreshComments();
    }
  }

  refreshComments(){
    this.loading = true;
    this.workflowsCommentsService
      .getWorkflow(this.selectedCompany?.workflows[0].companyWorkflowId ?? 0)
      .pipe(
        catchError((error) => {
          this.displayErrorMessage(error, "Error refreshing comments. Please try again.");
          return of(null);
        }), 
      )
      .subscribe((response) => {
        if (response?.data) {
          response.data?.companyWorkflowComments.sort((comment1: any, comment2: any) => new Date(comment2.createDate).getTime() - new Date(comment1.createDate).getTime());
          this.workflow = response.data;
          this.loading = false;
        } else {
          this.displayErrorMessage(response, "Error refreshing comments. Please try again.");
        }
      });
  }

  addEditComment(comment: string, isEdit = false, companyWorkflowCommentId: number = 0) {
    return isEdit
      ? this.workflowsCommentsService.updateWorkflowComment(
          companyWorkflowCommentId,
          comment,
        )
      : this.workflowsCommentsService.addWorkflowComment(
          this.selectedCompany?.workflows[0].companyWorkflowId ?? 0,
          comment,
        );
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }
}
