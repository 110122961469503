import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FilterType, WidgetFilter } from "../../models/analytics-dashboard-enums";

@Component({
  selector: "bpc-widget",
  templateUrl: "./widget.component.html",
  styleUrl: "./widget.component.scss",
})
export class WidgetComponent {
  @Input() title: string = "";
  @Input() filters: WidgetFilter[] = [];

  @Output() onFilterChange = new EventEmitter<{}>();

  filterChangeEvent(selectedFilters: any) {
    this.onFilterChange.emit(selectedFilters);
  }
}
