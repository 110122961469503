import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Company } from '@models/company';
import * as moment from 'moment';
import { Nullable } from '@models/nullable';
import { EmployeeSeries, Interaction, InteractionType, InvestorRole, Round } from '@models/auxiliary-company-models';
import { MixpanelService } from '@core/services/mixpanel.service';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';

@Component({
  selector: 'bpc-interaction-history',
  templateUrl: './interaction-history.component.html',
  styleUrls: ['./interaction-history.component.scss']
})
export class InteractionHistoryComponent implements OnChanges {
  @Input() companyDetails!: Company;
  @Input() interactions!: Interaction[];
  @Input() selectedInteractionType!: string;

  selectedInteraction: Nullable<number> = null;
  interactionsData: Interaction[] = [];
  validInteractionsData: boolean = false;

  get rounds(): Round[] {
    return this.companyDetails.rounds ?? [];
  }

  get ftes(): EmployeeSeries[] {
    return this.companyDetails.employeeSeries ?? [];
  }

  // get interactions(): Interaction[] {
  //   return this.companyDetails.interactions ?? [];
  // }

  // ngOnInit(): void {
  //   this.generateInteractionsData();
  // }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  constructor(
    private mixpanelService: MixpanelService,
    public chromeExtensionService: ChromeExtensionService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.generateInteractionsData();
    this.selectedInteraction = null;
  }

  getInvestorName(id: number): string {
    return this.companyDetails.investors?.find(investor => investor.id === id)?.name ?? '';
  }

  getLeadInvestorName(round: Round): Nullable<string> {
    const leadInvestor = round.investments?.find(i => i.investorRole === InvestorRole.LEAD);
    if (leadInvestor) {
      return this.getInvestorName(leadInvestor.investorId);
    }

    return null;
  }

  toggleInteraction(index: number) {
    if ((this.selectedInteraction === index ? null : index)  !== null) {
      this.mixpanelService.trackEvent('Interaction_History_View', {
        companyName: this.companyDetails.name,
        isExtension: this.isExtension,
      });
    }
    this.selectedInteraction = this.selectedInteraction === index ? null : index;
  }

  private generateInteractionsData() {
    this.validInteractionsData = false;


    if (this.selectedInteractionType != null){
      const interactionType = InteractionType[this.selectedInteractionType.replace(' ', '') as keyof typeof InteractionType];

      this.interactionsData = this.interactions;
      if (interactionType !== InteractionType.All) {
        this.interactionsData = this.interactionsData.filter(f => f.interactionType === interactionType);
      }

      this.interactionsData = this.interactionsData.sort((a, b) => {
        return Date.parse(b.interactionDate) - Date.parse(a.interactionDate);
      });
      }
    else{
      const fullData = this.interactions.sort((a, b) => {
        return Date.parse(b.interactionDate) - Date.parse(a.interactionDate);
      });
      this.interactionsData = fullData;
    }

    if (this.interactionsData.length > 0) {
      this.validInteractionsData = true;
    }
  }

}

interface ActivityPeriod {
  label: string,
  value: number
}
