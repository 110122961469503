import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Nullable } from '@core/models/nullable';
import { ModalActions, Tab } from '@core/models/types';
import { ModalUtilsService } from 'src/app/services/modal-utils.service';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  fromEvent,
  map,
  merge,             
  mergeAll,
  MonoTypeOperatorFunction,
  Observable,
  pipe,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttle,
  timer,
} from "rxjs";
import { ApiResponse } from 'src/app/api/base';
import { MessageService } from 'primeng/api';
import { OperationResult } from '@core/models/operation-result';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { TabUtilsService } from 'src/app/services/tab-utils.service';
import { createNotificationTriggerModal, decreaseCounter, removeCompanyFromTab } from 'src/app/utils/tab-utils';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { MainFilterRaw } from '@core/models/main-filter-view';
import { User, UserRole } from '@core/models/user';
import { TabData } from '@core/static/tabs';
import { Company } from '@core/models/company';
import { DestroyObservable } from 'src/app/rxjs/DestroyObservable';
import { FilterDropdown, InteractionType, NotificationTriggerModal, StatusId } from '@core/models/auxiliary-company-models';
import { DropdownOption } from '@core/models/forms';
import { companyStatusOptions } from '@shared/forms/dropdown-options';
import { UsersService } from 'src/app/services/users/users.service';
import { getVotingBackgroundClasses, getVotingTextColorClasses } from 'src/app/utils/company-utils';
import { TimeBasedTriggerOccurrence } from '@core/directives/dropdown/time-based-trigger-occurrences.directive';
import moment from 'moment';


export enum FrequenyType {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

export interface ModalParameters {
  currentModalAction: Nullable<ModalActions>,
  displayCompanyDetails?: boolean,
  mainFilterRaw?: MainFilterRaw,
  tabs?: Record<UserRole, TabData<Company>[]>,
  currentTabIndex?: Tab,
  currentChildIndex?: Nullable<number>,
  selectedCompany: Nullable<Company>,
  companyDetails?: Nullable<Company>,
  newStatusId?: Nullable<StatusId>,
  coverageUsers?: Nullable<User[]>,
  comment?: Nullable<string>,
  previousInvestors?: Nullable<string>,
  investors?: Nullable<string>,
  checkSize?: number,
  savedSearchId?: number,
  cronJobId?: Nullable<string>,
  nextStep?: Nullable<string>,
  outreachOwner?: User,
  dateAdded?: string | Date,
  companyWorkFlowId?: number,
  icVote?: Nullable<number>,
  actionTaken?: Nullable<InteractionType>,
  title?: Nullable<string>
  timeBasedChecked?: boolean,
  recurringCheckbox?: boolean,
  oneTimeCheckBox?: boolean,
  timeBasedTriggerType?: TimeBasedTriggerOccurrence,
  recurring?: Nullable<boolean>,
  recurringWeekly?: number,
  recurringMonthly?: number,
  minTriggerDate?: Date,
  triggerOn?: Nullable<Date>,
  fteTrigger?: NotificationTriggerModal,
  fteGrowthTrigger?: NotificationTriggerModal,
  picTrigger?: NotificationTriggerModal,
  revenueTrigger?: NotificationTriggerModal,
  selectedJobId?: Nullable<string>,
  frequency?: FrequenyType,
  selectedDayOfMonth?: number,
  selectedDayOfWeek?: number,
  selectedTime?: Nullable<Date>,
  userRoles?: any[],
  userGroups?: any[],
  userPods?: any[],
  email?: string;
  name?: string;
  initials?: string;
  externalId?: Nullable<number>;
  userId?: Nullable<number>;
  isActive?: boolean;
  base64Image?: string;
  userStatus?: boolean;
  chatThreadId?: string;
  profileImageFile?: File;
  signedUrl?: string;
  teamMembers?: Nullable<User[]>,
}


@Component({
  selector: 'bpc-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {

  toggleModal: BehaviorSubject<ModalParameters>;
  currentModalAction: Nullable<ModalActions> = null;
  displayModal: boolean = false;
  modalLoading: boolean = false;
  modalParameters: ModalParameters;
  newStatusId: Nullable<StatusId> = null;
  changeCompanyStatusDropdownOptions: DropdownOption[];
  coverageUsers: User[] = [];
  users: User[] = [];
  showEditButton: boolean = false;
  showSaveButton: boolean = false;
  imageLoading: boolean = false;

  toggleEditButton(event: boolean) {
    this.showEditButton = event;
  }

  toggleSaveButton(event: boolean) {
    this.showSaveButton = event;
  }

  toggleImageLoading(event: boolean) {
    this.imageLoading = event;
  }


  get frequencyType(): typeof FrequenyType {
    return FrequenyType;
  }

  get modalClosable(): boolean {
    return !this.modalLoading;
  }

  get selectedCompanyStatusId(): StatusId | null {
    return this.modalParameters.companyDetails?.statusId ?? this.modalParameters.selectedCompany?.statusId ?? null;
  }

  get dropdownTypes() {
    return FilterDropdown;
  }

  get notifyIn(): number {
    return moment(this.modalParameters.triggerOn).diff(this.modalParameters.minTriggerDate, "day") + 1;
  }

  get positiveButtonDisabled(): boolean {
    switch (this.currentModalAction) {
      case "joinCoverage":
      case "enrichData":
      case "removeFromJarvis":
      case "markAsActive":
      case "addToPriority":
      case "extendPriority":
      case "removeFromPriority":
      case "closeCompanyDetails":
      case "removeFromIC":
      case "editICAsk":
      case "editICDateAdded":
      case "editOutReachOwner":
      case "editNextStep":
      case "archiveCompany":
      case "flagForICVoting":
      default:
        return false;
      case "changeStatus":
        return this.newStatusId === this.selectedCompanyStatusId;
      case "editInvestors": 
        return this.modalParameters.investors === this.modalParameters.previousInvestors
      // case "editCoverage":
      //   // return !this.coverageUsers.length;
      //   return false;
      // case "actionTaken":
      //   return this.actionTaken == null || this.title == null || this.comment == null;
      // case "icVoting":
      //   return this.icVote === null;
    }
  }

  constructor(
    public modalUtilsService: ModalUtilsService,
    public messageService: MessageService,
    public companyHelperService: CompanyHelperService,
    public tabUtilsService: TabUtilsService,
    public companiesService: CompaniesService,
    protected readonly destroy$: DestroyObservable,
    public usersService: UsersService,
  ) {
    this.toggleModal = this.modalUtilsService.toggalModal;
    this.changeCompanyStatusDropdownOptions = companyStatusOptions;
    this.modalParameters = this.modalUtilsService.modalParameters;
  }

  ngOnInit() {
    this.subscribeToModalToggle();
    this.initializeMenuOptionsStream();
    this.usersService.getAllUsers().subscribe((res) => {
      if (res.data) {
        this.users = res.data;
      }
    });
  }

  resetNotificationTriggers() {
    this.modalParameters.recurring = null;
    this.modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
    this.modalParameters.recurringWeekly = 1;
    this.modalParameters.recurringMonthly = 1;
    this.modalParameters.timeBasedChecked = false;
    this.modalParameters.triggerOn = null;
    this.modalParameters.fteTrigger = createNotificationTriggerModal();
    this.modalParameters.fteGrowthTrigger = createNotificationTriggerModal();
    this.modalParameters.picTrigger = createNotificationTriggerModal();
    this.modalParameters.revenueTrigger = createNotificationTriggerModal();
  }

  onClickRecurring() {
    if (this.modalParameters.recurringCheckbox) {
      this.modalParameters.oneTimeCheckBox = false;
      this.modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
    } else if (!this.modalParameters.recurringCheckbox) {
      this.modalParameters.oneTimeCheckBox = true;
      this.modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.OneTime;
    }
  }

  onClickOneTime() {
    if (this.modalParameters.oneTimeCheckBox) {
      this.modalParameters.recurringCheckbox = false;
      this.modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.OneTime;
    } else if (!this.modalParameters.oneTimeCheckBox) {
      this.modalParameters.recurringCheckbox = true;
      this.modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
    }
  }

  changeCompanyAction(action: ModalActions) {
    this.currentModalAction = action;
    this.onModalPositiveButtonClick();
  }

  subscribeToModalToggle() {
    this.toggleModal.subscribe((response) => {
      if (response.currentModalAction !== null) {
        if (response.currentModalAction === "addToPriority" || response.currentModalAction === "removeFromPriority" || response.currentModalAction === "extendPriority") {
          this.modalParameters = response;
          this.changeCompanyAction(response.currentModalAction);
          return;
        }
        this.currentModalAction = response.currentModalAction;
        this.modalUtilsService.toggleNavigation.next(false);
        this.displayModal = true;
        this.modalParameters = response;
        this.newStatusId = this.selectedCompanyStatusId;
      }
      else {
        this.modalUtilsService.toggleNavigation.next(true);
        this.displayModal = false;
        this.showSaveButton = false;
        this.showEditButton = false;
        this.modalParameters = this.modalUtilsService.modalParameters;
        this.modalLoading = false;
        this.currentModalAction = null;
      }

    })
  }

  closeModal() {
    this.modalUtilsService.toggleNavigation.next(false);
    // this.modalUtilsService.toggalModal.next('none')
  }

  getVotingClass(vote: number) {
    return getVotingBackgroundClasses(vote);
  }

  getVotingTextClass(vote: number) {
    return getVotingTextColorClasses(vote);
  }

  submitICVote(vote: number) {
    this.modalParameters.icVote = vote;
  }

  initializeMenuOptionsStream() {
    this.modalUtilsService.menuSubject.pipe(takeUntil(this.destroy$)).pipe(mergeAll()).subscribe();
  }

  onModalNegativeButtonClick() {
    this.displayModal = false;
    if(this.modalParameters.currentModalAction === 'AddUser' || this.modalParameters.currentModalAction === 'EditUser') {
      this.showSaveButton = false;
      this.showEditButton = false;
    }
    this.modalUtilsService.toggleNavigation.next(true);
    this.currentModalAction = null;
    // this.cancelModal.next();
  }

  onModalPositiveButtonClick() {
    if (this.modalParameters.currentModalAction === "createOrEditNotificationTrigger") {
      if (this.modalParameters.fteTrigger!.value !== null) {
        this.modalParameters.fteTrigger!.isActive = true;
      }

      if (this.modalParameters.fteGrowthTrigger!.value !== null) {
        this.modalParameters.fteGrowthTrigger!.isActive = true;
      }

      if (this.modalParameters.picTrigger!.value !== null) {
        this.modalParameters.picTrigger!.isActive = true;
      }

      if (this.modalParameters.revenueTrigger!.value !== null) {
        this.modalParameters.revenueTrigger!.isActive = true;
      }
    }

    if(this.modalParameters.currentModalAction === 'UserActiveToggle'){
      this.modalUtilsService.toggleNavigation.next(true);
      this.displayModal = false;
    }
    
    this.modalLoading = true;
    this.modalParameters.newStatusId = this.newStatusId;
    this.modalUtilsService.menuSubject.next(this.modalUtilsService.getModalSaveObservable(this.modalParameters) as Observable<ApiResponse<OperationResult>>);
  }

  resetModalItems() {
    if(this.modalParameters.currentModalAction === 'AddUser' || this.modalParameters.currentModalAction === 'EditUser') {
      this.showSaveButton = false;
      this.showEditButton = false;
      this.imageLoading = false;
    }
    this.displayModal = false;
    this.modalUtilsService.toggleNavigation.next(true);

    // this.newStatusId = null;
    // this.modalLoading = false;
    // this.displayModal = false;
    // this.title = null;
    // this.comment = null;
    // this.checkSize = undefined;
    // this.coverageUsers = [];
    // this.actionTaken = null;
    // this.selectedCompany = null;
    // this.resetNotificationTriggers();
    // if (this.displayCompanyDetails) {
    //   blockScroll();
    // }
    // this.icVote = null;
    // this.currentModalAction = null;
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }



  setFormControlValue(event: User, type: FilterDropdown) {
    switch(type){
      case FilterDropdown.teamMembers : this.modalParameters.teamMembers!.push(event); break;
      default : this.modalParameters.coverageUsers!.push(event); break;
    }
    
  }

  removeDropdownValue(id: number, type: FilterDropdown) {
    switch(type){
      case FilterDropdown.teamMembers : {
        const index = this.modalParameters.teamMembers!.findIndex((item: User) => item.id === id);
        if (index !== -1) {
          this.modalParameters.teamMembers!.splice(index, 1);
        }
        break;
      }
      default : {
        const index = this.modalParameters.coverageUsers!.findIndex((item: User) => item.id === id);
        if (index !== -1) {
          this.modalParameters.coverageUsers!.splice(index, 1);
        }
        break;
      }
    }
    
  }

  setRoleFormControlValue(event: any, type: FilterDropdown) {
    this.modalParameters.userRoles!.push(event);
  }

  removeRoleDropdownValue(id: number, type: FilterDropdown) {
    const index = this.modalParameters.userRoles!.findIndex((item: User) => item.id === id);
    if (index !== -1) {
      this.modalParameters.userRoles!.splice(index, 1);
    }
  }

  setGroupsFormControlValue(event: any, type: FilterDropdown) {
    this.modalParameters.userGroups!.push(event);
  }

  removeGroupsDropdownValue(id: number, type: FilterDropdown) {
    const index = this.modalParameters.userGroups!.findIndex((item: User) => item.id === id);
    if (index !== -1) {
      this.modalParameters.userGroups!.splice(index, 1);
    }
  }

  setPodsFormControlValue(event: any, type: FilterDropdown) {
    this.modalParameters.userGroups!.push(event);
  }

  removePodsDropdownValue(id: number, type: FilterDropdown) {
    const index = this.modalParameters.userGroups!.findIndex((item: User) => item.id === id);
    if (index !== -1) {
      this.modalParameters.userGroups!.splice(index, 1);
    }
  }

  handleBase64Image(base64Image: string) {
    this.modalParameters.base64Image = base64Image;
    this.imageLoading = false;
  }

  handleProfileImageFile(profileImageFile: File) {
    this.modalParameters.profileImageFile = profileImageFile;
  }

  filterUserGroups(): any[] {
    if (this.modalParameters && this.modalParameters.userGroups) {
      return this.modalParameters.userGroups.filter(item => item.groupType === 2);
    } else {
      return [];
    }
  }

  filterUserPods(): any[] {
    if (this.modalParameters && this.modalParameters.userGroups) {
      return this.modalParameters.userGroups.filter(item => item.groupType === 1);
    } else {
      return [];
    }
  }

  generateInitials() {
    if (this.modalParameters.name) {
        let nameParts = this.modalParameters.name.split(' ');
        nameParts = nameParts.map((part) => part.trim().charAt(0));
        let initials = nameParts.join('').slice(0, 3);
        this.modalParameters.initials = initials.toUpperCase();
    } else {
        this.modalParameters.initials = '';
    }
  }
}
