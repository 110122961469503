import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vote } from '../voting-stats/voting-stats.component';
import { Nullable } from 'primeng/ts-helpers';
import { VotingService } from '@core/services/voting.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { MessageService } from 'primeng/api';
import { catchError, of } from 'rxjs';
import { WorkflowsCommentsService } from 'src/app/services/workflows-comments/workflows-comments.service'; 

@Component({
  selector: 'bpc-delete-comment-popup',
  templateUrl: './delete-comment-popup.component.html',
  styleUrls: ['./delete-comment-popup.component.scss']
})
export class DeleteCommentPopupComponent {
  @Input() visible: boolean = false;
  @Input() comment: Nullable<any> = null;
  @Input() companyWorkflowCommentId: Nullable<number> = null;
  modalLoading: boolean = false;
  positiveButtonDisabled: boolean = false;
  @Output() toggleVisible = new EventEmitter<boolean>();
  @Output() refreshComments = new EventEmitter<null>();

  constructor(
    private votingService: VotingService,
    private companiesService: CompaniesService,
    private messageService: MessageService,
    private workflowCompaniesService: WorkflowsCommentsService
  ){

  }

  
  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  onNegativeClick(){
    this.toggleVisible.emit(false);
    this.positiveButtonDisabled = false;
  }

  onPositiveClick(){
    this.modalLoading = true;
    this.positiveButtonDisabled = true;


    if (this.comment != null && this.companyWorkflowCommentId != null){
      this.workflowCompaniesService.deleteWorkflowComment(this.companyWorkflowCommentId).pipe(
          catchError((error) => {
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.showErrorToastMessage("Error deleting comment. Please try again.");
            this.toggleVisible.emit(false);
            return of(null);
          })
        ).subscribe(response => {
          if (response?.data.result){
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.refreshComments.emit();
            this.showSuccessToastMessage("Comment deleted.");
            this.toggleVisible.emit(false);
          }
          else{
            this.modalLoading = false;
            this.positiveButtonDisabled = false;
            this.showErrorToastMessage(response.data.message);
            this.toggleVisible.emit(false);
          }
        });
    }
  }
}
