import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FilterType, WidgetFilter } from "../../models/analytics-dashboard-enums";
import { AnalyticsWidgetService } from "src/app/services/analytics-widget/analytics-widget.service";
import { Subscription } from "rxjs";

@Component({
  selector: "bpc-filter-dropdown",
  templateUrl: "./filter-dropdown.component.html",
  styleUrl: "./filter-dropdown.component.scss",
})
export class FilterDropdownComponent implements OnDestroy, OnChanges {
  @Input() filters: WidgetFilter[] = [];

  @Output() onChange = new EventEmitter<{}>();
  selectedFilter: any = {};
  FilterType = FilterType;

  private subscriptions: Subscription[] = []; // Array to hold subscriptions

  constructor(private analyticsWidgetService: AnalyticsWidgetService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.analyticsWidgetService.dashboardInitialization$.subscribe((data) => {
        if (data) {
          this.filters.forEach((filter) => {
            console.log("Filter", filter);
            this.selectedFilter[FilterType[filter.filterType]] = filter.default;
          });
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes["filters"]);
    if (changes["filters"]) {
      this.filters.forEach((filter) => {
        this.selectedFilter[FilterType[filter.filterType]] = filter.default;
      });
    }
  }

  onDropdownChange(e: any) {
    console.log(e);
    this.onChange.emit(this.selectedFilter);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
