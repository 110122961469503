<div class="main">
  <portfolio-update *ngIf="selectedUpdateType === UpdateTypesEnum.Portfolio">
    <ng-container *ngFor="let ut of updateTypes">
      <a
        *ngIf="accessControl[ut]"
        class="vote-tab-buttons"
        [ngClass]="{ 'active-ic-vote-tab': selectedUpdateType === ut }"
        [routerLink]="null"
        (click)="onSelection(ut)">
        {{ ut }}
      </a>
    </ng-container>
  </portfolio-update>

  <bpc-prospect-update *ngIf="selectedUpdateType === UpdateTypesEnum.Prospect">
    <ng-container *ngFor="let ut of updateTypes">
      <a
        *ngIf="accessControl[ut]"
        class="vote-tab-buttons"
        [ngClass]="{ 'active-ic-vote-tab': selectedUpdateType === ut }"
        [routerLink]="null"
        (click)="onSelection(ut)">
        {{ ut }}
      </a>
    </ng-container>
  </bpc-prospect-update>

  <bpc-ir-update *ngIf="selectedUpdateType === UpdateTypesEnum.IR">
    <ng-container *ngFor="let ut of updateTypes">
      <a
        *ngIf="accessControl[ut]"
        class="vote-tab-buttons"
        [ngClass]="{ 'active-ic-vote-tab': selectedUpdateType === ut }"
        [routerLink]="null"
        (click)="onSelection(ut)">
        {{ ut }}
      </a>
    </ng-container>
  </bpc-ir-update>

  <bpc-newsletter-update *ngIf="selectedUpdateType === UpdateTypesEnum.Daily">
    <ng-container *ngFor="let ut of updateTypes">
      <a
        *ngIf="accessControl[ut]"
        class="vote-tab-buttons"
        [ngClass]="{ 'active-ic-vote-tab': selectedUpdateType === ut }"
        [routerLink]="null"
        (click)="onSelection(ut)">
        {{ ut }}
      </a>
    </ng-container>
  </bpc-newsletter-update>
</div>
