import { Component, Input } from "@angular/core";
import { AssigneeRole } from "@core/models/main-filter-view";
import { BaseSortingFilter } from "src/app/services/companies-search/saved-search.service";
import { DCCompanyType } from "../../../services/companies/companies.service";

export interface ColumnMapping {
  header: string;
  mappedTo: string;
  case?: string;
  showHeader?: boolean;
  sortable?: boolean;
}

interface DataPaging {
  pageNumber?: number;
  pageSize?: number;
  thenSortDirection?: string;
  thenSortProperty?: string;
}

interface FilterFields {
  statusesInclude?: number[];
  assigneesInclude?: number[];
  interactionTypes?: number[];
  assigneeRole?: AssigneeRole;
  dcCompanyTypes?: DCCompanyType;
  interactionInitiatorsInclude?: number[];
}

export interface DataFilter extends DataPaging, BaseSortingFilter, FilterFields {}

@Component({
  selector: "bpc-dynamic-table",
  templateUrl: "./dynamic-table.component.html",
  styleUrl: "./dynamic-table.component.scss",
})
export class DynamicTableComponent {
  @Input() data: any[] = []; // The data to be displayed
  @Input() columns: ColumnMapping[] = []; // The columns to be displayed
  @Input() totalCount: number = 10;
  @Input() pageSize = 100;
  @Input() loading = false;

  sortField: string = ""; // For sorting field
  sortOrder: number = 1; // For sorting order (1 for ascending, -1 for descending)
  pagination = false; // To hide pagination if needed

  constructor() {}

  get Array(): ArrayConstructor {
    return Array;
  }

  ngOnInit(): void {}

  // Sorting function
  onSort(event: any) {
    this.sortField = event.field;
    this.sortOrder = event.order;
  }

  getSortClass(column: any) {
    if (column.sortProperty === this.sortField) {
      switch (this.sortOrder) {
        case 1:
          return "fa-sort-down";
        case 2:
          return "fa-sort-up";
        default:
          return "fa-sort";
      }
    }

    return "fa-sort";
  }
}
