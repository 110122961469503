import { Component, EventEmitter, Input, Output } from "@angular/core";
import Highcharts from "highcharts";

@Component({
  selector: "bpc-highchart",
  templateUrl: "./highchart.component.html",
  styleUrl: "./highchart.component.scss",
})
export class HighchartComponent {
  @Input() chartOptions: Highcharts.Options = {};
  @Input() data: any = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "chart";

  constructor() {}

  ngOnInit() {}
}
