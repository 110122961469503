import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { GenericPopupService } from 'src/app/services/generic-popup/generic-popup.service';
import { GenericPopupOperation } from '@core/models/generic-popup-enums';
import { GenericPopupParameters } from '@core/models/generic-popup-models';


@Component({
  selector: 'bpc-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrl: './generic-popup.component.scss'
})
export class GenericPopupComponent {
  @Input() visible: boolean = false;

  @Output() toggleVisible = new EventEmitter<boolean>();

  header: string = "";
  operationStatus: boolean = false;
  positiveButtonDisabled: boolean = false;

  get operation(): GenericPopupOperation{
    return this.genericPopupService.operation;
  }

  get parameters(): GenericPopupParameters{
    return this.genericPopupService.parameters;
  }
  
  constructor(    
    private genericPopupService : GenericPopupService
  ){  
    this.genericPopupService.toggleGenericPopup$.subscribe((data)=>{
      this.setupPopup();
    })
  }


  ngOnInit() {
  }


  setupPopup(){
    this.positiveButtonDisabled = false;
    switch(this.operation){
      case GenericPopupOperation.SendIcWorkflowEmail : {
        this.genericPopupService.sendIcWorkflowEmail$.subscribe((data)=>{
          this.positiveButtonDisabled = false;
          this.toggleVisible.emit(false);
          this.operationStatus = false;
        })
        this.header = "Are you sure you would like to send a summary of IC and voting comments to the deal team?"
        break;
      }
    }
  }


  onNegativeClick(){
    this.toggleVisible.emit(false);
  }

  onPositiveClick(){
    this.operationStatus = true;
    this.positiveButtonDisabled = true;

    this.genericPopupService.positiveCall(this.operation);
  } 
}