import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { CompanyInteractionMostActiveUserGraphData, CompanyInteractionMostActiveUserGraphDataQuery, TopIcContributers, TopIcContributersDataQuery } from '@core/models/analytics-dashboard-models';
import { Company } from '@core/models/company';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ApiResponse, BaseApiService } from 'src/app/api/base';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsDashboardService extends BaseApiService<Company> {

  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super('/analytics', authService, router, messageService);
  }

  public getMostActiveUsersByCompanyInteractions(query: CompanyInteractionMostActiveUserGraphDataQuery | undefined): Observable<ApiResponse<CompanyInteractionMostActiveUserGraphData[]>> {
    return this.post(`/users-activity/by-interaction`, null, query);
  }

  public getTopICContributers(query: TopIcContributersDataQuery | undefined): Observable<ApiResponse<TopIcContributers[]>> {
    const params = new URLSearchParams();
    if (query?.top !== undefined) params.append('top', query.top.toString());
    if (query?.days !== undefined) params.append('days', query.days.toString());
    return this.get(`/top-ic-contributors?${params.toString()}`, null, null);
}
}