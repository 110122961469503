<ng-container *ngIf="!mapLoading else showLoaders">
  <div class="chart-heatmap-container">
    <div class="chart-container" [ngClass]="{ 'full-width': !showHeatmapGrid }">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        style="width: 100%; height: 600px; display: block; background-color: #F5FAFF;">
      </highcharts-chart>
    </div>
    <div class="heatmap-container" *ngIf="showHeatmapGrid" [ngClass]="{ 'no-width': !showHeatmapGrid }">
      <bpc-heatmap-grid [companies]="clusterData" (onCloseGridEmitter)="onCloseGrid($event)"></bpc-heatmap-grid>
    </div>
  </div>
</ng-container>
<ng-template #showLoaders>
  <div class="loading-container">
    <p-progressSpinner
    class="spinner">
    </p-progressSpinner>
    <p>Loading map, please wait...</p>
  </div>
</ng-template>
