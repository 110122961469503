import { Component } from "@angular/core";
import { isSafari } from "../../utils/utils";

@Component({
  selector: "bpc-mobile-layout",
  templateUrl: "./mobile-layout.component.html",
  styleUrl: "./mobile-layout.component.scss",
})
export class MobileLayoutComponent {

  get isSafari(): boolean {
    return isSafari();
  }
}
