import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  AssigneeFilterType,
  FilterPriority,
  IncludeOrExclude,
  Interaction,
  InteractionCountType,
  LogicalOperator,
  MainFilterRaw,
} from "@models/main-filter-view";
import { FilterDropdown, FormName, InteractionType } from "@models/auxiliary-company-models";
import { FilterHelperService } from "@core/services/filter-helper.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { combineLatest, map, takeUntil } from "rxjs";
import { DestroyObservable } from "../../../rxjs/DestroyObservable";
import { InvestorTab, Tab } from "@models/types";
import { MessageService } from "primeng/api";
import { Nullable } from "../../models/nullable";
import { CompanyTypeService } from "@core/services/company-type.service";
import { CompanyType, GroupedCompanyTypes } from "@core/models/company-type";
import { InvestorService } from "@core/services/investor.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import {
  BpcCoverageForm,
  CompanyOverviewForm,
  EndMarketsForm,
  EstimatedRevenueForm,
  FundingForm,
  InvestorsForm,
  MainFormGroup,
  ProductsServicesForm,
  TagsForm,
  filterCountsType,
  getKeywordsItemsCount,
  keywordInnerFilter,
  keywordsGroup,
} from "@core/models/filters-types";
import { SavedSearchService } from "src/app/services/companies-search/saved-search.service";
import { ApiResponse } from "src/app/api/base";
import { OperationResult } from "@core/models/operation-result";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { MixpanelService } from "@core/services/mixpanel.service";

@Component({
  selector: "bpc-main-filter",
  templateUrl: "./main-filter.component.html",
  styleUrls: ["./main-filter.component.scss"],
  viewProviders: [DestroyObservable],
})
export class MainFilterComponent implements OnInit, OnChanges {
  @Output() mainFilterRawChange = new EventEmitter<MainFilterRaw>();
  @Input() mainFilterRaw!: MainFilterRaw;
  @Input() currentTabIndex!: Nullable<Tab>;
  @Input() isExpanding!: boolean;
  options = ["Name", "Description"];
  @ViewChild("myNgSelect") myNgSelect!: NgSelectComponent;
  keywordFilterCount: number = 0;
  keywordFilterLimit: number = 5;
  activeItem: string | null = null;
  savedSearchModalVisible: boolean = false;
  companyTypes: CompanyType[] = [];
  savedSearchName: string = "";
  logicalOperators = [
    { name: "OR", id: LogicalOperator.OR },
    { name: "AND", id: LogicalOperator.AND },
  ];
  fieldControlValues(type: FilterDropdown) {
    let values: any = [];
    let control = this.getFormControl(type);
    if (control) {
      values = control?.value;
    }
    return values;
  }

  get dropdownTypes() {
    return FilterDropdown;
  }

  get isFormInvalid(): boolean {
    return this.mainFilterForm && this.mainFilterForm.invalid;
  }

  grouped: GroupedCompanyTypes = {};
  @HostListener("document:click", ["$event"])
  onClick(event: Event): void {
    // TODO: Refactor/optimize the implementation
    const clickedElement = event.target as HTMLElement;
    const isMenuContainerClicked = clickedElement.closest(".menu") !== null;
    const hasCalendarClass = hasClassOrParentWithClass(clickedElement, "p-datepicker");
    const datepickerHeader = hasClassOrParentWithClass(clickedElement, "p-datepicker-header");
    const hasDropdownClass = hasClassOrParentWithClass(clickedElement, "custom-select-drop-down");
    const hasPickerMonthClass = hasClassOrParentWithClass(clickedElement, "p-datepicker-month");
    const hasPickerYearClass = hasClassOrParentWithClass(clickedElement, "p-datepicker-year");
    const hasMonthPickerClass = hasClassOrParentWithClass(clickedElement, "p-monthpicker");
    const hasYearPickerClass = hasClassOrParentWithClass(clickedElement, "p-yearpicker");

    if (
      !isMenuContainerClicked &&
      !hasCalendarClass &&
      !hasDropdownClass &&
      !datepickerHeader &&
      !hasPickerMonthClass &&
      !hasPickerYearClass &&
      !hasMonthPickerClass &&
      !hasYearPickerClass
    ) {
      this.activeItem = null; // Close the inner ul if clicked outside the menu container
    }
  }

  onMenuClick(event: Event, item: string): void {
    event.stopPropagation();
    if (this.activeItem === item) {
      this.activeItem = null; // Close the inner ul if the same item is clicked again
    } else {
      this.activeItem = item; // Open the clicked inner ul
    }
  }

  getMenuStyle(): { top: string } {
    const menuElement = this.elementRef.nativeElement.querySelector(".menu");
    const menuRect = menuElement.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const menuBottomPosition = menuRect.top + menuRect.height;

    const top = menuBottomPosition <= windowHeight ? "100%" : "auto";
    return { top };
  }

  getInnerUlStyle(): { top: string } {
    const innerUlElement = this.elementRef.nativeElement.querySelector(".inner-ul");
    const innerUlRect = innerUlElement.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const innerUlBottomPosition = innerUlRect.top + innerUlRect.height;

    const top = innerUlBottomPosition <= windowHeight ? "0" : "0";
    return { top };
  }

  get interactionType(): typeof InteractionType {
    return InteractionType;
  }

  clearFormFilters(form: string) {
    const currentForm: string = FormName[form];
    // reset keywords to default few things
    this.filterCounts[currentForm] = 0;
    if (currentForm === "keywordsGroup") {
      let keyFiltersForm = this.mainFilterForm.get("keywordsGroup") as FormArray;
      keyFiltersForm.clear();
      keyFiltersForm.push(this.newKeywordsGroup());
    } else if (form === "ownership") {
      this.filterCounts["ownershipForm"] = 0;
      Object.keys(this.grouped).forEach((item) => {
        this.grouped[item].isChecked = false;
        this.grouped[item].companies.forEach((company) => {
          company.isChecked = false;
        });
      });
    } else {
      this.mainFilterForm.get(currentForm)?.reset();
    }
  }

  // @HostListener("document:click", ["$event"])
  // onClick(event: Event): void {
  //   const clickedElement = event.target as HTMLElement;
  //   const isMenuContainerClicked = clickedElement.closest(".menu") !== null;

  //   if (!isMenuContainerClicked) {
  //     this.activeItem = null; // Close the inner ul if clicked outside the menu container
  //   }
  // }

  // onMenuClick(event: Event, item: string): void {
  //   event.stopPropagation();
  //   if (this.activeItem === item) {
  //     this.activeItem = null; // Close the inner ul if the same item is clicked again
  //   } else {
  //     this.activeItem = item; // Open the clicked inner ul
  //   }
  // }

  // getMenuStyle(): { top: string } {
  //   const menuElement = this.elementRef.nativeElement.querySelector(".menu");
  //   const menuRect = menuElement.getBoundingClientRect();

  //   const windowHeight = window.innerHeight;
  //   const menuBottomPosition = menuRect.top + menuRect.height;

  //   const top = menuBottomPosition <= windowHeight ? "100%" : "auto";
  //   return { top };
  // }

  // getInnerUlStyle(): { top: string } {
  //   const innerUlElement = this.elementRef.nativeElement.querySelector(".inner-ul");
  //   const innerUlRect = innerUlElement.getBoundingClientRect();

  //   const windowHeight = window.innerHeight;
  //   const innerUlBottomPosition = innerUlRect.top + innerUlRect.height;

  //   const top = innerUlBottomPosition <= windowHeight ? "0" : "auto";
  //   return { top };
  // }

  minTotal = 0;
  maxTotal = 999999999999;
  minGrowth = -10000;
  maxGrowth = 10000;
  maxAllowableDate: Date;

  mainFilterForm!: FormGroup<MainFormGroup>;

  topPriorityTooltip =
    "70+ FTE<br>" + "More than 50% FTE Growth<br>" + "Less than $30M PiC<br>" + "Exclude status: IC, Active DEAL";
  secondPriorityTooltip =
    "150+ FTE<br>" + "More than 25% FTE Growth<br>" + "Less than $30M PiC<br>" + "Exclude status: IC, Active DEAL";
  thirdPriorityTooltip =
    "70+ FTE<br>" + "More than 30% FTE Growth<br>" + "Less than $30M PiC<br>" + "Exclude status: IC, Active DEAL";

  constructor(
    private filterHelper: FilterHelperService,
    private readonly destroy$: DestroyObservable,
    private messageService: MessageService,
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private companyTypeService: CompanyTypeService,
    private investorService: InvestorService,
    private destroyObservable: DestroyObservable,
    protected savedSearchService: SavedSearchService,
    public mixpanelService: MixpanelService,
  ) {
    this.maxAllowableDate = new Date();
  }

  ngOnInit(): void {
    this.companyTypeService.getCompanyTypes().subscribe((response: ApiResponse<CompanyType[]>) => {
      if (response.data) {
        this.companyTypes = response.data;
        this.getCompanyTypesByGroupId();
      }
    });
    // this.initForm();
    // this.patchValue(this.mainFilterRaw);
    // this.patchKeywordFilters(this.mainFilterRaw);
    // this.getCompanyTypesByGroupId(this.mainFilterRaw);
    // this.getOwnershipCounts();
    // this.detectFormChangeInitially();
  }

  ngOnChanges(changes: SimpleChanges) {
    // This method will be called when there are changes to the input properties
    // console.log('Input property changed:', changes['mainFilterRaw'].currentValue);
    this.initForm();
    this.patchValue(changes["mainFilterRaw"].currentValue);
    this.patchKeywordFilters(changes["mainFilterRaw"].currentValue);
    this.getCompanyTypesByGroupId();
    this.getOwnershipCounts();
    this.detectFormChangeInitially();
  }

  filterCounts: filterCountsType = {
    keywordsGroup: 0,
    companyOverviewForm: 0,
    ownershipForm: 0,
    fundingForm: 0,
    investorsForm: 0,
    endMarketsForm: 0,
    productsServicesForm: 0,
    estimatedRevenueForm: 0,
    tagsForm: 0,
    bpcCoverageForm: 0,
  };

  detectFormChangeInitially() {
    Object.values(FormName).forEach((key: string) => {
      let formValues = this.mainFilterForm.get(key)?.value;
      let count = Object.keys(formValues).length;
      if (FormName["keywords"] === key) {
        const keywordCount = getKeywordsItemsCount(formValues);
        const cleansedCount = keywordCount.cleansedCount;
        const allCount = keywordCount.allCount;

        this.keywordFilterCount = allCount;
        this.filterCounts[key] = cleansedCount;
      } else {
        Object.values(formValues).forEach((value) => {
          if (value === null || value === 0 || (Array.isArray(value) && value.length === 0)) {
            count--;
          }
        });
        this.filterCounts[key] = count;
      }
    });
  }

  detectFormChanges() {
    Object.values(FormName).forEach((key: string) => {
      this.mainFilterForm.get(key)?.valueChanges.subscribe((formValues) => {
        if (FormName["keywords"] === key) {
          const keywordCount = getKeywordsItemsCount(formValues);
          const cleansedCount = keywordCount.cleansedCount;
          const allCount = keywordCount.allCount;
          this.keywordFilterCount = allCount;
          this.filterCounts[key] = cleansedCount;
        } else {
          let count = Object.keys(formValues).length;
          Object.values(formValues).forEach((value) => {
            if (value === null || value === 0 || (Array.isArray(value) && value.length === 0)) {
              count--;
            }
          });
          this.filterCounts[key] = count;
        }
      });
    });
  }

  get logicalOperator(): typeof LogicalOperator {
    return LogicalOperator;
  }

  get includeExclude(): typeof IncludeOrExclude {
    return IncludeOrExclude;
  }

  @HostListener("window:keyup.enter")
  onKeyup() {
    if (this.isExpanding) this.onSubmit();
  }

  getOwnershipCounts() {
    let count = 0;
    Object.keys(this.grouped).forEach((item) => {
      this.grouped[item].companies.forEach((company) => {
        if (company.isChecked) {
          count++;
        }
      });
    });
    this.filterCounts["ownershipForm"] = count;
  }

  getCompanyTypesByGroupId() {
    this.grouped = this.companyTypes.reduce((result, item) => {
      const key = item.companyTypeGroupName;
      if (key) {
        if (!result[key]) {
          let parentChecked = false;
          if (this.mainFilterRaw?.companyTypesInclude && this.mainFilterRaw?.companyTypesInclude?.length > 0) {
            parentChecked = true;
          }
          result[key] = { isChecked: parentChecked, companies: [] };
        }
        let childrenExists = false;
        if (this.mainFilterRaw?.companyTypesInclude && item) {
          childrenExists = this.mainFilterRaw?.companyTypesInclude?.indexOf(item.id) > -1;
        }
        result[key].companies.push({ ...item, isChecked: childrenExists });
        if (this.mainFilterRaw?.companyTypesInclude && this.mainFilterRaw?.companyTypesInclude?.length > 0) {
          result[key].isChecked = result[key].isChecked !== false ? childrenExists : false;
        }
      }
      return result;
    }, {} as GroupedCompanyTypes);

    this.getOwnershipCounts();
  }

  setAll(companyGroupName: string, event: CheckboxChangeEvent) {
    if (this.grouped[companyGroupName].companies.length == 0) {
      return;
    }
    this.grouped[companyGroupName].companies.forEach((t) => (t.isChecked = event.checked));
    this.getOwnershipCounts();
  }
  updateIndividual(companyGroupName: string, event: CheckboxChangeEvent) {
    this.grouped[companyGroupName].isChecked =
      this.grouped[companyGroupName].companies != null &&
      this.grouped[companyGroupName].companies.every((item) => item.isChecked);
    this.getOwnershipCounts();
  }

  /**
   * After extending this formGroup update:
   * FilterHelperService -> getFilterLabels() to show and update labels on the grid
   * FilterHelperService -> convertRealToRaw() to covert main filter component data to BE recognized data
   * FilterHelperService -> convertRawToReal() to covert BE recognized data to main filter component data
   * FilterCountPipe -> transform() to count active filters
   */
  initForm() {
    let filterObj = null;
    if (this.mainFilterRaw.filtersString) {
      filterObj = JSON.parse(this.mainFilterRaw.filtersString);
    }

    this.mainFilterForm = new FormGroup<MainFormGroup>({
      keywordsGroup: new FormArray<FormGroup<keywordsGroup>>([this.newKeywordsGroup()]),
      companyOverviewForm: new FormGroup<CompanyOverviewForm>({
        companyOverviewIndustryInclude: new FormControl([], { nonNullable: true }),
        companyOverviewIndustryExclude: new FormControl([], { nonNullable: true }),
        companyOverviewLocationDropdown: new FormControl([], { nonNullable: true }),
        companyOverviewHeadquarterCheckbox: new FormControl(null),
        companyOverviewRadiusSlider: new FormControl(null),
        companyOverviewMinEmployee: new FormControl(null),
        companyOverviewMaxEmployee: new FormControl(null),
        companyOverviewGrowthRateMin: new FormControl(null),
        companyOverviewGrowthRateMax: new FormControl(null),
        companyOverviewStartYear: new FormControl(null),
        companyOverviewEndYear: new FormControl(null),
      }),
      fundingForm: new FormGroup<FundingForm>({
        fundingTotalMin: new FormControl(null),
        fundingTotalMax: new FormControl(null),
        fundingLastMin: new FormControl(null),
        fundingLastMax: new FormControl(null),
        fundingLastDateStart: new FormControl(null),
        fundingLastDateEnd: new FormControl(null),
      }),
      investorsForm: new FormGroup<InvestorsForm>({
        investorsInclude: new FormControl([], { nonNullable: true }),
        investorsExclude: new FormControl([], { nonNullable: true }),
      }),
      endMarketsForm: new FormGroup<EndMarketsForm>({
        endMarketsInclude: new FormControl([], { nonNullable: true }),
        endMarketsExclude: new FormControl([], { nonNullable: true }),
      }),
      productsServicesForm: new FormGroup<ProductsServicesForm>({
        productsServicesInclude: new FormControl([], { nonNullable: true }),
        productsServicesExclude: new FormControl([], { nonNullable: true }),
      }),
      estimatedRevenueForm: new FormGroup<EstimatedRevenueForm>({
        estimatedRevenueMin: new FormControl(null),
        estimatedRevenueMax: new FormControl(null),
        estimatedRevenueGrowthMin: new FormControl(null),
        estimatedRevenueGrowthMax: new FormControl(null),
      }),
      tagsForm: new FormGroup<TagsForm>({
        // tagsTaggedSince: new FormControl(null),
        // tagsTaggedBefore: new FormControl(null),
        tagsInclude: new FormControl([], { nonNullable: true }),
        tagsExclude: new FormControl([], { nonNullable: true }),
      }),
      bpcCoverageForm: new FormGroup<BpcCoverageForm>({
        bpcNoExistingCoverage: new FormControl(null),
        bpcInclude: new FormControl([], { nonNullable: true }),
        bpcExclude: new FormControl([], { nonNullable: true }),
        bpcDealCloudDropDown: new FormControl([], { nonNullable: true }),
        bpcDealCloudDropDownExclude: new FormControl([], { nonNullable: true }),
        bpcDCStartDate: new FormControl([]),
        bpcDCEndDate: new FormControl([]),
        bpcLastInteractionFrom: new FormControl(null),
        bpcLastInteractionTo: new FormControl(null),
        bpcInteractionType: new FormControl([]),
        bpcRecallDateFrom: new FormControl([]),
        bpcRecallDateTo: new FormControl([]),
      }),
      companyName: new FormControl([]),
      companyTypesInclude: new FormControl([]),
      companyTypeExclude: new FormControl([]),
      companyDescription: new FormControl(),
      excludeLatestRoundDateTo: new FormControl(),
      latestRoundDateTo: new FormControl("6"), // Default value is 6 months
      statuses: new FormControl([]),
      statusesExclude: new FormControl("false"),
      industries: new FormControl([]),
      industriesExclude: new FormControl("false"),
      locationsInclude: new FormControl([]),
      locationsExclude: new FormControl([]),
      investors: new FormControl([]),
      investorsExclude: new FormControl("false"),
      paidInCapitalMinTotal: new FormControl(),
      paidInCapitalMaxTotal: new FormControl(),
      revenueMinTotal: new FormControl(),
      revenueMaxTotal: new FormControl(),
      revenueMinGrowth: new FormControl(),
      revenueMaxGrowth: new FormControl(),
      predefinedFilter: new FormControl(FilterPriority.NONE),
      interactionFilterType: new FormControl(),
      interactionExclude: new FormControl("false"),
      interactionCountFilterType: new FormControl(InteractionCountType.EMAIL),
      interactionMinTotal: new FormControl(),
      interactionMaxTotal: new FormControl(),
      interactionDate: new FormControl(),
      assigneeFilterType: new FormControl(AssigneeFilterType.INCLUDE),
      assignees: new FormControl([]),
      tags: new FormControl([]),
      tagsExclude: new FormControl("false"),
      workflows: new FormControl(false),
      isActive: new FormControl("true"),
      hasNotificationTriggers: new FormControl("false"),
    });

    this.detectFormChanges();

    this.subscribeToFormChanges();
  }

  setFilterToDefault() {
    this.initForm();
    const defaultValues = this.filterHelper.getDefaultFilter(this.currentTabIndex || InvestorTab.COMPANIES);
    this.patchValue(defaultValues);
  }

  setPredefinedFilter(priority: FilterPriority) {
    this.initForm();
    const predefinedValue = {
      ...this.filterHelper.getDefaultFilter(InvestorTab.COMPANIES),
      // ...this.filterHelper.getPredefinedFilterValue(priority),
      ...this.updateForm(),
    };

    this.patchValue(predefinedValue);
    this.mainFilterForm.patchValue(
      {
        predefinedFilter: priority,
      },
      { emitEvent: false },
    );
  }

  updateForm(): MainFilterRaw {
    return {
      fullTimeEquivalentMinTotal: this.mainFilterForm.value.companyOverviewForm?.companyOverviewMinEmployee,
      fullTimeEquivalentMinGrowth: this.mainFilterForm.value.companyOverviewForm?.companyOverviewGrowthRateMin,
      paidInCapitalMaxTotal: this.mainFilterForm.value.fundingForm?.fundingTotalMax,
    };
  }

  setNoneInteractions() {
    this.setInteraction(Interaction.NONE);
  }

  setAnyInteraction() {
    this.setInteraction(Interaction.ANY);
  }

  setRecentInteraction() {
    this.setInteraction(Interaction.RECENT);
  }

  setInteraction(interaction: Interaction) {
    const newValue = this.mainFilterForm.value.interactionFilterType === interaction ? null : interaction;
    this.mainFilterForm.patchValue(
      {
        interactionFilterType: newValue,
      },
      { emitEvent: true },
    );
  }

  clearFilter() {
    this.clearFilterCounts();
    this.clearOwnershipForm();
    this.initForm();
  }

  clearOwnershipForm() {
    Object.keys(this.grouped).forEach((item) => {
      this.grouped[item].isChecked = false;
      this.grouped[item].companies.forEach((company) => {
        company.isChecked = false;
      });
    });
  }

  clearFilterAndSubmit() {
    this.clearFilterCounts();
    this.clearOwnershipForm();
    this.initForm();
    this.onSubmit();
  }

  clearFilterCounts() {
    Object.keys(this.filterCounts).forEach((key) => {
      this.filterCounts[key] = 0;
    });
  }

  /**
   * Selecting any of the predefined filters will mark it as active
   * Changing any value while predefined filter is active will remove the active status
   */
  subscribeToFormChanges() {
    this.mainFilterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.mainFilterForm.patchValue(
        {
          predefinedFilter: FilterPriority.NONE,
        },
        { emitEvent: false },
      );
    });

    this.mainFilterForm
      .get("assigneeFilterType")!
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((v: AssigneeFilterType) => {
        const assigneesFormControl = this.mainFilterForm.get("assignees")!;
        if (v === AssigneeFilterType.NONE) {
          assigneesFormControl.disable();
        } else {
          assigneesFormControl.enable();
        }
      });
  }

  patchValue(mainFilterRaw: MainFilterRaw) {
    this.mainFilterForm.patchValue(this.filterHelper.convertRawToReal(mainFilterRaw), { emitEvent: false });
  }

  /**
   * This function will be setting form array values
   * @param mainFilterRaw
   */
  patchKeywordFilters(mainFilterRaw: MainFilterRaw) {
    const keyFiltersForm = this.mainFilterForm.get("keywordsGroup") as FormArray<FormGroup<keywordsGroup>>;

    const { filtersString } = mainFilterRaw;

    if (filtersString) {
      const filterJson: JSON[] = JSON.parse(filtersString);
      if (filterJson && filterJson.length > 0) {
        keyFiltersForm.clear();

        for (let i = 0; i < filterJson.length; i++) {
          let currentFilter = filterJson[i] as unknown as keywordsGroup;
          let innerFilters = new FormArray<FormGroup<keywordInnerFilter>>([]);
          if (currentFilter?.filterLines && currentFilter?.filterLines.length > 0) {
            for (let j = 0; j < currentFilter.filterLines.length; j++) {
              // const currentInnerFilter = currentFilter.filterLines[j];
              const currentInnerFilter = currentFilter.filterLines.at(j) as unknown as keywordInnerFilter;
              innerFilters.push(
                this.updateFilterValue(
                  currentInnerFilter?.innerOperator as unknown as LogicalOperator,
                  currentInnerFilter?.keyword as unknown as string,
                  currentInnerFilter?.field as unknown as string,
                ) as FormGroup<keywordInnerFilter>,
              );
            }
          }
          const newFormGroup = this.fb.group({
            outerOperator: currentFilter.outerOperator as unknown as LogicalOperator,
            includeOrExclude: currentFilter.includeOrExclude as unknown as IncludeOrExclude,
            filterLines: innerFilters,
          }) as FormGroup<keywordsGroup>;
          keyFiltersForm.push(newFormGroup);
        }
      }
    }
  }

  keywordsGroup(): FormArray<FormGroup<keywordsGroup>> {
    return this.mainFilterForm.get("keywordsGroup") as FormArray<FormGroup<keywordsGroup>>;
  }

  newKeywordsGroup(): FormGroup<keywordsGroup> {
    // const parentGroup = {
    //   outerOperator: LogicalOperator.AND,
    //   includeOrExclude: IncludeOrExclude.INCLUDE,
    //   filterLines: this.fb.array([this.newFilter()]),
    // }
    // return this.fb.group(parentGroup);
    return new FormGroup<keywordsGroup>({
      outerOperator: new FormControl<LogicalOperator>(LogicalOperator.AND, { nonNullable: true }),
      includeOrExclude: new FormControl<IncludeOrExclude>(IncludeOrExclude.INCLUDE, { nonNullable: true }),
      filterLines: new FormArray<FormGroup<any>>([this.newFilter()]),
    });
  }

  addKeywordGroup() {
    this.keywordsGroup().push(this.newKeywordsGroup());
  }

  removeKeywordGroup(keywordIndex: number) {
    this.keywordsGroup().removeAt(keywordIndex);
  }

  keywordFilters(keywordIndex: number): FormArray {
    return this.keywordsGroup().at(keywordIndex).get("filterLines") as FormArray;
  }

  newFilter(): FormGroup<keywordInnerFilter> {
    // return this.fb.group({
    //   innerOperator: LogicalOperator.AND,
    //   keyword: "",
    //   field: "Name",
    // });
    return new FormGroup<keywordInnerFilter>({
      innerOperator: new FormControl<LogicalOperator>(LogicalOperator.AND, { nonNullable: true }),
      keyword: new FormControl(""),
      field: new FormControl("Name"),
    });
  }

  updateFilterValue(innerOperator: LogicalOperator, keyword: string, field: string): FormGroup<keywordInnerFilter> {
    // return this.fb.group({
    //   innerOperator,
    //   keyword,
    //   field,
    // });
    return new FormGroup<keywordInnerFilter>({
      innerOperator: new FormControl<LogicalOperator>(innerOperator, { nonNullable: true }),
      keyword: new FormControl(keyword),
      field: new FormControl(field),
    });
  }

  addFilterRow(keywordIndex: number) {
    this.keywordFilters(keywordIndex).push(this.newFilter());
  }

  removeFilter(keywordIndex: number, filterIndex: number) {
    this.keywordFilters(keywordIndex).removeAt(filterIndex);
  }
  onSubmit() {
    if (!this.mainFilterForm.get("companyName")!.valid) {
      this.messageService.add({ key: "bc", severity: "error", detail: "Invalid company name.", life: 10_000 });
      return;
    }
    if (this.mainFilterForm.value.assigneeFilterType === AssigneeFilterType.NONE) {
      this.mainFilterForm.patchValue({ assignees: [] });
    }
    //write condition to check if filtercount is zero
    if (!Object.keys(this.filterCounts).every((key) => this.filterCounts[key] === 0)) {
      this.mixpanelService.trackEvent("Search_Filter", this.filterCounts);
    }
    this.mainFilterRawChange.emit(this.filterHelper.convertRealToRaw(this.mainFilterForm.value, this.grouped));
  }

  setFormControlValue(event: any, type: FilterDropdown) {
    if (type !== null) {
      const control = this.getFormControl(type);
      if (control !== null) {
        control.patchValue([...control.value, event]);
      }
    }
  }

  getFormControl(type: FilterDropdown) {
    let control = null;
    let fg = null;
    if (type !== null) {
      switch (type) {
        case FilterDropdown.companyLocation:
          fg = this.mainFilterForm.get("companyOverviewForm");
          if (fg) {
            control = fg.get("companyOverviewLocationDropdown");
          }
          break;

        case FilterDropdown.coveragePersonExclude:
          fg = this.mainFilterForm.get("bpcCoverageForm");
          if (fg) {
            control = fg.get("bpcExclude");
          }
          break;
        case FilterDropdown.coveragePersonInclude:
          fg = this.mainFilterForm.get("bpcCoverageForm");
          if (fg) {
            control = fg.get("bpcInclude");
          }
          break;

        case FilterDropdown.companyStatusInclude:
          fg = this.mainFilterForm.get("bpcCoverageForm");
          if (fg) {
            control = fg.get("bpcDealCloudDropDown");
          }
          break;
        case FilterDropdown.companyStatusExclude:
          fg = this.mainFilterForm.get("bpcCoverageForm");
          if (fg) {
            control = fg.get("bpcDealCloudDropDownExclude");
          }
          break;
        case FilterDropdown.investorInclude:
          fg = this.mainFilterForm.get("investorsForm");
          if (fg) {
            control = fg.get("investorsInclude");
          }
          break;
        case FilterDropdown.investorExclude:
          fg = this.mainFilterForm.get("investorsForm");
          if (fg) {
            control = fg.get("investorsExclude");
          }
          break;
        case FilterDropdown.tagInclude:
          fg = this.mainFilterForm.get("tagsForm");
          if (fg) {
            control = fg.get("tagsInclude");
          }
          break;
        case FilterDropdown.tagExclude:
          fg = this.mainFilterForm.get("tagsForm");
          if (fg) {
            control = fg.get("tagsExclude");
          }
          break;
        case FilterDropdown.industryInclude:
          fg = this.mainFilterForm.get("companyOverviewForm");
          if (fg) {
            control = fg.get("companyOverviewIndustryInclude");
          }
          break;
        case FilterDropdown.industryExclude:
          fg = this.mainFilterForm.get("companyOverviewForm");
          if (fg) {
            control = fg.get("companyOverviewIndustryExclude");
          }
          break;
      }
    }
    return control;
  }

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  displaySavedSearchModal() {
    if (this.mainFilterRaw.savedSearchId) {
      this.savedSearchService.getSavedSearchById(this.mainFilterRaw.savedSearchId).subscribe((res: any) => {
        // this.savedSearchButtonLoading = false;
        if (res.data) {
          this.savedSearchName = res.data.name;
        } else {
          this.mainFilterRaw.savedSearchId = undefined;
        }
        this.savedSearchModalVisible = true;
      });
    } else {
      this.savedSearchModalVisible = true;
    }
  }
  resetSavedSearch() {
    this.savedSearchModalVisible = false;
  }
  onSaveSearch() {
    const filterJson = this.filterHelper.convertRealToRaw(this.mainFilterForm.value, this.grouped);
    let filterCount = 0;
    for (const key in this.filterCounts) {
      if (this.filterCounts.hasOwnProperty(key)) {
        filterCount += this.filterCounts[key];
      }
    }
    let savedSearchPayload: any = {
      name: this.savedSearchName || "",
      filterCount,
      savedFilterData: JSON.stringify(filterJson),
      filterTypeId: this.currentTabIndex === InvestorTab.COMPANIES ? 1 : 2,
      isActive: true,
    };

    this.savedSearchService.upsertSavedSearch(savedSearchPayload, this.mainFilterRaw.savedSearchId).subscribe((res) => {
      if (res.data) {
        this.mixpanelService.trackEvent("New_Saved_Search");
        this.onSubmit();
        this.showSuccessToastMessage(res?.data?.message);
        this.savedSearchModalVisible = false;
      } else if (res.error) {
        this.displayErrorMessage(res.error.response, "An error happened while saving filter.");
      }
    });
  }

  removeDropdownValue(id: number, type: FilterDropdown) {
    const control = this.getFormControl(type);
    if (control !== null) {
      if (control && control.value) {
        let controlValues = control.value;
        const index = control.value.findIndex((item: any) => item.id === id);
        if (index !== -1) {
          controlValues.splice(index, 1);
          control.patchValue([...controlValues]);
        }
      }
    }
  }
}

function hasClassOrParentWithClass(element: HTMLElement | null, dropdownClass: string): boolean {
  const parentClassName = "filter-submenu-body";
  if (!element) {
    return false;
  }

  if (element.classList.contains(dropdownClass)) {
    return true;
  } else if (element.classList.contains(parentClassName)) {
    return false;
  }

  return hasClassOrParentWithClass(element.parentElement, dropdownClass);
}
