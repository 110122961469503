import { Nullable } from '@models/nullable';
import { BaseModel } from '@models/base';
import { BaseUser, User } from '@models/user';
import { NotificationOperator } from './notifications';
import { Tab } from './types';
import { Params } from '@angular/router';
import { InteractionList } from './forms';

export interface CompanyAddress {
  city: string;
  country: {
    name: string;
    code: string;
  };
}

export enum NavigateCompany {
  PREVIOUS = -1,
  NONE = 0,
  NEXT = 1
}

export enum InvestorRole {
  UNDEFINED,
  LEAD,
  PARTICIPATING,
  ACQUIRER,
  BANKER,
  STRATEGIC
}

export interface Investor extends BaseModel {
  id: number;
  name: string;
  description: string;
  affinity?: number;
  roles: InvestorRole[];
  dominantRole: InvestorRole;
}

export interface LatestRound {
  name: string;
  investors: Investor[];
  round: RoundType;
  amount: number;
  investmentDate: string;
  raiseDate: string;
}

export enum StatusId {
  None,
  IC,
  ActiveDeal,
  Pearl,
  MustMeet,
  CantCrack,
  HeartsMinds,
  Contender,
  Early,
  Unlikely,
  Monitor,
  NoOutreach,
  PortfolioMA,
  firmWideElevation,
  PriorityPipeline,
  PipelineDeal,
  ProspectMA,

}

export interface Interaction extends BaseModel {
  details: string;
  interactionDate: string;
  interactionId: number;
  interactionType: InteractionType;
  summary: string;
  user: User;
  internalAttendees: Nullable<string>;
  externalContacts: Nullable<string>;
}

export enum InteractionType {
  Notes,
  Meeting,
  Phone,
  Email,
  VideoCall,
  All,
  NeverContacted
}

export const interactions: InteractionList[] = [
  { id: InteractionType.Notes, label: 'undefinedInteractions', name:"Notes"},
  { id: InteractionType.Email, label: 'emailInteractions', name:"Email"},
  { id: InteractionType.Phone, label: 'phoneInteractions', name:"Phone"},
  { id: InteractionType.VideoCall, label: 'videoCallInteractions', name:"Video Call"},
  { id: InteractionType.Meeting, label: 'meetingInteractions', name:"Meeting"},
  // { id: InteractionType.All, label: 'totalInteractions', name:"All"},

];

export enum RoundType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export interface Round {
  amount: number;
  raiseDate: string;
  roundType: number;
  leadInvestorName?: Nullable<string>;
  investments: Investment[];
}

export interface Investment extends BaseModel {
  amount: number;
  investorId: number;
  investorRole: number;
  investorName?: string;
  roundId: number;
}

export interface Source {
  sourceType: number;
  sourceUrl: Nullable<string>;
}

export interface IndustryWrapper extends BaseModel {
  companyId: number;
  rank: number;
  industry: Industry;
}

export interface Industry extends BaseModel {
  category: number;
  code: string | number;
  description: string;
  name: string;
}

export enum WorkFlowId {
  Enrich = 1,
  ICVote = 2
}

export interface ICVotingWorkFlowDetails {
  averageVote: number;
  currentUserVote: Nullable<number>;
  currentUserComment: Nullable<string>;
}

export interface WorkFlow {
  companyWorkflowId?: number;
  id?: number;
  owner: BaseUser;
  assignee: Nullable<BaseUser>;
  statusId: WorkFlowStatusId;
  workflowTypeId: WorkFlowId;
  createDate: string;
  votingDate: string;
  description?: string;
  details?: ICVotingWorkFlowDetails;
  checkSize?: number;
  companyWorkflowUsers?:any[];
  summary?: string;
  companyWorkflowComments?:any[];
}

export enum WorkFlowStatusId {
  // Enrichment
  NoStatus,
  ToDo,
  InProgress,
  Done,
  RevisionRequired,
  Accepted,

  // IC
  InReview = 11,
  Complete = 12
}

export interface EmployeeSeries {
  count: number;
  seriesDate: string;
}

export enum Priority {
  COMPANIES,
  PIPELINE = 2,
  PRIORITY
}
export enum CompanyType {
  Unkown,
  Private,
  VentureCapital,
  PrivateEquality,
  PublicSub,
  NonProfit,
  Public,
  Seed,
  PrivateSub,
  Other,
  GovAgency,
  JointVenture,
  EduInstitution
}
export interface CompanyScore extends BaseModel {
  ltmView: string;
  growth: string;
  ltmRating: string;
  growthPercentile: string;
  ltmViewsPercentile: string;
  productScores: CompanyProductScore[];
  slug: Nullable<string>;
}
export interface CompanyProductScore extends BaseModel {
  categoryName: string;
  productName: string;
  pageView: string;
  categoryTrafficPercentage: string;
  categoryGrowth: string;
}

export enum FilterDropdown {
  companyLocation,
  investorInclude,
  investorExclude,
  tagInclude,
  tagExclude,
  coveragePersonInclude,
  coveragePersonExclude,
  companyStatusInclude,
  companyStatusExclude,
  companyQuickSearch,
  industryInclude,
  industryExclude,
  userRoles, 
  userGroups, 
  userPods,
  teamMembers
}

export enum RawValueMappingType {
  locationsInclude,
  investorsInclude,
  investorsExclude,
  tagsInclude,
  tagsExclude,
  industriesInclude,
  industriesExclude,
  assigneesInclude,
  assigneesExclude,
  statusesInclude,
  interactionTypes,
  interactionInitiatorsInclude,
  dcCompanyTypes
}

export enum GroupType {
  Prospect = 'prospect',
  InvestorRelation = 'investor-relation',
  Newsletter = 'newsletter',
  OpenSearches="OpenSearches"
}



export const FormName: { [key: string]: string } = {
  keywords: 'keywordsGroup',
  companyOverview: 'companyOverviewForm',
  funding: 'fundingForm',
  investors: 'investorsForm',
  endMarkets: 'endMarketsForm',
  productsServices: 'productsServicesForm',
  estimatedRevenue: 'estimatedRevenueForm',
  tags: 'tagsForm',
  bpcCoverage: 'bpcCoverageForm',
}

export interface NotificationTriggerModal {
  isActive: boolean;
  operator: NotificationOperator;
  value: Nullable<number>;
}

export interface URLParams {
  currentTabIndex: Nullable<Tab>;
  currentChildIndex: Nullable<number>;
  params: Params;
}
