<bpc-widget [title]="title" [filters]="filters" (onFilterChange)="onFilterChange($event)">
  <div class="chart-container">
    <bpc-highchart [chartOptions]="chartOptions" [loading]="loading"/>
    <div class="vertical-line"></div>
    <div class="reason-list">
      <ul>
        <li *ngFor="let d of data; index as index;" (click)="onChartReasonClick(d, index)">
          <div class="reason-list-items">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="12" [attr.fill]="colors[index]" />
            </svg>
            <span>
              {{d.name}}
            </span>
          </div>
          <div class="horizontal-line"></div>
        </li>
      </ul>
    </div>
    <bpc-dynamic-skeleton-loader [numberOfSkeletons]="loaders" *ngIf="loading"></bpc-dynamic-skeleton-loader>

    <bpc-dropout-companies-modal [dealDropReason]="dealDropReason" [showModal]="showCompaniesModal" (onClosed)="onModalClosed()"/>
  </div>
</bpc-widget>
