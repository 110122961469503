import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PodsDropdownComponent } from "./components/pods-dropdown/pods-dropdown.component";
import { PrimeNgModule } from "./modules/prime-ng/prime-ng.module";
import { FormsModule } from "@angular/forms";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { DynamicCardsComponent } from "./components/dynamic-cards/dynamic-cards.component";
import { FilterDropdownComponent } from "./components/filter-dropdown/filter-dropdown.component";
import { WidgetComponent } from "./components/widget/widget.component";
import { HighchartComponent } from "./components/highchart/highchart.component";
import { HighchartsChartModule } from "highcharts-angular";
import { SkeletonModule } from "primeng/skeleton";
import { DynamicSkeletonLoaderComponent } from "../components/dynamic-skeleton-loader/dynamic-skeleton-loader.component";
import { GenericListingComponent } from "./components/generic-listing/generic-listing.component";
import { HorizontalBarComponent } from "./components/horizontal-bar/horizontal-bar.component";
import { CircularDownloadProgressComponent } from "./components/circular-download-progress/circular-download-progress.component";
import { DynamicTableComponent } from "./components/dynamic-table/dynamic-table.component";
import { SocialIconComponent } from "@shared/components/social-icon/social-icon.component";
import { GridLoaderLineComponent } from "@shared/components/grid-loader-line/grid-loader-line.component";
import { OverlayPanelTooltipDirective } from "./directives/overlay-panel-tooltip.directive";

@NgModule({
  declarations: [
    PodsDropdownComponent,
    PaginatorComponent,
    DynamicCardsComponent,
    FilterDropdownComponent,
    WidgetComponent,
    HighchartComponent,
    DynamicSkeletonLoaderComponent,
    GenericListingComponent,
    HorizontalBarComponent,
    CircularDownloadProgressComponent,
    DynamicTableComponent,
    SocialIconComponent,
    GridLoaderLineComponent,
    OverlayPanelTooltipDirective
  ],
  exports: [
    PodsDropdownComponent,
    PaginatorComponent,
    DynamicCardsComponent,
    FilterDropdownComponent,
    WidgetComponent,
    HighchartComponent,
    DynamicSkeletonLoaderComponent,
    GenericListingComponent,
    HorizontalBarComponent,
    CircularDownloadProgressComponent,
    DynamicTableComponent,
    SocialIconComponent,
    GridLoaderLineComponent,
    OverlayPanelTooltipDirective
  ],
  imports: [CommonModule, FormsModule, PrimeNgModule, HighchartsChartModule, SkeletonModule],
})
export class CoreModule {}
