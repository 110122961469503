import { Component, Input } from "@angular/core";

export interface CardItemMapping {
  title: string;
  id: string;
  subtitle?: string;
  description?: string;
  details?: ItemDetails[];
  route?: string;
}

interface ItemDetails {
  title: string;
  value: string;
}

@Component({
  selector: "bpc-dynamic-cards",
  templateUrl: "./dynamic-cards.component.html",
  styleUrl: "./dynamic-cards.component.scss",
})
export class DynamicCardsComponent {
  @Input() loading = true;
  @Input() data: CardItemMapping[] = []; // The data to be displayed
  @Input() rows: number = 5; // Number of rows per page

  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }
}
