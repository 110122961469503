import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, MonoTypeOperatorFunction, Observable, Subject, Subscription, catchError, filter, finalize, from, of, pipe, switchMap, tap } from 'rxjs';
import { ApiResponse } from '../api/base';
import { OperationResult } from '@core/models/operation-result';
import { TabUtilsService } from './tab-utils.service';
import { addEnrichmentWorkflow, addVotingWorkflow } from '../utils/company-utils';
import { Interaction, InteractionType, Priority, StatusId, WorkFlowId, WorkFlowStatusId } from '@core/models/auxiliary-company-models';
import { createNotificationTriggerModal, decreaseCounter, increaseCounter, removeCompanyFromTab } from '../utils/tab-utils';
import { CompanyHelperService } from '@core/services/company-helper.service';
import { DataTrigger, NotificationTrigger, NotificationType, OneTimeTrigger, RecurringTrigger } from '@core/models/notifications';
import { Company } from '@core/models/company';
import { TabData } from '@core/static/tabs';
import { Nullable } from '@core/models/nullable';
import { User, UserRole } from '@core/models/user';
import { AuthService } from '@core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { MainFilterRaw } from '@core/models/main-filter-view';
import { InvestorTab, ModalActions, Tab } from '@core/models/types';
import { CompaniesService } from './companies/companies.service';
import moment from "moment";
import { NotificationsService } from '@core/services/notifications.service';
import { SavedSearchService } from './companies-search/saved-search.service';
import { FrequenyType, ModalParameters } from '../components/generic-modal/generic-modal.component';
import { TimeBasedTriggerOccurrence } from '@core/directives/dropdown/time-based-trigger-occurrences.directive';
import { getFullTimeEquivalentGrowthTrigger, getFullTimeEquivalentTrigger, getMonthlyTrigger, getOneTimeTrigger, getPaidInCapitalTrigger, getRevenueTrigger, getWeeklyTrigger } from '../utils/notification-utils';
import CronTime from 'cron-time-generator';
import { AdminPanelService } from './admin/admin-panel.service';
import { UsersService } from './users/users.service';
import { urlRegex } from '@core/static/constants';
import { AIPrepService, LoadConversationType } from '@core/services/ai-prep.service';
import { HttpClient } from '@angular/common/http';
import { CompanyPortfolioService } from '@core/services/company.portfolio.service';

@Injectable({
  providedIn: 'root'
})
export class ModalUtilsService {

  toggleNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  toggalModal: BehaviorSubject<ModalParameters> = new BehaviorSubject<ModalParameters>({ currentModalAction: null, selectedCompany: null });
  toggleModalSubscription?: Subscription;
  menuSubject = new Subject<Observable<ApiResponse<OperationResult>>>();
  showPictureToastMessage: boolean = false;
  changeStatus: boolean = false;
  get user(): User {
    return this.authService.user.getValue()!;
  }

  get modalParameters(): ModalParameters {
    return {
      currentModalAction: null,
      displayCompanyDetails: undefined,
      mainFilterRaw: undefined,
      tabs: undefined,
      currentTabIndex: undefined,
      currentChildIndex: null,
      selectedCompany: null,
      companyDetails: null,
      newStatusId: null,
      coverageUsers: null,
      comment: null,
      checkSize: undefined,
      savedSearchId: undefined,
      nextStep: null,
      outreachOwner: undefined,
      dateAdded: undefined,
      companyWorkFlowId: undefined,
      icVote: null,
      actionTaken: null,
      title: null,
      timeBasedChecked: false,
      recurringCheckbox: true,
      oneTimeCheckBox: false,
      timeBasedTriggerType: TimeBasedTriggerOccurrence.Recurring,
      recurring: null,
      recurringWeekly: 1,
      recurringMonthly: 1,
      minTriggerDate: this.nextDay(),
      triggerOn: null,
      fteTrigger: createNotificationTriggerModal(),
      fteGrowthTrigger: createNotificationTriggerModal(),
      picTrigger: createNotificationTriggerModal(),
      revenueTrigger: createNotificationTriggerModal(),
      selectedJobId: null,
      frequency: FrequenyType.Daily,
      selectedDayOfMonth: 0,
      selectedDayOfWeek: 0,
      selectedTime: null,
      userRoles: [],
      userGroups: [],
      userPods: [],
      name: '',
      initials: '',
      email: '',
      externalId: null, 
      userId: null,
      isActive: true, 
      base64Image: '',
      userStatus: undefined,
      chatThreadId: '',
      teamMembers: null,
    }
  }

  constructor(public tabUtilsService: TabUtilsService,
    public companyHelperService: CompanyHelperService,
    public authService: AuthService,
    public messageService: MessageService,
    public companiesService: CompaniesService,
    public notificationsService: NotificationsService,
    public savedSearchService: SavedSearchService,
    public adminService: AdminPanelService, 
    public usersService: UsersService,
    public aiPrepService: AIPrepService,
    private httpClient: HttpClient,
    private companyPortfolioService: CompanyPortfolioService

  ) { }

  formatUTCDate(originalDate?: Nullable<Date>) {
    if (originalDate) {
      return moment(originalDate).format('MM/DD/YYYY')
    }
    return undefined;
  }

  getCurrentNotificationTriggers(selectedCompany: Nullable<Company>, companyDetails: Nullable<Company>): NotificationTrigger[] {
    return selectedCompany?.notificationTriggers ?? companyDetails?.notificationTriggers ?? [];
  }

  setUpNotificationTriggers(modalParameters: ModalParameters) {
    const triggers = this.getCurrentNotificationTriggers(modalParameters.selectedCompany, modalParameters.companyDetails!);
    let trigger: NotificationTrigger | undefined = getOneTimeTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.OneTime;
      modalParameters.oneTimeCheckBox = true;
      modalParameters.recurringCheckbox = false;
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.triggerOn = moment(trigger.dateValue).toDate();
    }

    trigger = getWeeklyTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
      modalParameters.recurringCheckbox = true;
      modalParameters.oneTimeCheckBox = false;
      modalParameters.recurring = true;
      modalParameters.recurringWeekly = trigger.dayOfValue;
    }

    trigger = getMonthlyTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
      modalParameters.recurringCheckbox = true;
      modalParameters.oneTimeCheckBox = false;
      modalParameters.recurring = false;
      modalParameters.recurringMonthly = trigger.dayOfValue;
    }

    trigger = getFullTimeEquivalentTrigger(triggers);
    if (trigger) {
      modalParameters.fteTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getFullTimeEquivalentGrowthTrigger(triggers);
    if (trigger) {
      modalParameters.fteGrowthTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getPaidInCapitalTrigger(triggers);
    if (trigger) {
      modalParameters.picTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getRevenueTrigger(triggers);
    if (trigger) {
      modalParameters.revenueTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    return modalParameters;
  }


  getSelectedCompanyId(selectedCompany: Nullable<Company>, companyDetails: Nullable<Company>): number {
    return selectedCompany?.id ?? companyDetails!.id;
  }

  private getTriggers(modalParameters: ModalParameters): NotificationTrigger[] {
    const triggers: NotificationTrigger[] = [];

    if (modalParameters.timeBasedChecked) {
      if (modalParameters.timeBasedTriggerType === TimeBasedTriggerOccurrence.Recurring) {
        triggers.push(<RecurringTrigger>{
          isActive: true,
          type: modalParameters.recurring ? NotificationType.Weekly : NotificationType.Monthly,
          dayOfValue: modalParameters.recurring ? modalParameters.recurringWeekly : modalParameters.recurringMonthly,
        });
      } else {
        triggers.push(<OneTimeTrigger>{
          isActive: true,
          type: NotificationType.OneTime,
          dateValue: this.formatUTCDate(modalParameters.triggerOn),
        });
      }
    }

    if (modalParameters.fteTrigger!.isActive) {
      triggers.push(<DataTrigger>{
        isActive: true,
        type: NotificationType.FullTimeEquivalent,
        operator: modalParameters.fteTrigger!.operator,
        scalarValue: modalParameters.fteTrigger!.value,
      });
    }

    if (modalParameters.fteGrowthTrigger!.isActive) {
      triggers.push(<DataTrigger>{
        isActive: true,
        type: NotificationType.FullTimeEquivalentGrowth,
        operator: modalParameters.fteGrowthTrigger!.operator,
        scalarValue: modalParameters.fteGrowthTrigger!.value,
      });
    }

    if (modalParameters.picTrigger!.isActive) {
      triggers.push(<DataTrigger>{
        isActive: true,
        type: NotificationType.PaidInCapital,
        operator: modalParameters.picTrigger!.operator,
        scalarValue: modalParameters.picTrigger!.value,
      });
    }

    if (modalParameters.revenueTrigger!.isActive) {
      triggers.push(<DataTrigger>{
        isActive: true,
        type: NotificationType.Revenue,
        operator: modalParameters.revenueTrigger!.operator,
        scalarValue: modalParameters.revenueTrigger!.value,
      });
    }

    return triggers;
  }

  nextDay(): Date {
    return moment().add(1, "day").startOf("day").toDate();
  }

  updateCompany(
    update: (company: Company, tab?: TabData<Company>, companyIndex?: number) => void,
    tabs: Record<UserRole, TabData<Company>[]>,
    selectedCompany: Nullable<Company>,
    companyDetails: Nullable<Company>,
    refreshReference = false
  ) {
    this.tabUtilsService.getAvailableUserTabs(tabs).forEach((tab) => {
      if (tab.data) {
        const companyIndex = tab.data!.findIndex(({ id }) => id === this.getSelectedCompanyId(selectedCompany, companyDetails));
        if (companyIndex !== -1) {
          update(tab.data![companyIndex], tab, companyIndex);
          if (refreshReference) {
            tab.data![companyIndex] = { ...tab.data![companyIndex] };
          }
        }
      } else if (tab.children) {
        tab.children.forEach((ctab) => {
          const companyIndex = ctab.data!.findIndex(({ id }) => id === this.getSelectedCompanyId(selectedCompany, companyDetails));
          if (companyIndex !== -1) {
            update(ctab.data![companyIndex], ctab, companyIndex);
            if (refreshReference) {
              tab.data![companyIndex] = { ...tab.data![companyIndex] };
            }
          }
        });
      }
    });
    if (this.getSelectedCompanyId(selectedCompany, companyDetails) === companyDetails?.id) {
      update(companyDetails);
      if (refreshReference) {
        companyDetails = { ...companyDetails };
      }
    }
  }

  resetModalItems() {
    // let obj = {
    //   newStatusId: null,
    //   modalLoading: false,
    //   displayModal: false,
    //   title: null,
    //   comment: null,
    //   checkSize: undefined,
    //   coverageUsers: [],
    //   actionTaken: null,
    //   selectedCompany: null,
    // }
    // this.resetNotificationTriggers();
    // if (this.displayCompanyDetails) {
    //   blockScroll();
    // }
    // this.icVote = null;
    // this.currentModalAction = null;
  }

  showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  getModalSaveObservable(modalParameters: ModalParameters) {
    const interactionDate = new Date().toISOString();
    const handleUserProfilePictureUpload: MonoTypeOperatorFunction<ApiResponse<any>> = pipe(
      switchMap((response) => {
        if ((response.error || !response.data?.result) && response?.error?.status !== 403) {
          this.showErrorToastMessage(
            response?.error?.response?.title ??
            response?.data?.message ??
            "An error happened while performing the operation."
          );
          this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
          return of(response);
        } else if(modalParameters.base64Image) {
          this.showPictureToastMessage = true;
          if(!urlRegex.test(modalParameters.base64Image)) {
            // Switching to the observable returned by UpdateProfilePicture
            return this.usersService.UpdateProfilePicture({
              userId: modalParameters.userId ?? response.data.data,
              ProfilePictureData: modalParameters.base64Image,
            });
          }
         
        }
        if (response.data?.result) {
          this.showSuccessToastMessage('Operation Successful');
        }
        return of(response);
      }),
      tap((response) => {
        if ((response.error || !response.data?.result) && response?.error?.status !== 403 && this.showPictureToastMessage) {
          this.showErrorToastMessage(
            "An error happened while uploading the picture"
          );
        }
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
      }),
      filter((response:any) => {
        return !!(response.data?.result ?? response.error)
      })
    );


    const ProfilePictureUpload: MonoTypeOperatorFunction<ApiResponse<any>> = pipe(
      switchMap((response) => {
        if ((response.error || !response.data?.result) && response?.error?.status !== 403) {
          this.showErrorToastMessage(
            response?.error?.response?.title ??
            response?.data?.message ??
            "An error happened while performing the operation."
          );
          this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
          this.resetModalItems();
          return of(response);
        }
        
        if (modalParameters.userId ?? response.data.data) {
          if (modalParameters.profileImageFile){
            const userId = modalParameters.userId ?? response.data.data;
            this.ProfilePicture(userId, modalParameters.profileImageFile, modalParameters);
          }
          else{
            if (modalParameters.userId){
              this.showSuccessToastMessage('User Updated Successfully'); 
            }
            else{
              this.showSuccessToastMessage('User Created Successfully'); 
            }
            this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
            this.tabUtilsService.refreshData.next();
            this.resetModalItems();
          }
        }

        return of(response);

      }),
      tap((response) => {

      }),
      filter((response: any) => !!(response.data?.result || response.error))
    );

    const handleResponse: MonoTypeOperatorFunction<ApiResponse<any>> = pipe(
      tap((response) => {
        if ((response.error || !response.data?.result) && response?.error?.status !== 403) {
          this.showErrorToastMessage(
            response?.error?.response?.title ??
            response?.data?.message ??
            "An error happened while performing the operation."
          );
        }
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
      }),
      filter((response) => !!response.data?.result)
    );

    const handleThreadResponse = (type: LoadConversationType): MonoTypeOperatorFunction<ApiResponse<any>> => pipe(
      tap((response) => {
        if ((response.error || !response.data?.result) && response?.error?.status !== 403) {
          this.showErrorToastMessage(
            response?.error?.response?.title ??
            response?.data?.message ??
            "An error happened while performing the operation."
          );
        }
        this.aiPrepService.aiPrepConversationSubject.next(type);
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
      }),
      filter((response) => !!response.data?.result)
    );

    const successfulOperation: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      filter((value) => !!value.data?.result)
    );

    const showSuccessMessage: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap((response) => {
        this.showSuccessToastMessage(response?.data?.message ?? "Operation successful");
      })
    );

    const addToPriority: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        if (modalParameters.companyDetails) {
          modalParameters.companyDetails.priority = Priority.PRIORITY;
        }
        increaseCounter(this.tabUtilsService.getPriorityTab(modalParameters.tabs!));
        this.showSuccessToastMessage("The company has been successfully moved to Priority.");
      })
    );

    const takeOwnership: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        if (modalParameters.displayCompanyDetails) {
          this.companyHelperService.changeMessage("refresh-details");
          if (modalParameters.mainFilterRaw && modalParameters.tabs && modalParameters.currentTabIndex && modalParameters.currentChildIndex) {
            this.tabUtilsService.navigateToCurrentTab(modalParameters.mainFilterRaw, modalParameters.tabs, modalParameters.currentTabIndex, modalParameters.currentChildIndex);
          }
        }
      })
    );
    const changeStatus: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company) => {
          company.statusId = modalParameters.newStatusId!;
        }, modalParameters.tabs!, modalParameters.selectedCompany, modalParameters.companyDetails!);
        this.refreshStatusBar();
      })
    );

    const editInvestors: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company) => {
          company.investorsDetails = modalParameters.investors!;
        }, modalParameters.tabs!, modalParameters.selectedCompany, modalParameters.companyDetails!);
        //this.refreshStatusBar();
      })
    );

    const editCoverage: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company, tab, companyIndex) => {
          if (modalParameters.displayCompanyDetails) {
            this.companyHelperService.changeMessage("refresh-details");
          } else {
            if (modalParameters.mainFilterRaw && modalParameters.tabs && modalParameters.currentTabIndex && modalParameters.currentChildIndex) {
              this.tabUtilsService.navigateToCurrentTab(modalParameters.mainFilterRaw, modalParameters.tabs, modalParameters.currentTabIndex, modalParameters.currentChildIndex);
            }
          }
          this.refreshStatusBar();
        }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );

    const closeModal: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = finalize(() => this.resetModalItems());

    const flagCompanyForVote: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company) => {
          addVotingWorkflow(company, {
            owner: this.user,
            workflowTypeId: WorkFlowId.ICVote,
            statusId: WorkFlowStatusId.InReview,
            assignee: null,
            createDate: interactionDate,
            votingDate: interactionDate,
          });
        }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );

    const enrichData: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.showSuccessToastMessage("Your request for additional analysis has been sent.");
        this.updateCompany((company) => {
          addEnrichmentWorkflow(company, {
            owner: this.user,
            workflowTypeId: WorkFlowId.Enrich,
            statusId: WorkFlowStatusId.ToDo,
            assignee: null,
            createDate: interactionDate,
            votingDate: interactionDate,
          });
        }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );

    const removeFromPriority: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        if (modalParameters.companyDetails) {
          modalParameters.companyDetails.priority = Priority.PIPELINE;
        }
        this.updateCompany((company, tab, companyIndex) => {
          if (tab?.route === "priority") {
            removeCompanyFromTab(tab, companyIndex);
          }
        }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
        this.showSuccessToastMessage("The company has been successfully removed from Priority.");
      })
    );

    const refreshCurrentTabData: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      tap(() => this.tabUtilsService.refreshData.next())
    );

    const markAsActive: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company, tab, companyIndex) => removeCompanyFromTab(tab, companyIndex), modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );


    const removeFromJarvis: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company, tab, companyIndex) => removeCompanyFromTab(tab, companyIndex), modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );

    const changeActivity: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        modalParameters.selectedCompany!.latestInteraction = {
          user: this.user,
          details: modalParameters.comment!,
          summary: modalParameters.title!,
          interactionType: modalParameters.actionTaken!,
          interactionDate,
        } as Interaction;
        switch (modalParameters.actionTaken) {
          case 1:
            modalParameters.selectedCompany!.meetingsCount++;
            break;
          case 2:
            modalParameters.selectedCompany!.callsCount++;
            break;
          case 3:
            modalParameters.selectedCompany!.emailsCount++;
            break;
        }
      })
    );

    const removeFromIC: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        this.updateCompany((company, tab, companyIndex) => {
          removeCompanyFromTab(tab, companyIndex);
        }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
      })
    );

    const saveTriggers: (triggers: NotificationTrigger[]) => MonoTypeOperatorFunction<ApiResponse<OperationResult>> = (
      triggers: NotificationTrigger[]
    ) =>
      pipe(
        tap(() => {
          this.updateCompany((company) => {
            company.notificationTriggers = triggers;
          }, modalParameters.tabs!, modalParameters.selectedCompany!, modalParameters.companyDetails!);
        })
      );

    // const closeCompanyDetailsModal: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = finalize(() =>
    //   // this.closeCompanyDetailsPanel()
    // );

    const releaseCompany: MonoTypeOperatorFunction<ApiResponse<OperationResult>> = pipe(
      successfulOperation,
      tap(() => {
        if (modalParameters.companyDetails) {
          // const index = this.companyDetails.coverageUsers?.findIndex(x => x.id === this.user.id);
          // if (typeof index !== 'undefined') {
          //   this.companyDetails.coverageUsers?.splice(index);
          // }
          // this.companyDetails.priority = Priority.COMPANIES;
          if (modalParameters.displayCompanyDetails) {
            this.companyHelperService.changeMessage("refresh-details");
          } else {
            if (modalParameters.mainFilterRaw && modalParameters.tabs && modalParameters.currentTabIndex && modalParameters.currentChildIndex) {
              this.tabUtilsService.navigateToCurrentTab(modalParameters.mainFilterRaw, modalParameters.tabs, modalParameters.currentTabIndex, modalParameters.currentChildIndex);
            }
          }
        }
        if (modalParameters.tabs && modalParameters.currentTabIndex && modalParameters.currentChildIndex && modalParameters.selectedCompany && modalParameters.companyDetails) {
          this.updateCompany((company, tab, companyIndex) => {
            if (tab?.route === "pipeline" || tab?.route === "priority") {
              removeCompanyFromTab(tab, companyIndex);
            }
          }, modalParameters.tabs, modalParameters.selectedCompany, modalParameters.companyDetails);
          decreaseCounter(this.tabUtilsService.getCurrentTab(modalParameters.tabs, modalParameters.currentTabIndex, modalParameters.currentChildIndex));
        }


      })
    );

    switch (modalParameters.currentModalAction!) {
      case "joinCoverage":
        return this.companiesService
          .moveToPipeline(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, takeOwnership, closeModal);
      case "leaveCoverage":
        return this.companiesService
          .releaseCompany(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, releaseCompany, showSuccessMessage, closeModal);
      case "changeStatus":
        return this.companiesService
          .changeStatus(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.newStatusId!)
          .pipe(handleResponse, changeStatus, refreshCurrentTabData, showSuccessMessage, closeModal);
          case "changeStatus":
      case "addToPortfolio":
      return this.companyPortfolioService
        .addPortfolioCompany(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.teamMembers)
        .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "editCoverage":
        return this.companiesService
          .editCoveragePersons(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.coverageUsers!, modalParameters.comment!)
          .pipe(handleResponse, editCoverage, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "flagForICVoting":
        return this.companiesService
          .flagForICVoting(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.comment ?? "", modalParameters.checkSize ?? 0, modalParameters.teamMembers ?? null)
          .pipe(handleResponse, flagCompanyForVote, showSuccessMessage, closeModal);
      case "enrichData":
        return this.companiesService.enrichData(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, enrichData, closeModal);
      case "addToPriority":
        return this.companiesService
          .moveToPriority(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, addToPriority, takeOwnership, closeModal);
      case "removeFromPriority":
        return this.companiesService
          .moveFromPriority(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.comment!)
          .pipe(handleResponse, removeFromPriority, takeOwnership, closeModal);
      case "removeSavedSearch":
        return this.savedSearchService
          .removeSavedSearch(modalParameters.savedSearchId)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "editNextStep":
        return this.companiesService
          .updateNextStep(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.nextStep!)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "editOutReachOwner":
        return this.companiesService
          .updateOutReachOwner(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.outreachOwner?.email?.toString())
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "editICDateAdded":
        return this.companiesService
          .updateICDateAdded(modalParameters.companyWorkFlowId!, moment(modalParameters.dateAdded!).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS"))
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "icVoting":
        return this.companiesService
          .voteForCompany(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.icVote!, modalParameters.comment!)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "removeFromJarvis":
        return this.companiesService.archive(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!)).pipe(handleResponse, removeFromJarvis, closeModal);
      case "markAsActive":
        return this.companiesService.activate(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!)).pipe(handleResponse, markAsActive, closeModal);
      case "actionTaken":
        return this.companiesService
          .interact(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.actionTaken!, modalParameters.title!, modalParameters.comment!, interactionDate)
          .pipe(handleResponse, changeActivity, closeModal);
      case "removeFromIC":
        return this.companiesService
          .removeFromIC(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, refreshCurrentTabData, removeFromIC, showSuccessMessage, closeModal);
      case "createOrEditNotificationTrigger":
        const triggers = this.getTriggers(modalParameters);
        return this.notificationsService
          .upsertNotificationTriggers(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), triggers)
          .pipe(handleResponse, showSuccessMessage, saveTriggers(triggers), closeModal);
      case "editICAsk":
        return this.companiesService
          .editICAsk(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!), modalParameters.comment!)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "archiveCompany":
        return this.companiesService
          .moveCompanyFromActiveICtoHistory(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      // case "closeCompanyDetails":
      //   return EMPTY.pipe(closeCompanyDetailsModal, closeModal);
      case "updateCronJob":
        const cronExpression = this.getCronExpression(modalParameters);
        return this.adminService
          .updateJob(modalParameters.selectedJobId!, cronExpression!)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
      case "removeCronJob":
        return this.adminService
          .deleteJob(modalParameters.selectedJobId!)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
        break;
      case "AddUser":
        this.showPictureToastMessage = false;
        const addUserData = this.getUserData(modalParameters);
        return this.usersService
          .AddUser(addUserData!)// refresh and closing modal is being handled inside profile picture upload now since upload can take more time.
          .pipe(ProfilePictureUpload);
        break;
      case "EditUser":
        this.showPictureToastMessage = false;
        const editUserData = this.getUserData(modalParameters);
        return this.usersService
          .EditUser(editUserData!, modalParameters.userId!)
          .pipe(ProfilePictureUpload);
        break;
      case 'UserInactiveToggle':
        case 'UserActiveToggle':
          const userStatus: boolean = modalParameters.userStatus ?? false;
          return this.usersService
          .updateUserStatus(modalParameters.userId!, userStatus)
          .pipe(handleResponse, refreshCurrentTabData, showSuccessMessage, closeModal);
          break;
      case 'DeleteChatThread':
        return this.aiPrepService.deleteConversationsByThreadId(modalParameters.chatThreadId!)
        .pipe(handleThreadResponse("delete"), refreshCurrentTabData, showSuccessMessage, closeModal);
        break;
      case 'UpdateChatThread':
        return this.aiPrepService.updateThreadTitle(modalParameters.chatThreadId!, { title: modalParameters.title! })
        .pipe(handleThreadResponse("rename"), refreshCurrentTabData, showSuccessMessage, closeModal);
        break;
      case 'editInvestors':
        return this.companiesService
          .updateInvestorsDetails(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!,), modalParameters.investors, modalParameters.previousInvestors)
          .pipe(handleResponse, editInvestors, refreshCurrentTabData, showSuccessMessage, closeModal);
      default:
        return this.companiesService
          .releaseCompany(this.getSelectedCompanyId(modalParameters.selectedCompany, modalParameters.companyDetails!))
          .pipe(handleResponse, releaseCompany, showSuccessMessage, closeModal);

    }
  }

  getUserData(modalParameters: ModalParameters) {
    const roleIds = modalParameters.userRoles?.map((role) => {
      return {
        roleId: role.roleId
      }
    });
    const podIds = modalParameters.userPods?.map((pod) => {
      return {
        groupId: pod.id
      }
    });
    const groupIds = modalParameters.userGroups?.map((group) => {
      return {
        groupId: group.id
      }
    });
    const combinedGroupIds = [...(groupIds ?? []), ...(podIds ?? [])];
    return {
      email: modalParameters.email,
      name: modalParameters.name,
      initials: modalParameters.initials,
      externalId: modalParameters.externalId,
      userRoles: roleIds, 
      userGroups: combinedGroupIds, 
      isActive: modalParameters.isActive
    }
  }

  extractTimeFromDate(time: Date) {
    const timestamp = new Date(time);
    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();
    return { hours: hours, minutes: minutes };
  }

  getCronExpression(modalParameters: ModalParameters) {
    let cronExpression;
    if(modalParameters.selectedTime) {
      const time = this.extractTimeFromDate(modalParameters.selectedTime)
      switch (modalParameters.frequency) {
        case FrequenyType.Daily:
          cronExpression = CronTime.everyDayAt(time.hours, time.minutes);
          break;
        case FrequenyType.Weekly:
          cronExpression = CronTime.everyWeekAt(modalParameters.selectedDayOfWeek!, time.hours, time.minutes);
          break;
        case FrequenyType.Monthly:
          cronExpression = CronTime.everyMonthOn(modalParameters.selectedDayOfMonth!, time.hours, time.minutes);
          break;
      }
    }
    return cronExpression;
  }

  refreshStatusBar(){
    this.changeStatus = true;
  }

  stopRefreshStatusBar(){
    this.changeStatus = false;
  }

  ProfilePicture(userId: number, file: File, modalParameters: ModalParameters){

    // Start the profile picture upload flow
    this.usersService.BeginProfilePictureUpload(userId).pipe(
      catchError((error) => {
        this.showErrorToastMessage("An error happened while uploading the picture.");
        if (modalParameters.userId){
          this.showSuccessToastMessage('User Updated Successfully'); 
        }
        else{
          this.showSuccessToastMessage('User Created Successfully'); 
        }
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
        this.resetModalItems();
        return of(null);
      })
    ).subscribe(response1 => {
      if (response1.data){
        const signedUrl = response1.data?.data;
        this.modalParameters.signedUrl = signedUrl;
        this.UploadPicture(signedUrl, file, userId, modalParameters);
      }

      
    });
      
  }

  async UploadPicture(signedUrl: string, file: File, userId: number, modalParameters: ModalParameters){
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      },
      body: file
    });
    if (!response.ok) {
      this.showErrorToastMessage("An error happened while uploading the picture.");
      if (modalParameters.userId){
        this.showSuccessToastMessage('User Updated Successfully'); 
      }
      else{
        this.showSuccessToastMessage('User Created Successfully'); 
      }
      this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
      this.resetModalItems();
    }

    this.usersService.CompleteProfilePictureUpload(userId).pipe(
      catchError((error) => {
        this.showErrorToastMessage("An error happened while uploading the picture.");
        if (modalParameters.userId){
          this.showSuccessToastMessage('User Updated Successfully'); 
        }
        else{
          this.showSuccessToastMessage('User Created Successfully'); 
        }
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
        this.resetModalItems();
        return of(null);
      })
    ).subscribe(response => {
      if (response.data){
        if (modalParameters.userId){
          this.showSuccessToastMessage('User Updated Successfully'); 
        }
        else{
          this.showSuccessToastMessage('User Created Successfully'); 
        }
        this.toggalModal.next({ currentModalAction: null, selectedCompany: null });
        this.tabUtilsService.refreshData.next();
        this.resetModalItems();
      }
    });

  }

}
