export enum BpcChartType {
  BarChart,
  PieChart,
  Pie = "pie",
}

export enum FilterType {
  top_n,
  pods,
  sort_by_interaction_type,
  last_n,
}

export enum WidgetTypes {
  chart,
  rankings,
  dealdrop,
}

export interface WidgetFilter {
  filterType: FilterType;
  default?: any;
  classes?: string;
  options: any[];
}