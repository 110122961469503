import { Component, Input } from "@angular/core";
import { WidgetConfig } from "@core/models/analytics-dashboard-models";
import { WidgetTypes, FilterType, BpcChartType } from "@core/models/analytics-dashboard-enums";
import { GridsterConfig } from "angular-gridster2";
import * as Highcharts from "highcharts";
import { Subscription } from "rxjs";

@Component({
  selector: "bpc-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  options: GridsterConfig = {};
  widgets: Array<WidgetConfig> = [];
  WidgetTypes = WidgetTypes;
  Highcharts: typeof Highcharts = Highcharts;

  private subscriptions: Subscription[] = []; // Array to hold subscriptions

  constructor() {}

  ngOnInit() {
    this.setupGridsterOptions();
    this.initializeDashboard();
  }

  setupGridsterOptions() {
    this.options = {
      // itemChangeCallback: (item) => console.log("Item changed:", item),
      // itemResizeCallback: (item) => console.log("Item resized:", item),
      draggable: { enabled: false },
      resizable: { enabled: false },
      gridType: "fit",
      margin: 10,
      maxCols: 8,
      //minRows: 20,
      minItemCols: 2,
      minItemRows: 2,
    };
  }

  initializeDashboard() {
    this.widgets = [
      {
        x: 0,
        y: 0,
        rows: 5,
        cols: 5,
        type: WidgetTypes.chart,
        chartType: BpcChartType.BarChart,
        title: "Activity",
        filters: [FilterType.pods, FilterType.last_n],
        loaders: 20,
      },
      {
        x: 5,
        y: 0,
        rows: 5,
        cols: 2,
        type: WidgetTypes.rankings,
        title: "IC",
        loaders: 18,
      },
      /*{
        x: 0,
        y: 0,
        //y: 5,
        rows: 5,
        cols: 7,
        type: WidgetTypes.dealdrop,
        title: "Deal drop reasons",
        loaders: 18,
      },*/
    ];
  }

  removeItem(item: any) {
    this.widgets.splice(this.widgets.indexOf(item), 1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
