import { Nullable } from "@models/nullable";
import { Company } from "@models/company";
import { MainFilterRaw } from "@models/main-filter-view";
import { UserRole } from "@models/user";
import { Pod } from "@models/pod-filter";
import { Subscription } from "rxjs";

export type TableHeaderSortIcon =
  | "pi-sort-alt" // Neutral, with arrows pointing up and down
  | "pi-sort-amount-down-alt" // Ascending, with arrow pointing down
  | "pi-sort-amount-down" // Descending, with arrow pointing down
  | "fa-sort" // Neutral, with arrows pointing up and down
  | "fa-sort-up" // Ascending, with arrow pointing down
  | "fa-sort-down"; // Descending, with arrow pointing down

export enum TabType {
  SavedSearch,
  AiSummary,
  ProspectUpdate,
  PortfolioUpdate,
  IRUpdate,
  NewsletterUpdate,
  AdminPanel,
  UserPanel,
  AlertPanel,
  DeepChat,
  Dashboard,
}

export type TabRoute =
  | ""
  | "companies"
  | "elevations"
  | "pipeline"
  | "priority"
  | "ic-voting"
  | "history"
  | "data-quality"
  | "ai-summary"
  | "saved-search"
  | "portfolio-update"
  | "prospect-update"
  | "weekly-update"
  | "ir-update"
  | "newsletter-update"
  | "admin-panel"
  | "alert"
  | "chatbot"
  | "interactions"
  | "v2/weekly-update"
  | "v2/dashboard"
  | "dashboard"
  | "combined";

export type ColumnLabel =
  | "Company"
  | "FTE"
  | "Growth%"
  | "Ownership"
  | "Score"
  | "PIC"
  | "Latest round"
  | "Latest Round Date"
  | "Stage"
  | "Last Interaction"
  | "Investors"
  | "Revenue"
  | "Status"
  | "Activity"
  | "Coverage"
  | "Days in priority"
  | "Date added"
  | "IC Ask"
  | "Voting"
  | "Summary"
  | "Remove"
  | "Archive"
  | "IC Date"
  | "Check Size"
  | "Outreach Owner"
  | "Next Steps"
  | "Financials"
  | "Voting Menu"
  | "Search Filter Name"
  | "Saved Date"
  | "Number Of Filters"
  | "Cron Job Name"
  | "Frequency"
  | "Recipients"
  | "Last Execution"
  | "Picture"
  | "Name"
  | "Email"
  | "DealCloud ID"
  | "Role(s)"
  | "Pod(s)"
  | "Group(s)"
  | "Investors Details"
  | "CEO / Founder City"
  | "Deal Team"
  | "Comments";

export interface GridFilter {
  activePod: Pod;
}

export interface TabData<T> extends Partial<TabDataGrid<T>> {
  route: TabRoute;
  ordinal?: number;
  label: string;
  type?: TabType;
  hidden?: boolean;
  disabled: boolean;
  children?: TabData<T>[];
  tableClass?: string;
  downloadingProgress: number;
  enableDownload?: boolean;
  mobileHidden?: boolean;
  top_right_label?: string;
}

export interface TabDataGrid<T> {
  columns: TableHeader[];
  data: T[];
  total: number;
  currentTotal: number;
  totalCountByAssignee?: number;
  mainFilterRaw: MainFilterRaw;
  gridFilter: GridFilter;
  loading: boolean;
  hasFilters: boolean;
  usePagination: boolean;
  hasByPodLeaderFilter: boolean;
  hasFilterByTagFilter?: boolean;
  hasFilterByMyElevations?: boolean;
  hasFilterLabels?: boolean;
  disableFilterDelete?: boolean;
  hasPods: boolean;
  accessRoles?: string[];
  downloadSubscription: Nullable<Subscription>;
  showMenu?: boolean;
  hasBarFilter?: boolean;
  hasStageBar?: boolean;
  hasInteractionsBar?: boolean;
  hasScroll?: boolean;
  hasChartNavigator?: boolean;
  hasHeatMap?: boolean;
  hasInteractionsChart?: boolean;
}

export interface TableHeader {
  label: ColumnLabel;
  tooltip?: string;
  sortProperty: Nullable<string>;
  sortDirection?: Nullable<string>;
}

export const firmPod: Pod = {
  name: "Firm",
  members: [],
};

export const myCompaniesPod: Pod = {
  name: "My Companies",
  members: [],
  assigneeFilterType: undefined,
};

export const myInteractionsPod: Pod = {
  name: "My Interactions",
  members: [],
  assigneeFilterType: undefined,
};

export const customPod: Pod = {
  name: "Custom filter",
  inactive: true,
  members: [], // userId: 0 => Show all
};

const dashboardTab: TabData<Company> = {
  route: "v2/dashboard",
  ordinal: 8,
  label: "Analytics",
  disabled: false,
  hasFilters: true,
  usePagination: true,
  hasByPodLeaderFilter: true,
  hasPods: false,
  downloadingProgress: 0,
  enableDownload: false,
  hasBarFilter: false,
  hasFilterLabels: true,
  hasScroll: true,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
  type: TabType.Dashboard,
  columns: [],
  data: [],
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
};

const companiesTab: TabData<Company> = {
  route: "companies",
  ordinal: 1,
  label: "Pipeline",
  children: [
    {
      tableClass: "company-table",
      route: "",
      label: "Companies",
      top_right_label: "Companies",
      columns: [
        { label: "Company", sortProperty: "name" },

        // Removed due to dealcloud conflict.
        // { label: "FTE", sortProperty: "fullTimeEquivalent" },
        // { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
        // { label: "PIC", sortProperty: "paidInCapital" },
        // { label: "Latest round", sortProperty: null },
        // { label: "Latest Round Date", sortProperty: null },
        // { label: "Investors", sortProperty: null },
        { label: "Financials", sortProperty: null },
        { label: "Coverage", sortProperty: null },
        { label: "Investors", sortProperty: null },
        { label: "CEO / Founder City", sortProperty: null },
        { label: "Stage", sortProperty: "status" },
        //{ label: "Last Interaction", sortProperty: "latestinteraction" },
        // SourceScrub Exclusive
        // { label: "Ownership", sortProperty: "companyType" },
        // { label: "Score", tooltip: "score", sortProperty: "rank" },
        // { label: "Contacts", sortProperty: null },
        // { label: "Status", sortProperty: null },
        // { label: "Activity", sortProperty: null },
        // { label: "Outreach Owner", sortProperty: null },
        // { label: "Next Steps", sortProperty: null },
      ],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: firmPod,
      },
      enableDownload: true,
      loading: false,
      disabled: false,
      hasFilters: true,
      usePagination: true,
      hasByPodLeaderFilter: true,
      hasPods: true,
      downloadingProgress: 0,
      hasStageBar: true,
      hasChartNavigator: true,
      hasHeatMap: true,
      hasInteractionsChart: false,
    },
    {
      tableClass: "interactions-table",
      route: "interactions",
      label: "Interactions",
      top_right_label: "Interactions\n(Last 3 Months)",
      columns: [
        { label: "Company", sortProperty: "name" },

        // Removed due to dealcloud conflict.
        // { label: "FTE", sortProperty: "fullTimeEquivalent" },
        // { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
        // { label: "PIC", sortProperty: "paidInCapital" },
        // { label: "Latest round", sortProperty: null },
        // { label: "Latest Round Date", sortProperty: null },
        // { label: "Investors", sortProperty: null },
        { label: "Coverage", sortProperty: null },
        { label: "Stage", sortProperty: "status" },
        { label: "Last Interaction", sortProperty: "latestinteraction" },
        //{ label: "Last Interaction", sortProperty: "latestinteraction" },
        // SourceScrub Exclusive
        // { label: "Ownership", sortProperty: "companyType" },
        // { label: "Score", tooltip: "score", sortProperty: "rank" },
        // { label: "Contacts", sortProperty: null },
        // { label: "Status", sortProperty: null },
        // { label: "Activity", sortProperty: null },
        // { label: "Outreach Owner", sortProperty: null },
        // { label: "Next Steps", sortProperty: null },
      ],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: firmPod,
      },
      enableDownload: true,
      loading: false,
      disabled: false,
      hasFilters: true,
      usePagination: true,
      hasByPodLeaderFilter: true,
      hasPods: true,
      downloadingProgress: 0,
      hasChartNavigator: true,
      hasInteractionsBar: true,
      hasHeatMap: false,
      hasInteractionsChart: true,
    },
  ],
  disabled: false,
  hasFilters: true,
  usePagination: true,
  hasByPodLeaderFilter: true,
  hasPods: true,
  downloadingProgress: 0,
  enableDownload: true,
  hasBarFilter: true,
  hasFilterLabels: true,
  hasScroll: true,
  hasHeatMap: true,
  hasInteractionsChart: true,
  hasChartNavigator: true,
};

const pipeline: TabData<Company> = {
  route: "pipeline",
  ordinal: 2,
  label: "Pipeline",
  tableClass: "pipeline-table",
  columns: [
    // { label: "Score", tooltip: "score", sortProperty: "rank" },
    { label: "Company", sortProperty: "name" },

    // Removed due to dealcloud conflict.
    // { label: "FTE", sortProperty: "fullTimeEquivalent" },
    // { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
    // { label: "Ownership", sortProperty: "companyType" },
    // { label: "PIC", sortProperty: "paidInCapital" },
    // { label: "Latest round", sortProperty: null },
    // { label: "Latest Round Date", sortProperty: null },
    // { label: "Investors", sortProperty: null },

    { label: "Stage", sortProperty: "status" },
    { label: "Coverage", sortProperty: null },
    { label: "Financials", sortProperty: null },
    { label: "Last Interaction", sortProperty: null },
    // { label: "Contacts", sortProperty: null },
    // { label: "Status", sortProperty: null },
    // { label: "Activity", sortProperty: null },
    // { label: "Outreach Owner", sortProperty: null },
    // { label: "Next Steps", sortProperty: null },
  ],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: myCompaniesPod,
  },
  loading: false,
  disabled: false,
  hasFilters: true,
  usePagination: true,
  hasByPodLeaderFilter: true,
  hasPods: true,
  downloadingProgress: 0,
  enableDownload: true,
  mobileHidden: true,
  hasBarFilter: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const elevationTab: TabData<Company> = {
  route: "elevations",
  ordinal: 4,
  label: "Elevations",
  tableClass: "elevation-table",
  columns: [
    // { label: "Score", tooltip: "score", sortProperty: "rank" },
    { label: "Company", sortProperty: "name" },
    { label: "Coverage", sortProperty: null },
    { label: "Investors", sortProperty: null },
    // Removed due to deal cloud conflict
    // { label: "FTE", sortProperty: "fullTimeEquivalent" },
    // { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
    // // { label: "Ownership", sortProperty: "companyType" },
    // { label: "PIC", sortProperty: "paidInCapital" },
    // { label: "Latest round", sortProperty: null },
    // { label: "Latest Round Date", sortProperty: null },
    // { label: "Investors", sortProperty: null },

    // { label: "Stage", sortProperty: null },
    // { label: "Coverage", sortProperty: null },
    { label: "Financials", sortProperty: null },
    // { label: "Last Interaction", sortProperty: null },
    // { label: "Contacts", sortProperty: null },
    // { label: "Status", sortProperty: null },
    // { label: "Activity", sortProperty: null },
    { label: "Outreach Owner", sortProperty: null },
    { label: "Next Steps", sortProperty: null },
  ],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: { sortDirection: "asc", sortProperty: "name", pageNumber: "1", pageSize: "100" },
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  hasByPodLeaderFilter: false,
  hasFilterByTagFilter: true,
  hasFilterByMyElevations: true,
  hasFilterLabels: true,
  hasPods: false,
  downloadingProgress: 0,
  mobileHidden: true,
  hasBarFilter: false,
  hasScroll: true,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const priority: TabData<Company> = {
  route: "priority",
  ordinal: 3,
  label: "Priority",
  tableClass: "priority-table",
  columns: [
    { label: "Company", sortProperty: "name" },
    { label: "FTE", sortProperty: "fullTimeEquivalent" },
    { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
    // { label: "Ownership", sortProperty: "companyType" },
    { label: "PIC", sortProperty: "paidInCapital" },
    // { label: "Latest round", sortProperty: null },
    // { label: "Latest Round Date", sortProperty: null },
    // { label: "Investors", sortProperty: null },
    { label: "Stage", sortProperty: null },
    // { label: "Coverage", sortProperty: null },
    { label: "Last Interaction", sortProperty: null },
    // { label: "Financials", sortProperty: null },
    // { label: "Contacts", sortProperty: null },
    { label: "Status", sortProperty: null },
    // { label: "Activity", sortProperty: null },
    // { label: "Outreach Owner", sortProperty: null },
    // { label: "Next Steps", sortProperty: null },
    { label: "Days in priority", sortProperty: null },
  ],
  // { label: "Company", sortProperty: "name" },
  // { label: "FTE", sortProperty: "fullTimeEquivalent" },
  // { label: "Growth%", sortProperty: "fullTimeEquivalentGrowth" },
  // { label: "Ownership", sortProperty: null },
  // { label: "PIC", sortProperty: "paidInCapital" },
  // { label: "Latest round", sortProperty: null },
  // { label: "Latest Round Date", sortProperty: null },
  // { label: "Investors", sortProperty: null },
  // { label: "Stage", sortProperty: null },
  // { label: "Coverage", sortProperty: null },
  // { label: "Last Interaction", sortProperty: null },
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  hasByPodLeaderFilter: false,
  hasPods: false,
  downloadingProgress: 0,
  mobileHidden: true,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

let icColumnsVotingHistory: TableHeader[] = [
  // { label: "Score", tooltip: "score", sortProperty: null },
  { label: "Company", sortProperty: null },
  { label: "Check Size", sortProperty: null },
  { label: "IC Ask", sortProperty: null },
  { label: "Deal Team", sortProperty: null },
  { label: "IC Date", sortProperty: "votingDate" },
  { label: "Comments", sortProperty: null },
  { label: "Summary", sortProperty: null },
];

let icColumnsActiveVoting: TableHeader[] = [
  // { label: "Score", tooltip: "score", sortProperty: null },
  { label: "Company", sortProperty: null },
  { label: "Check Size", sortProperty: null },
  { label: "IC Ask", sortProperty: null },
  { label: "Deal Team", sortProperty: null },
  { label: "IC Date", sortProperty: "votingDate" },
  { label: "Comments", sortProperty: null },
  { label: "Voting", sortProperty: null },
];

const icVoting: TabData<Company> = {
  route: "ic-voting",
  ordinal: 5,
  label: "IC",
  disabled: false,
  tableClass: "voting-table",
  downloadingProgress: 0,
  children: [
    {
      route: "",
      label: "Active Voting",
      tableClass: "voting-table",
      columns: icColumnsActiveVoting,
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: true,
      hasByPodLeaderFilter: false,
      hasPods: false,
      downloadingProgress: 0,
      showMenu: true,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
      hasFilterLabels: true,
    },
    {
      route: "history",
      label: "Voting History",
      tableClass: "voting-table",
      columns: icColumnsVotingHistory,
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: true,
      hasByPodLeaderFilter: false,
      hasPods: false,
      accessRoles: ["admin", "votercommittee", "investmentcommitteeadmin"],
      downloadingProgress: 0,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
      hasFilterLabels: true,
    },
    {
      route: "combined",
      label: "Complete Voting History",
      tableClass: "voting-table",
      columns: icColumnsVotingHistory,
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: true,
      hasByPodLeaderFilter: false,
      hasPods: false,
      accessRoles: ["admin", "votercommittee", "investmentcommitteeadmin"],
      downloadingProgress: 0,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
      hasFilterLabels: true,
      disableFilterDelete: true,
      hidden: true,
    },
  ],
  mobileHidden: true,
  hasBarFilter: false,
  hasFilterLabels: true,
  disableFilterDelete: true,
  hasScroll: true,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const dataQuality: TabData<Company> = {
  route: "data-quality",
  ordinal: 2,
  label: "Data Quality Queue",
  tableClass: "data-quality",
  columns: [
    // { label: "Score", sortProperty: null },
    { label: "Company", sortProperty: null },
    { label: "FTE", sortProperty: null },
    { label: "Growth%", sortProperty: null },
    { label: "PIC", sortProperty: null },
    // { label: "Latest round", sortProperty: null },
    // { label: "Latest Round Date", sortProperty: null },
    // { label: "Investors", sortProperty: null },
    { label: "Stage", sortProperty: null },
    { label: "Coverage", sortProperty: null },
    { label: "Last Interaction", sortProperty: null },
    { label: "Status", sortProperty: null },
    // { label: "Activity", sortProperty: null },
  ],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  hasByPodLeaderFilter: false,
  hasPods: false,
  downloadingProgress: 0,
  enableDownload: true,
  mobileHidden: true,
  hasBarFilter: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

// const aiSummary: TabData<Company> = {
//   route: "ai-summary",
//   ordinal: 7,
//   label: "AI Prep (alpha)",
//   tableClass: "ai-summary",
//   type: TabType.AiSummary,
//   columns: [],
//   data: [],
//   total: 0,
//   currentTotal: 0,
//   mainFilterRaw: {},
//   gridFilter: {
//     activePod: customPod,
//   },
//   loading: false,
//   disabled: false,
//   hasFilters: false,
//   usePagination: true,
//   downloadingProgress: 0,
//   mobileHidden: true,
// };

const weeklyUpdate: TabData<Company> = {
  route: "v2/weekly-update",
  ordinal: 7,
  label: "Updates",
  tableClass: "ai-summary",
  // type: TabType.PortfolioUpdate,
  columns: [],
  children: [
    {
      route: "",
      label: "Portfolio",
      tableClass: "ai-summary",
      type: TabType.PortfolioUpdate,
      columns: [],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: true,
      downloadingProgress: 0,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
    },
    {
      route: "prospect-update",
      label: "Prospects",
      tableClass: "ai-summary",
      type: TabType.ProspectUpdate,
      columns: [],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: {},
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: true,
      downloadingProgress: 0,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
    },
  ],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  downloadingProgress: 0,
  mobileHidden: true,
  hasBarFilter: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
  hasScroll: false,
};

const IRChildTab: TabData<Company> = {
  route: "ir-update",
  label: "IR",
  tableClass: "ai-summary",
  type: TabType.IRUpdate,
  columns: [],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  downloadingProgress: 0,
  hasBarFilter: false,
  hasStageBar: false,
  hasInteractionsBar: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const newsletterChildTab: TabData<Company> = {
  route: "newsletter-update",
  label: "Daily News",
  tableClass: "ai-summary",
  type: TabType.NewsletterUpdate,
  columns: [],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: true,
  downloadingProgress: 0,
  hasBarFilter: false,
  hasStageBar: false,
  hasInteractionsBar: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

// const portfolioUpdate: TabData<Company> = {
//   route: "portfolio-update",
//   label: "Portfolio",
//   tableClass: "ai-summary",
//   type: TabType.PortfolioUpdate,
//   columns: [],
//   data: [],
//   total: 0,
//   currentTotal: 0,
//   mainFilterRaw: {},
//   gridFilter: {
//     activePod: customPod,
//   },
//   loading: false,
//   disabled: false,
//   hasFilters: false,
//   usePagination: true,
//   downloadingProgress: 0,
// };

// const prospectUpdate: TabData<Company> = {
//   route: "prospect-update",
//   label: "Prospects",
//   tableClass: "ai-summary",
//   type: TabType.ProspectUpdate,
//   columns: [],
//   data: [],
//   total: 0,
//   currentTotal: 0,
//   mainFilterRaw: {},
//   gridFilter: {
//     activePod: customPod,
//   },
//   loading: false,
//   disabled: false,
//   hasFilters: false,
//   usePagination: true,
//   downloadingProgress: 0,
// };

const savedSearch: TabData<Company> = {
  route: "saved-search",
  ordinal: 10,
  label: "Saved Search",
  tableClass: "saved-search",
  type: TabType.SavedSearch,
  hidden: true,
  mobileHidden: true,
  columns: [
    { label: "Search Filter Name", sortProperty: null },
    { label: "Saved Date", sortProperty: null },
    { label: "Number Of Filters", sortProperty: null },
  ],
  data: [],
  total: 0,
  currentTotal: 0,
  mainFilterRaw: { sortDirection: "desc", sortProperty: "createDate" },
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  usePagination: true,
  downloadingProgress: 0,
  hasBarFilter: false,
  hasStageBar: false,
  hasInteractionsBar: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const adminPanel: TabData<Company> = {
  route: "admin-panel",
  ordinal: 9,
  label: "Admin",
  disabled: false,
  tableClass: "saved-search",
  downloadingProgress: 0,
  children: [
    {
      route: "",
      type: TabType.UserPanel,
      label: "Users",
      tableClass: "user-table",
      columns: [
        { label: "Picture", sortProperty: null },
        { label: "Name", sortProperty: null },
        { label: "Email", sortProperty: null },
        { label: "DealCloud ID", sortProperty: null },
        { label: "Role(s)", sortProperty: null },
        { label: "Pod(s)", sortProperty: null },
        { label: "Group(s)", sortProperty: null },
      ],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: { sortDirection: "desc", sortProperty: "createDate" },
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: false,
      hasByPodLeaderFilter: false,
      hasPods: false,
      downloadingProgress: 0,
      showMenu: true,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
    },
    {
      route: "alert",
      type: TabType.AlertPanel,
      label: "Alerts",
      tableClass: "saved-search",
      columns: [
        { label: "Cron Job Name", sortProperty: null },
        { label: "Frequency", sortProperty: null },
        { label: "Recipients", sortProperty: null },
        { label: "Last Execution", sortProperty: null },
      ],
      data: [],
      total: 0,
      currentTotal: 0,
      mainFilterRaw: { sortDirection: "desc", sortProperty: "createDate" },
      gridFilter: {
        activePod: customPod,
      },
      loading: false,
      disabled: false,
      hasFilters: false,
      usePagination: false,
      hasByPodLeaderFilter: false,
      hasPods: false,
      downloadingProgress: 0,
      showMenu: true,
      hasBarFilter: false,
      hasStageBar: false,
      hasInteractionsBar: false,
      hasHeatMap: false,
      hasInteractionsChart: false,
      hasChartNavigator: false,
    },
  ],
  mobileHidden: true,
  hasBarFilter: false,
  hasStageBar: false,
  hasInteractionsBar: false,
  hasScroll: true,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const deepChat: TabData<Company> = {
  route: "chatbot",
  ordinal: 6,
  label: "Tech Analyst",
  type: TabType.DeepChat,
  columns: [],
  data: [],
  mainFilterRaw: {},
  gridFilter: {
    activePod: customPod,
  },
  loading: false,
  disabled: false,
  hasFilters: false,
  usePagination: false,
  downloadingProgress: 0,
  hasBarFilter: false,
  hasHeatMap: false,
  hasInteractionsChart: false,
  hasChartNavigator: false,
};

const rolePermissions: { [key: string]: TabData<Company>[] } = {
  admin: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, dashboardTab, adminPanel, deepChat],
  inventmentcommitteadmin: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, deepChat],
  investor: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, deepChat],
  votercommittee: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, deepChat],
  analyst: [companiesTab, /*dataQuality,*/ savedSearch],
  investorrelation: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, deepChat],
  newslettermoderator: [companiesTab, elevationTab, icVoting, weeklyUpdate, savedSearch, deepChat],
};

function getUserTabs(
  rolePermissions: { [key: string]: TabData<Company>[] },
  userRoles: UserRole[],
): TabData<Company>[] {
  const unionOfRolePermissions = userRoles.reduce((accumulator: TabData<Company>[], currentRole: string) => {
    const stringsForRole = rolePermissions[currentRole] || [];
    return [...accumulator, ...stringsForRole];
  }, []);
  return Array.from(new Set(unionOfRolePermissions)).sort((a, b) => a.ordinal! - b.ordinal!);
}

export function getTabs(role: UserRole, userRoles: UserRole[]): TabData<Company>[] {
  if (userRoles.includes("investmentcommitteeadmin") && role === "investmentcommitteeadmin") {
    //icColumnsActiveVoting.push({ label: "Summary", sortProperty: null });
  }

  if (userRoles.includes("investorrelation") && role === "investorrelation") {
    weeklyUpdate.children?.push(IRChildTab);
  }

  if (userRoles.includes("newslettermoderator") && role === "newslettermoderator") {
    weeklyUpdate.children?.push(newsletterChildTab);
  }

  return getUserTabs(rolePermissions, userRoles);
}
