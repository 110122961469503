<div class="mobile-cards">
    <ng-container *ngIf="data.length > 0">
        <div class="card" *ngFor="let row of data">
            <!-- Header Section -->
            <div class="card-header">
                <h2>
                    {{ row.title }}
                    <i *ngIf="row.description" class="pi pi-info-circle info-icon" pTooltip="{{ row.description }}"
                        tooltipPosition="top" appendTo="body" [tooltipEvent]="'focus'" tabindex="0"></i>
                </h2>
                <p *ngIf="row.subtitle" class="card-subtitle">{{ row.subtitle }}</p>
            </div>

            <!-- Financial Section -->
            <div class="card-details">
                <div class="detail-item" *ngFor="let detailItem of row.details">
                    <strong>{{detailItem.title}}:</strong>
                    <span [innerHTML]="detailItem.value"></span>
                </div>
            </div>

            <!-- Footer Section -->
            <div class="card-footer" *ngIf="row.route">
                <a class="company-link" [routerLink]="['/m' + row.route]">View Details</a>
            </div>
        </div>
    </ng-container>

    <div *ngIf="loading" class="card">
        <bpc-dynamic-skeleton-loader [numberOfSkeletons]="10" />
    </div>

     <!-- No Records Card -->
     <div *ngIf="!loading && data.length === 0" class="card no-record-card">
        <div class="card-header">
            <h2>No Records Found</h2>
        </div>
        <div class="card-details">
            <p>There are currently no records to display. Please check back later or refine your filters.</p>
        </div>
    </div>
</div>
