<bpc-top-navigation *ngIf="!isExtension && !isMobile"></bpc-top-navigation>
<bpc-mobile-navigation *ngIf="!isExtension && isMobile"></bpc-mobile-navigation>

<bpc-web-tabs></bpc-web-tabs>
<!-- Mobile Tab Data ( show only in mobile devices) -->
<!-- <bpc-mobile-tabs *ngIf="isMobile"></bpc-mobile-tabs> -->

<bpc-generic-modal></bpc-generic-modal>

<p-toast position="bottom-center" key="home" [preventOpenDuplicates]="true"></p-toast>
