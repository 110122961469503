import * as Highcharts from "highcharts";

export const MOST_ACTIVE_EMPLOYEES_CHART_OPTIONS = (
  categories: string[],
  emailData: number[],
  notesData: number[],
  callData: number[],
  meetingData: number[],
  videoCallData: number[],
): Highcharts.Options => ({
  chart: {
    type: "bar",
    backgroundColor: "transparent",
  },
  title: {
    text: "",
    align: "left",
    style: { color: "black" },
  },
  xAxis: {
    categories,
    labels: { style: { color: "black" } },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Count",
      style: { color: "black" },
    },
    stackLabels: {
      enabled: true,
      style: {
        color: "black",
      },
    },
    labels: { style: { color: "black" } },
  },
  legend: {
    enabled: true,
    itemStyle: { color: "black" },
  },
  tooltip: {
    shared: true,
  },
  plotOptions: {
    bar: {
      stacking: "normal",
      pointWidth: 15,
    },
  },
  noData: {
    position: {
      // Adjust position
      align: "center",
      verticalAlign: "middle",
    },
    style: {
      fontSize: "16px",
      color: "#666666",
    },
    useHTML: true, // Use HTML for custom messages
  },
  series: [
    {
      type: "bar",
      name: "Emails",
      data: emailData,
      color: "#F89D16",
    },
    {
      type: "bar",
      name: "Notes",
      data: notesData,
      color: "#47D8BF",
    },
    {
      type: "bar",
      name: "Phone",
      data: callData,
      color: "#9747FF",
    },
    {
      type: "bar",
      name: "Meetings",
      data: meetingData,
      color: "#095A70",
    },
    {
      type: "bar",
      name: "Video Calls",
      data: videoCallData,
      color: "#E34624",
    },
  ],
});

export const DEFAULT_BAR_CHART_OPTIONS: Highcharts.Options = {
  chart: { type: "bar", backgroundColor: "transparent" },
  title: { text: "Dummy Chart", align: "center", style: { color: "gray" } },
  xAxis: {
    categories: ["Category 1", "Category 2", "Category 3"],
    labels: { style: { color: "gray" } },
  },
  yAxis: {
    min: 0,
    title: { text: "Count", style: { color: "gray" } },
    labels: { style: { color: "gray" } },
  },
  legend: { enabled: true, itemStyle: { color: "gray" } },
  tooltip: { shared: true },
  plotOptions: { bar: { stacking: "normal", pointWidth: 15 } },
  series: [
    { type: "bar", name: "Series 1", data: [5, 3, 4], color: "#999" },
    { type: "bar", name: "Series 2", data: [2, 2, 3], color: "#ccc" },
    { type: "bar", name: "Series 3", data: [3, 4, 4], color: "#bbb" },
  ],
};

export const DEFAULT_PIE_CHART_OPTIONS = (seriesOptionsType: Highcharts.SeriesOptionsType[]): Highcharts.Options => ({
  chart: { type: "pie", backgroundColor: "transparent" },
  title: { text: "", align: "left", style: { fontSize: "1em", color: "#fff" } },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: { enabled: true, color: "#fff", style: { fontSize: "1em" } },
    },
  },
  series: seriesOptionsType,
  credits: { enabled: false },
});
