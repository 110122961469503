<ng-container>
  <div class="grid grid-no-tb-gutter">
    <div class="col">
      <div class="tags-wrap-labels card-container" *ngIf="tabIndex!=0">
        <span *ngFor="let fl of mainFilterRaw | filterLabels | slice : 0 : maxTagsCount; trackBy: trackBy" class="tags">
          <span [innerHtml]="fl.value"></span>
          <i *ngIf="!disableFilterDelete" (click)="deleteLabel(fl)" class="pi pi-times"></i>
        </span>
      </div>
    </div>
    <div class="col flex flex-w-auto">
      <div class="buttons-wrap-labels">
        <button
          pButton
          id="{{ 'show-more-tags-' + tabIndex }}"
          title="Show More"
          (click)="showMore()"
          *ngIf="tabIndex!=0 && hasFilters && (mainFilterRaw | filterLabels).length > maxTagsCount"
          class="mr-2"
        >
          Show more
        </button>

        <a
          class="lbl-clear-filter mgr-0"
          title="Clear All"
          (click)="clearAllFilters()"
          *ngIf="hasFilters && (mainFilterRaw | filterLabels).length > 0"
          >Clear All</a
        >
      </div>
    </div>
  </div>

  <!-- <div *ngIf="isMobile" class="overflow-x-auto filter-badges">
    <div class="flex">
      <span *ngFor="let fl of (mainFilterRaw | filterLabels); trackBy: trackBy"
        class="text-sm rounded bg-gray-300 px-2 py-1.5 flex items-center text-black font-semibold mr-2 flex-[0_0_auto]">
        <span>{{ fl.value }}</span>
        <i (click)="deleteLabel(fl)" class="ml-1.5 pi pi-times text-xs cursor-pointer"></i>
      </span>
    </div>
  </div> -->
</ng-container>

<p-dialog contentStyleClass="w-500" (onHide)="resetModalItems()" [(visible)]="displayFilterLabelModal">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Filtered Tags</span>
    <i class="pi pi-times cursor-pointer" (click)="displayFilterLabelModal = false"></i>
  </ng-template>
  <div class="tags-wrap-modal">
    <span *ngFor="let fl of mainFilterRaw | filterLabels; trackBy: trackBy" class="tags">
      <span [innerHtml]="fl.value"></span>
      <i (click)="deleteLabel(fl)" class="pi pi-times"></i>
    </span>
  </div>
</p-dialog>
