import { Component } from "@angular/core";
import { CompanyV2Service } from "../../core/services/company-v2.service";
import { DCCompanyType } from "../../services/companies/companies.service";
import { CardItemMapping } from "../../core/components/dynamic-cards/dynamic-cards.component";
import { ColumnMapping, DataFilter } from "../../core/components/dynamic-table/dynamic-table.component";
import { CompaniesSearchService } from "../../services/companies-search/companies-search.service";
import { HorizontalBarType } from "../../core/components/generic-listing/generic-listing.component";

@Component({
  selector: "bpc-interactions",
  templateUrl: "./interactions.component.html",
  styleUrl: "./interactions.component.scss",
})
export class InteractionsComponent {
  HorizontalBarType = HorizontalBarType;
  isLoading = false;

  interactions: CardItemMapping[] | any = [];
  interactionColumns: ColumnMapping[] = [
    { header: "Company", showHeader: false, mappedTo: "name", case: "company", sortable: true },
    { header: "Coverage", showHeader: true, mappedTo: "coverageUsers" },
    { header: "Stage", showHeader: true, mappedTo: "stage" },
    { header: "Last Interaction", showHeader: true, mappedTo: "lastInteraction" },
  ];

  interactionFilter: DataFilter = {
    pageNumber: 1,
    pageSize: 100,
    sortDirection: "desc",
    sortProperty: "latestinteraction",
    dcCompanyTypes: DCCompanyType.Target,
  };
  totalCount = 0;

  constructor(private companyV2Service: CompanyV2Service, private companiesSearchService: CompaniesSearchService) {}

  ngOnInit(): void {
    this.fetchInteractions();
  }

  // Fetch companies listing data
  fetchInteractions(refreshData = true) {
    if (this.isLoading) {
      return; // Prevent multiple simultaneous calls
    }

    this.isLoading = true;

    const myFilter: any = this.interactionFilter;

    this.companiesSearchService.getActiveCompaniesByInteractionsV2(myFilter).subscribe({
      next: (response) => {
        if (response?.data?.data) {
          const newInteractions = this.companyV2Service.transformInteractionData(response.data.data);
          if (refreshData) {
            this.interactions = [...newInteractions];
          } else this.interactions.push(...newInteractions);

          this.totalCount = response.data.totalCount;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error("Error fetching companies:", error);
        this.isLoading = false;
        if (this.interactionFilter.pageNumber)
          this.interactionFilter.pageNumber = this.interactionFilter.pageNumber - 1;
      },
      complete: () => {
        console.log("Fetch companies completed");
      },
    });
  }

  loadMoreData() {
    if (this.interactionFilter.pageNumber) {
      this.interactionFilter.pageNumber = this.interactionFilter.pageNumber + 1;
      this.fetchInteractions(false);
    }
  }

  onPageChange(pageNumber: number) {
    this.interactionFilter.pageNumber = pageNumber;
    this.fetchInteractions();
  }

  onPageSizeChange(pageSize: number) {
    this.interactionFilter.pageNumber = 1;
    this.interactionFilter.pageSize = pageSize;
    this.fetchInteractions();
  }

  onFilterChange(filters: any) {
    this.interactions = [];
    this.interactionFilter = { ...this.interactionFilter, ...filters };
    this.interactionFilter.pageNumber = 1;
    this.fetchInteractions();
  }
}
