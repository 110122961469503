import { Component } from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";

enum UpdateTypes {
  Portfolio = "Portfolio",
  Prospect = "Prospects",
  IR = "IR",
  Daily = "Daily News",
}

@Component({
  selector: "bpc-portfolio-update-v2",
  templateUrl: "./portfolio-update-v2.component.html",
  styleUrl: "./portfolio-update-v2.component.scss",
})
export class PortfolioUpdateV2Component {
  UpdateTypesEnum = UpdateTypes;
  updateTypes = Object.values(UpdateTypes);
  selectedUpdateType: UpdateTypes = UpdateTypes.Portfolio;

  get hasInvestorRelationsRole() {
    return this.authService.userRoles.includes("investorrelation");
  }

  get hasNewsletterModeratorRole() {
    return this.authService.userRoles.includes("newslettermoderator");
  }

  get accessControl() {
    return {
      [UpdateTypes.Portfolio]: true, // Always visible
      [UpdateTypes.Prospect]: true, // Always visible
      [UpdateTypes.IR]: this.authService.userRoles.includes("investorrelation"), // Check role
      [UpdateTypes.Daily]: this.authService.userRoles.includes("newslettermoderator"), // Check role
    };
  }

  constructor(private authService: AuthService) {}

  onSelection(type: UpdateTypes) {
    this.selectedUpdateType = type;
  }
}
