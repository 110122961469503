<p-dialog class="deal-drop-modal" header="Deal drop reasons" [closable]="true"  [modal]="true" [(visible)]="showModal"
    [style]="{ width: '95%', maxHeight: '50%' }" (onHide)="onDialogHide()">
    <ng-template pTemplate="header">
        <div class="dialog-header">
            Deal Drop Reasons
        </div>
        <button class="close-button" (click)="onDialogHide()">
            <i class="pi pi-times"></i>
        </button>
    </ng-template>

    <div class="title mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="12" [attr.fill]="dealDropReason.color" />
        </svg>
        <span>
            {{dealDropReason.reason}}
        </span>
    </div>
    <div class="gap-4 mb-4">
        <bpc-dynamic-table [data]="companies" [columns]="companiesColumns" />
    </div>
</p-dialog>