import { Injectable } from '@angular/core';
import { User } from '@core/models/user';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

type EventProperties = {
  [key: string]: any;
};

export enum SocialLinkType {
  www = 'Domain',
  dealCloud = 'Deal Cloud',
  sourceScrub = 'SourceScrub',
  linkedIn = 'LinkedIn',
  mixRank = 'MixRank',
  facebook = 'Facebook',
  twitter = 'Twitter',
  crunchbase = 'Crunchbase',
  g2 = 'G2',
  grata = "Grata"
}

export enum TopBarButtonActionType {
  joinCoverage = 'Join Coverage',
  enrichData = 'Enrich Data',
  removeFromJarvis = 'Remove from Jarvis',
  addToPriority = 'Add to Priority',
  changeStatus = 'Change Status',
  editCoverage = 'Edit Coverage',
  removeFromPriority = 'Remove from Priority',
  actionTaken = 'Action Taken',
  closeCompanyDetails = 'Close Company Details',
  extendPriority = 'Extend Priority',
  icVoting = 'IC Voting',
  flagForICVoting = 'Flag for IC Voting',
  removeFromIC = 'Remove from IC',
  archiveCompany = 'Archive Company',
  editICAsk = 'Edit IC Ask',
  leaveCoverage = 'Leave Coverage',
  markAsActive = 'Mark as Active',
  createOrEditNotificationTrigger = 'Create or Edit Notification Trigger',
  editICDateAdded = 'Edit IC Date Added',
  editOutReachOwner = 'Edit OutReach Owner',
  editNextStep = 'Edit Next Step',
  removeSavedSearch = 'Remove Saved Search',
}

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    this.mixpanelInit();
  }

  mixpanelInit() {
    mixpanel.init(environment.mixpanelToken, {
      debug: environment.mixpanelDebug || false,
      persistence: 'localStorage'
    });
  };

  trackPageView() {
    mixpanel.track_pageview();
  }

  trackEvent(eventName: string, eventProperties?: EventProperties) {
    mixpanel.track(eventName, eventProperties);
  }

  onLogin(user: User, isSSOLogin: boolean = false) {
    mixpanel.identify(user.id.toString());
    mixpanel.people.set({
      id: user.id,
      name: user.name,
      role: user.role,
      email: user.email,
      isSSOLogin: isSSOLogin
    });
    mixpanel.track('User Logged In', { 'user_role': user.role });
  }

  onLogout() {
    mixpanel.reset();
  }

}
