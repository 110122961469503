import { Injectable } from '@angular/core';
import { TabUtilsService } from '../tab-utils.service';
import { NavigationEnd, Router } from '@angular/router';
import { TabRoute, TabType } from '@core/static/tabs';
import { AdminTab, AnalystTab, InvestorTab, Tab } from '@core/models/types';
import { Nullable } from 'primeng/ts-helpers';
import { AuthService } from '@core/auth/auth.service';
import { filter } from 'rxjs';
import { MixpanelService } from '@core/services/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  currentTabIndex!: Tab;
  currentChildIndex!: Nullable<number>;
  tabType!: TabType; 

  constructor(
    private tabUtilsService: TabUtilsService,
    public router: Router,
    private authService: AuthService,
    private mixpanelService: MixpanelService
  ) {
    this.subscribeToTabSwitch();
  }

  get tabs() {
    return this.tabUtilsService.tabs;
  } 

  onAvailableUserTabs() {
    return this.tabUtilsService.getAvailableUserTabs(this.tabs);
  }

  navigateToTab(tab: Tab) {
    let queryParams = { ...this.tabUtilsService.getAvailableUserTabs(this.tabs)[tab].mainFilterRaw };
    this.mixpanelService.trackEvent('Navigate_To_Tab', { tab: InvestorTab[tab] });
    this.router.navigate([this.tabUtilsService.getAvailableUserTabs(this.tabs)[tab].route], { queryParams });
  }

  initializeDataLoadingStream(url: string){
    console.log(url)
    switch (url) {
      // v1
      case "":
      case "/companies":
        this.currentTabIndex = InvestorTab.COMPANIES; 
        this.currentChildIndex = 0;
        break;
  
      case "/companies/interactions":
        this.currentTabIndex = InvestorTab.COMPANIES; 
        this.currentChildIndex = 1;
        break;
  
      case "/elevations":
        this.currentTabIndex = InvestorTab.ELEVATIONS;
        this.currentChildIndex = null;
        break;
  
      case "/weekly-update":
        this.currentTabIndex = InvestorTab.WEEKLY_UPDATE;
        this.currentChildIndex = 0;
        this.tabType = TabType.PortfolioUpdate;
        break;
  
      case "/ic-voting":
        this.currentTabIndex = InvestorTab.VOTING;
        this.currentChildIndex = 0;
        break;
      
      case "/weekly-update/prospect-update":
        this.currentTabIndex = InvestorTab.WEEKLY_UPDATE;
        this.currentChildIndex = 1;
        this.tabType = TabType.ProspectUpdate;
        break;
  
      case "/weekly-update/ir-update":
        this.currentTabIndex = InvestorTab.WEEKLY_UPDATE;
        this.currentChildIndex = 2;
        this.tabType = TabType.IRUpdate;
        break;
  
      case "/weekly-update/newsletter-update":
        this.currentTabIndex = InvestorTab.WEEKLY_UPDATE;
        if (this.authService.isInvestorRelation) {
          this.currentChildIndex = 3;
        } else {
          this.currentChildIndex = 2;
        }
        this.tabType = TabType.NewsletterUpdate;
        break;
  
      case "/ic-voting/history":
        this.currentTabIndex = InvestorTab.VOTING;
        this.currentChildIndex = 1;
        break;
      case "/admin-panel":
        this.currentTabIndex = InvestorTab.ADMIN_PANEL;
        this.currentChildIndex = 0;
        break;
      case "/admin-panel/alert":
        this.currentTabIndex = InvestorTab.ADMIN_PANEL;
        this.currentChildIndex = 1;
        break;
      case "/chatbot":
        this.currentTabIndex = InvestorTab.DEEP_CHAT;
        this.currentChildIndex = null;
        break;

      // v2
      case "/v2/dashboard":
        this.currentTabIndex = InvestorTab.DASHBOARD; 
        this.tabType = TabType.Dashboard;
        break;
      case "/v2/weekly-update":
        this.currentTabIndex = InvestorTab.WEEKLY_UPDATE; 
        break;
      }
  }

  private subscribeToTabSwitch() {
    this.router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd) // Type narrowing
    )
    .subscribe((event: NavigationEnd) => {
      this.initializeDataLoadingStream(event.urlAfterRedirects);
    });
  }
}
