import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, filter, forkJoin, map, switchMap } from "rxjs";
import { MessageService } from "primeng/api";
import { ApiResponse, BaseApiService } from "../../api/base";
import { BasicCompany, Company } from "@models/company";
import { MainFilterRaw } from "@models/main-filter-view";
import { AuthService } from "@core/auth/auth.service";
import { Tag } from "@models/tag";
import { CONVERT_TO_ARRAY_ITEMS } from "@core/services/filter-helper.service";
import { myInteractionsPod } from "@core/static/tabs";

export interface PagerData<T> {
  data: T[];
  count: number;
  totalCount: number;
  totalCountByAssignee: number;
}

export interface BasePagingFilter {
  pageNumber?: string;
  pageSize?: string;
}

export interface BaseSortingFilter {
  sortProperty?: string;
  sortDirection?: "asc" | "desc" | string;
  thenSortProperty?: string;
  thenSortDirection?: "asc" | "desc" | string;
}

export interface CompaniesFilter extends BasePagingFilter, BaseSortingFilter, MainFilterRaw {}

interface Global {
  companies: BasicCompany[];
  lists: Tag[];
}
@Injectable({
  providedIn: "root",
})
export class CompaniesSearchService extends BaseApiService<PagerData<Company>> {
  transformBooleanAndNonArray(filterParams: any): any {
    let transformedFilterParams = { ...filterParams };
    if (transformedFilterParams && transformedFilterParams.filtersString?.length > 0) {
      const keywordFilters = JSON.parse(transformedFilterParams.filtersString);
      delete transformedFilterParams.filtersString;
      transformedFilterParams.keywordsFilter = {};
      if (keywordFilters.length > 0) {
        transformedFilterParams.keywordsFilter.keywords = keywordFilters;
      }
    }
    return this.covertBoolAndStrings(transformedFilterParams);
  }

  covertBoolAndStrings(filterParams: any): any {
    for (const key in filterParams) {
      if (key === "keywordsFilter") {
        continue;
      }
      if (Array.isArray(filterParams[key]) && filterParams[key].length > 0) {
        filterParams[key] = this.covertBoolAndStrings(filterParams[key]);
      }
      if (!Array.isArray(filterParams[key]) && typeof filterParams[key] === "object" && filterParams[key] !== null) {
        filterParams[key] = this.covertBoolAndStrings(filterParams[key]);
      } else if (!isNaN(filterParams[key])) {
        filterParams[key] = Number(filterParams[key]);
      } else if (filterParams[key] === "false") {
        filterParams[key] = false;
      } else if (filterParams[key] === "true") {
        filterParams[key] = true;
      }
      if (!!CONVERT_TO_ARRAY_ITEMS[key]) {
        if (typeof filterParams[key] === "string" || typeof filterParams[key] === "number") {
          filterParams[key] = [filterParams[key]];
        }
      }
    }
    return filterParams;
  }
  constructor(authService: AuthService, router: Router, messageService: MessageService) {
    super("/companies/search", authService, router, messageService);
  }

  getActiveCompanies(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/active", null, transformedFilter);
  }

  getActiveGeoLocationCompanies(filter: CompaniesFilter): Observable<ApiResponse<any>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/active/geo-location", null, transformedFilter);
  }

  getActiveCompaniesByInteractions(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    if (!this.authService.isPartner) {
      transformedFilter.interactionInitiatorsInclude = [this.authService.userId];
    }
    return this.post("/active/by-interactions", null, transformedFilter);
  }

  getActiveCompaniesByInteractionsV2(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter: any = filter;
    if (!this.authService.isPartner) {
      transformedFilter.interactionInitiatorsInclude = [this.authService.userId];
    }
    if (filter.assigneeRole) {
      // convert assigneeRole to number
      transformedFilter.assigneeRole = +filter.assigneeRole;
    }
    return this.post("/active/by-interactions", null, transformedFilter);
  }

  getElevations(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/elevation", null, transformedFilter);
  }

  getPipelineCompanies(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/pipeline", null, transformedFilter);
  }

  getPriorityCompanies(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/priority", null, transformedFilter);
  }

  getICVotingCompanies(filter?: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    return this.post("/reviewed", null, transformedFilter);
  }

  getICVotingHistoryCompanies(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    if (typeof transformedFilter.userIds === "number") {
      transformedFilter.userIds = [transformedFilter.userIds];
    }
    return this.post("/reviewed/archive", null, transformedFilter);
  }

  getCombinedICVotingCompaniesSequentially(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    const transformedFilter = this.transformBooleanAndNonArray(filter);
    if (typeof transformedFilter.userIds === "number") {
      transformedFilter.userIds = [transformedFilter.userIds];
    }
    return this.post("/reviewed", null, transformedFilter).pipe(
      switchMap((votingCompanies) =>
        this.post("/reviewed/archive", null, transformedFilter).pipe(
          map((votingHistoryCompanies) => {
            // Safeguard to ensure `data` exists in both responses
            const votingCompaniesData = votingCompanies?.data ?? {
              data: [],
              count: 0,
              totalCount: 0,
              totalCountByAssignee: 0,
            };
            const votingHistoryCompaniesData = votingHistoryCompanies?.data ?? {
              data: [],
              count: 0,
              totalCount: 0,
              totalCountByAssignee: 0,
            };

            // Combine the data from both responses
            const combinedData: PagerData<Company> = {
              data: [...(votingCompaniesData.data || []), ...(votingHistoryCompaniesData.data || [])],
              count: (votingCompaniesData.count || 0) + (votingHistoryCompaniesData.count || 0),
              totalCount: (votingCompaniesData.totalCount || 0) + (votingHistoryCompaniesData.totalCount || 0),
              totalCountByAssignee:
                (votingCompaniesData.totalCountByAssignee || 0) +
                (votingHistoryCompaniesData.totalCountByAssignee || 0),
            };

            // Return a single ApiResponse object
            return { ...votingCompanies, data: combinedData };
          }),
        ),
      ),
    );
  }

  getEnrichedCompanies(filter: CompaniesFilter): Observable<ApiResponse<PagerData<Company>>> {
    return this.get("/enriched", filter);
  }

  quickSearch(query: string): Observable<ApiResponse<Global>> {
    return this.get(`/global/${query}`);
  }

  quickCompaniesOnlySearch(query: string): Observable<ApiResponse<BasicCompany[]>> {
    return this.get(`/global/v2/${query}`);
  }

  getBulkCompaniesFromFile(file: File): Observable<ApiResponse<BasicCompany[]>> {
    const body = new FormData();
    body.append("file", file, file.name);
    return this.post("/bulk-companies/file", null, body);
  }
}
