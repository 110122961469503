<div class="main-layout">
  <bpc-mobile-navigation />
  <div class="content-wrapper">
    <div class="content">
      <div class="router-outlet-container">
        <router-outlet></router-outlet>
        <span *ngIf="isSafari" class="bottom-spacing"></span>
      </div>
    </div>
  </div>
</div>
<bpc-generic-modal></bpc-generic-modal>
