<header class="primary-header">
  <div class="fluid-container">
    <div class="grid align-items-center grid-no-tb-gutter">
      <div class="col">
        <i 
          [ngClass]="iconClass" 
          (click)="toggleSidebar()"
          aria-label="Open Menu">
        </i>
      </div>
      <div class="col flex align-items-center justify-content-end nav-list">
        <ng-container *ngIf="user$ | async as user">
          <ul class="nav-list">
            <!--
            <li (click)="openTagsSidebar(true)">
              <a pTooltip="Tags" tooltipPosition="bottom" [hideDelay]="500">
                <span class="icon-tag"></span>
              </a>
            </li>
            -->
            <li>
              <a class="mx-4" pTooltip="Support" tooltipPosition="bottom" [hideDelay]="500"
                href="{{ supportService.supportEmail() }}">
                <span class="icon-mail"></span>
              </a>
            </li>
            <li (click)="toggleSidebar()" class="opacity-50">
              <span class="icon-notification" style="position: relative;">
                <!--<span class="absolute text-white-400 bg-red-600 text-sm top-0 notification-count"></span>-->
              </span>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>

  <bpc-meta-tags #metaTag></bpc-meta-tags>
  <div class="sidebar-container">
     <p-sidebar [(visible)]="isSidebarVisible" position="left" [baseZIndex]="1001">
      <ul class="sidebar-menu">
        <li *ngFor="let item of menuItems">
          <a (click)="item.command && item.command()" [routerLink]="item.route" [ngClass]="{ 'active': currentRoute === item.route}">{{ item.label }}</a>
          <i *ngIf="item.icon" class="pi pi-chevron-right" [ngClass]="{ 'active': currentRoute === item.route}"></i>
          <hr />
        </li>
      </ul>
      <ul class="logout-section">
        <li>
          <a (click)="onLogout()" [routerLink]="'/login'">Logout</a>
          <i class="pi pi-sign-out"></i>
        </li>
      </ul>
    </p-sidebar>
  </div>
  
</header>
