import { Pipe, PipeTransform } from '@angular/core';

import { ModalActions } from '../../models/types';
import { Nullable } from '@models/nullable';

@Pipe({
  name: 'positiveButtonLabel'
})
export class PositiveButtonLabelPipe implements PipeTransform {

  transform(modalAction: ModalActions): Nullable<string> {
    switch (modalAction) {
      case 'joinCoverage':
      case 'removeFromJarvis':
      case 'markAsActive':
      case 'addToPriority':
      case 'extendPriority':
      case 'removeSavedSearch':
      case 'removeCronJob':
      case 'closeCompanyDetails':
      case 'UserInactiveToggle':
      case 'UserActiveToggle':
      case 'DeleteChatThread':
        return 'Yes';
      case 'icVoting':
        return 'Vote now';
      case 'changeStatus':
        return 'Change status';
      case 'updateCronJob':
      case 'editCoverage':
      case 'createOrEditNotificationTrigger':
      case 'AddUser':
      case 'addToPortfolio':
      case 'EditUser':
        return 'Save';
      case 'removeFromPriority':
        return 'Record action';
      case 'actionTaken':
        return 'Record action';
      case 'enrichData':
        return 'Yes, send for data enrichment';
      case 'flagForICVoting':
        return 'Flag company';
      case 'removeFromIC':
        return 'Remove';
      case 'editICAsk':
      case 'editICDateAdded':
        return 'Update';
      case 'editOutReachOwner':
        return 'Update';
      case 'editNextStep':
      case 'UpdateChatThread':
        return 'Update';
      case 'archiveCompany':
        return 'Move';
      case 'leaveCoverage':
        return 'Release';
      case 'editInvestors':
        return 'Update';
    }
  }
}