import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { Interaction, InteractionType, Investor, InvestorRole, WorkFlowStatusId } from '@core/models/auxiliary-company-models';
import { Company } from '@core/models/company';
import { IdNamePair, MainFilterRaw } from '@core/models/main-filter-view';
import { Nullable } from '@core/models/nullable';
import { InvestorTab, Tab } from '@core/models/types';
import { InvestorRolePipe } from '@core/pipes/investor-role.pipe';
import { ColumnLabel, TabData, TabType, TableHeader, TableHeaderSortIcon } from '@core/static/tabs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BasePagingFilter, BaseSortingFilter } from 'src/app/services/companies-search/companies-search.service';
import { mergeMapWithShareReplayableProjections } from "../../rxjs/mergeMapWithShareReplayableProjections";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  fromEvent,
  map,
  merge,
  mergeAll,
  MonoTypeOperatorFunction,
  Observable,
  pipe,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttle,
  timer,
} from "rxjs";
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { CompanyTypeService } from '@core/services/company-type.service';
import { CompanyType } from '@core/models/company-type';
import { getActiveICVotingWorkflow, getActiveOrLastICVotingWorkflow, getCurrentUserVotingComment, getCurrentUserVotingScore } from 'src/app/utils/company-utils';
import { VotingService } from '@core/services/voting.service';
import { MenuItem } from '@shared/components/menu-native/menu-native-item';
import { AuthService } from '@core/auth/auth.service';
import { SavedSearchService } from 'src/app/services/companies-search/saved-search.service';
import { TabUtilsService } from 'src/app/services/tab-utils.service';
import { UserRole } from '@core/models/user';
import { ChromeExtensionService } from 'src/app/services/chrome-extension/chrome-extension.service';
import { createNotificationTriggerModal, iterateSortDirection } from 'src/app/utils/tab-utils';
import { ModalUtilsService } from 'src/app/services/modal-utils.service';
import moment from 'moment';
import { NotificationTrigger } from '@core/models/notifications';
import { getFullTimeEquivalentGrowthTrigger, getFullTimeEquivalentTrigger, getMonthlyTrigger, getOneTimeTrigger, getPaidInCapitalTrigger, getRevenueTrigger, getWeeklyTrigger } from 'src/app/utils/notification-utils';
import { TimeBasedTriggerOccurrence } from '@core/directives/dropdown/time-based-trigger-occurrences.directive';
import { FrequenyType, ModalParameters } from '../generic-modal/generic-modal.component';
import { Table } from 'primeng/table';
import { convertCronExpressionToTime } from 'src/app/utils/utils';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';
import { UsersService } from 'src/app/services/users/users.service';
import { MessageService } from 'primeng/api';
import { SocialLink } from '@core/models/social-link';
import { MixpanelService, SocialLinkType } from '@core/services/mixpanel.service';

@Component({
  selector: 'bpc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() i!: number;
  currentTabData!: Company[];
  @Input()
  set _currentTabData(value: Company[]) {
    this.currentTabData = value;
    this.selectedItems = [];
    this.selectedItemsEmitter.emit(this.selectedItems);
  }
  @Input() currentTab!: TabData<Company>;
  @Input() loading: boolean = false;
  @Input() mainFilterRaw!: MainFilterRaw;
  @Input() companyDetails!: Nullable<Company>;
  @Input() isInvestmentCommitteeAdmin!: boolean;
  @Input() tab!: TabData<Company>;
  @Input() tabs!: Record<UserRole, TabData<Company>[]>;
  @Input() companyDetailsLoading!: boolean;
  // @Input() menuItems!: MenuItem[];

  @Output() onColumnSortClickEmitter = new EventEmitter();
  @Output() applyMainFilterRawEmitter = new EventEmitter();
  @Output() editOutReachOwnerEmitter = new EventEmitter();
  @Output() editNextStepEmitter = new EventEmitter();
  @Output() editICDateEmitter = new EventEmitter();
  @Output() voteForCompanyEmitter = new EventEmitter();
  @Output() showNotificationTriggersAlertEmitter = new EventEmitter();
  @Output() removeSavedSearchEmitter = new EventEmitter();
  @Output() removeFromICEmitter = new EventEmitter();
  @Output() moveCompanyToArchiveEmitter = new EventEmitter();
  @Output() menuClickEmitter = new EventEmitter();
  @Output() selectedItemsEmitter = new EventEmitter<Company[]>();

  selectedItems: Company[] = [];
  companyInteractionsSubject = new Subject<number>();
  interactionLoading = true;
  companyInteractions = new Map<number, { meetings: Nullable<Interaction>; mail: Nullable<Interaction> }>();
  companyTypes: CompanyType[] = [];
  skeletonRowCount: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  selectedCompany!: Company;
  displayModal: boolean = false;
  @ViewChild("dataTable") dataTable!: Table;

  menuItems: MenuItem[] = [{
    label: "Delete",
    command: () => {
      if (this.selectedCompany) {
        this.removeFromIC(this.selectedCompany);
      }
    },
  },
  {
    label: "Archive",
    command: () => {
      if (this.selectedCompany) {
        this.moveCompanyToArchive(this.selectedCompany);
      }
    },
  }];

  get showAllUsers(): boolean {
    return this.mainFilterRaw.showAllUsers === "true";
  }

  set showAllUsers(value: boolean) {
    let mainFilterRaw: MainFilterRaw;
    if (value) {
      mainFilterRaw = {
        ...this.mainFilterRaw,
        showAllUsers: "true",
      };
    } else {
      mainFilterRaw = { ...this.mainFilterRaw };
      delete mainFilterRaw.showAllUsers;
    }

    this.tabUtilsService.navigateToCurrentTab(mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }

  get interactionType(): typeof InteractionType {
    return InteractionType;
  }

  get availableGridColumns(): ColumnLabel[] {
    return this.currentTab.columns!.map((column) => column.label);
  }

  get columns(): TableHeader[] {
    return this.currentTab.columns!;
  }

  get tableClass(): string {
    const classes = ["main-grid-table"];
    this.loading && classes.push("loading");
    return classes.join(" ");
  }

  get isVotingTab(): boolean {
    return this.currentTabIndex === InvestorTab.VOTING;
  }

  get canHaveNeutral(): boolean {
    return (
      this.currentTabIndex !== InvestorTab.COMPANIES &&
      this.currentTabIndex !== InvestorTab.ELEVATIONS &&
      this.currentTabIndex !== InvestorTab.VOTING
    );
  }
  get sortProperty(): string | undefined {
    return this.mainFilterRaw.sortProperty;
  }

  get defaultSortProperty(): string | undefined {
    switch (this.currentTabIndex) {
      case InvestorTab.COMPANIES:
        return "rank";
      case InvestorTab.ELEVATIONS:
        return "name";
      case InvestorTab.VOTING:
        return "votingDate";
      case InvestorTab.PIPELINE:
        return "status";
      default:
        return;
    }
  }

  get sortDirection(): "asc" | "desc" | undefined {
    switch (this.mainFilterRaw.sortDirection?.toLowerCase()) {
      case "asc":
        return "asc";
      case "desc":
        return "desc";
      default:
        return;
    }
  }

  get defaultSortDirection(): "asc" | "desc" | undefined {
    switch (this.currentTabIndex) {
      case InvestorTab.COMPANIES:
      case InvestorTab.ELEVATIONS:
      case InvestorTab.VOTING:
        return "desc";
      case InvestorTab.PIPELINE:
        return "asc";
      default:
        return;
    }
  }

  get baseFilter(): BasePagingFilter & BaseSortingFilter {
    return {
      sortProperty: this.sortProperty ?? this.defaultSortProperty,
      sortDirection: this.sortDirection ?? this.defaultSortDirection,
      pageSize: this.pageSize.toString(10),
      pageNumber: this.page.toString(10),
    };
  }

  get page(): number {
    return +(this.mainFilterRaw.pageNumber ?? "1");
  }

  set page(value: number) {
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageNumber: value.toString(10) }, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  get pageSize(): number {
    return +(this.mainFilterRaw.pageSize ?? "100");
  }

  get currentPageTotal(): string {
    let currentPageLastCompanyIndex = ((this.page - 1) * this.pageSize) + this.currentTabData.length;
    let currentPageFirstCompanyIndex = ((this.page - 1) * this.pageSize) + 1;
    if (currentPageLastCompanyIndex === 0) {
      currentPageFirstCompanyIndex = 0;
    }
    return `${currentPageFirstCompanyIndex}-${currentPageLastCompanyIndex}`;
  }

  get disableFirstPage(): boolean {
    return this.page === 1;
  }

  get currentTotalLabel(): Nullable<string> {
    const currentTotal = this.currentTotal(this.currentTabIndex);
    return currentTotal ? `${currentTotal} Companies` : `0 Companies`;
  }

  get currentTotalPages(): number {
    return Math.ceil(this.currentTotal(this.currentTabIndex) / this.pageSize) || 1;
  }

  get tabType(): typeof TabType {
    return TabType;
  }

  constructor(private deviceDetectorService: DeviceDetectorService,
    private investorRolePipe: InvestorRolePipe,
    private companiesService: CompaniesService,
    private companyTypeService: CompanyTypeService,
    private votingService: VotingService,
    public authService: AuthService,
    public savedSearchService: SavedSearchService,
    public tabUtilsService: TabUtilsService,
    public chromeExtensionService: ChromeExtensionService,
    public modalUtilsService: ModalUtilsService,
    public userRolesService: UserRolesService,
    public userGroupsService: UserGroupsService,
    public usersService: UsersService,
    public messageService: MessageService,
    public mixPanelService: MixpanelService
  ) {

    this.getCompanyTypes();

  }

  ngOnInit(): void {

    if (!this.isExtension) {
      this.initializeCompanyInteractionsFetching();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes["currentTabIndex"]){
      if (!this.companyDetailsLoading) this.firstPage();
    }
  }

  getUserRoles(roles: any) {
    const roleIds = roles.map((role: any) => {
      return role.roleId;
    });
    const roleNames = this.userRolesService.userRoles.filter((userRole: any) => {
      return roleIds.includes(userRole.roleId);
    }).map((role) => role.name);
    return roleNames;
  }

  getUserGroups(groups: any) {
    const groupIds = groups.map((group: any) => {
      return group.groupId;
    });
    const groupNames = this.userGroupsService.userGroups.filter((userGroup: any) => {
      return groupIds.includes(userGroup.id) && userGroup.groupType === 2;
    }).map((group) => group.name);
    return groupNames;
  }

  getUserPods(groups: any) {
    const groupIds = groups.map((group: any) => {
      return group.groupId;
    });
    const groupNames = this.userGroupsService.userGroups.filter((userGroup: any) => {
      return groupIds.includes(userGroup.id) && userGroup.groupType === 1;
    }).map((group) => {
      const sortedPodMembers = group.members.sort((a: any, b: any) => {
        return b.isAdmin - a.isAdmin;
      });
      group.name = "Pod: " + sortedPodMembers.map((member: any) => member.initials).join(", ");
      return group.name;
    });
    return groupNames;
  }

  get isExtension(): boolean {
    return this.chromeExtensionService.isExtension.getValue();
  }

  getCompanyTypes() {
    this.companyTypeService.companyTypes$.subscribe((response) => {
      this.companyTypes = response.data ?? [];
    });
  }

  getCompanyTypeName(companyId: number) {
    return this.companyTypes.find(x => x.id === companyId)?.name;

  }

  fetchInteractionsData({ id }: Company) {
    this.companyInteractionsSubject.next(id);
  }

  protected initializeCompanyInteractionsFetching() {
    this.companyInteractionsSubject
      .pipe(
        mergeMapWithShareReplayableProjections((companyId) =>
          this.companiesService
            .getInteractions(companyId)
            .pipe(tap(() => (this.interactionLoading = true)))
            .pipe(
              map((interactionsResponse) => [companyId, interactionsResponse.data ?? []] as [number, Interaction[]])
            )
        )
      )
      .subscribe(([companyId, interactions]) => {
        this.interactionLoading = false;
        if (!this.companyInteractions.has(companyId)) {
          interactions.sort((a, b) => Date.parse(b.interactionDate) - Date.parse(a.interactionDate));
          const meetings =
            interactions.find(({ interactionType }) => interactionType !== InteractionType.Email) ?? null;
          const mail = interactions.find(({ interactionType }) => interactionType === InteractionType.Email) ?? null;
          this.companyInteractions.set(companyId, { meetings, mail });
        }
      });
  }

  trackBy(index: number, item: Company) {
    return item.id;
  }

  onColumnSortClick(column: TableHeader) {
    const newFilter = iterateSortDirection(this.mainFilterRaw, column, this.canHaveNeutral);

    this.onColumnSortClickEmitter.emit(newFilter);
  }

  getSortClass(column: TableHeader): TableHeaderSortIcon {
    if (column.sortProperty === this.mainFilterRaw.sortProperty) {
      switch (this.mainFilterRaw.sortDirection?.toLowerCase()) {
        case "asc":
          return "fa-sort-down";
        case "desc":
          return "fa-sort-up";
        default:
          return "fa-sort";
      }
    }

    return "fa-sort";
  }

  extractInvestors(investors: Investor[]): Investor[] {
    const leadInvestors = investors.filter((investor) => investor.dominantRole === InvestorRole.LEAD);
    const otherInvestors = investors.filter((investor) => investor.dominantRole !== InvestorRole.LEAD);
    return [...leadInvestors, ...otherInvestors];
  }

  investorRole(role: InvestorRole) {
    return this.investorRolePipe.transform(role);
  }

  setInvestorFilter(investor: Investor) {
    // const defaultFilter = Object.assign(this.baseFilter, this.filterHelper.getDefaultFilter(this.currentTabIndex));
    const defaultFilter = Object.assign(this.baseFilter);
    const investorPairInclude: IdNamePair[] = [{ id: investor?.id, name: investor.name }];
    const newFilterObj = {
      ...defaultFilter,
      investorsInclude: investor?.id,
      rawValueMapping: JSON.stringify({ investorsInclude: investorPairInclude }),
    }
    const values = {
      filters: newFilterObj,
      route: ["companies"],
    }
    this.applyMainFilterRawEmitter.emit(values);
  }

  showVotingStats(company: Company) {
    this.votingService.company = company;
    const workflow = getActiveOrLastICVotingWorkflow(company.workflows);
    if (workflow?.id) {
      this.votingService.showVotingStats(
        workflow.id,
        company.name,
        `${company.address?.city},  ${company.address?.country.name}`
      );
    }
  }

  applySavedSearch(id: number) {
    this.savedSearchService.getSavedSearchById(id).subscribe((res: any) => {
      if (res.data) {
        const filterData = JSON.parse(res.data.savedFilterData);
        this.mainFilterRaw = { ...filterData, savedSearchId: res.data.id };
        if (filterData.filters && filterData.filters.length > 0) {
          this.mainFilterRaw.filtersString = JSON.stringify(filterData.filters);
        }
        delete this.mainFilterRaw["filters"];
        // this.isExpanding = true;
        this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex, [res.data.filterTypeId === 1 ? "/companies" : "/pipeline"]);
      }
    });
  }

  //this function is commented out temporarily and needs to be corrected as per the data flow.

  onContactsClick(company: Company) {
    this.tabUtilsService.navigateToCompany(company, false, "relationship");
  }

  nextDay(): Date {
    return moment().add(1, "day").startOf("day").toDate();
  }

  getCurrentNotificationTriggers(selectedCompany: Nullable<Company>): NotificationTrigger[] {
    return selectedCompany?.notificationTriggers ?? this.companyDetails?.notificationTriggers ?? [];
  }

  setUpNotificationTriggers(modalParameters: ModalParameters) {
    const triggers = this.getCurrentNotificationTriggers(modalParameters.selectedCompany);
    let trigger: NotificationTrigger | undefined = getOneTimeTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.OneTime;
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.triggerOn = moment(trigger.dateValue).toDate();
    }

    trigger = getWeeklyTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
      modalParameters.recurring = true;
      modalParameters.recurringWeekly = trigger.dayOfValue;
    }

    trigger = getMonthlyTrigger(triggers);

    if (trigger) {
      modalParameters.timeBasedChecked = trigger.isActive;
      modalParameters.timeBasedTriggerType = TimeBasedTriggerOccurrence.Recurring;
      modalParameters.recurring = false;
      modalParameters.recurringMonthly = trigger.dayOfValue;
    }

    trigger = getFullTimeEquivalentTrigger(triggers);
    if (trigger) {
      modalParameters.fteTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getFullTimeEquivalentGrowthTrigger(triggers);
    if (trigger) {
      modalParameters.fteGrowthTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getPaidInCapitalTrigger(triggers);
    if (trigger) {
      modalParameters.picTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }
    trigger = getRevenueTrigger(triggers);
    if (trigger) {
      modalParameters.revenueTrigger = createNotificationTriggerModal(trigger.isActive, trigger.operator, trigger.scalarValue);
    }

    return modalParameters;
  }

  showNotificationTriggersAlert(company?: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    if (company) {
      modalParameters.selectedCompany = company;
    }
    modalParameters.currentModalAction = "createOrEditNotificationTrigger";
    modalParameters.minTriggerDate = this.nextDay();
    modalParameters = this.setUpNotificationTriggers(modalParameters);

    this.modalUtilsService.toggalModal.next(modalParameters);

    // this.showNotificationTriggersAlertEmitter.emit(company);
  }

  //this function is commented out temporarily and needs to be corrected as per the data flow.
  onActivityClick(company: Company) {
    this.tabUtilsService.navigateToCompany(company, false, "activity-data");
  }

  editOutReachOwner(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "editOutReachOwner";
    modalParameters.selectedCompany = company;
    modalParameters.outreachOwner = company.outreachOwner;

    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.editOutReachOwnerEmitter.emit(company);
  }

  editStage(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "changeStatus";
    modalParameters.selectedCompany = company;
    modalParameters.mainFilterRaw = this.mainFilterRaw;
    modalParameters.tabs = this.tabs;
    modalParameters.currentTabIndex = this.currentTabIndex;
    modalParameters.currentChildIndex = this.currentChildIndex;
      
    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.editOutReachOwnerEmitter.emit(company);
  }

  editInvestors(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "editInvestors";
    modalParameters.selectedCompany = company;
    modalParameters.mainFilterRaw = this.mainFilterRaw;
    modalParameters.tabs = this.tabs;
    modalParameters.currentTabIndex = this.currentTabIndex;
    modalParameters.currentChildIndex = this.currentChildIndex;
    modalParameters.investors = company.investorsDetails;
    modalParameters.previousInvestors = company.investorsDetails;
    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.editOutReachOwnerEmitter.emit(company);
  }

  editCoverage(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "editCoverage";
    modalParameters.selectedCompany = company;
    modalParameters.mainFilterRaw = this.mainFilterRaw;
    modalParameters.tabs = this.tabs;
    modalParameters.currentTabIndex = this.currentTabIndex;
    modalParameters.currentChildIndex = this.currentChildIndex;
    modalParameters.coverageUsers = []
    company.coverageUsers?.forEach(coverageUser=>{
      modalParameters.coverageUsers?.push(coverageUser);
    })
    modalParameters.teamMembers = [];
    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.editOutReachOwnerEmitter.emit(company);
  }

  editNextStep(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "editNextStep";
    modalParameters.selectedCompany = company;
    modalParameters.nextStep = company.nextStep;

    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.editNextStepEmitter.emit(company);
  }

  // need to test this functionality on UI
  editICDateAdded(company: Company) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "editICDateAdded";

    if (this.isInvestmentCommitteeAdmin) {
      modalParameters.selectedCompany = company;
      const voteWorkflow = getActiveICVotingWorkflow(
        company.workflows,
        this.currentChildIndex === 0 ? WorkFlowStatusId.InReview : WorkFlowStatusId.Complete
      );
      if (voteWorkflow) {
        modalParameters.dateAdded = moment(voteWorkflow!.votingDate!).toDate();
        modalParameters.companyWorkFlowId = voteWorkflow!.id!;
        this.modalUtilsService.toggalModal.next(modalParameters);

      }
    }

    // this.editICDateEmitter.emit(company);
  }

  voteForCompany(company: Company) {

    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "icVoting";
    modalParameters.selectedCompany = company;
    modalParameters.icVote = getCurrentUserVotingScore(company.workflows);
    modalParameters.comment = getCurrentUserVotingComment(company.workflows);

    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.voteForCompanyEmitter.emit(company);
  }

  removeSavedSearch(id: number) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "removeSavedSearch";
    modalParameters.savedSearchId = id;
    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.removeSavedSearchEmitter.emit(id);
  }

  removeCronJob(id: string) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "removeCronJob";
    modalParameters.selectedJobId = id;
    this.modalUtilsService.toggalModal.next(modalParameters);
  }

  removeFromIC(company: Company) {

    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "removeFromIC";
    modalParameters.selectedCompany = company;
    modalParameters.tabs = this.tabs
    this.modalUtilsService.toggalModal.next(modalParameters);
    // this.removeFromICEmitter.emit(company);
  }

  moveCompanyToArchive(company: Company) {

    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = "archiveCompany";
    modalParameters.selectedCompany = company;

    this.modalUtilsService.toggalModal.next(modalParameters);

    // this.moveCompanyToArchiveEmitter.emit();
  }

  onMenuClick(company: Company) {
    this.selectedCompany = company;
    // this.menuClickEmitter.emit(company);
  }

  usePagination(tab: TabData<Company>): boolean {
    if (tab.children && this.currentChildIndex !== null) {
      return tab.children ? this.tabUtilsService.children(tab)[this.currentChildIndex]?.usePagination ?? false : tab?.usePagination ?? false;
    }
    else {
      return tab?.usePagination ?? false;
    }
  }

  currentTotal(page: number): number {
    return (
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].children?.[this.currentChildIndex!]?.currentTotal ??
      this.tabUtilsService.getAvailableUserTabs(this.tabs)[page].currentTotal!
    );
  }

  firstPage() {
    this.page = 1;
    this.scrollToTop();
  }

  scrollToTop() {
    if (this.dataTable) {
      this.dataTable.scrollTo({ top: 0 }); // Scroll to top
    }
    scrollTo(0, 0);
  }

  previousPage() {
    if (this.page > 1) {
      this.page--;
      this.scrollToTop();
    }
  }

  goToPage(pageNumber: number) {
    if (pageNumber <= this.currentTotalPages) {
      this.page = pageNumber;
      this.scrollToTop();
    }
  }

  get disableLastPage(): boolean {
    return this.page === this.currentTotalPages;
  }

  nextPage() {
    if (this.page < this.currentTotalPages) {
      this.page++;
      this.scrollToTop();
    }
  }

  lastPage() {
    this.page = this.currentTotalPages;
    this.scrollToTop();
  }

  pageSizeChanged(pageSize: number) {
    this.scrollToTop();
    this.tabUtilsService.navigateToCurrentTab({ ...this.mainFilterRaw, pageSize: pageSize.toString(10), pageNumber: "1" }, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  onSelection() {
    this.selectedItemsEmitter.emit(this.selectedItems);
  }

  updateCronJob(company: any) {
    let modalParameters = this.modalUtilsService.modalParameters;
    modalParameters.currentModalAction = 'updateCronJob';
    modalParameters.selectedJobId = company.id;
    const convertedExpression = convertCronExpressionToTime(company);
    modalParameters.frequency = convertedExpression.frequency || undefined;
    modalParameters.selectedDayOfWeek = convertedExpression.selectedDayOfWeek || undefined;
    modalParameters.selectedDayOfMonth = convertedExpression.selectedDayOfMonth || undefined;
    modalParameters.selectedTime = convertedExpression.selectedTime;
    this.modalUtilsService.toggalModal.next(modalParameters);
  };

  addOrEditUser(user: any = null) {
    let modalParameters = this.modalUtilsService.modalParameters;
    if (user) {
      modalParameters.currentModalAction = 'EditUser';
      modalParameters.name = user.name;
      modalParameters.initials = user.initials;
      modalParameters.email = user.email;
      modalParameters.externalId = user.externalId;
      modalParameters.base64Image = user.profilePictureUrl ?? '';
      const userGroupIds = user.userGroups.map((group: any) => {
        return group.groupId;
      });
      const userRoleIds = user.userRoles.map((role: any) => {
        return role.roleId;
      });
      const userPodsIds = user.userGroups.map((group: any) => {
        return group.groupId;
      });
      const selectedGroups = this.userGroupsService.userGroups
        .filter((group) => {
          return userGroupIds.includes(group.id);
        });
      modalParameters.externalId = user.externalId;
      modalParameters.userGroups = selectedGroups;
      const selectedRoles = this.userRolesService.userRoles
        .filter((role) => {
          return userRoleIds.includes(role.roleId)
        });
      modalParameters.userRoles = selectedRoles;
      modalParameters.userId = user.id;
      modalParameters.isActive = user.isActive;
    }
    else {
      modalParameters.currentModalAction = 'AddUser';
    }

    this.modalUtilsService.toggalModal.next(modalParameters);
  };

  handleUserStatus(status: any, user: any) {
    let modalParameters = this.modalUtilsService.modalParameters;
    if (status.checked) {
      modalParameters.currentModalAction = 'UserActiveToggle';
    } else {
      modalParameters.currentModalAction = 'UserInactiveToggle';
    }
    modalParameters.userId = user.id;
    modalParameters.userStatus = status.checked;
    this.modalUtilsService.toggalModal.next(modalParameters);
    this.tabUtilsService.navigateToCurrentTab(this.mainFilterRaw, this.tabs, this.currentTabIndex, this.currentChildIndex);
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  getCronFrequency(company: any): string | null {
    const convertedExpression = convertCronExpressionToTime(company);
    if (convertedExpression.frequency === FrequenyType.Daily) {
      return ', Daily';
    }
    else return null;
  }
  socialUrlClick(event: SocialLink) {
    if (event) {
      const socialLinkType = SocialLinkType[event.type];
      this.mixPanelService.trackEvent("Company_Details_Social_Link_Clicked", {
        companyName: this.companyDetails?.name,
        socialLinkType: socialLinkType,
        isExtension: this.isExtension,
      });
    }
  };
}
