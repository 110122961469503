import { Pipe, PipeTransform } from '@angular/core';
import { WorkFlow } from '@models/auxiliary-company-models';
import { Nullable } from '@models/nullable';
import { getActiveOrLastICVotingWorkflow } from '../../../utils/company-utils';

@Pipe({
  name: 'commentsSummary'
})
export class CommentsSummaryPipe implements PipeTransform {

  transform(workflows: WorkFlow[]): Nullable<string> {
    return getActiveOrLastICVotingWorkflow(workflows)?.summary ?? "No summary generated.";
  }
}
