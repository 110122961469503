import { Component, ViewChild } from "@angular/core";
import { MenuItem } from "../../../shared/components/menu-native/menu-native-item";
import { AuthService } from "../../../core/auth/auth.service";
import { Router } from "@angular/router";
import { SupportService } from "../../../core/services/support.service";
import { MetaTagsComponent } from "../meta-tags/meta-tags.component";
import { Location } from "@angular/common";

@Component({
  selector: "bpc-mobile-navigation",
  templateUrl: "./mobile-navigation.component.html",
  styleUrl: "./mobile-navigation.component.scss",
})
export class MobileNavigationComponent {
  user$ = this.authService.user;
  currentRoute: string = "";
  searchQuery = "";
  isSidebarVisible: boolean = false;
  @ViewChild("metaTag") metaTag!: MetaTagsComponent;

  constructor(
    private authService: AuthService,
    private route: Router,
    public supportService: SupportService,
    private location: Location,
  ) {
    this.route.events.subscribe((event) => {
      this.currentRoute = this.route.url;
    });
  }

  get iconClass() {
    if (this.isSidebarVisible) return "pi pi-times";
    if (this.currentRoute.indexOf("m/companies/details") > -1) return "pi pi-chevron-left";

    return "pi pi-bars";
  }

  menuItems: MenuItem[] = [
    {
      label: "Companies",
      icon: "pi-sitemap",
      route: "/m/companies",
      command: () => {
        this.toggleSidebar();
      },
    },
    {
      label: "Interactions",
      icon: "pi-share-alt",
      route: "/m/companies/interactions",
      command: () => {
        this.toggleSidebar();
      },
    },
    {
      label: "Tech Analyst",
      icon: "pi-user",
      route: "/m/chatbot",
      command: () => {
        this.toggleSidebar();
      },
    },
    {
      label: "Updates",
      icon: "pi-user",
      route: "/m/weekly-update",
      command: () => {
        this.toggleSidebar();
      },
    },
  ];

  toggleSidebar() {
    if (this.currentRoute.indexOf("m/companies/details") > -1) {
      this.location.back();
      return;
    }

    this.isSidebarVisible = !this.isSidebarVisible;
  }

  openTagsSidebar(val?: boolean) {
    this.metaTag.showTags();
  }

  onLogout() {
    this.authService.logout();
  }
}
