export type ModalActions =
  | 'joinCoverage'
  | 'enrichData'
  | 'removeFromJarvis'
  | 'addToPriority'
  | 'changeStatus'
  | 'editCoverage'
  | 'removeFromPriority'
  | 'actionTaken'
  | 'closeCompanyDetails'
  | 'extendPriority'
  | 'icVoting'
  | 'flagForICVoting'
  | 'removeFromIC'
  | 'archiveCompany'
  | 'editICAsk'
  | 'leaveCoverage'
  | 'markAsActive'
  | 'createOrEditNotificationTrigger'
  | 'editICDateAdded'
  | 'editOutReachOwner'
  | 'editNextStep'
  | 'removeSavedSearch'
  | 'updateCronJob'
  | 'removeCronJob'
  | 'EditUser'
  | 'AddUser'
  | 'UserActiveToggle'
  | 'UserInactiveToggle'
  | 'DeleteChatThread'
  | 'UpdateChatThread'
  | 'editInvestors'
  | 'addToPortfolio'
  ;

export type Tab = AdminTab | InvestorTab | AnalystTab;

export enum AdminTab {
  COMPANIES,
  ELEVATIONS,
  VOTING,
  DEEP_CHAT,
  WEEKLY_UPDATE,
  DASHBOARD,
  ADMIN_PANEL,
  // this is done to remove priority tab and fix tabs routing for it
  PRIORITY,  
  SAVED_SEARCH,
  AI_SUMMARY,
  PIPELINE,
}

export enum InvestorTab {
  COMPANIES,
  ELEVATIONS,
  VOTING,
  DEEP_CHAT,
  WEEKLY_UPDATE,
  DASHBOARD,
  ADMIN_PANEL,
  // this is done to remove priority tab and fix tabs routing for it
  PRIORITY,
  SAVED_SEARCH,
  AI_SUMMARY,
  PIPELINE,
}

export enum AnalystTab {
  COMPANIES,
// // AI_SUMMARY,
// DATA_QUALITY,
  // PORTFOLIO_UPDATE,
  SAVED_SEARCH = 2,
}

export enum Prompt {
  COMPANY_DESCRIPTION,
  MARKET_OVERVIEW,
  KEY_COMPETITORS,
  INVESTMENT_THESIS,
  QUESTIONS_FOR_CEO,
  PRODUCT,
  LEADERSHIP,
  VOTING_SUMMARY,
  EXTENSION
}