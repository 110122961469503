import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GenericPopupService } from 'src/app/services/generic-popup/generic-popup.service';
import { GenericPopupOperation } from '@core/models/generic-popup-enums';
import { GenericPopupParameters } from '@core/models/generic-popup-models';

@Component({
  selector: 'bpc-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrl: './generic-popup.component.scss'
})
export class GenericPopupComponent implements OnDestroy {
  @Input() visible: boolean = false;

  @Output() toggleVisible = new EventEmitter<boolean>();

  header: string = "";
  operationStatus: boolean = false;
  positiveButtonDisabled: boolean = false;

  private subscription: Subscription | undefined;

  get operation(): GenericPopupOperation {
    return this.genericPopupService.operation;
  }

  get parameters(): GenericPopupParameters {
    return this.genericPopupService.parameters;
  }

  constructor(private genericPopupService: GenericPopupService) {
    this.genericPopupService.toggleGenericPopup$.subscribe((data) => {
      this.setupPopup();
    });
  }

  ngOnInit() {}

  setupPopup() {
    this.positiveButtonDisabled = false;
    this.subscription = this.genericPopupService.callComplete$.subscribe((data) => {
      this.positiveButtonDisabled = false;
      this.toggleVisible.emit(true);
      this.operationStatus = false;
      this.unsubscribeFromService();
    });
    switch (this.operation) {
      case GenericPopupOperation.SendIcWorkflowEmail:
        {
          this.header = 'Are you sure you would like to send a summary of IC and voting comments to the deal team?';
          break;
        }
      case GenericPopupOperation.DeletePortfolioCompany:
        {
          this.header = 'Are you sure you would to delete this portfolio company?';
          break;
        }
    }
  }

  onNegativeClick() {
    this.unsubscribeFromService();
    this.toggleVisible.emit(false);
  }

  onPositiveClick() {
    this.operationStatus = true;
    this.positiveButtonDisabled = true;

    this.genericPopupService.positiveCall(this.operation);
  }

  private unsubscribeFromService() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined; 
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromService();
  }
}
