<p-table class="custom-table company-table" [value]="data" [responsiveLayout]="'scroll'" [paginator]="pagination" [rows]="pageSize" [rowsPerPageOptions]="[25, 50, 100]"
    [totalRecords]="totalCount" [sortField]="sortField" [sortOrder]="sortOrder">

    <!-- Table Header -->
    <ng-template pTemplate="header">
        <bpc-grid-loader-line [loading]="loading"></bpc-grid-loader-line>
        <tr>
            <th pFrozenColumn *ngFor="let column of columns" [pSortableColumn]="column.sortable ? column.mappedTo : undefined">
                <span class="whitespace-nowrap">
                <span>{{ column.header | titlecase }}</span>
                <ng-container *ngIf="column.sortable">
                    <span class="fa-solid cursor-pointer ml-2 text-xs" [ngClass]="getSortClass(column)"></span>
                </ng-container>
                </span>
            </th>
        </tr>
    </ng-template>

    <!-- Table Body -->
    <ng-template pTemplate="body" let-row>
        <tr>
            <td *ngFor="let column of columns">
                <!-- Check if value is an array -->
                <ng-container *ngIf="column.case; else normalValues">
                    <div class="name-wrap">
                    <div *ngIf="row[column.mappedTo].socialLinks" class="social-wrap flex">
                        <!--<p-tableCheckbox
                        (click)="onSelection()"
                        class="social-link"
                        *ngIf="currentTab.enableDownload && !isTablet"
                        [value]="company"
                        ></p-tableCheckbox>-->
                        <bpc-social-icon
                            *ngFor="let link of row[column.mappedTo].socialLinks"
                            class="social-links"
                            style="margin-left: 4px;"
                            [socialLink]="link"
                        >
                        </bpc-social-icon>
                    </div>
                    <div class="company-info">
                        <a
                        class="company-link"
                        [routerLink]="['/v2' + row['route']]"
                        [overlayPanelTooltip]="companyDescriptionPanel"
                        >
                        {{ row[column.mappedTo].name }}
                        </a>
                        <span *ngIf="row[column.mappedTo].city" class="company-address">
                        {{ row[column.mappedTo].city }}
                        </span>
                        <p-overlayPanel #companyDescriptionPanel>
                            <div [innerHTML]="row[column.mappedTo].description ? row[column.mappedTo].description : 'No description available'"></div>
                        </p-overlayPanel>
                    </div>
                    </div>
                </ng-container>
                <!-- Fallback for normal value -->
                <ng-template #normalValues>
                    <span class="md:text-xs lg:text-sm" [innerHTML]="row[column.mappedTo]"></span>
                </ng-template>
            </td>
        </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" *ngIf="!loading">
        <tr>
            <td [colSpan]="columns.length">No data available. loader:{{loading}}</td>
        </tr>
    </ng-template>

    <!-- Table Summary -->
    <ng-template pTemplate="summary" *ngIf="pagination">
        <div class="summary">
            <strong>Total Items:</strong> {{ data.length }}
        </div>
    </ng-template>


    <ng-template pTemplate="summary">
        <ng-content></ng-content>
        <!--<bpc-paginator *ngIf="pagination" /> -->
    </ng-template>
</p-table>
