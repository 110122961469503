import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Company } from "@core/models/company";
import { InteractionList } from "@core/models/forms";
import { Stage } from "@core/models/pod-filter";
import { Tab } from "@core/models/types";
import { TabData } from "@core/static/tabs";
import { Nullable } from "primeng/ts-helpers";

@Component({
  selector: "bpc-horizontal-bar",
  templateUrl: "./horizontal-bar.component.html",
  styleUrls: ["./horizontal-bar.component.scss"],
})
export class HorizontalBarComponent {
  @Input() tab!: TabData<Company>;
  @Input() currentTabIndex!: Tab;
  @Input() currentChildIndex!: Nullable<number>;
  @Input() stages: Stage[] = [];
  @Input() interactions: InteractionList[] = [];
  @Input() stageLoading: boolean = true;
  @Input() interactionLoading: boolean = true;

  @Output() onApplyStageEmitter: EventEmitter<Stage> = new EventEmitter<Stage>();
  @Output() onApplyInteractionEmitter: EventEmitter<InteractionList> = new EventEmitter<InteractionList>();

  @Input() useOldVersion = true;
  @Input() data: any = [];
  @Input() barClasses = "";

  selectedItem = "";

  @Output() onSelection: EventEmitter<{}> = new EventEmitter<{}>();

  constructor() {}

  onSelect(e: any) {
    if (this.selectedItem === e.dispalyName) {
      this.selectedItem = "";
      this.onSelection.emit({ value: {} });
      return;
    }

    this.selectedItem = e.dispalyName;
    this.onSelection.emit({ value: e });
  }

  onApplyStatus(stage: Stage) {
    this.onApplyStageEmitter.emit(stage);
  }

  onApplyInteraction(interaction: InteractionList) {
    this.onApplyInteractionEmitter.emit(interaction);
  }
}
