import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalModalService {
  private companyIdSubject = new Subject<string | undefined>();

  companyId$ = this.companyIdSubject.asObservable();
  displayCompanyDetails: boolean = false;

  openCompanyModal(companyId: string) {
    this.companyIdSubject.next(companyId);
    this.displayCompanyDetails = true;
  }

  close() {
    this.companyIdSubject.next(undefined);
    this.displayCompanyDetails = false;
  }
}
