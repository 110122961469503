<div *ngIf="isExtension">
  <div class="wrapper" *ngIf="companyDetails">
    <div class="details-header">
      <div class="grid flex justify-content-between flex-wrap grid-nogutter">
        <div class="col align-items-center">
          <h2 style="font-size: 26px;">
            {{ companyDetails.name }}
          </h2>
        </div>
      </div>
    </div>
    <div class="details-content">
      <div class="grid grid-nogutter info-wrapper" style="margin-bottom:1px">
        <div class="col extention-full-w">
          <div class="grid grid-no-tb-gutter">
              <div style="display: flex; align-items: center;">
                <!-- Social Links -->
                <bpc-social-icon style="padding-right: 8px;"
                  *ngFor="let link of companyDetails | socialLinks"
                  class="social-links"
                  [socialLink]="link"
                  (urlClick)="socialUrlClick($event)"
                >
                </bpc-social-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<div>
  <div *ngIf="isMobile" class="sticky-bar">
    <div class="sticky-bar-left">
      <span>Tech Analyst</span>
    </div>
  </div>
  <div [ngClass]="isExtension ? 'chat-container-extension' : 'chat-container'">
    <div class="sidebar" *ngIf="isMenuOpen && !isExtension">
      <div class="sidebar-header">
        <h2>History  <i class="pi pi-times-circle" [ngClass]="{'display-none': !isMobile}" style="color:#FF6C39; padding-left: 10px;" (click)="toggleMenu()"></i></h2>
      </div>
      <div class="sidebar-messages" [ngClass]="{ 'mobile-height': isMobile }">
        <ul class="chat-history">
          <li
            (click)="handleThreadClick('')"
            class="message"
            [ngClass]="{ selected: '' === threadId }"
            [class.disabled]="loading"
          >
            <p>Create New Conversation</p>
            <span class="icon-edit"></span>
          </li>
          <li
            class="message"
            *ngFor="let message of conversationThread"
            [ngClass]="{ selected: message.ThreadId === threadId }"
            [class.disabled]="loading"
          >
            <p (click)="handleThreadClick(message.ThreadId)">
              {{ message.ThreadTitle || message.ThreadId }}
            </p>
            <bpc-menu-native-primeng [items]="menuItems">
              <span class="pi pi-ellipsis-v p-2 cursor-pointer" (click)="onMenuClick($event, message)"> </span>
            </bpc-menu-native-primeng>
          </li>
        </ul>
      </div>
    </div>
    <div [ngClass]="isMenuOpen && !isExtension ? 'deep-chat-container-mobile' : isExtension ? 'deep-chat-container-extension' : 'deep-chat-container'">
      <deep-chat
        #deepChatRef
        [request]="requestParams"
        stream="true"
        [validateInput]="validateInput"
        [initialMessages]="conversationHistory"
        textInput='{
          "styles": {
              "text": {"font-size": "13.125px", "color": "black", "padding-left": "40px"},
              "container": {"borderRadius": "20px", "border": "1px solid #969696", "padding": "8px 0", "backgroundColor": "#f5f9ff", "width": "calc(100% - 70px)"},
              "focus": {"border": "2px solid #a2a2ff"}
          },
          "placeholder":{
              "text": "Ask me anything...", 
              "styles": {
                "padding-left": "10px"
              }
          }
        }'
        inputAreaStyle='{
          "justify-content": "center"
        }'
        submitButtonStyles='{
          "submit": {
            "container": {
              "default": {"font-size": "17px", "padding": "1px 0 0 0"}, 
              "hover": {"background-color": "transparent"},
              "click": {"background-color": "transparent"}
            }
          }
        }'
        messageStyles='{
            "default": {
              "shared": {"bubble": {"font-size": "13.125px"}},
              "ai": {"bubble": {"backgroundColor": "#e4e6eb", "color": "black", "max-width":"70%"}},
              "user": {"bubble": {"backgroundColor": "#0084ff", "color": "white", "max-width":"70%"}}
            }
          }'
        style="
          background-color: #f8f9fa;
          border-radius: 8px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          width: 100%;
          height: calc(95vh - 164px);
        "
        [style]="{ 'height': isMobile ? 'calc(95vh - 120px) !important' : 'calc(95vh - 164px)' }"
        auxiliaryStyle="
          ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #095A70;
            border-radius: 5px;
          }"
        [avatars]="avatarStyles"
        mixedFiles='{
              "type": true,
              "files": {
                "maxNumberOfFiles": 10,
                "acceptedFormats": ".pdf,.doc,.docx,.html,.txt,.tex,.json,.md,.pptx,.js"
              }, 
              "button": {
                "styles": {
                  "container": {
                    "default": {"font-size": "18px","margin-bottom": "3px", "padding-right": "10px"},
                    "hover": {"background-color": "transparent"},
                    "click": {"background-color": "transparent"}
                  }
                },
                "position": "inside-left"
              }
            }'
        errorMessages='{
              "overrides": {
                "default": "Something went wrong",
                "service": "Error occurred while processing..."
              }
            }'
        attachmentContainerStyle='{"backgroundColor": "#d7d7d73b", "width": "calc(100% - 100px)"}'
      >
        <div style="display: none">
          <div
            *ngIf="conversationLoading"
            style="width: 600px; background-color: #f3f3f3; border-radius: 0px; padding: 12px; padding-bottom: 15px"
          >
            <div style="text-align: center; margin-bottom: 8px; font-size: 16px; color: #ff6c39">
              <b>Loading your Jarvis Chat ...</b>
            </div>
          </div>
        </div>
      </deep-chat>
      <div *ngIf="!isExtension">
        <button class="menu-button" [ngClass]="{ 'display-none': isMenuOpen }" (click)="toggleMenu()">
          <i class="pi pi-bars"></i>
        </button>
      </div>
    </div>
  </div>
</div>
