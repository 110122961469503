import { Injectable } from "@angular/core";
import { OperationResult } from "@core/models/operation-result";
import { MessageService } from "primeng/api";
import { BehaviorSubject, catchError, map, Observable, of, takeUntil } from "rxjs";
import { ApiResponse } from "src/app/api/base";
import { AnalyticsDashboardService } from "../analytics-dashboard/analytics-dashboard.service";
import { RawValueMappingType } from "@core/models/auxiliary-company-models";
import { UsersService } from "../users/users.service";
import { Pod, PodGroupType } from "@core/models/pod-filter";
import { firmPod, TabData } from "@core/static/tabs";
import {
  CompanyInteractionMostActiveUserGraphData,
  CompanyInteractionMostActiveUserGraphDataQuery,
  FilterModel,
  TopIcContributers,
  TopIcContributersDataQuery,
} from "@core/models/analytics-dashboard-models";
import { lastn, topn } from "@core/models/analytics-dashboard-constants";
import { Company } from "@core/models/company";
import { TabUtilsService } from "../tab-utils.service";
import { MainFilterRaw } from "@core/models/main-filter-view";
import { appendToRawValueMapping } from "@core/services/filter-helper.service";
import { InvestorTab } from "@core/models/types";

@Injectable({
  providedIn: "root",
})
export class AnalyticsWidgetService {
  // Behaviour Subjects
  // Dashboard & Widget Initialization
  public dashboardInitializationSubject = new BehaviorSubject<boolean | null>(null);
  public dashboardInitialization$ = this.dashboardInitializationSubject.asObservable();

  // Data Variables
  mostActiveUsersData!: CompanyInteractionMostActiveUserGraphData[];
  topIcContributersData!: TopIcContributers[];
  pods!: Pod[];

  get tabs() {
    return this.tabUtilsService.tabs;
  }

  get icVotingCombinedTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.VOTING];
    return tab.children?.[2] ?? tab;
  }

  get icActiveVotingTab(): TabData<Company> {
    const tab = this.tabUtilsService.getAvailableUserTabs(this.tabs)[InvestorTab.VOTING];
    return tab.children?.[0] ?? tab;
  }

  get mainFilterRaw(): MainFilterRaw {
    return this.icVotingCombinedTab.mainFilterRaw!;
  }

  constructor(
    private messageService: MessageService,
    private analyticsDashboardService: AnalyticsDashboardService,
    private usersService: UsersService,
    private tabUtilsService: TabUtilsService,
  ) {}

  private displayErrorMessage(response: ApiResponse<OperationResult>, defaultErrorMessage: string) {
    this.messageService.add({
      key: "cd",
      severity: "error",
      detail: response.message ?? response?.message ?? defaultErrorMessage,
    });
  }

  protected showErrorToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "error", detail: message, life: 10_000 });
  }

  protected showSuccessToastMessage(message: string) {
    this.messageService.add({ key: "bc", severity: "success", detail: message, life: 5_000 });
  }

  public getTopICContributers(last_n: FilterModel): Observable<any> {
    const query: TopIcContributersDataQuery = {
      days: last_n?.id,
    };

    return this.analyticsDashboardService.getTopICContributers(query).pipe(
      map((response) => {
        if (response?.data) {
          this.topIcContributersData = response.data;
          return response.data;
        } else {
          this.showErrorToastMessage("Top IC contributors leaderboard could not be loaded, please refresh!");
          return null;
        }
      }),
      catchError((error) => {
        this.displayErrorMessage(error, "Error fetching, Please try again!");
        return of(null);
      }),
    );
  }

  getMostActiveUsers(last_n: FilterModel, pod: Pod): Observable<any> {
    const query: CompanyInteractionMostActiveUserGraphDataQuery = {
      days: last_n?.id,
      interactionInitiatorsInclude: pod?.members?.map((member) => member.userId) || [],
    };

    return this.analyticsDashboardService.getMostActiveUsersByCompanyInteractions(query).pipe(
      map((response) => {
        if (response?.data) {
          const categories = response.data.map((item: any) => item.userName);
          const meetingData = response.data.map((item: any) => item.meetingCount);
          const callData = response.data.map((item: any) => item.phoneCount);
          const emailData = response.data.map((item: any) => item.emailCount);
          const videoCallData = response.data.map((item: any) => item.videoCallCount);
          const notesData = response.data.map((item: any) => item.notesCount);

          this.mostActiveUsersData = response?.data;

          return {
            categories: categories,
            meetingData,
            callData,
            emailData,
            videoCallData,
            notesData,
          };
        } else {
          throw new Error("Most active users could not be loaded, please refresh!");
        }
      }),
      catchError((error) => {
        this.displayErrorMessage(error, "Error fetching, Please try again!");
        return of(null);
      }),
    );
  }

  public loadUserIcs(contributor: TopIcContributers, last_n: FilterModel) {
    if (this.icVotingCombinedTab.mainFilterRaw != null) {
      this.icVotingCombinedTab.mainFilterRaw.userIds = [contributor.userId];

      appendToRawValueMapping(this.icVotingCombinedTab.mainFilterRaw, RawValueMappingType.userIds, [
        { id: contributor.userId, name: contributor.name },
      ]);
      this.icVotingCombinedTab.mainFilterRaw.days = last_n.id;
      var rawValueMapping;
      Object.values(lastn).forEach((value) => {
        if (value.id == last_n.id) {
          rawValueMapping = { name: value.name, id: value.id };
        }
      });
      if (rawValueMapping != null) {
        appendToRawValueMapping(this.icVotingCombinedTab.mainFilterRaw, RawValueMappingType.days, [rawValueMapping]);
      }

      this.tabUtilsService.navigateToCurrentTab(
        this.icVotingCombinedTab.mainFilterRaw,
        this.tabs,
        InvestorTab.VOTING,
        2,
      );
    }
  }

  public getPods(): Observable<Pod[]> {
    return this.usersService.getPods().pipe(
      catchError((error) => {
        this.displayErrorMessage(error, "Error getting pods, Please try again!");
        return of(null); // Fallback to null in case of error
      }),
      map((response) => {
        if (response?.data && response.data.length) {
          let pods: Pod[] = response.data
            .filter(({ groupType }) => groupType === PodGroupType.Pod)
            .map((pod) => ({ ...pod }));

          pods = pods.map((pod) => {
            const sortedPodMembers = pod.members.sort((a: any, b: any) => {
              return b.isAdmin - a.isAdmin;
            });
            pod.name = "Pod: " + sortedPodMembers.map((member) => member.initials).join(", ");
            return pod;
          });

          return [firmPod, ...pods]; // Processed pods
        }
        return []; // Return an empty array if no data
      }),
    );
  }

  getDealdropReasons(filters: any) {
    const data = [
      { name: "Misalignment Between Buyer and Seller Expectations", y: 55.02 },
      { name: "Inadequate Due Diligence", y: 26.71 },
      { name: "Changing Market Conditions or Business Environment", y: 1.09 },
      { name: "Lack of Strong Leadership in the Target Company", y: 15.5 },
      { name: "Unresolved Legal or Compliance Issues", y: 1.68 },
    ];

    return of(data);
  }
}
